import React, { useEffect, useState } from "react";
import UseCookies from "../../Helpers/UseCookies";
import "./style.scss";
// -------------Imports---------------
import { FilterMatchMode } from "primereact/api";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Tag } from "primereact/tag";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import { Paginator } from "primereact/paginator";
// -----------------------------------
// ----------Assets Import------------
import editIcon from "../../../src/assets/images/editIcon.jpg";
import categorycsv from '../../assets/files/Category.csv';
// -----------------------------------

//------Imports from other Components-----
import {
  GetDepartmentCategoryConfigurationList,
  AddDepartmentCategoryConfiguration,
  EditDepartmentCategoryConfiguration,
} from "../../Services/CategoryConfigurationService";
import {
  GetDepartmentList,
  AddDepartment,
  EditDepartment,
  ImportDepartmentCategory,
} from "../../Services/DepartmentService";

//----------------------------------------

//-----------Context ---------------
import { useDispatch, useSelector } from "react-redux";
import { addOrganizationList, setNavVisibilty } from "../../Redux/AppSlice";
import { exportDataInExcel, exportFilterdExcel, isShow } from "../../Services/ExportServices";
import { FileUpload } from "primereact/fileupload";
import Modalinfo from "../utili/Modalinfo";
import { Calendar } from "primereact/calendar";
//----------------------------------------

function CategoryConfiguration() {
  const [cookies, setCookie, removeCookie] = UseCookies();
  const Context = useSelector((state) => state.AppContext);
  const dispatch = useDispatch();
  const [departmentList, setDepartmentList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [isAddCategory, setIsAddCategory] = useState(false);
  const [departmentCategory, setDepartmentCategory] = useState({
    id: "",
    deptId: "",
    department: "",
    departmentHeadName: "",
    departmentEmail: "",
    deptStatus: true,
    category: [
      {
        "name": "",
        "isDeleted": true,
        "id": 0

      }
    ],

  });
  const [isPopUp, setIsPopUp] = useState(false);
  const navigate = useNavigate();
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [categoryConfigurationExists, setCategoryConfigurationExists] =
    useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [isImportInvalid, setIsImportInvalid] = useState(false);
  const [isImport, setIsImport] = useState(false);
  const [isImportInvalidFile, setIsImportInvalidFile] = useState(false);
  const [isFileValidating, setFileValidating] = useState(false);
  const [emailError, setEmailError] = useState(false);
  // Pagination
  const [first, setFirst] = useState(0);
  const [totalCount, setTotalRecords] = useState(0);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // name: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [filterObj, setFilterObj] = useState({
    status: "",
    depId: "",
  });

  const onGlobalFilterChange = (e) => {
    const value = e ? e.target.value : "";
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);

    getDepartmentCategoryConfigurationList(0, 10, value, filterObj?.depId, filterObj?.status);

  };



  const onHide = () => {
    setShowConfirmation(false);
    setIsAddCategory(false);
    setDepartmentCategory({
      departmentHeadName: "",
      departmentName: "",
      category: "",
      departmentEmail: "",
      id: "",
    });
  };

  const handleConfirmation = (confirmed) => {
    if (confirmed) {
      onHide();
    } else {
      setShowConfirmation(false);
    }
  };

  const removeCode = (index) => {
    setDepartmentCategory((prev) => ({
      ...prev,
      category: prev.category.filter((code, i) => i !== index)
    }));
  };

  const header = () => {
    return (
      <div
        className="heading"
        style={{
          fontWeight: "700",
          fontSize: "23px",
          color: "#ae9d81",
          textAlign: "center",
        }}
      >
        <p style={{ margin: "0px" }}>
          {isAddCategory ? "Add" : "Edit"} Category Configuration Details
        </p>
      </div>
    );
  };
  const popup = () => {
    return (
      <>
        <Dialog
          header={header}
          visible={isPopUp}
          closable={false}
          style={{ width: "85vw" }}
        >
          <div className="popup ">
            {/* <div className="heading">
                        <p>Add New Details</p>
                    </div> */}
            <div className="body">
              <div style={{
                fontWeight: "500",
                fontSize: "18px",
                color: "#ae9d81",
              }}>Department Head Details</div>
              <div className="label grid" style={{ marginTop: '5px', width: "700px" }}>
                <div className="col">
                  Department Head Name<span style={{ color: "red" }}>*</span>
                </div>
                <div className="col">
                  Department Email<span style={{ color: "red" }}>*</span>
                </div>
              </div>
              <div className="input grid " style={{ width: "700px" }}>
                <div className="col">
                  <InputText
                    placeholder="Enter Department Head Name"
                    value={departmentCategory?.departmentHeadName}
                    style={{ width: "90%" }}
                    onChange={(e) => {
                      setDepartmentCategory((prev) => ({
                        ...prev,
                        departmentHeadName: e.target.value,
                      }));
                    }}
                  />
                  {
                    categoryConfigurationExists && (
                      // <div>
                      <p style={{ color: "red" }}>
                        Department head name is already exists in the same
                        department
                      </p>
                    )
                    // </div>
                  }
                  {
                    isInvalid &&
                    departmentCategory?.departmentHeadName == "" && (
                      // <div>
                      <p style={{ color: "red" }}>
                        Department head name is required.
                      </p>
                    )
                    // </div>
                  }
                </div>
                <div className="col">
                  <InputText
                    placeholder="Enter Department Email"
                    style={{ width: "90%" }}
                    value={departmentCategory?.departmentEmail}
                    onChange={(e) => {
                      setDepartmentCategory((prev) => ({
                        ...prev,
                        departmentEmail: e.target.value,
                      }));
                    }}
                  />
                  {
                    isInvalid && departmentCategory?.departmentEmail == "" && (
                      // <div>
                      <p style={{ color: "red" }}>
                        Department email is required
                      </p>
                    )

                    // </div>
                  }
                  {
                    emailError &&
                    <p style={{ color: "red" }}>
                      Email address is already exist
                    </p>

                  }
                </div>
              </div>
              <div className="label grid" style={{ width: "700px" }}>
                {/* <div className="col">
                                    Category<span style={{ color: "red" }}>*</span>
                                </div> */}
                <div className="col">
                  Department<span style={{ color: "red" }}>*</span>
                </div>
                <div className="col">
                  Status
                </div>
              </div>
              <div className="input grid " style={{ width: "700px" }}>

                <div className="col">
                  <Dropdown
                    placeholder="Select Department"
                    options={departmentList}
                    optionLabel="name"
                    value={departmentCategory?.department}
                    className="border"
                    onChange={(e) => {
                      console.log("Event", e);
                      setDepartmentCategory((prev) => ({
                        ...prev,
                        deptId: e.target.value.id,
                        department: e.value
                      }));
                    }}
                    style={{
                      border: "1px solid #AE9D81",
                      width: "90%",
                      height: "50px",
                      borderRadius: "5px",
                      boxShadow: "none",
                    }}
                  />

                  {
                    isInvalid && departmentCategory?.deptId == "" && (
                      // <div>
                      <p style={{ color: "red" }}>Deparment is required</p>
                    )
                    // </div>
                  }
                </div>

                <div className="col " >
                  <label className="switch " >
                    <input type="checkbox" checked={departmentCategory?.deptStatus} onChange={(e) => { setDepartmentCategory((prev) => ({ ...prev, deptStatus: e.target.checked })) }} />
                    <span className="slider round"></span>
                  </label>
                </div>

              </div>
              {/* <hr style={{ color: 'rgb(214 209 209)' }} /> */}
              <div style={{
                fontWeight: "500",
                fontSize: "18px",
                color: "#ae9d81",
                marginTop: '30px'
              }}>Category Details</div>
              <div className="label grid" style={{ display: 'flex', marginTop: '10px' }}>
                {departmentCategory?.category.map((item, index, array) => {
                  return (
                    <div  >
                      {/* <div className="label grid" key={index} >
                                            <div className='col' > Category<span style={{ color: 'red' }}>*</span></div>
                                            <div className='col'>Status</div>
                                        </div> */}
                      <div className="input grid " style={{ marginRight: '10px', width: "400px" }}>
                        <div className="col" >
                          <div key={index}>
                            <div className='col' > Category<span style={{ color: 'red' }}>*</span></div>
                          </div>
                          <InputText
                            placeholder="Enter Category"
                            // style={{ width: "70%" }}
                            value={item?.name}
                            onChange={(e) => {
                              setDepartmentCategory((prev) => ({
                                ...prev, category: prev.category.map((innerItem, innerIndex) => {
                                  if (index == innerIndex) {
                                    return { ...item, name: e.target.value }
                                  }
                                  else {
                                    return innerItem;
                                  }
                                })
                              }))
                            }}
                          />
                          {
                            isInvalid && (item?.name == "" || !item?.name) &&
                            // <div>
                            <p style={{ color: "red", fontSize: "12px" }}>
                              Department category is required
                            </p>

                            // </div>
                          }
                        </div>
                        <div className="col " >
                          <div key={index}>
                            <div className='col' > Status</div>
                          </div>
                          <label className="switch " >
                            <input type="checkbox" checked={item?.isDeleted} onChange={(e) => {
                              setDepartmentCategory((prev) => ({
                                ...prev, category: prev.category.map((innerItem, innerIndex) => {
                                  if (index == innerIndex) {
                                    return { ...innerItem, isDeleted: e.target.checked }
                                  }
                                  else {
                                    return innerItem;
                                  }
                                })
                              }))
                            }} />
                            <span className="slider round"></span>
                          </label>
                        </div>
                        {array.length == index + 1 &&
                          <div className="col" style={{ marginTop: '40px' }} >
                            <div className="additembtn" >
                              <i className="pi pi-plus" style={{ fontSize: '1rem', cursor: "pointer" }}
                                onClick={() => {
                                  setDepartmentCategory((prev) => ({
                                    ...prev, category: [...prev.category, {
                                      "name": "",
                                      "isDeleted": true
                                    }]
                                  }))
                                }}
                              ></i>
                            </div>
                          </div>}
                        {array.length > 1 && !item?.id && (
                          <div className="col" style={{ marginTop: '45px' }}>
                            <div className="removeitembtn"
                              style={{
                                margin: 'auto'
                              }}
                            >
                              <i
                                className="pi pi-trash"
                                style={{ fontSize: '1rem' }}
                                onClick={() => {
                                  removeCode(index);
                                }}
                              ></i>
                            </div>
                          </div>
                        )}
                      </div>

                    </div>
                  )
                })}

              </div>
            </div>
            <div className="footer flex  grid">
              <div className="col flex justify-content-center ">
                <Button
                  label="Cancel"
                  onClick={() => {
                    setIsAddCategory(false);
                    setIsPopUp(false);
                    setIsInvalid(false);
                    setEmailError(false);
                    setCategoryConfigurationExists(false);
                    setDepartmentCategory({
                      departmentHeadName: "",
                      departmentEmail: "",
                      deptId: "",
                      deptStatus: true,
                      category: [{
                        "name": "",
                        "isDeleted": true
                      }]
                    });
                  }}
                  style={{
                    backgroundColor: "#8E8E8E",
                    border: "none",
                    margin: "10px",
                    height: "40px",
                  }}
                />
                <Button
                  label={isAddCategory ? "Submit" : "Update"}
                  onClick={() => {
                    isAddCategory
                      ? addDepartmentCategory()
                      : editDepartmentCategory();
                  }}
                  style={{
                    backgroundColor: "#ae9d81",
                    border: "none",
                    margin: "10px",
                    height: "40px",
                  }}
                />
              </div>
            </div>
          </div>
        </Dialog>
      </>
    );
  };
  const statusBodyTemplate = ((row) => {
    // ////console.log(row);
    if (row?.status === "Active") {
      return <p style={{ color: "green" }}  >Active</p>
    }
    else {
      return <p style={{ color: "red" }} >Inactive</p>
    }
  });

  // const actionButtonTemplate = ((row) => {
  //     return <div  onClick={(e,row)=>{//console.log(e.target,row);}} >
  //         <img src={editIcon} style={{cursor:'pointer'}}  /></div>
  // })
  const renderCategoryNames = (rowData) => {
    const categories = rowData.categories.map((category, index) => {
      if (index === rowData.categories.length - 1) {
        return <span key={category.id}>{category.name}</span>;
      } else {
        return <span key={category.id}>{category.name}, </span>;
      }
    });

    return (
      <>
        {categories}
      </>
    );
  };



  const actionButtonTemplate = (row) => {

    return (
      <div
        onClick={(e) => {
          let FIlteredDepartment = departmentList.filter((department) => {
            return department.id == row.departmentId;
          });
          setIsPopUp(true);
          setDepartmentCategory((prev) => ({
            ...prev,
            id: row?.id,
            deptId: row?.deptId,
            departmentHeadName: row?.departmentHeadName,
            department: FIlteredDepartment[0],
            departmentEmail: row?.departmentEmail,
            deptStatus: row?.status == "Inactive" ? false : true,
            isDeleted: row?.status == "Inactive" ? false : true,
            category: row?.categories?.length == 0 ? [{
              "name": "",
              "isDeleted": false
            }] : row?.categories.map((item) => {
              return {
                "name": item?.name,
                "isDeleted": !item?.isDeleted,
                "id": item?.id,
              }
            })

          }));
          setIsAddCategory(false);
          // setOrg((prev) => ({
          //     ...prev,
          //     orgName: row?.orgName,
          //     orgStatus: !row?.isDeleted,
          //     orgId: row?.id
          // }))
        }}
      >
        <img src={editIcon} style={{ cursor: "pointer" }} />
      </div>
    );
  };


  const getDepartmentCategoryConfigurationList = async (skip, take, searchValue = "", depId = "", status = "") => {
    setIsLoading(true);
    let obj = {
      status: status,
      depId: depId
    }
    await GetDepartmentCategoryConfigurationList(skip, take, cookies().bearerToken, searchValue, depId, status ? (status == "In Active") ? "true" : "false" : "", isShow(obj))
      .then((res) => {
        if (res.status === 200) {
          //console.log("Response", res);
          setCategoryList(res?.data?.data);
          setTotalRecords(res?.data?.count);
        }
      })
      .catch((error) => {
        //console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const addDepartmentCategory = () => {
    if (
      departmentCategory?.departmentHeadName == "" ||
      departmentCategory?.departmentEmail == "" ||
      departmentCategory?.department == ""
    ) {
      setIsInvalid(true);
      return;
    }
    setIsInvalid(false);
    if (departmentCategory?.category?.filter((_) => _.name == "").length > 0) {
      setIsInvalid(true);
      return;
    }

    setIsInvalid(false);
    let isExist = categoryList.filter(
      (department) =>
        department.departmentHeadName ==
        departmentCategory?.departmentHeadName &&
        department.departmentId === departmentCategory.department.id
    );

    if (isExist.length != 0) {
      setCategoryConfigurationExists(true);
      return;
    }
    setCategoryConfigurationExists(false);

    if (categoryList.some(item => item.departmentEmail === departmentCategory.departmentEmail)) {
      setEmailError(true);
      return;
    }
    setEmailError(false);

    let data = {
      departmentId: departmentCategory?.department?.id,
      headName: departmentCategory?.departmentHeadName,
      email: departmentCategory?.departmentEmail,
      isDeleted: !departmentCategory?.deptStatus,
      categories: departmentCategory?.category.map((sub) => {
        return {
          "name": sub.name,
          "isDeleted": !sub.isDeleted
        }
      }
      )
    };
    AddDepartmentCategoryConfiguration(data, cookies().bearerToken)
      .then((res) => {
        if (res.status === 200) {
          if (res?.data === true) {
            setIsPopUp(false);
            setIsAddCategory(false);
            setDepartmentCategory({
              deptId: "",
              departmentHeadName: "",
              departmentEmail: "",
              deptStatus: true,
              category: [{
                "name": '',
                "isDeleted": false
              }]

            });
            getDepartmentCategoryConfigurationList(first, 10, globalFilterValue, filterObj?.depId, filterObj?.status);
            toast.success("New department category is added successfully", {
              position: "top-right",
            });
          } else {
            toast.error(res?.data, {
              position: "top-right",
            });
          }
        }
        if (res?.response?.status === 400) {
          toast.error(res?.response?.data, {
            position: "top-right",
          });
        }
      })
      .catch((error) => {
        //console.log(error);
      });

  };

  const editDepartmentCategory = () => {
    if (
      departmentCategory?.departmentHeadName == "" ||
      departmentCategory?.departmentEmail == "" ||
      departmentCategory?.department == "" ||
      departmentCategory?.category.filter((item) =>
        item.name == "").length !== 0 ||
      departmentCategory?.category.filter((item) =>
        !item.name).length !== 0

    ) {
      setIsInvalid(true);
      return;
    }
    setIsInvalid(false);
    if (departmentCategory?.category?.filter((_) => _.name == "").length > 0) {
      setIsInvalid(true);
      return;
    }
    setIsInvalid(false);
    let isExist = categoryList.filter(
      (department) =>
        department.departmentHeadName ==
        departmentCategory?.departmentHeadName &&
        department.id !== departmentCategory.id
    );
    if (isExist.length != 0) {
      setCategoryConfigurationExists(true);
      return;
    }
    setCategoryConfigurationExists(false);

    if (categoryList.some(item => item.departmentEmail === departmentCategory.departmentEmail && item.id !== departmentCategory.id)) {
      setEmailError(true);
      return;

    }
    setEmailError(false);


    let data = {
      id: departmentCategory?.id,
      departmentId: departmentCategory?.department?.id,
      headName: departmentCategory?.departmentHeadName,
      email: departmentCategory?.departmentEmail,
      isDeleted: !departmentCategory?.deptStatus,
      editCategories: [],
      newCategories: []

    };
    departmentCategory?.category?.forEach(sub => {
      if (sub?.id) {
        data.editCategories?.push({
          name: sub.name,
          isDeleted: !sub?.isDeleted ? true : false,
          id: sub?.id || 0
        })
      } else {
        data?.newCategories?.push({
          name: sub?.name,
          isDeleted: false
        })
      }
    })
    // {
    //     "name": "string",
    //         "isDeleted": true
    // }
    console.log(data, "datadata");
    // return

    EditDepartmentCategoryConfiguration(data, cookies().bearerToken)
      .then((res) => {
        // //console.log(res);
        if (res.status === 200) {
          setIsPopUp(false);
          setIsAddCategory(false);
          setDepartmentCategory({
            id: "",
            departmentHeadName: "",
            departmentEmail: "",
            deptId: "",
            deptStatus: true,
            category: [{
              "name": '',
              "isDeleted": false,
              "id": ""
            }]

          });
          getDepartmentCategoryConfigurationList(first, 10, globalFilterValue, filterObj?.depId, filterObj?.status);
          toast.success("Department category is updated successfully", {
            position: "top-right",
          });
        }
        if (res?.response?.status === 400) {
          toast.error(res?.response?.data, {
            position: "top-right",
          });
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const back = () => {
    navigate("/dashboard");
  };

  const getDepartmentList = () => {
    let take = 50;
    let skip = "0";
    GetDepartmentList(take, skip, cookies().bearerToken)
      .then((res) => {
        if (res.status === 200) {
          setDepartmentList(
            res.data.data?.filter((_) => _?.isDeleted !== true)
          );
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  const onPageChange = (e, index) => {
    setFirst(e.first);
    getDepartmentCategoryConfigurationList(e.first, 10, globalFilterValue, filterObj?.depId, filterObj?.status);

  };


  const exportDeptInfo = () => {
    // exportDataInExcel('CategoryConfiguration', cookies().bearerToken);
    let isShowValue = globalFilterValue || filterObj?.depId || filterObj?.status;
    let urlRoute = `Export/CategoryConfiguration?searchTerm=${globalFilterValue || ""}&departmentId=${filterObj?.depId || ""}${filterObj?.status ? `&status=${filterObj?.status == "In Active"}&showAll=${isShowValue ? isShow({ ...filterObj, search: globalFilterValue }) : true}` : ""}&forExport=true`;
    exportFilterdExcel(urlRoute, cookies().bearerToken);
  }

  const onUpload = (e) => {
    //console.log(e?.files[0]);
    setFileValidating(true);
    if (e.files.length == 0) {
      setIsImportInvalid(true);
    }
    setIsImportInvalid(false);
    // if (e.files[0].name !== "Item.csv") {
    //     // alert("Please upload Tenantcsv file")
    //     setIsImportInvalidFile(true);
    //     e.files.pop()
    // }
    // else {
    setIsImportInvalidFile(false);
    const formData = new FormData();
    formData.append('file', e.files[0]);
    ImportDepartmentCategory(formData, cookies().bearerToken)
      .then((res) => {
        //console.log(res);
        if (res?.response?.status === 400) {
          toast.info(res?.response?.data);
          return
        }
        if (res.status === 200) {
          toast.success("Category imported successfully");
          setIsImport(false);
          setFileValidating(false);
          getDepartmentCategoryConfigurationList(0, 10, globalFilterValue, filterObj?.depId, filterObj?.status);
          setGlobalFilterValue("");
        }
      })
      .catch((err) => {
        //console.log(err);
        setFileValidating(false);
        if (err.response.status === 400) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Something went wrong");
        }
      });
    // }
  };
  const importHeader = () => {
    return <div className="heading" style={{ fontWeight: "700", fontSize: "23px", color: "#ae9d81", textAlign: 'center' }}>
      <p style={{ margin: '0px' }}>Import Department Category Details</p>
    </div>
  }
  const importPopup = (() => {
    return (<>
      <Dialog header={importHeader} visible={true} style={{ width: '50vw' }} closable={true} onHide={() => { setIsImport(false) }}>
        <div className="popup " >
          <div className="body">
            <div className="col">
              <div className="label grid">
                <div className='col'>
                  Sample Template {" "}
                  <i className="pi pi-download" style={{ color: 'slateblue', marginLeft: "7px", cursor: "pointer" }}
                    // on click i need to download a file 
                    onClick={() => {
                      // window.open(Itemcsv)
                      var downloadLink = document.createElement("a");
                      downloadLink.href = categorycsv;
                      downloadLink.download = "categorycsv.csv";
                      document.body.appendChild(downloadLink);
                      downloadLink.click();
                      document.body.removeChild(downloadLink);
                    }}
                  ></i>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="label grid">
                <div className='col' style={{ display: "flex", alignItems: "center" }}>Select File<span style={{ color: 'red' }}>*</span> <Modalinfo /></div>
              </div>
              <div className="input grid ">
                <div className="col">
                  {/* <FileUpload mode="basic" name="demo[]" accept="*.csv" maxFileSize={1000000} onUpload={onUpload} chooseLabel="Browse" /> */}
                  <FileUpload name="demo[]" accept=".csv" mode='basic' multiple={false} maxFileSize={1000000} customUpload uploadHandler={onUpload} emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>} />
                  {/* {isFileValidating && <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i>} */}
                  {isImportInvalid &&
                    // <div>
                    <p style={{ color: "red" }}>Please choose a file</p>
                    // </div>
                  }
                  {isImportInvalidFile &&
                    // <div>
                    <p style={{ color: "red" }}>Name of the uploaded file should be same as the sample file.</p>
                    // </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>)
  })



  useEffect(() => {
    getDepartmentCategoryConfigurationList(0, 10);
    getDepartmentList();
  }, [])
  // Export Filter
  const [exportFilterDialog, setExportFilterDialog] = useState(false);
  const [exportDetails, setExportDetails] = useState({
    startDate: "",
    endDate: "",
    organization: "",
    desk: "",
    status: true
  });

  return (
    <>
      <Dialog header={<h3>Export Filter</h3>} draggable={false} style={{ width: '50vw', height: "max-content" }} closable={true} onHide={() => setExportFilterDialog(false)} visible={exportFilterDialog} >
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div style={{ width: "50%" }}>
            <label htmlFor="StartDate" className="font-bold block mb-2">Start Date
            </label>
            <Calendar
              id="StartDate"
              style={{ width: "95%" }}
              value={exportDetails?.startDate}
              placeholder="Choose start end"
              onChange={((value) => setExportDetails(() => ({ ...exportDetails, startDate: value })))}
            ></Calendar>
          </div>
          <div style={{ width: "50%" }}>
            <label htmlFor="EndDate" className="font-bold block mb-2">End Date
            </label>
            <Calendar
              id="EndDate"
              style={{ width: "95%" }}
              value={exportDetails?.endDate}
              placeholder="Choose end date"
              onChange={((value) => setExportDetails(() => ({ ...exportDetails, endDate: value })))}
            ></Calendar>
          </div>
          <div style={{ width: "50%" }}>
            <label htmlFor="organization" className="font-bold block mb-2">Organization
            </label>
            <InputText
              id="organization"
              style={{ width: "95%" }}
              value={exportDetails?.organization}
              onChange={((value) => setExportDetails(() => ({ ...exportDetails, organization: value })))}
              placeholder="Enter organization" ></InputText>
          </div>
          <div style={{ width: "50%" }}>
            <label htmlFor="Status" className="font-bold block mb-2">Status
            </label>
            {/* <InputText
                        id="Status"
                        style={{ width: "95%" }}
                        value={exportDetails?.status}

                        placeholder="Enter organization" ></InputText> */}
            <div className="input grid ">
              <div className="col ">
                <label className="switch ">
                  <input type="checkbox" value={exportDetails?.status} checked={exportDetails?.status} onChange={((e) => setExportDetails(() => ({ ...exportDetails, status: e.target.checked })))} />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          </div>

        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "25px",
          }}
        >
          <Button
            label="Submit"
            onClick={''}
            // icon={"pi pi-file-export"}
            style={{
              backgroundColor: " #ae9d81",
              color: "#fff",
              border: "none",
              // borderRadius: "20px",
              marginRight: "0",
              cursor: totalCount > 0 ? "pointer" : "not-allowed",
              padding: "0.6rem",
            }}
          />
        </div>


      </Dialog>
      <div className="CategoryConfiguration">
        <div className="header">
          <div style={{ display: "flex", flexDirection: "row", flexGrow: "3", justifyContent: "flex-start" }} className='flex align-items-center org'>
            <i className="pi pi-arrow-circle-left nav-back-btn" onClick={back} style={{ marginLeft: '30px', cursor: 'pointer', fontSize: '1.5rem' }} />
            <div className="heading">
              <p>Department Category </p>
            </div>
          </div>
          <div className="flex align-items-center">
            <div className="mx-2">
              <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Status</h4>
              <Dropdown value={filterObj?.status} options={[{ name: "Active" }, { name: "In Active" }]} optionLabel='name' optionValue='name' onChange={(e) => { setFilterObj((prev) => ({ ...prev, status: e.value })); getDepartmentCategoryConfigurationList(0, 10, globalFilterValue, filterObj?.depId, e.value) }} placeholder='Select' style={{ border: "solid 1px #ae9d81", width: 180 }} showClear={filterObj?.status ? true : false} />
            </div>
            <div className="mx-2">
              <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Department</h4>
              <Dropdown value={filterObj?.depId} options={departmentList} optionLabel='name' optionValue='id' onChange={(e) => { setFilterObj((prev) => ({ ...prev, depId: e.value })); getDepartmentCategoryConfigurationList(0, 10, globalFilterValue, e.value, filterObj?.status) }} placeholder='Select' style={{ border: "solid 1px #ae9d81", width: 180 }} showClear={filterObj?.depId ? true : false} />
            </div>
            <div className="mx-2">
              <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Search</h4>
              <div className="search">
                <span className="p-input-icon-left">
                  <i
                    className="pi pi-search"
                    style={{ color: "black", fontWeight: "700", fontSize: "1rem" }}
                  />
                  <InputText
                    placeholder="Search"
                    value={globalFilterValue}
                    onChange={onGlobalFilterChange}
                    style={{ width: 180 }}
                  />
                  <i
                    className="pi pi-times"
                    style={{ color: "black", fontWeight: "400" }}
                    onClick={() => {
                      setGlobalFilterValue("");
                      onGlobalFilterChange();
                      getDepartmentCategoryConfigurationList(0, 10, "", filterObj?.depId, filterObj?.status);
                    }}
                  />
                </span>
              </div>
            </div>


            <div className="mx-2 align-self-end">
              <Button
                label=" Add New"
                onClick={() => {
                  setIsPopUp(true);
                  setIsAddCategory(true);
                  setDepartmentCategory((prev) => ({
                    ...prev,
                    deptId: "",
                    departmentHeadName: "",
                    departmentEmail: "",
                    deptStatus: true,
                    category: [
                      {
                        "name": "",
                        "isDeleted": true,
                        "id": 0

                      }
                    ],

                  }))
                }}
                style={{ backgroundColor: "#ae9d81", border: "none" }}
              />
            </div>

            <div className="mx-2 align-self-end">
              <Button label='Export' onClick={totalCount > 0 ? exportDeptInfo : null} icon={"pi pi-file-export"} style={{ backgroundColor: 'transparent', color: '#ae9d81', border: '1px #ae9d81 solid', borderRadius: '20px', cursor: totalCount > 0 ? 'pointer' : 'not-allowed' }} />
            </div>

            <div className="mx-2 align-self-end">
              <Button label='Import' icon={"pi pi-file-import"} style={{ backgroundColor: 'transparent', color: '#ae9d81', border: '1px #ae9d81 solid', borderRadius: '20px' }} onClick={() => { setIsImport(true) }} />
            </div>
          </div>
        </div>

        <div className="datatable">
          {isLoading ? (
            <div className="spinner spinner-center"></div>
          ) : (
            <>
              <DataTable
                value={categoryList}
                tableStyle={{ width: "90rem" }}
                style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
                stripedRows
                rows={10}
                // filters={filters}
                // globalFilterFields={[
                //     "departmentHeadName",
                //     "department",
                //     "category",
                //     "departmentEmail",
                // ]}
                emptyMessage={
                  <>
                    <p style={{ textAlign: "center" }}>
                      Department Category List is Empty
                    </p>
                  </>
                }
              >
                <Column
                  field="departmentHeadName"
                  header="Department Head Name"
                  style={{ width: "20%" }}
                ></Column>
                <Column
                  field="departmentName"
                  header="Department"
                  style={{ width: "25%" }}
                ></Column>
                <Column
                  field="name"
                  body={renderCategoryNames} header="Category"
                  style={{ width: "25%" }}
                ></Column>
                <Column
                  field="departmentEmail"
                  header="Department  Email"
                  style={{ width: "20%" }}
                ></Column>
                <Column field="status" header="Status" body={statusBodyTemplate} style={{ width: "20%" }}></Column>
                <Column
                  rowEditor
                  header="Action"
                  body={actionButtonTemplate}
                  style={{ width: "20%" }}
                ></Column>
              </DataTable>
            </>
          )}
        </div>
        {isLoading || categoryList?.length == 0 ? (
          ""
        ) : (
          <div style={{ justifyContent: "center", display: "flex" }}>
            <Paginator
              first={first}
              rows={10}
              totalRecords={totalCount}
              onPageChange={(e) => onPageChange(e, 1)}
              template={{
                layout:
                  "RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport",
              }}
              className="justify-content-start"
            />
          </div>
        )}
      </div>

      <div>
        {isPopUp == true ? popup() : ""}
        {isImport == true ? importPopup() : ""}
      </div>
    </>
  );
}




export default CategoryConfiguration;
