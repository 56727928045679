import React from 'react'
import UseCookies from './UseCookies';
import { SocketConnection } from '../App';
import { toast } from 'react-toastify';
export default function Socket() {

    const [cookies, setCookie, removeCookie] = UseCookies();
    const socket = SocketConnection;


    const connectSockket = () => {
        console.log(socket);
        socket.onopen = function (socketResult) {
            console.log("WebSocket connection established with socket.", socketResult);
        };
    };
    const sendMessage = (id) => {
        socket.send(id);
    }
    socket.onmessage = function (event) {
        let socketId = event.data; // This will receive the socket ID
        console.log("Received Socket ID from server: " + socketId);
        toast.success(socketId, { position: 'top-right' });
    };
    const closeSockket = () => {
        // socket.onclose = function () {
        //     //console.log("WebSocket connection closed with socket.");
        // }
    };

    return [connectSockket, closeSockket, sendMessage]
}
