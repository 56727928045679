import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { Paginator } from "primereact/paginator";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setNavVisibilty } from "../../Redux/AppSlice";
import { AddPlateCountry, AddVehicleBrands, EditPlateCountry, EditVehicleBrand, GetPlateCountry, GetVehicleBrands } from "../../Services/VIPLoungeMaster";
import UseCookies from "../../Helpers/UseCookies";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import { toast } from "react-toastify";
import { FileUpload } from "primereact/fileupload";
import VehicleCountry from '../../assets/files/VehicleCountry.csv'
import VehicleBrand from '../../assets/files/VehicleBrand.csv'
import { ImportVehichleCountry, ImportVehicleBrand } from "../../Services/ImportService";
import Modalinfo from "../utili/Modalinfo";
import { useNavigate } from "react-router-dom";

const VipMasterConfig = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = UseCookies();
  const [vipMasterObj, setVipMasterObj] = useState({
    data: [],
    activeMasterValue: "Brand Model",
    rowValue: 10,
    pagePositionValue: 0,
    totalrecord: 0,
    isVisible: false
  });
  let initialValueObj = {
    name: "",
    isTrigger: false,

  }
  const [isLoading, setIsLoading] = useState(false);
  const [isModelVisible, setIsModelVisible] = useState(false);
  const [valueObj, setValueObj] = useState(initialValueObj);
  const [modelData, setModelData] = useState({
    id: "",
    name: "",
    status: ""
  });
  const [isInvalid, setIsInvalid] = useState(false);

  const [isImport, setIsImport] = useState(false);
  const [isImportInvalid, setIsImportInvalid] = useState(false);
  const [isImportInvalidFile, setIsImportInvalidFile] = useState(false);

  const masterConfigutration = [
    {
      name: "Brand Model",
    }, {
      name: "Plate Country"
    }
  ];
  const assignVipMaster = (key, value) => {
    {
      console.log(key, value, "key,value");
      setVipMasterObj((prev) => ({ ...prev, [key]: value }));
    }
  }
  const statusBodyTemplate = (row) => {
    return (
      <div
        className="flex align-items-center"
        style={{ justifyContent: "unset" }}
      >
        <div className="px-2">
          <span
            style={{
              color: !row?.isDeleted ? "green" : "red",
              fontWeight: "500",
            }}
          >
            {!row?.isDeleted ? "Active" : "InActive"}
          </span>
        </div>
      </div>
    );
  };
  const actionBodyTemplate = (row) => {
    return (
      <div
        className="flex align-items-center"
        style={{ justifyContent: "unset" }}
      >
        <div className="px-2">
        </div>
        {/* {!(
              vipMasterObj?.activeMasterValue?.name == "Baggage Tags" &&
              row?.status === "Inactive"
            ) && ( */}
        <div className="px-2">
          <i onClick={() => {
            setIsModelVisible(true);
            setModelData({ id: row?.id, name: row?.name, status: !row?.isDeleted })
          }}
            className="pi pi-pencil"
            style={{ fontSize: "1rem", cursor: "pointer", color: "#005F87" }}
          />
        </div>
        {/* )} */}
      </div>
    );
  };

  const getCountryPlate = async () => {
    setIsLoading(true);
    await GetPlateCountry(cookies()?.bearerToken).then(res => {
      if (res?.status == 200) {
        assignVipMaster("data", res?.data);
      }
    });
    setIsLoading(false);
  }
  const addPlateCountry = async () => {
    setIsLoading(true);
    setVipMasterObj(prev => ({ ...prev, isVisible: false }));
    let data = {
      name: valueObj?.name,
      isActive: true,
      isDeleted: false
    }
    await AddPlateCountry(data, cookies()?.bearerToken).then(res => {
      if (res?.status == 200) {
        getCountryPlate();
        setValueObj(initialValueObj);
        toast.success("Plate country successfully added successfully");
        return
      }

      setVipMasterObj(prev => ({ ...prev, isVisible: true }));
      toast.error(res?.response?.data || "Error while adding plate country");
    })
    setIsLoading(false);
  }

  const editPlateCountry = async (obj) => {
    setIsLoading(true);
    let data = {
      id: obj?.id,
      name: obj?.name,
      isActive: false,
      isDeleted: !obj?.status
    }
    await EditPlateCountry(data, cookies()?.bearerToken).then(res => {
      if (res?.status == 200) {
        getCountryPlate();
        toast.success("Plate country successfully updated successfully");
      }
    })
    setIsLoading(false);
    setIsModelVisible(false);
  }
  const getCarModel = async () => {
    setIsLoading(true);
    await GetVehicleBrands(cookies()?.bearerToken).then(res => {
      if (res?.status == 200) {
        assignVipMaster("data", res?.data);

      }
    }).catch(error => {
      console.log(error);
    }).finally(() => {
      setIsLoading(false);
    })
  }

  const addBrandModel = async () => {
    setVipMasterObj(prev => ({ ...prev, isVisible: false }));
    setIsLoading(true);
    let data = {
      name: valueObj?.name,
      isDeleted: false
    }
    await AddVehicleBrands(data, cookies()?.bearerToken).then(res => {
      if (res?.status == 200) {
        toast.success("Vehicle brand successfully added successfully");
        getCarModel();
        setValueObj(initialValueObj);
        return
      }
      setVipMasterObj(prev => ({ ...prev, isVisible: true }));
      toast.error(res?.response?.data || "Error while adding vehicle brand");
    })
    setIsLoading(false);
  }
  const editBrandModel = async (obj) => {
    setIsLoading(true);
    let data = {
      id: obj?.id,
      name: obj?.name || "",
      isDeleted: !obj?.status
    }
    await EditVehicleBrand(data, cookies()?.bearerToken).then(res => {
      if (res?.status == 200) {
        getCarModel();
        toast.success("Vehicle brand successfully updated successfully");
        return
      }
    })
    setIsLoading(false);
    setIsModelVisible(false);

  }
  const onChangeMasterDropdownValue = (label) => {
    if (label !== masterConfigutration[0]?.name) {
      getCountryPlate();
    } else {
      getCarModel();
    }
  }
  const onCreate = (key) => {
    setValueObj((prev) => ({ ...prev, isTrigger: true }));
    if (!valueObj?.name) {
      return
    }
    switch (key) {
      case "Brand Model":
        addBrandModel();
        break;
      case "Plate Country":
        addPlateCountry();
        break;

      default:
        break;
    }
  }
  const onEditing = async (key, currentObj) => {
    console.log(currentObj, "ccc");
    switch (key) {
      case "Brand Model":
        await editBrandModel(currentObj);
        break;
      case "Plate Country":
        await editPlateCountry(currentObj);
        break;

      default:
        break;
    }
  }
  const importHeader = () => {
    return <div className="heading" style={{ fontWeight: "700", fontSize: "23px", color: "#ae9d81", textAlign: 'center' }}>
      <p style={{ margin: '0px' }}>Import {vipMasterObj?.activeMasterValue}</p>
    </div>
  }
  const onUpload = (e) => {
    //console.log(e?.files[0]); 
    if (e.files.length == 0) {
      setIsImportInvalid(true);
    }
    setIsImportInvalid(false);
    setIsImportInvalidFile(false);
    const formData = new FormData();
    formData.append('file', e.files[0]);
    const dynamicFunction = vipMasterObj?.activeMasterValue == "Brand Model" ? ImportVehicleBrand : ImportVehichleCountry;
    dynamicFunction(formData, cookies().bearerToken)
      .then((res) => {
        if (res?.response?.status === 400) {
          toast.info(res?.response?.data);
          return
        }
        if (res.status === 200) {
          toast.success(`${vipMasterObj?.activeMasterValue} imported successfully`);
          setIsImport(false);
          vipMasterObj?.activeMasterValue == "Brand Model" ? getCarModel() : getCountryPlate();
          return
        }
        toast.error("Something went wrong");
      })
      .catch((err) => {
        console.log(err + "err");
      });
    // }
  };
  const back = () => {
    navigate('/dashboard');
  };

  useEffect(() => {
    getCarModel();
  }, [])
  return (
    <div>

      <Dialog header={<div className="heading" style={{ fontWeight: "700", fontSize: "23px", color: "#ae9d81", textAlign: 'center' }}>
        <p style={{ margin: '0px' }}>Edit {vipMasterObj?.activeMasterValue}</p>
      </div>}
        visible={isModelVisible} style={{ width: '30vw' }}
        closable={false} >
        <div className="body"  >
          <div className="label grid" >
            <div className='col' >{vipMasterObj?.activeMasterValue} Name<span style={{ color: 'red' }}>*</span></div>
            <div className='col' style={{ position: 'absolute', left: '240px' }}>Status</div>
          </div>
          <div className="input grid ">
            <div className="col">
              <InputText placeholder="Enter Name" value={modelData?.name} onChange={(e) => { setModelData((prev) => ({ ...prev, name: e.target.value })) }} />
              {isInvalid && modelData?.orgName == "" &&
                <p style={{ color: "red" }}>{vipMasterObj?.activeMasterValue} name is required</p>
              }
              {/* {true &&
                <p style={{ color: "red" }}>{vipMasterObj?.activeMasterValue} Name is already exists</p>
              } */}
            </div>
            <div className="col ">
              <label className="switch ">
                <input type="checkbox" checked={modelData?.status} onChange={(e) => { setModelData((prev) => ({ ...prev, status: e.target.checked })) }} />
                <span className="slider round"></span>
              </label>
            </div>

          </div>
        </div>
        <div className="footer flex  grid">
          <div className="col flex justify-content-center ">
            <Button label="Cancel"
              onClick={() => {
                setModelData({ name: "", status: "" });
                setIsModelVisible(false);
              }}
              style={{ backgroundColor: '#8E8E8E', border: "none", margin: "10px", height: '40px' }} />
            <Button label="Update" onClick={() => { onEditing(vipMasterObj?.activeMasterValue, modelData) }} style={{ backgroundColor: '#ae9d81', border: "none", margin: "10px", height: '40px' }} />
          </div>
        </div>

      </Dialog>
      <div className="header flex align-items-center justify-content-between" >
        <div style={{ display: "flex" }} className="align-items-center">
          <div className="menubtn" style={{ padding: "10px 10px 5px 20px" }}>
            <i className="pi pi-arrow-circle-left nav-back-btn" onClick={back} style={{ marginLeft: '30px', cursor: 'pointer', fontSize: '1.5rem' }} />
          </div>
          <div className="heading">
            <p className="configuration">VIP Master</p>
          </div>
        </div>
        <div className="px-4 flex justify-content-end pb-2">
          <div className="mx-2">
            <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Configuration List</h4>
            <Dropdown style={{ height: 40 }} value={vipMasterObj?.activeMasterValue} options={masterConfigutration}
              placeholder={`select ${vipMasterObj?.activeMasterValue?.toLocaleLowerCase()}`} optionValue="name" optionLabel="name" onChange={(e) => { assignVipMaster("activeMasterValue", e?.value); onChangeMasterDropdownValue(e?.value) }} />
          </div>
          <div className="mx-2 align-self-end">
            <Button
              label={`Add ${vipMasterObj?.activeMasterValue?.toLocaleLowerCase()}`}
              style={{ backgroundColor: "#ae9d81", width: "180px", height: "40px", lineHeight: "40px", border: 0 }}
              onClick={() => {
                setVipMasterObj((prev) => ({ ...prev, isVisible: true }));
                setValueObj(initialValueObj);
              }}
            ></Button>
          </div>
          <div className="mx-2 align-self-end">
            <Button label='Import' icon={"pi pi-file-import"} style={{ backgroundColor: 'transparent', color: '#ae9d81', border: '1px #ae9d81 solid', borderRadius: '20px', cursor: 'pointer', padding: "8px 14px 8px 14px" }} onClick={() => { setIsImport(true) }} />
          </div>
        </div>
      </div>

      <>
        {isLoading ? (
          <div
            className="spinner spinner-center"
            style={{ display: "flex", margin: "auto", marginTop: "200px" }}
          ></div>
        ) : (
          <div className="col-12 px-4">
            <DataTable
              value={vipMasterObj?.data}
              tableStyle={{ minWidth: "50rem" }}
              emptyMessage="No data found"
              paginator={vipMasterObj?.data?.length > 0}
              rows={10}
            >
              <Column
                field="name"
                header="Name"
                className="customeColumn"
              ></Column>
              <Column
                field="status"
                header="Status"
                className="customeColumn"
                body={statusBodyTemplate}
              ></Column>
              <Column
                field="action"
                header="Action"
                className="customeColumn"
                body={actionBodyTemplate}
              ></Column>
            </DataTable>

          </div>
        )}

      </>


      <Dialog header="VIP Master" visible={vipMasterObj?.isVisible} style={{ width: '30vw' }} onHide={() => { setVipMasterObj((prev) => ({ ...prev, isVisible: false })) }} >
        <div className="m-0">
          <p>{vipMasterObj?.activeMasterValue} <span style={{ color: "red" }}>*</span></p>
          <InputText value={valueObj?.name} placeholder={`Enter ${vipMasterObj?.activeMasterValue?.toLocaleLowerCase()} name`} className="w-full" onChange={(e) => setValueObj((prev) => ({ ...prev, name: e.target.value }))} />
          {valueObj?.isTrigger && !valueObj?.name && <p style={{ color: "red" }}>{`Please enter ${vipMasterObj?.activeMasterValue?.toLocaleLowerCase()} name`}</p>}

          <div className="text-center my-3">
            <Button className="my-2" style={{ backgroundColor: "#ae9d81", border: 0 }} onClick={() => {
              onCreate(vipMasterObj?.activeMasterValue)
            }}>
              Submit
            </Button>
          </div>
        </div>
      </Dialog>
      <Dialog header={importHeader} visible={isImport} style={{ width: '50vw' }} closable={true} onHide={() => { setIsImport(false) }}>
        <div className="popup " >
          <div className="body">
            <div className="col">
              <div className="label grid">
                <div className='col'>
                  Sample Template {" "}
                  <i className="pi pi-download" style={{ color: 'slateblue', marginLeft: "7px", cursor: 'pointer' }}
                    // on click i need to download a file 
                    onClick={() => {
                      var downloadLink = document.createElement("a");
                      downloadLink.href = vipMasterObj?.activeMasterValue == "Brand Model" ? VehicleBrand : VehicleCountry;
                      downloadLink.download = vipMasterObj?.activeMasterValue == "Brand Model" ? "VehicleBrand.csv" : "VehicleCountry.csv";
                      document.body.appendChild(downloadLink);
                      downloadLink.click();
                      document.body.removeChild(downloadLink);
                    }}
                  ></i>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="label grid">
                <div className='col' style={{ display: "flex", alignItems: "center" }}>Select File<span style={{ color: 'red' }}>*</span> <Modalinfo /></div>
              </div>
              <div className="input grid ">
                <div className="col">
                  <FileUpload name="demo[]" accept=".csv" mode='basic' multiple={false} maxFileSize={1000000} customUpload uploadHandler={onUpload} emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>} />
                  {isImportInvalid &&
                    <p style={{ color: "red" }}>Please choose a file</p>
                  }
                  {isImportInvalidFile &&
                    <p style={{ color: "red" }}>Name of the uploaded file should be same as the sample file.</p>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>

  )
}
export default VipMasterConfig;


