import "./App.scss";
import "../node_modules/primeflex/primeflex.css";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { Provider } from 'react-redux';
import Store from './Redux/Store';
import { PrimeReactProvider } from 'primereact/api';
import Router from './Router';
import SideNav from './Components/SideNavBar/SideNav';
import 'react-toastify/dist/ReactToastify.css';
import "react-toastify/dist/ReactToastify.css";
import "primeicons/primeicons.css";
import { useEffect, useState } from "react";
import { WebNotification } from "./Components/utili/WebNotification";
import UseCookies from "./Helpers/UseCookies";

export const SocketConnection = new WebSocket(`wss://localhost:7106/ws`);
function App() {
  const [ws, setWs] = useState(null);
  const [cookies] = UseCookies();


  useEffect(() => {
    
  });



  return (
    <>
      <PrimeReactProvider>
        <Provider store={Store}>
          <div className='app'>
            <Router>
              <SideNav />
            </Router>
          </div >
        </Provider>
      </PrimeReactProvider>
    </>
  );
}

export default App;
