import './style.scss';
import x from '../../assets/icons/x.svg';
import filetypePng from '../../assets/icons/filetype-png.svg';
import { useEffect, useRef, useState } from 'react';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { getCategories, getSubCategories, uploadFiles, getNationality, feedBackSubmission } from '../../Services/FeedbackService';
import Modal from '../Modal/Modal';
// import logo from "../../assets/images/MGLogo.jpeg";
import logo from "../../assets/images/newlogo.png";
import localization from "../../assets/JSON/Localization.json";
import nationalityLists from "../../assets/JSON/Nationality.json";



import { Button } from 'primereact/button';
import UseCookies from '../../Helpers/UseCookies';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function FeedbackForm({ languageValue, clearLanguage }) {
    const localizationValue = "en";
    const navigation = useNavigate();
    const inputRef = useRef(null);
    const [cookies, setCookie, removeCookie] = UseCookies();
    // const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [inVisible, setIsvisible] = useState(false);
    const [feedBackDetail, setFeedbackDetail] = useState({
        title: null,
        firstName: "",
        lastName: "",
        // countryCode: null,
        nationality: "",
        category: "",
        subCategory: "",
        location: "",
        // attachment: "",
        email: "",
        feedback: "",
        // mobileNumber: "",
    });
    const [countryCode, setCountryCode] = useState(null);
    console.log(countryCode, "countryCode");
    const [emailValid, setEmailValid] = useState(false);
    const [isSubmitTriggered, setIsSubmitTriggered] = useState(false);
    const [imageAsset, setImageAsset] = useState([]);
    const titleList = [
        { label: "Mr", value: "Mr", arabicLabel: "السيد" },
        { label: "Mrs", value: "Mrs", arabicLabel: "السيدة" },
        { label: "Miss", value: "Miss", arabicLabel: "الآنسة" },
        { label: "Ms", value: "Ms", arabicLabel: "الآنسة" },
    ];
    const [categoriesList, setCategoriesList] = useState([{
        id: 0,
        categoryName: "",
        categoryNameInAr: "",
        isDeleted: false
    }]);
    const [subCategoriesList, setSubCategoriesList] = useState([
    ]);
    const [recordSubCategoriesList, setRecordSubCategoriesList] = useState([{
        subCategoryId: 0,
        subCategoryName: "",
        categoryName: "",
        createdOn: "",
        createdBy: "",
        isDeleted: false
    }]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isPressed, setIsPressed] = useState(false);
    const [nationalityList, setNationalityList] = useState([]);
    const getMobileNumber = () => {
        let mobileNumber = inputRef?.current?.selectedCountryData?.dialCode + inputRef?.current?.state?.value
        assignValue("mobileNumber", inputRef?.current?.state?.value)
        return mobileNumber;
    }
    const assignValue = (key, value) => {
        // console.log(key, value);

        setFeedbackDetail((prevState) => {
            return {
                ...prevState,
                [key]: value
            };
        });
    }
    const isValidEmail = () => {
        var pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        setEmailValid(pattern.test(feedBackDetail?.email));
        // console.log(pattern.test(feedBackDetail?.email), feedBackDetail.email);
        return pattern.test(feedBackDetail?.email);
    }
    const isEmptyCheck = () => {
        return Object.values(feedBackDetail)?.every(_ => _ !== "");
    };

    const onSubmit = () => {
        setIsSubmitTriggered(true);
        if (isValidEmail() && isEmptyCheck() && !isNaN(feedBackDetail?.mobileNumber)) {
            setIsLoading(true);
            feedSubmission();
        }
    };


    const getImages = async (e) => {
        // console.log(e.target.files[0]);
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                const base64String = reader.result.split(',')[1];
                setImageAsset([...imageAsset, {
                    base64: base64String,
                    fileName: file?.name
                }]);
            };

            reader.readAsDataURL(file);
        }

    };

    const removeImage = (fileName) => {
        // console.log(fileName);
        setImageAsset(imageAsset?.filter(ele => ele?.fileName !== fileName) || []);
    };

    const getCategoriesList = async () => {
        await getCategories()
            .then((res) => {
                // console.log(res?.data);
                if (res?.status === 200) {
                    setCategoriesList(res?.data)
                }
            })

            .catch((err) => {
                // console.log(err);
            })
    };

    const getSubcategoriesList = async () => {
        await getSubCategories().then(list => {
            // console.log(list?.data);
            if (list?.data) {
                // setSubCategoriesList(list?.data);
                setRecordSubCategoriesList(list?.data?.data)
            }
        })
    }
    const tenantAttachments = async (feedbackId) => {
        // console.log(imageAsset, "image");
        let data = {
            id: feedbackId,
            module: "Feedback",
            inspectionId: 0,
            files: imageAsset
        }
        // console.log(data);
        // return
        await uploadFiles(data).then((res) => {
            // console.log(res);
            if (res) {
                // setIsDialogOpen(!isDialogOpen);
                // setIsvisible(true);
                // setIsPressed(false);
                toast.success("Feedback submitted successfully");
                window.location.href = "/guestservicestaff"
            }
        })
    }
    const getNationalityList = async () => {
        await getNationality().then(data => {
            if (data?.status == 200) {
                setNationalityList(data?.nationalityList)
            }
        })
    }
    // console.log(feedBackDetail);
    const feedSubmission = async () => {

        let data = {
            organizationId: 1,
            location: feedBackDetail.location,
            title: feedBackDetail.title,
            firstName: feedBackDetail.firstName,
            lastName: feedBackDetail.lastName,
            mobileNumber: getMobileNumber(),
            email: feedBackDetail.email,
            nationality: feedBackDetail.nationality,
            categoryId: feedBackDetail.category,
            subCategoryId: feedBackDetail.subCategory,
            comment: feedBackDetail.feedback,
            userId: atob(cookies().userId)
        }
        await feedBackSubmission(data)
            .then((res) => {
                if (imageAsset?.length === 0 || !imageAsset) {
                    toast.success("Feedback submitted successfully");
                    // window.location.href = "/guestservicestaff"
                    navigation("/guestservicestaff")
                    return
                } else {
                    tenantAttachments(res?.data);
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setIsvisible(true);
            })
    };

    const getFilteredSubCategories = (categoryId) => {
        let categoryName = categoriesList?.filter(_ => _?.id == categoryId)
        setSubCategoriesList(recordSubCategoriesList?.filter(_ => _?.categoryName == categoryName[0]?.categoryName));
    }

    const addEllipsis = (str, maxLength) => {
        if (str.length > maxLength) {
            return str.substring(0, maxLength) + '...';
        } else {
            return str;
        }
    }
    useEffect(() => {
        getNationalityList();
        getSubcategoriesList();
        getCategoriesList();
    }, [])

    return (
        // empty Commit
        <div className='mg_care__body'>
            {/* {inVisible && <Modal setIsvisible={setIsvisible} languageValue={languageValue} />} */}
            <div className='mg_care__bg_image' />
            <div className='mg_care__form_wrapper'>
                <div className="mg_care__form">
                    <div className="mg_care__form_header">

                        <div style={{ display: "flex", alignItems: "center", }}>
                            <div style={{}}>
                                <img src={logo} alt='not found' style={{ paddingLeft: "20px", width: '137px', height: '60px', objectFit: "contains" }} onClick={() => { clearLanguage("") }} />
                            </div>
                            <div style={{ width: "80%" }}>
                                <h3 style={{ textAlign: localizationValue == "en" && "center", paddingLeft: "20px", direction: localizationValue == "en" ? "ltr" : "rtl" }}>{localization[localizationValue]?.translation?.appTitle}</h3>
                            </div>
                        </div>
                    </div>

                    <div className="mg_care__form_body">
                        {localizationValue == "en" &&
                            <div style={{ display: 'flex', gap: '10px' }} className='mobile'>
                                <div style={{ width: '20%' }} className='title'>
                                    <label style={{ direction: localizationValue == "en" ? "ltr" : "rtl" }} className='mg_care__label' htmlFor='title'>
                                        {localization[localizationValue]?.translation?.TitleLabel}
                                        <span style={{ color: "red", padding: "0 2px" }}>*</span></label>
                                    <select style={{ direction: localizationValue == "en" ? "ltr" : "rtl" }} placeholder="hi" className='mg_care__input' id='title' onChange={(e) => { assignValue('title', e.target.value) }} >
                                        <option value="">
                                            {localization[localizationValue]?.translation?.TitlePlaceholder}</option>
                                        {
                                            titleList?.map(_ => {
                                                return <option value={localizationValue == "en" ? _.label : _.arabicLabel}>{localizationValue == "en" ? _.label : _.arabicLabel}</option>
                                            })}
                                    </select>
                                    {isSubmitTriggered && !feedBackDetail?.title && <p style={{ color: "red", margin: "0", marginBottom: '8px', direction: localizationValue == "en" ? "ltr" : "rtl" }}>{localization[localizationValue]?.translation?.errorMessage}</p>}
                                </div>
                                <div style={{ width: '40%' }} className='title'>
                                    <label style={{ direction: localizationValue == "en" ? "ltr" : "rtl" }} className='mg_care__label' htmlFor='firstName'>{localization[localizationValue]?.translation?.firstNameLabel}<span style={{ color: "red", padding: "0 2px" }}>*</span></label>
                                    <input style={{ direction: localizationValue == "en" ? "ltr" : "rtl" }} type='text' className='mg_care__input' id='firstName' placeholder={localization[localizationValue]?.translation?.firstnamePlaceholder} onChange={(e) => assignValue('firstName', e.target.value)} />

                                    {isSubmitTriggered && !feedBackDetail?.firstName && <p style={{ color: "red", margin: "0", marginBottom: '8px', direction: localizationValue == "en" ? "ltr" : "rtl" }}>{localization[localizationValue]?.translation?.firstNameErrorMessage}</p>}
                                </div>
                                {/* </div> */}
                                <div style={{ width: '40%' }} className='title'>
                                    <label style={{ direction: localizationValue == "en" ? "ltr" : "rtl" }} className='mg_care__label' htmlFor='lastName'>{localization[localizationValue]?.translation?.LastNameLabel}<span style={{ color: "red", padding: "0 2px" }}>*</span></label>
                                    <input style={{ direction: localizationValue == "en" ? "ltr" : "rtl" }} type='text' className='mg_care__input' id='lastName' placeholder={localization[localizationValue]?.translation?.lastnamePlaceholder} onChange={(e) => assignValue('lastName', e.target.value)} />
                                    {isSubmitTriggered && !feedBackDetail?.lastName && <p style={{ color: "red", margin: "0", marginBottom: '8px', direction: localizationValue == "en" ? "ltr" : "rtl" }}>{localization[localizationValue]?.translation?.LastNameErrorMessage}</p>}
                                </div>
                            </div>

                        }
                        {localizationValue == "ar" &&
                            <div style={{ display: 'flex', gap: '10px' }} className='mobile'>
                                <div style={{ width: '40%' }} className='title'>
                                    <label style={{ direction: localizationValue == "en" ? "ltr" : "rtl" }} className='mg_care__label' htmlFor='lastName'>{localization[localizationValue]?.translation?.LastNameLabel}<span style={{ color: "red", padding: "0 2px" }}>*</span></label>
                                    <input style={{ direction: localizationValue == "en" ? "ltr" : "rtl" }} type='text' className='mg_care__input' id='lastName' placeholder={localization[localizationValue]?.translation?.lastnamePlaceholder} onChange={(e) => assignValue('lastName', e.target.value)} />
                                    {isSubmitTriggered && !feedBackDetail?.lastName && <p style={{ color: "red", margin: "0", marginBottom: '8px', direction: localizationValue == "en" ? "ltr" : "rtl" }}>{localization[localizationValue]?.translation?.LastNameErrorMessage}</p>}
                                </div>
                                <div style={{ width: '40%' }} className='title'>
                                    <label style={{ direction: localizationValue == "en" ? "ltr" : "rtl" }} className='mg_care__label' htmlFor='firstName'>{localization[localizationValue]?.translation?.firstNameLabel}<span style={{ color: "red", padding: "0 2px" }}>*</span></label>
                                    <input style={{ direction: localizationValue == "en" ? "ltr" : "rtl" }} type='text' className='mg_care__input' id='firstName' placeholder={localization[localizationValue]?.translation?.firstnamePlaceholder} onChange={(e) => assignValue('firstName', e.target.value)} />

                                    {isSubmitTriggered && !feedBackDetail?.firstName && <p style={{ color: "red", margin: "0", marginBottom: '8px', direction: localizationValue == "en" ? "ltr" : "rtl" }}>{localization[localizationValue]?.translation?.firstNameErrorMessage}</p>}
                                </div>
                                <div style={{ width: '20%' }} className='title'>
                                    <label style={{ direction: localizationValue == "en" ? "ltr" : "rtl" }} className='mg_care__label' htmlFor='title'>
                                        {localization[localizationValue]?.translation?.TitleLabel}
                                        <span style={{ color: "red", padding: "0 2px" }}>*</span></label>
                                    <select style={{ direction: localizationValue == "en" ? "ltr" : "rtl" }} placeholder="hi" className='mg_care__input' id='title' onChange={(e) => { assignValue('title', e.target.value) }} >
                                        <option value="">
                                            {localization[localizationValue]?.translation?.TitlePlaceholder}</option>
                                        {
                                            titleList?.map(_ => {
                                                return <option value={localizationValue == "en" ? _.label : _.arabicLabel}>{localizationValue == "en" ? _.label : _.arabicLabel}</option>
                                            })}
                                    </select>
                                    {isSubmitTriggered && !feedBackDetail?.title && <p style={{ color: "red", margin: "0", marginBottom: '8px', direction: localizationValue == "en" ? "ltr" : "rtl" }}>{localization[localizationValue]?.translation?.errorMessage}</p>}
                                </div>
                            </div>
                        }

                        <div style={{ display: 'flex', gap: '10px' }} className='mobile'>
                            <div style={{ width: '80%' }} className='title'>
                                <label style={{ direction: localizationValue == "en" ? "ltr" : "rtl" }} className='mg_care__label' htmlFor='mobileNumber'>{localization[localizationValue]?.translation?.MobileNumber}<span style={{ color: "red", padding: "0 2px" }}>*</span></label>
                                <IntlTelInput
                                    // className='mg_care__input'
                                    style={{ width: "100%", }}
                                    containerClassName="intl-tel-input phoneInputContainerStyle"
                                    inputClassName={localizationValue == "en" ? "phoneInputStyle" : "phoneInputStyle directionRtl"}
                                    onPhoneNumberChange={getMobileNumber}
                                    defaultCountry="bh"
                                    preferredCountries={["bh", "ae"]}
                                    ref={inputRef}

                                />
                                {isSubmitTriggered && !feedBackDetail?.mobileNumber && <p style={{ color: "red", margin: "0", marginBottom: '8px', marginTop: "8px", direction: localizationValue == "en" ? "ltr" : "rtl" }}>{localization[localizationValue]?.translation?.MobileNumberErrorMessage}</p>}
                                {feedBackDetail?.mobileNumber && isNaN(feedBackDetail?.mobileNumber) && <p style={{ color: "red", margin: "0", marginBottom: '8px', direction: localizationValue == "en" ? "ltr" : "rtl" }}>{localization[localizationValue]?.translation?.validMobileNumberErrorMessage}</p>}
                            </div>
                            <div style={{ width: '50%' }} className='title'>
                                <label style={{ direction: localizationValue == "en" ? "ltr" : "rtl" }} className='mg_care__label' htmlFor='email'>{localization[localizationValue]?.translation?.EmailId}<span style={{ color: "red", padding: "0 2px" }}>*</span></label>
                                <input style={{ direction: localizationValue == "en" ? "ltr" : "rtl" }} type='email' className='mg_care__input' id='email' placeholder={localization[localizationValue]?.translation?.EmailIdPlaceholder} onChange={(e) => assignValue('email', e.target.value)} />

                                {isSubmitTriggered && !feedBackDetail?.email && <p style={{ color: "red", margin: "0", marginBottom: '8px', direction: localizationValue == "en" ? "ltr" : "rtl" }}>{localization[localizationValue]?.translation?.EmailIdErrorMessage}</p>}
                                {isSubmitTriggered && !emailValid && feedBackDetail.email && <p style={{ color: "red", margin: "0", marginBottom: '8px', direction: localizationValue == "en" ? "ltr" : "rtl" }}>{localization[localizationValue]?.translation?.EmailValidErrorMessage}</p>}
                            </div>
                        </div>
                        <div style={{ display: 'flex', gap: '10px' }} className='mobile'>
                            <div style={{ width: '50%' }} className='title'>
                                <label style={{ direction: localizationValue == "en" ? "ltr" : "rtl" }} className='mg_care__label' htmlFor='nationality' >{localization[localizationValue]?.translation?.Nationality}<span style={{ color: "red", padding: "0 2px" }}>*</span></label>
                                <select style={{ direction: localizationValue == "en" ? "ltr" : "rtl" }} className='mg_care__input' id='nationality' onChange={(e) => assignValue('nationality', e.target.value)}>
                                    <option value="">{localization[localizationValue]?.translation?.nationalityPlaceholder}</option>
                                    {/* {nationalityList?.map((_) => {
                                        return <option value={_?.name?.common}>{_?.name?.common}</option>
                                    })} */}
                                    {nationalityLists?.map((_) => {
                                        return <option value={localizationValue == "en" ? _?.english_name : _?.arabic_name}>{localizationValue == "en" ? _?.english_name : _?.arabic_name}</option>
                                    })}
                                </select>
                                {isSubmitTriggered && !feedBackDetail?.nationality && <p style={{ color: "red", margin: "0", marginBottom: '8px', direction: localizationValue == "en" ? "ltr" : "rtl" }}>{localization[localizationValue]?.translation?.nationalityErrorMessage}</p>}
                            </div>
                            <div style={{ width: '50%' }} className='title'>
                                <label style={{ direction: localizationValue == "en" ? "ltr" : "rtl" }} className='mg_care__label' htmlFor='location'>{localization[localizationValue]?.translation?.Location}<span style={{ color: "red", padding: "0 2px" }}>*</span></label>
                                <input style={{ direction: localizationValue == "en" ? "ltr" : "rtl" }} type='text' className='mg_care__input' id='location' placeholder={localization[localizationValue]?.translation?.locationPlaceholder} onChange={(e) => assignValue('location', e.target.value)} />
                                {isSubmitTriggered && !feedBackDetail?.location && <p style={{ color: "red", margin: "0", marginBottom: '8px', direction: localizationValue == "en" ? "ltr" : "rtl" }}>{localization[localizationValue]?.translation?.LocationErrorMessage}</p>}
                            </div>
                        </div>
                        {localizationValue == "en" &&
                            <div style={{ display: 'flex', gap: '10px' }} className='mobile'>
                                <div style={{ width: '50%' }} className='title'>
                                    <label style={{ direction: localizationValue == "en" ? "ltr" : "rtl" }} className='mg_care__label' htmlFor='category'>{localization[localizationValue]?.translation?.Category}<span style={{ color: "red", padding: "0 2px" }}>*</span></label>
                                    <select style={{ direction: localizationValue == "en" ? "ltr" : "rtl" }} className='mg_care__input' id='category' onChange={(e) => { assignValue('category', e.target.value); getFilteredSubCategories(e.target.value) }}>
                                        <option value="">{localization[localizationValue]?.translation?.categoryPlaceholder}</option>
                                        {categoriesList?.map((_) => {
                                            return <option value={_?.id}>{localizationValue == "en" ? _?.categoryName : _?.categoryNameInAr}</option>
                                        })}
                                    </select>

                                    {isSubmitTriggered && !feedBackDetail?.category && <p style={{ color: "red", margin: "0", marginBottom: '8px', direction: localizationValue == "en" ? "ltr" : "rtl" }}>{localization[localizationValue]?.translation?.CategoryErrorMessage}</p>}

                                </div>
                                <div style={{ width: '50%' }} className='title'>
                                    <label style={{ direction: localizationValue == "en" ? "ltr" : "rtl" }} className='mg_care__label' htmlFor='subCategory'>{localization[localizationValue]?.translation?.SubCategory}<span style={{ color: "red", padding: "0 2px" }}>*</span></label>
                                    {/* <input type='text' className='mg_care__input' id='subCategory' placeholder='Enter sub category' /> */}
                                    <select style={{ direction: localizationValue == "en" ? "ltr" : "rtl" }} name="subcategory" className='mg_care__input' id="subCategory" onChange={(e) => assignValue('subCategory', e.target.value)} disabled={subCategoriesList?.length == 0} title={subCategoriesList?.length == 0 && 'Please choose category'} >
                                        <option value="">{localization[localizationValue]?.translation?.SubCategoryPlaceholder}</option>
                                        {subCategoriesList?.map((_) => {
                                            // console.log(subCategoriesList, "subCategoriesList");
                                            return <option value={_?.subCategoryId}>{localizationValue == "en" ? _?.subCategoryName : _?.subCategoryNameInArabic}</option>
                                        })}
                                    </select>
                                    {isSubmitTriggered && !feedBackDetail?.subCategory && <p style={{ color: "red", margin: "0", marginBottom: '8px', direction: localizationValue == "en" ? "ltr" : "rtl" }}> {localization[localizationValue]?.translation?.SubCategoryErrorMessage}</p>}
                                </div>
                            </div>
                        }
                        {localizationValue == "ar" &&
                            <div style={{ display: 'flex', gap: '10px' }} className='mobile'>
                                <div style={{ width: '50%' }} className='title'>
                                    <label style={{ direction: localizationValue == "en" ? "ltr" : "rtl" }} className='mg_care__label' htmlFor='subCategory'>{localization[localizationValue]?.translation?.SubCategory}<span style={{ color: "red", padding: "0 2px" }}>*</span></label>
                                    {/* <input type='text' className='mg_care__input' id='subCategory' placeholder='Enter sub category' /> */}
                                    <select style={{ direction: localizationValue == "en" ? "ltr" : "rtl" }} name="subcategory" className='mg_care__input' id="subCategory" onChange={(e) => assignValue('subCategory', e.target.value)} disabled={subCategoriesList?.length == 0} title={subCategoriesList?.length == 0 && 'Please choose category'} >
                                        <option value="">{localization[localizationValue]?.translation?.SubCategoryPlaceholder}</option>
                                        {subCategoriesList?.map((_) => {
                                            // console.log(subCategoriesList, "subCategoriesList");
                                            return <option value={_?.subCategoryId}>{localizationValue == "en" ? _?.subCategoryName : _?.subCategoryNameInArabic}</option>
                                        })}
                                    </select>
                                    {isSubmitTriggered && !feedBackDetail?.subCategory && <p style={{ color: "red", margin: "0", marginBottom: '8px', direction: localizationValue == "en" ? "ltr" : "rtl" }}> {localization[localizationValue]?.translation?.SubCategoryErrorMessage}</p>}
                                </div>
                                <div style={{ width: '50%' }} className='title'>
                                    <label style={{ direction: localizationValue == "en" ? "ltr" : "rtl" }} className='mg_care__label' htmlFor='category'>{localization[localizationValue]?.translation?.Category}<span style={{ color: "red", padding: "0 2px" }}>*</span></label>
                                    <select style={{ direction: localizationValue == "en" ? "ltr" : "rtl" }} className='mg_care__input' id='category' onChange={(e) => { assignValue('category', e.target.value); getFilteredSubCategories(e.target.value) }}>
                                        <option value="">{localization[localizationValue]?.translation?.categoryPlaceholder}</option>
                                        {categoriesList?.map((_) => {
                                            return <option value={_?.id}>{localizationValue == "en" ? _?.categoryName : _?.categoryNameInAr}</option>
                                        })}
                                    </select>

                                    {isSubmitTriggered && !feedBackDetail?.category && <p style={{ color: "red", margin: "0", marginBottom: '8px', direction: localizationValue == "en" ? "ltr" : "rtl" }}>{localization[localizationValue]?.translation?.CategoryErrorMessage}</p>}

                                </div>

                            </div>
                        }

                        <div >
                            <label style={{ direction: localizationValue == "en" ? "ltr" : "rtl" }} className='mg_care__label' htmlFor='feedback'>{localization[localizationValue]?.translation?.FeedbackLabel}<span style={{ color: "red", padding: "0 2px" }}>*</span></label>
                            <textarea style={{ direction: localizationValue == "en" ? "ltr" : "rtl" }} className='mg_care__input' id='feedback' placeholder={localization[localizationValue]?.translation?.feedbackPlaceholder} onChange={(e) => assignValue('feedback', e.target.value)}
                            />
                            {isSubmitTriggered && !feedBackDetail?.feedback && <p style={{ color: "red", margin: "0", marginBottom: '8px', direction: localizationValue == "en" ? "ltr" : "rtl" }}>{localization[localizationValue]?.translation?.feedbackErrorMessage
                            }</p>}
                        </div>
                        <div>
                            <label style={{ direction: localizationValue == "en" ? "ltr" : "rtl" }} className='mg_care__label' htmlFor='attachment'>{localization[localizationValue]?.translation?.Attachment}</label>
                            <div className='mg_care__file_upload'>
                                <div style={{ overflowY: "scroll", height: 60 }}>
                                    {imageAsset?.map(imageDetail => {
                                        return (
                                            <div div className='mg_care__file_item' style={{ width: "30%" }}>
                                                <div className='mg_care__filename_wrapper'>
                                                    <img src={filetypePng} alt="" />
                                                    <span className='mg_care__filename' >{addEllipsis(imageDetail?.fileName, 20)}</span>
                                                </div>
                                                <img src={x} alt="" className='mg_care__x' onClick={() => removeImage(imageDetail?.fileName)} />
                                            </div>
                                        )
                                    })}
                                </div>
                                <div style={{ padding: "10px 0" }}>
                                    <label style={{ direction: localizationValue == "en" ? "ltr" : "rtl" }} htmlFor="attachment">{localization[localizationValue]?.translation?.uploadFileLabel}</label>
                                    <input type='file' className='mg_care__input' id='attachment' onChange={getImages} />
                                </div>
                            </div>
                        </div>

                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Button className='mg_care__button' style={{ width: '100%', justifyContent: 'center', margin: "0 10px", background: "#adadad" }}
                                onClick={() => window.location.href = "/guestservicestaff"}
                            // onClick={back}
                            >{localization[localizationValue]?.translation?.cancelLabel}</Button>
                            <Button className='mg_care__button' style={{ width: '100%', justifyContent: 'center', margin: "0 10px" }} onClick={onSubmit} loading={isLoading} >{!isLoading && localization[localizationValue]?.translation?.submit}</Button>
                        </div>

                    </div>

                </div>
            </div>
            {/* <dialog style={{ position: "absolute" }}>
                <p>onilhlblkbvyjtudc</p>
            </dialog> */}
        </div >
    )
}


export default FeedbackForm