import React, { useState, useEffect } from 'react';
import UseCookies from '../../Helpers/UseCookies';
import './style.scss'
// -------------Imports---------------
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Tag } from 'primereact/tag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import FeedbackCategorycsv from '../../assets/files/FeedbackCategory.csv';

// -----------------------------------


// ----------Assets Import------------
import editIcon from '../../../src/assets/images/editIcon.jpg';
// -----------------------------------


//----------------Service Imports--------------------------
import { GetFeedbackCategoryList, AddFeedbackSubCategory, EditFeedbackCategory, ImportFeedbackCategory, GetFeedbackCategoriesForList } from '../../Services/FeedbackCategoryServices';
//------------------------------------------------------------------

//------------Context------------------------

import { useDispatch, useSelector } from "react-redux";
import { addFeedbackCategoryList, setNavVisibilty } from '../../Redux/AppSlice';
import { baseUrl } from '../../Helpers/Axios';
import { ExportOpenInNewTab, exportDataInExcel, exportExcel } from '../../Services/ExportServices';
import { FileUpload } from 'primereact/fileupload';
import Modalinfo from '../utili/Modalinfo';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';

//------------------------------------------------------------------


function FeedbackCategory() {
    const [cookies, setCookie, removeCookie] = UseCookies();
    const Context = useSelector((state) => state.AppContext);
    const dispatch = useDispatch();
    const [feedbackCategoryList, setFeedbackCategoryList] = useState([]);
    const [isAddFeedbackCategoryPopupOpen, setIsAddFeedbackCategoryPopupOpen] = useState(false)
    const [isAddFeedbackCategory, setIsAddFeedbackCategory] = useState({
        "categoryName": "",
        "categoryNameInAr": "",
        "isDeleted": false
    });
    const [isInvalid, setIsInvalid] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const navigate = useNavigate();
    const [isCategoryAlreadyExist, setIsCategoryAlreadyExist] = useState(false);
    const [isCategoryARExist, setIsCategoryARExist] = useState(false);

    const [isCategoryValid, setIsCategoryValid] = useState(false);
    const [isLoading, setIsLoading] = useState(true);




    // Export Filter
    const [exportFilterDialog, setExportFilterDialog] = useState(false);
    const [exportDetails, setExportDetails] = useState({
        startDate: "",
        endDate: "",
        organization: "",
        desk: "",
        status: true
    });


    // Filters
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [status, setStatus] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [totalCount, setTotalRecords] = useState(0)
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        // name: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const [isImportInvalid, setIsImportInvalid] = useState(false);
    const [isImport, setIsImport] = useState(false);
    const [isImportInvalidFile, setIsImportInvalidFile] = useState(false);
    const [isFileValidating, setFileValidating] = useState(false);
    const onCancelClick = () => {
        setShowConfirmation(true);
    };

    const onHide = () => {
        setShowConfirmation(false);
        setIsAddFeedbackCategoryPopupOpen(false);
        setIsAddFeedbackCategory({
            "categoryName": "",
            "categoryNameInAr": "",
            "isDeleted": false
        });
    };

    const handleConfirmation = (confirmed) => {
        if (confirmed) {
            onHide();
        } else {
            setShowConfirmation(false);
        }
    };

    const onGlobalFilterChange = (e) => {

        const value = e ? e.target.value : "";
        // let _filters = { ...filters };
        // _filters['global'].value = value;
        // setFilters(_filters);
        setGlobalFilterValue(value);
        getFeedbackCategoryList(value, status);
    };
    const onStatusFilterChange = (e) => {
        setStatus(e.target.value);
        getFeedbackCategoryList(globalFilterValue, e.target.value);
    };

    const back = () => {
        navigate('/dashboard');
    };



    const addFeedbackCategory = (() => {

        let data = {
            "categoryName": isAddFeedbackCategory?.categoryName,
            "categoryNameInAr": isAddFeedbackCategory?.categoryNameInAr,
            "isDeleted": isAddFeedbackCategory?.isDeleted
        }
        let filteredData = feedbackCategoryList?.filter((item) => item.categoryName === data.categoryName.trim())
        let filteredDataAR = feedbackCategoryList?.filter((item) => item.categoryNameInAr === data.categoryNameInAr.trim())
        if (isAddFeedbackCategory?.categoryName === "" || isAddFeedbackCategory?.categoryNameInAr === "") {
            setIsCategoryValid(true);
            return;
        }
        setIsCategoryValid(false);
        if (filteredData.length > 0) {
            setIsCategoryAlreadyExist(true);
            return;
        }
        setIsCategoryAlreadyExist(false);

        if (filteredDataAR.length > 0) {
            setIsCategoryARExist(true);
            return;
        }
        setIsCategoryARExist(false);


        setIsAddFeedbackCategoryPopupOpen(false);

        AddFeedbackSubCategory(data, cookies().bearerToken)
            .then(res => {

                if (res?.status == 200) {
                    // getFeedbackCategoryList();
                    // setIsAddFeedbackCategoryPopupOpen(false);
                    setIsAddFeedbackCategory({
                        "categoryName": "",
                        "categoryNameInAr": "",
                        "isDeleted": false,
                        "id": 0
                    });
                    setIsCategoryAlreadyExist(false);
                    setIsCategoryARExist(false);
                    setIsCategoryValid(false);
                    getFeedbackCategoryList();
                    toast.success("New Feedback Category is added Successfully", { position: "top-right" });
                } else {
                    toast.info("Something went wrong, Wihle adding category", { position: "top-right" });
                }
            })
            .catch(err => {
                //console.log(err);
            })
    });

    const editFeedbackCategory = (() => {

        let data = {
            "categoryName": isAddFeedbackCategory?.categoryName,
            "categoryNameInAr": isAddFeedbackCategory?.categoryNameInAr,
            "isDeleted": isAddFeedbackCategory?.isDeleted,
            "id": isAddFeedbackCategory?.id
        }
        let filteredData = feedbackCategoryList?.filter((item) => {
            return item.categoryName === data.categoryName && item.id !== isAddFeedbackCategory?.id;
        })
        let filteredDataAR = feedbackCategoryList?.filter((item) => {
            return item.categoryNameInAr === data.categoryNameInAr && item.id !== isAddFeedbackCategory?.id;
        })
        if (filteredData.length > 0) {
            setIsCategoryAlreadyExist(true);
            return;
        }
        setIsCategoryAlreadyExist(false);

        if (filteredDataAR.length > 0) {
            setIsCategoryARExist(true);
            return;
        }
        setIsCategoryARExist(false);

        if (isAddFeedbackCategory?.categoryName === "" || isAddFeedbackCategory?.categoryNameInAr === "") {
            setIsCategoryValid(true);
            return;
        }
        setIsCategoryValid(false);
        EditFeedbackCategory(data, cookies().bearerToken)
            .then(res => {

                if (res?.status == 200) {
                    // setIsAddFeedbackCategoryPopupOpen(false);
                    // getFeedbackCategoryList();
                    setIsAddFeedbackCategory({
                        "categoryName": "",
                        "categoryNameInAr": "",
                        "isDeleted": false,
                        "id": 0
                    })
                    setIsAddFeedbackCategoryPopupOpen(false);
                    setIsCategoryAlreadyExist(false);
                    setIsCategoryARExist(false);
                    getFeedbackCategoryList();
                    toast.success("Feedback Category is updated successfully", { position: "top-right" });
                } else {
                    toast.info("Something went wrong, Wihle editing category", { position: "top-right" });
                }
            })
            .catch(err => {
                //console.log(err);
            })

    })

    const statusBodyTemplate = ((row) => {
        // //console.log(row);
        if (row.isDeleted === false) {
            return <p style={{ color: "green" }}  >Active</p>
        }
        else {
            return <p style={{ color: "red" }} >Inactive</p>
        }
    });

    const actionButtonTemplate = (row) => {
        return (
            <div onClick={(e) => {
                setIsAdd(false);
                setIsAddFeedbackCategoryPopupOpen(true);
                setIsAddFeedbackCategory({
                    "categoryName": row.categoryName,
                    "categoryNameInAr": row.categoryNameInAr,
                    "isDeleted": row.isDeleted,
                    "id": row.id

                })
            }}>
                <img src={editIcon} style={{ cursor: 'pointer' }} />
            </div>
        );
    };

    const header = () => {
        return <div className="heading" style={{ fontWeight: "700", fontSize: "23px", color: "#ae9d81", textAlign: 'center' }}>
            <p style={{ margin: '0px' }}>{isAdd ? "Add" : "Edit"} Feedback Category Details</p>
        </div>
    };

    const popup = (() => {
        return (
            <>
                <Dialog header={header} closable={false} visible={true} style={{ width: '30vw' }} onHide={() => {
                    setIsCategoryAlreadyExist(false);
                    setIsCategoryARExist(false); setIsAddFeedbackCategoryPopupOpen(false); setIsCategoryValid(false);
                    setIsAddFeedbackCategory({
                        "categoryName": "",
                        "categoryNameInAr": "",
                        "isDeleted": false
                    });
                }}>
                    <div className="popup " >
                        {/* <div className="heading">
                            <p>Add New Details</p>
                        </div> */}
                        <div className="body">
                            <div className="label grid">
                                <div className='col'>Feedback Category Name<span style={{ color: 'red' }}>*</span></div>
                                <div className='col' style={{ position: 'absolute', left: '240px' }}>Status</div>
                            </div>
                            <div className="input grid ">
                                <div className="col">
                                    <InputText placeholder="Enter Name" value={isAddFeedbackCategory.categoryName} onChange={(e) => { setIsAddFeedbackCategory((prev) => ({ ...prev, categoryName: e.target.value })); setIsCategoryAlreadyExist(false) }} />
                                    {isCategoryAlreadyExist &&
                                        // <div>
                                        <p style={{ color: "red" }}>Feedback category name is already exists.</p>
                                        // </div>
                                    }
                                    {isCategoryValid && isAddFeedbackCategory?.categoryName == "" &&
                                        // <div>
                                        <p style={{ color: "red" }}>Feedback category name is required.</p>
                                        // </div>
                                    }
                                </div>
                                <div className="col ">

                                    <label className="switch ">
                                        <input type="checkbox" checked={!isAddFeedbackCategory.isDeleted} onChange={
                                            (e) => {
                                                setIsAddFeedbackCategory((prev) => ({ ...prev, isDeleted: !e.target.checked }))
                                            }

                                        } />
                                        <span className="slider round"></span>
                                    </label>
                                </div>

                            </div>
                            <div style={{ marginBottom: '10px' }}>Feedback Category Name AR<span style={{ color: 'red' }}>*</span></div>
                            <div >
                                <InputText placeholder="Enter Name AR" style={{ direction: 'rtl', textAlign: 'right' }} value={isAddFeedbackCategory.categoryNameInAr} onChange={(e) => { setIsCategoryARExist(false); setIsAddFeedbackCategory((prev) => ({ ...prev, categoryNameInAr: e.target.value })) }} />
                                {isCategoryARExist &&
                                    // <div>
                                    <p style={{ color: "red" }}>Feedback category name (AR) is already exists.</p>
                                    // </div>
                                }
                                {isCategoryValid && isAddFeedbackCategory?.categoryNameInAr == "" &&
                                    // <div>
                                    <p style={{ color: "red" }}>Feedback category name (AR) is required.</p>
                                    // </div>
                                }
                            </div>
                        </div>
                        <div className="footer flex  grid">
                            <div className="col flex justify-content-center ">
                                <Button label="Cancel" onClick={() => {
                                    setIsCategoryAlreadyExist(false);
                                    setIsCategoryARExist(false);
                                    setIsAddFeedbackCategoryPopupOpen(false);
                                    setIsCategoryValid(false);

                                    setIsAddFeedbackCategory({
                                        "categoryName": "",
                                        "categoryNameInAr": "",
                                        "isDeleted": false
                                    });
                                }} style={{ backgroundColor: '#8E8E8E', border: "none", margin: "10px", height: '40px' }} />
                                <Button label={isAdd ? "Submit" : "Update"} onClick={() => { isAdd ? addFeedbackCategory() : editFeedbackCategory() }} style={{ backgroundColor: '#ae9d81', border: "none", margin: "10px", height: '40px' }} />
                            </div>
                        </div>
                    </div>
                </Dialog>

            </>
        )
    });

    const getFeedbackCategoryList = (searchTerm, status, isExport = false) => {
        setIsLoading(true);
        GetFeedbackCategoriesForList(searchTerm, status, isExport)
            .then(res => {
                // //console.log(res);
                setFeedbackCategoryList(res.data);
                setTotalRecords(res?.data?.count);

            })
            .catch((err) => {
                console.warn(err);
                return;

            })
            .finally(() => {
                setIsLoading(false);
            })
    };

    const exportFeedbackCategory = () => {
        // window.open(baseUrl)
        exportDataInExcel('FeedbackCategory', cookies().bearerToken);

    };

    const onUpload = (e) => {
        //console.log(e?.files[0]);
        setFileValidating(true);
        if (e.files.length == 0) {
            setIsImportInvalid(true);
        }
        setIsImportInvalid(false);
        // if (e.files[0].name !== "Item.csv") {
        //     // alert("Please upload Tenantcsv file")
        //     setIsImportInvalidFile(true);
        //     e.files.pop()
        // }
        // else {
        setIsImportInvalidFile(false);
        const formData = new FormData();
        formData.append('file', e.files[0]);
        ImportFeedbackCategory(formData, cookies().bearerToken)
            .then((res) => {
                //console.log(res);
                if (res?.response?.status === 400) {
                    toast.info(res?.response?.data);
                    return
                }
                if (res.status === 200) {
                    toast.success("Feedback categories imported successfully");
                    setIsImport(false);
                    setFileValidating(false);
                    getFeedbackCategoryList();
                    setGlobalFilterValue("");
                }
            })
            .catch((err) => {
                //console.log(err);
                setFileValidating(false);
                if (err.response.status === 400) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error("Something went wrong");
                }
            });
        // }
    };
    const importHeader = () => {
        return <div className="heading" style={{ fontWeight: "700", fontSize: "23px", color: "#ae9d81", textAlign: 'center' }}>
            <p style={{ margin: '0px' }}>Import Feedback Category Details</p>
        </div>
    }
    const importPopup = (() => {
        return (<>
            <Dialog header={importHeader} visible={true} style={{ width: '50vw' }} closable={true} onHide={() => { setIsImport(false) }}>
                <div className="popup " >
                    <div className="body">
                        <div className="col">
                            <div className="label grid">
                                <div className='col'>
                                    Sample Template {" "}
                                    <i className="pi pi-download" style={{ color: 'slateblue', marginLeft: "7px", cursor: 'pointer' }}
                                        // on click i need to download a file 
                                        onClick={() => {
                                            // window.open(Itemcsv)
                                            var downloadLink = document.createElement("a");
                                            downloadLink.href = FeedbackCategorycsv;
                                            downloadLink.download = "FeedbackCategorycsv.csv";
                                            document.body.appendChild(downloadLink);
                                            downloadLink.click();
                                            document.body.removeChild(downloadLink);
                                        }}
                                    ></i>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="label grid">
                                <div className='col' style={{ display: "flex", alignItems: "center" }}>Select File<span style={{ color: 'red' }}>*</span> <Modalinfo /></div>
                            </div>
                            <div className="input grid ">
                                <div className="col">
                                    {/* <FileUpload mode="basic" name="demo[]" accept="*.csv" maxFileSize={1000000} onUpload={onUpload} chooseLabel="Browse" /> */}
                                    <FileUpload name="demo[]" accept=".csv" mode='basic' multiple={false} maxFileSize={1000000} customUpload uploadHandler={onUpload} emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>} />
                                    {/* {isFileValidating && <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i>} */}
                                    {isImportInvalid &&
                                        // <div>
                                        <p style={{ color: "red" }}>Please choose a file</p>
                                        // </div>
                                    }
                                    {isImportInvalidFile &&
                                        // <div>
                                        <p style={{ color: "red" }}>Name of the uploaded file should be same as the sample file.</p>
                                        // </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>)
    })

    const onExportFeedCategory = () => {
        // setExportFilterDialog(true);
        let urlRoute = `FeedbackCategory/GetCategoriesForList?searchTerm=${globalFilterValue}&status=${status}&forExport=true`;
        ExportOpenInNewTab(urlRoute);
    }



    useEffect(() => {
        getFeedbackCategoryList();
    }, [])
    return (
        <>
            <Dialog header={<h3>Export Filter</h3>} draggable={false} style={{ width: '50vw', height: "max-content" }} closable={true} onHide={() => setExportFilterDialog(false)} visible={exportFilterDialog} >
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="StartDate" className="font-bold block mb-2">Start Date
                        </label>
                        <Calendar
                            id="StartDate"
                            style={{ width: "95%" }}
                            value={exportDetails?.startDate}
                            placeholder="Choose start end"
                            onChange={((value) => setExportDetails(() => ({ ...exportDetails, startDate: value })))}
                        ></Calendar>
                    </div>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="EndDate" className="font-bold block mb-2">End Date
                        </label>
                        <Calendar
                            id="EndDate"
                            style={{ width: "95%" }}
                            value={exportDetails?.endDate}
                            placeholder="Choose end date"
                            onChange={((value) => setExportDetails(() => ({ ...exportDetails, endDate: value })))}
                        ></Calendar>
                    </div>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="organization" className="font-bold block mb-2">Organization
                        </label>
                        <InputText
                            id="organization"
                            style={{ width: "95%" }}
                            value={exportDetails?.organization}
                            onChange={((value) => setExportDetails(() => ({ ...exportDetails, organization: value })))}
                            placeholder="Enter organization" ></InputText>
                    </div>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="Status" className="font-bold block mb-2">Status
                        </label>
                        {/* <InputText
                                id="Status"
                                style={{ width: "95%" }}
                                value={exportDetails?.status}

                                placeholder="Enter organization" ></InputText> */}
                        <div className="input grid ">
                            <div className="col ">
                                <label className="switch ">
                                    <input type="checkbox" value={exportDetails?.status} checked={exportDetails?.status} onChange={((e) => setExportDetails(() => ({ ...exportDetails, status: e.target.checked })))} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>

                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "25px",
                    }}
                >
                    <Button
                        label="Submit"
                        onClick={''}
                        // icon={"pi pi-file-export"}
                        style={{
                            backgroundColor: " #ae9d81",
                            color: "#fff",
                            border: "none",
                            // borderRadius: "20px",
                            marginRight: "0",
                            cursor: totalCount > 0 ? "pointer" : "not-allowed",
                            padding: "0.6rem",
                        }}
                    />
                </div>


            </Dialog>
            <div className="feedbackCategoryMaster">
                <div className="header">
                    <div style={{ display: "flex", flexDirection: "row", flexGrow: "3", justifyContent: "flex-start" }} className='flex align-items-center org'>
                        <i className="pi pi-arrow-circle-left nav-back-btn" onClick={back} style={{ marginLeft: '30px', cursor: 'pointer', fontSize: '1.5rem' }} />
                        <div className="heading">
                            <p>Feedback Category</p>
                        </div>
                    </div>
                    <div className='mx-2'>
                        <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Search</h4>
                        <div className="search">
                            <span className="p-input-icon-left">
                                <i className="pi pi-search" style={{ color: 'black', fontWeight: '700', fontSize: '1rem' }} />
                                <InputText placeholder="Search" value={globalFilterValue} onChange={onGlobalFilterChange} />
                                {globalFilterValue && <i className="pi pi-times" style={{ color: 'black', fontWeight: '400' }} onClick={() => { setGlobalFilterValue(""); onGlobalFilterChange() }} />}
                            </span>
                        </div>
                    </div>
                    <div className='mx-2'>
                        <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Status</h4>
                        <div className="search">
                            <span className="p-input-icon-left">
                                <Dropdown placeholder="Status" showClear={status != null} options={[{ "label": "Active", "value": true }, { "label": "In Active", "value": false }]} value={status} optionLabel='label' optionValue='value' onChange={onStatusFilterChange} />
                            </span>
                        </div>
                    </div>

                    <div className="align-self-start mt-1 mx-2">
                        <Button label="Add New" onClick={() => { setIsAddFeedbackCategoryPopupOpen(true); setIsAdd(true) }
                        } style={{ backgroundColor: '#ae9d81', border: 'none' }} />
                    </div>
                    <div className='align-self-start mx-2'>
                        <Button label='Export' onClick={() => { onExportFeedCategory() }} icon={"pi pi-file-export"} style={{ backgroundColor: 'transparent', color: '#ae9d81', border: '1px #ae9d81 solid', borderRadius: '20px', cursor: feedbackCategoryList?.length > 0 ? 'pointer' : 'not-allowed' }} />
                    </div>
                    <div className='align-self-start mx-2'>
                        <Button label='Import' icon={"pi pi-file-import"} style={{ backgroundColor: 'transparent', color: '#ae9d81', border: '1px #ae9d81 solid', borderRadius: '20px' }} onClick={() => { setIsImport(true) }} />
                    </div>
                </div>

                <div className="datatable">
                    {isLoading ?
                        <div className='spinner spinner-center'></div>
                        :
                        <DataTable value={feedbackCategoryList?.length > 0 ? feedbackCategoryList : []} tableStyle={{ width: '90rem' }}
                            style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }} stripedRows
                            globalFilterFields={['categoryName']} filters={filters}
                            emptyMessage={<><p style={{ textAlign: "center" }}>No feedback categories found</p></>} paginator rows={10}>

                            <Column field="categoryName" header="Category Name " style={{ width: '25%' }}></Column>
                            <Column field="categoryNameInAr" header="Category Name(AR)" style={{ width: '25%' }}></Column>
                            <Column field="isDeleted" header="Status" body={statusBodyTemplate} style={{ width: '25%' }} ></Column>
                            <Column rowEditor header="Action" body={actionButtonTemplate} style={{ width: '25%' }}></Column>


                        </DataTable>}
                </div>
            </div>

            <div>
                {isAddFeedbackCategoryPopupOpen == true ? popup() : ""}
                {isImport == true ? importPopup() : ""}
            </div>
        </>
    )
}

export default FeedbackCategory