import style from "./style.module.scss";
import React, { useEffect, useState } from "react";
// -------------Imports---------------
// import { Button } from 'primereact/button'
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import classNames from "classnames";

import { useNavigate } from "react-router-dom";

// -----------------------------------

// -----------------------------------

//------Imports from other Components-----
import {
  GetChatList,
  GetFeedbackById,
  GetFeedbackList,
  GuestStaffSupervisorEditFeedbacks,
} from "../../Services/FeedbackService";
import { NewChat } from "../../Services/FeedbackService";
//----------------------------------------

//-----------Context ---------------
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  GetFeedbackDepartmentByFeedbackId,
  GetStatusForFeedback,
} from "../../Services/GuestSatffInspection";
import { GetAllFiles } from "../../Services/Files";
import { CiImageOn } from "react-icons/ci";
import { ToastContainer, toast } from "react-toastify";

import { DownloadFile } from "../../Services/Files";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import UseCookies from "../../Helpers/UseCookies";

function GuestServiceSupervisorView() {
  const { id, status } = useParams();
  const Context = useSelector((state) => state.AppContext);
  const [feedbackData, setFeedbackData] = useState([]);
  const [chatList, setChatList] = useState([]);
  const [chat, setChat] = useState("");
  const [cookies, setCookie, removeCookie] = UseCookies();
  const [chatError, setChatError] = useState("");
  const navigate = useNavigate();
  const [departmentDetails, setDepartmentDetails] = useState({});
  const [statusList, setStatusList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [activeStatus, setActiveStatus] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isInvalid, setIsInvalid] = useState(false);
  const [feedbackLoading, setFeedbackLoading] = useState(false);

  const formatDate = (timestamp) => {
    const timestampDate = new Date(timestamp);

    return timestampDate.toLocaleString("en-US", {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  const getChatList = () => {
    setIsLoading(true);
    GetChatList(cookies().bearerToken, id)
      .then((res) => {
        if (res?.status == 200) {
          setChatList(res?.data);
        }
      })
      .catch((error) => {
        //console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  // const newChat = () => {
  //     if (!chat.trim()) {
  //         setChatError("No messages found")
  //         return
  //     }
  //     const data = {
  //         feedbackId: id,
  //         message: chat.trim()
  //     }
  //     NewChat(data, cookies().bearerToken)
  //         .then((res) => {
  //             //console.log(res)
  //             setChatList(prev => {
  //                 return [...prev, {
  //                     id: -1,
  //                     messageFrom: localStorage.getItem("userName"),
  //                     message: chat.trim(),
  //                     messageDate: new Date().toString()
  //                 }]
  //             })

  //             setChat('')

  //             // dispatch(
  //             //     addChatList({
  //             //         chatList: res?.data
  //             //     }))
  //         })
  //         .catch((error) => {
  //             //console.log(error);
  //         })
  //         .finally(() => {
  //             // //console.log(chatList);
  //         })

  // }
  const sendChat = () => {
    if (!chat.trim()) {
      // setChatError("No messages found")
      return;
    }
    const data = {
      feedbackId: id,
      message: chat.trim(),
    };
    NewChat(data, cookies().bearerToken)
      .then((res) => {
        //console.log(res);
        setChatList((prev) => {
          return [
            ...prev,
            {
              id: -1,
              messageFrom: localStorage.getItem("userName"),
              message: chat.trim(),
              messageDate: new Date()?.toString(),
            },
          ];
        });
        setChat("");
        // dispatch(
        //     addChatList({
        //         chatList: res?.data
        //     }))
      })
      .catch((error) => {
        //console.log(error);
      })
      .finally(() => {
        // //console.log(chatList);
      });
  };

  const closePopup = () => {
    // setIsAddOrganization(false);
    navigate("/guestservicesupervisor");
  };
  // //console.log(feedbackData, "feedbackDatafeedbackData");
  const checkStatus = () => {
    return (
      feedbackData[0]?.status == "Resolved" ||
      feedbackData[0]?.status == "Closed"
    );
  };
  const header = () => {
    return (
      <div
        className="heading"
        style={{ fontWeight: "700", fontSize: "25px", color: "#ae9d81" }}
      ></div>
    );
  };
  const getFeedbackDepartmentByFeedbackId = async () => {
    await GetFeedbackDepartmentByFeedbackId(id, cookies().bearerToken).then(
      (res) => {
        if (res?.status == 200) {
          //console.log(res?.data, "dep");
          setDepartmentDetails(res?.data);
        }
      }
    );
  };
  const getStatusForFeedback = async () => {
    await GetStatusForFeedback(id, cookies()?.bearerToken).then((res) => {
      if (res?.status == 200) {
        setStatusList(res?.data);
        //console.log(res?.data, "staus");
      }
    });
  };
  const getAllFiles = async () => {
    await GetAllFiles(
      { id: id, inspectionId: "0", isForInspection: false, module: "Feedback" },
      cookies()?.bearerToken
    ).then((res) => {
      if (res?.status == 200) {
        setFileList(res?.data);
        //console.log(res?.data, "allfile");
      }
    });
  };
  const guestStaffSupervisorEditFeedbacks = async () => {
    setFeedbackLoading(true);

    if (Object.keys(activeStatus).length == 0) {
      setIsInvalid(true);
      return;
    }
    setIsInvalid(false);
    await GuestStaffSupervisorEditFeedbacks(
      { feedbackId: id, statusId: activeStatus?.id },
      cookies()?.bearerToken
    )
      .then((res) => {
        if (res?.status == 200) {
          //console.log("statsus updated");
          toast.success("Feedback status updated successfully", {
            position: "top-right",
          });
          navigate("/guestservicesupervisor");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setFeedbackLoading(false);
      });
  };

  const downloadFile = async (filename, filePath) => {
    DownloadFile(filename, filePath, cookies()?.bearerToken);
  };
  const getFeedbackById = async (feedbackId) => {
    await GetFeedbackById(cookies().bearerToken, feedbackId)
      .then((res) => {
        if (res?.status === 200) {
          setFeedbackData(res?.data);
          // setFeedbackData(Context.feedbackList?.filter((_) => _?.id == id));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getChatList();
    getFeedbackById(id);
    getFeedbackDepartmentByFeedbackId();
    getStatusForFeedback();
    getAllFiles();
  }, []);
  // useEffect(() => {
  //     //console.log(Context.feedbackList);
  //     if (Context.feedbackList?.length > 0) {
  //         setFeedbackData(Context.feedbackList?.filter((_) => _?.feedbackId == id));
  //     }
  // }, [Context.feedbackList, id]);
  //console.log(departmentDetails);
  return (
    <div>
      <div header={header} visible={true} style={{}}>
        {/* <ToastContainer /> */}
        <div className={style.head}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            className={style.arrowIcon}
            onClick={closePopup}
          />
          Edit Feedback Details
        </div>
        <div className={classNames(style.flex, style.forMargin)}>
          <div className="flex-1 p-4 flexbox-1">
            <div className="defaultWidth">
              <p className={style.defaultStyle}>Name{Context.name}</p>
              <InputText
                className={style.border}
                value={feedbackData[0]?.userName}
                // value={`${selectedFeedback?.firstName} ${selectedFeedback?.lastName}`}
                readOnly
              />
            </div>
            <div className="defaultWidth">
              <p className={style.defaultStyle}>Mobile Number</p>
              <InputText
                className={style.border}
                value={feedbackData[0]?.mobileNumber}
                readOnly
              />
            </div>
            <div className="defaultWidth">
              <p className={style.defaultStyle}>Email</p>
              <InputText
                className={style.border}
                value={feedbackData[0]?.email}
                readOnly
              />
            </div>
            <div className="defaultWidth">
              <p className={style.defaultStyle}>Nationality</p>
              <InputText
                className={style.border}
                value={feedbackData[0]?.nationality || "-"}
                readOnly
              />
            </div>
            <div className="defaultWidth">
              <p className={style.defaultStyle}>Attachments</p>
              <div
                style={{
                  height: "100px",
                  overflow: "auto",
                  width: "350px",
                  border: "solid 1px #AE9D81",
                  borderRadius: "4px",
                  padding: "10px",
                }}
              >
                {fileList?.map((_) => {
                  return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CiImageOn style={{ fontSize: 20 }} />
                      <p
                        style={{
                          fontSize: "12px",
                          padding: "0 10px",
                          cursor: "pointer",
                        }}
                        onClick={() => downloadFile(_?.fileName, _?.blobUrl)}
                      >
                        {_.fileName}
                      </p>
                    </div>
                  );
                })}
              </div>
              {/* <InputText className={classNames(style.border, style.attachment)} /> */}
            </div>
            {/* <p className={style.heading}>Guest Service Details</p> */}
            <div className="defaultWidth">
              <p className={style.defaultStyle}>Department</p>
              <Dropdown
                optionLabel="departmentName"
                options={[departmentDetails]}
                className={style.border}
                optionValue="departmentName"
                disabled
                value={departmentDetails?.departmentName}
              />
            </div>
          </div>
          <div className="flex-1  p-4 flexbox-2">
            <div className="defaultWidth">
              <p className={style.defaultStyle}>Category</p>
              <InputText
                className={style.border}
                value={feedbackData[0]?.category}
                readOnly
              />
            </div>
            <div className="defaultWidth">
              <p className={style.defaultStyle}>Sub Category</p>
              <InputText
                className={style.border}
                value={feedbackData[0]?.subCategory}
                readOnly
              />
            </div>
            <div className="defaultWidth">
              <p className={style.defaultStyle}>Location</p>
              <InputText
                className={style.border}
                value={feedbackData[0]?.location}
                readOnly
              />
            </div>
            <div className={style.feedbackBox}>
              <p className={style.defaultStyle}>Feedback</p>
              {/* <InputText
                className={classNames(style.border, style.feedback)}
                value={feedbackData[0]?.comment}
              /> */}
              <p
                className={classNames(style.border, style.feedback)}
                style={{
                  fontWeight: "100",
                  border: "1px solid #ae9d81",
                  width: "350px",
                  padding: "10px",
                  borderRadius: "5px",
                  wordWrap: "break-word",
                  overflow: "scroll",
                  overflowX: "hidden",
                  marginTop: "0px",
                }}
              >
                {feedbackData[0]?.comment}
              </p>
            </div>
            <div
              className={classNames(style.defaultwidth, style.status)}
              style={{ marginTop: "20px" }}
            >
              <p className={style.defaultStyle}>Status</p>
              {checkStatus() ? (
                <Dropdown
                  placeholder="Select Status"
                  options={statusList}
                  optionLabel="name"
                  className={style.border}
                  onChange={(e) => setActiveStatus(e?.value)}
                  value={activeStatus}
                />
              ) : (
                <InputText
                  className={classNames(style.border)}
                  value={feedbackData[0]?.status}
                  readOnly
                  disabled
                />
              )}
              {
                isInvalid && Object.keys(activeStatus).length == 0 && (
                  // <div>
                  <p style={{ color: "red" }}>Status is required</p>
                )
                // </div>
              }
            </div>
            <div
              className="flex justify-content-end"
              style={{ marginTop: "60px" }}
            >
              <Button
                className={style.cancelBtn}
                style={{ cursor: "pointer" }}
                onClick={closePopup}
              >
                Cancel
              </Button>
              {checkStatus() ? (
                <Button
                  className={style.submitBtn}
                  loading={feedbackLoading}
                  style={{ cursor: "pointer" }}
                  onClick={guestStaffSupervisorEditFeedbacks}
                >
                  Submit
                </Button>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="vl"></div>
          <div
            style={{
              width: "30%",
              margin: "5px",
              height: "600px",
            }}
          >
            <div
              style={{
                display: "flex",
                padding: "6px 10px",
                backgroundColor: "#ae9d81",
                margin: "10px 10px",
                justifyContent: "center",
              }}
            >
              <p style={{ color: "#fff", margin: 0 }}>Chat Box</p>
            </div>
            <div style={{ margin: "5px" }}>
              <InputTextarea
                // type="text"
                value={chat}
                rows={2}
                className="p-inputtext-lg"
                style={{
                  width: "88%",
                  height: "40px",
                  fontSize: "15px",
                  marginLeft: "5px",
                  overflowWrap: "break-word",
                  overflow: "scroll",
                  overflowX: "hidden",
                  overflowY: "hidden",
                }}
                placeholder="Comments"
                onChange={(e) => {
                  setChat(e.target.value);
                }}
              />
              <i
                className="pi pi-send"
                style={{
                  fontSize: "1.5rem",
                  color: "#AE9D81",
                  margin: "10px",
                  transform: "rotateZ(45deg)",
                  cursor: "pointer",
                }}
                onClick={() => {
                  sendChat();
                }}
              ></i>
            </div>
            {isLoading ? (
              <div
                className="spinner spinner-center"
                style={{ marginLeft: "200px", width: "30px", height: "30px" }}
              ></div>
            ) : (
              <div>
                {chatList?.length !== 0 ? (
                  <div div className="flex-1 p-4 flexbox-3">
                    <div style={{ overflow: "auto", height: "650px" }}>
                      {chatList.map((chat, index) => (
                        <div
                          key={chat.id}
                          className={
                            localStorage.getItem("userName") ===
                              chat.messageFrom
                              ? "user2"
                              : "user"
                          }
                        >
                          <div
                            style={{
                              fontWeight: 200,
                              fontSize: "15px",
                              margin: "0px 0px 5px 0px",
                            }}
                          >
                            {chat?.messageFrom}
                          </div>
                          <p
                            style={{
                              fontWeight: 400,
                              margin: "0px",
                              wordWrap: "break-word",
                              width: "250px",
                            }}
                          >
                            {chat?.message}
                          </p>

                          <span
                            style={{
                              fontWeight: 200,
                              fontSize: "15px",
                              margin: "0px 0px -5px 0px",
                            }}
                          ></span>
                          <span
                            style={{
                              fontWeight: 200,
                              fontSize: "15px",
                              margin: "0px 0px -5px 0px",
                              float: "right",
                            }}
                          >
                            {formatDate(chat.messageDate)}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div>
                    <div style={{ padding: "100px 0" }}>
                      <p style={{ textAlign: "center" }}>No Chats Found</p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          {/* {chatError} */}
        </div>
      </div>
    </div>
  );
}
export default GuestServiceSupervisorView;
