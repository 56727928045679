import React from 'react';
import './DTFeedbackstyle.scss'
import { useEffect, useState } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import {
    GetActiveFeedbackStatus,
    getCategories,
    getSubCategories,
} from "../../../Services/FeedbackService";

import {
    GetFeedbacks,
    GetGuestInspectionsInfo,
    GetGuestStaffInspections,
    GetSearchedFeedbacks,
    getSearchedFeedbacks,
    getSearchedInspectionForDashboard,
} from "../../../Services/DepartmentTeamService";
import { GetFeedbackList } from "../../../Services/FeedbackService";
import { FilterMatchMode } from "primereact/api";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Paginator } from "primereact/paginator";
import { exportDataInExcel } from "../../../Services/ExportServices";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";

// ----------Assets Import------------
import editIcon from "../../../../src/assets/images/editIcon.jpg";

//-----------Context ---------------
import { useDispatch, useSelector } from "react-redux";
import {
    setGuestInspectionsInfo,
    setFeedbackList,
    setNavVisibilty,
} from "../../../Redux/AppSlice";
import { Dropdown } from "primereact/dropdown";
import {
    EditGuesStaffInspectionStatus,
    GuestInspectionStatuses,
} from "../../../Services/InspectionService";
import { GetAllFiles } from "../../../Services/Files";
import FileView from "../../utili/FileView";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import UseCookies from '../../../Helpers/UseCookies';
import { toast } from 'react-toastify';
import { GetActiveOrganizationList } from '../../../Services/OrganizationService';
import { GetActiveDepartments } from '../../../Services/DepartmentService';

function DTFeedback() {
    const { tab } = useParams();

    const Context = useSelector((state) => state.AppContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = UseCookies();
    const [activeIndex, setActiveIndex] = useState(tab - 1);
    const [guestInspectionsList, setGuestInspectionsList] = useState([]);
    const [feedbackList, setFeedbackLists] = useState("");
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [guestStaffInspectionFilterValue, setGuestStaffInspectionValue] =
        useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [statusList, setStatusList] = useState([{ id: "", name: "" }]);
    const [isFilemodelView, setISFileModelView] = useState(false);
    const [imageList, setImageList] = useState([]);
    const [activeDepartments, setActiveDepartments] = useState([]);
    const [activeFeedbackStatus, setActiveFeedbackStatus] = useState([]);
    // Pagination
    const [first, setFirst] = useState(0);
    const [totalCount, setTotalRecords] = useState(0);
    const [feedbackTotalCount, setFeebackTotalRecords] = useState(0);
    const [guestStaffInspection, setGuestStaffInspection] = useState("");
    const [dialogVisible, setDialogVisible] = useState(false);
    const [dropDownObj, setDropDownObj] = useState({
        startDate: "",
        endDate: "",
        category: {},
        subCategory: {},
        location: "",
        activeDepartmentList: [],
        activeFeedbackStatus: []
    });

    // Filters
    const [IsFilters, setisFilters] = useState(false);
    const [dropdownObj, setDropdownObj] = useState({
        categoryList: [],
        subCategoryList: [],
        organizationList: [],
        activeDepartmentList: [],
        activeFeedbackStatus: []
    });
    const [exportObj, setExportObj] = useState({
        fromDate: "",
        toDate: "",
        searchTerm: "",
        category: "",
        subcategory: "",
        organization: "",
        isModalVisible: false,
        department: "",
        status: ""
    });
    // ----------------------------------------------------------------
    const [categoriesList, setCategoriesList] = useState([
        {
            id: 0,
            categoryName: "",
            categoryNameInAr: "",
            isDeleted: false,
        },
    ]);
    const [recordSubCategoriesList, setRecordSubCategoriesList] = useState([
        {
            subCategoryId: 0,
            subCategoryName: "",
            categoryName: "",
            createdOn: "",
            createdBy: "",
            isDeleted: false,
        },
    ]);
    const onGlobalFilterChange = (e) => {
        setGlobalFilterValue(e ? e?.target?.value : "");
        setFirst(0);
        if (!e) {
            getFeedbackList(0, 10);
            return;
        }
        getFeedbackList(0, 10, e?.target?.value);
    };
    const dateBodyTemplateTwo = (row) => {
        let date = new Date(row?.createdDate);
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    };
    const statusBodyTemplate = (row) => {
        if (row.status == "Completed") {
            return <p style={{ color: "#3ee63e" }}>Completed</p>;
        } else if (row.status == "Rejected") {
            return <p style={{ color: "red" }}>Rejected</p>;
        } else if (row.status == "Resolved") {
            return <p style={{ color: "#f553e7" }}>Resolved</p>;
        } else if (row.status == "Out of control") {
            return <p style={{ color: "#6ecbfa" }}>Out of control</p>;
        } else if (row.status == "Yet to Ackowledge") {
            return <p style={{ color: "#964B00" }}>Yet to Acknowledge</p>;
        } else if (row.status == "Assigned") {
            return <p style={{ color: "#bd16f5" }}>Assigned</p>;
        } else if (row?.status == "Reassign") {
            return <p style={{ color: "#1512cc" }}>Reassign</p>;
        } else if (row?.status == "Closed") {
            return <p style={{ color: "#3ee63e" }}>Closed</p>;
        } else if (row?.status == "Not Related to Work") {
            return <p style={{ color: "#f68181" }}>Not Related to Work</p>;
        }
    };
    const actionButtonTemplate1 = (row) => {
        return (
            <img
                src={editIcon}
                onClick={() => {
                    navigate(`/departmentstaffview/${row?.id}`);
                }}
                style={{ cursor: "pointer" }}
            />
        );
    };
    const onFeedbackListPageChange = (e, index) => {
        setFirst(e.first);
        getFeedbackList(e.first, 10, globalFilterValue, exportObj.category, exportObj?.subcategory, exportObj?.organization, exportObj?.fromDate, exportObj?.toDate, exportObj.status, exportObj.department);
    };
    const getFeedbackList = (skip, take, searchValue, categoryId, subCategoryId, orgId, startDate, endDate, feedbackStatusId, departmentId) => {
        setIsLoading(true);
        let data = {
            take: 10,
            skip: skip
        }
        if (searchValue) {
            data["searchTerm"] = searchValue;
        }
        const fromDate = !startDate ? "" : `${new Date(startDate)?.getFullYear()}-${(new Date(startDate)?.getMonth() + 1)?.toString()?.padStart(2, "0")}-${new Date(startDate)?.getDate()?.toString()?.padStart(2, "0")}`;
        const toDate = !endDate ? "" : `${new Date(endDate)?.getFullYear()}-${(new Date(endDate)?.getMonth() + 1)?.toString()?.padStart(2, "0")}-${new Date(endDate)?.getDate()?.toString()?.padStart(2, "0")}`;
        GetFeedbackList(data, cookies().bearerToken, categoryId, subCategoryId, orgId, fromDate, toDate, feedbackStatusId, departmentId)
            .then((res) => {

                if (res?.status == 200) {
                    // if (res?.status === 200) {
                    setFeedbackLists(res?.data?.feedbacks);
                    setTotalRecords(res?.data?.totalRecords)
                    // }
                    dispatch(setFeedbackList({ feedbackList: res?.data?.feedbacks }))
                }
                else {
                    dispatch(setFeedbackLists([]))
                }
            })
            .catch((error) => {
                //console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            })
    };
    const DateConversion = (inputDateStr) => {
        if (inputDateStr != "") {
            const decodedDateStr = decodeURIComponent(inputDateStr);
            const dateObj = new Date(decodedDateStr);
            const year = dateObj.getFullYear();
            const month = String(dateObj.getMonth() + 1).padStart(2, '0');
            const day = String(dateObj.getDate()).padStart(2, '0');
            const formattedDate = `${year}-${month}-${day}`;
            return formattedDate
        }
        else {
            return "";
        }
    };
    const exportFeedbacksForDT = () => {
        exportDataInExcel(
            `FeedbacksForDT?searchTerm=${globalFilterValue}&from=${DateConversion(dropDownObj?.startDate)}&to=${DateConversion(dropDownObj?.endDate)}&feedbackCategory=${dropDownObj?.category?.id || 0}&feedbackSubcategory=${dropDownObj?.subCategory?.subCategoryId || 0}&location=${dropDownObj?.location}`,
            cookies().bearerToken
        );
    };
    const getCategoriesList = async () => {
        await getCategories()
            .then((res) => {
                // console.log(res?.data);
                if (res?.status === 200) {
                    setDropdownObj((prev) => ({ ...prev, categoryList: res?.data }));
                }
            })
            .catch((err) => {
                // console.log(err);
            });
    };
    const getSubcategoriesList = async (categoryId) => {
        await getSubCategories(categoryId)
            .then((res) => {
                // console.log(list?.data);
                if (res?.data) {
                    setDropdownObj((prev) => ({ ...prev, subCategoryList: res?.data?.data }));
                }
            });
    };
    const getActiveDepartments = async () => {
        await GetActiveDepartments(cookies()?.bearerToken)
            .then((res) => {
                // console.log(res?.data);
                if (res?.status === 200) {
                    setDropdownObj((prev) => ({ ...prev, activeDepartmentList: res?.data }));
                }
            })
            .catch((err) => {
                // console.log(err);
            });
    };
    const getActiveFeedbackStatus = async () => {
        await GetActiveFeedbackStatus(cookies()?.bearerToken)
            .then((res) => {
                // console.log(res?.data);
                if (res?.status === 200) {
                    setDropdownObj((prev) => ({ ...prev, activeFeedbackStatus: res?.data }));
                }
            })
            .catch((err) => {
                // console.log(err);
            });
    };
    const getOrganizationList = () => {
        GetActiveOrganizationList(cookies()?.bearerToken).then(res => {
            if (res?.status == 200) {
                setDropdownObj((prev) => ({ ...prev, organizationList: res?.data }));
            }
        })
    }

    useEffect(() => {
        getOrganizationList();
        getCategoriesList();
        getSubcategoriesList();
        getFeedbackList(0, 10);
        getActiveDepartments();
        getActiveFeedbackStatus();

    }, []);

    return (
        <div className="DTFeedback max-w-full	">
            <Dialog
                header="Export Filter"
                visible={dialogVisible}
                style={{ width: "50vw" }}
                onHide={() => {
                    setDialogVisible(false);
                    setDropDownObj({
                        ...dropDownObj,
                        startDate: "",
                        endDate: "",
                        category: {},
                        subCategory: {},
                    });
                }}
            >
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="startDate" className="font-bold block mb-2">
                            Start Date
                        </label>
                        <Calendar
                            style={{ width: "95%" }}
                            id="startDate"
                            value={dropDownObj.startDate}
                            placeholder="Choose start date"
                            onChange={(e) =>
                                setDropDownObj({ ...dropDownObj, startDate: e.value })
                            }
                            showIcon
                        />
                    </div>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="endDate" className="font-bold block mb-2">
                            End Date
                        </label>
                        <Calendar
                            style={{ width: "100%" }}
                            id="endDate"
                            placeholder="Choose end date"
                            value={dropDownObj.endDate}
                            onChange={(e) =>
                                setDropDownObj({ ...dropDownObj, endDate: e.value })
                            }
                            showIcon
                        />
                    </div>
                    <div style={{ width: "50%", marginTop: "25px" }}>
                        <label htmlFor="category" className="font-bold block mb-2">
                            Category
                        </label>
                        <Dropdown
                            id="category"
                            style={{ width: "95%" }}
                            value={dropDownObj.category}
                            onChange={(e) =>
                                setDropDownObj({ ...dropDownObj, category: e.value })
                            }
                            options={categoriesList}
                            optionLabel="categoryName"
                            placeholder="Select a Category"
                        />
                    </div>
                    <div style={{ width: "50%", marginTop: "25px" }}>
                        <label htmlFor="subCategory" className="font-bold block mb-2">
                            Sub Category
                        </label>
                        <Dropdown
                            id="subCategory"
                            style={{ width: "100%" }}
                            value={dropDownObj.subCategory}
                            onChange={(e) =>
                                setDropDownObj({ ...dropDownObj, subCategory: e.value })
                            }
                            options={recordSubCategoriesList}
                            optionLabel="subCategoryName"
                            placeholder="Select a Subcategory"
                        />
                    </div>
                    <div style={{ width: "50%", marginTop: "25px" }}>
                        <label htmlFor="location" className="font-bold block mb-2">
                            Location
                        </label>
                        <InputText
                            id="location"
                            style={{ width: "100%" }}
                            value={dropDownObj?.location}
                            onChange={(e) =>
                                setDropDownObj({ ...dropDownObj, location: e.value })
                            }
                            placeholder="Enter Location"
                        />
                    </div>
                    <div style={{ width: "50%", marginTop: "25px" }}>

                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "25px",
                        }}
                    >
                        <Button
                            label="Submit"
                            onClick={feedbackTotalCount > 0 ? exportFeedbacksForDT : null}
                            // icon={"pi pi-file-export"}
                            style={{
                                backgroundColor: "transparent",
                                color: "#ae9d81",
                                border: "1px #ae9d81 solid",
                                borderRadius: "20px",
                                marginRight: "0",
                                cursor: feedbackTotalCount > 0 ? "pointer" : "not-allowed",
                                padding: "0.6rem",
                            }}
                        />
                    </div>
                </div>
            </Dialog>
            <div className="header ">
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        flexGrow: "3",
                        justifyContent: "flex-start",
                    }}
                    className="flex align-items-center org"
                >
                    <div className="menubtn" style={{ padding: "10px 10px 5px 20px" }}>
                        <i
                            className="pi pi-align-justify"
                            style={{
                                fontSize: "1rem",
                                color: "#ae9d81",
                                cursor: "pointer",
                            }}
                            onClick={() =>
                                dispatch(setNavVisibilty({ sideNavVisibility: true }))
                            }
                        ></i>
                    </div>

                    <div
                    // className="heading"
                    //  style={{ paddingLeft: "15px", marginLeft: '40px' }}
                    >
                        <p
                            style={{
                                color: "#2d6c8b",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "normal",
                                letterSpacing: "-0.3px",
                                margin: 0,
                            }}
                        >
                            Department Actions
                        </p>
                    </div>

                </div>
                <div
                    className="header md:absolute sm:relative lg:absolute"
                    style={{
                        // position: "absolute",
                        // top: "-34px",
                        right: "0",
                        // marginBottom:"10px",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <div className="flex align-items-center">
                        <div className="mx-2 align-self-end">
                            <Button
                                label="Export"
                                onClick={() => setDialogVisible(true)}
                                icon={"pi pi-file-export"}
                                style={{
                                    backgroundColor: "transparent",
                                    color: "#ae9d81",
                                    border: "1px #ae9d81 solid",
                                    borderRadius: "20px",
                                    marginRight: "0",
                                    cursor: feedbackTotalCount > 0 ? "pointer" : "not-allowed",
                                    padding: "0.6rem",
                                }}
                            />
                        </div>
                        <div className='mx-2'>
                            <p style={{ margin: "0", padding: "0", fontSize: "20px", color: "#ae9d81", fontWeight: "700", cursor: "pointer" }} onClick={() => { setisFilters(!IsFilters) }}>Filters</p>
                        </div>
                    </div>
                </div>
            </div>
            {IsFilters && <div className="filters" >
                <div className='mx-2'>
                    <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Category</h4>
                    <Dropdown style={{ border: "solid 1px #ae9d81", width: 170 }} showClear={exportObj?.category} value={exportObj?.category} options={dropdownObj?.categoryList} optionLabel='categoryName' optionValue='id' onChange={(e) => { setExportObj((prev) => ({ ...prev, category: e.value })); setFirst(1); getFeedbackList(0, 10, globalFilterValue, e.value, exportObj?.subcategory, exportObj?.organization, exportObj?.fromDate, exportObj?.toDate, exportObj.status, exportObj.department) }} placeholder='Select' />
                </div>
                <div className='mx-2'>
                    <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Subcategory</h4>
                    <Dropdown style={{ border: "solid 1px #ae9d81", width: 170 }} showClear={exportObj?.subcategory} value={exportObj?.subcategory} onFocus={() => { getSubcategoriesList(exportObj?.category) }} options={dropdownObj?.subCategoryList} optionLabel='subCategoryName' optionValue='subCategoryId' onChange={(e) => { setExportObj((prev) => ({ ...prev, subcategory: e.value })); setFirst(0); getFeedbackList(0, 10, globalFilterValue, exportObj?.category, e.value, exportObj?.organization, exportObj?.fromDate, exportObj?.toDate, exportObj.status, exportObj.department) }} placeholder='Select' />
                </div>
                <div className='mx-2'>
                    <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Organization</h4>
                    <Dropdown style={{ border: "solid 1px #ae9d81", width: 170 }} showClear={exportObj?.organization} value={exportObj?.organization} options={dropdownObj?.organizationList} optionLabel='name' optionValue='id' onChange={(e) => { setExportObj((prev) => ({ ...prev, organization: e.value })); setFirst(0); getFeedbackList(0, 10, globalFilterValue, exportObj?.category, exportObj?.subcategory, e.value, exportObj?.fromDate, exportObj?.toDate, exportObj.status, exportObj.department) }} placeholder='Select' />
                </div>
                <div className='mx-2'>
                    <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Department</h4>
                    <Dropdown style={{ border: "solid 1px #ae9d81", width: 170 }} showClear={exportObj?.department} value={exportObj?.department} options={dropdownObj?.activeDepartmentList} optionLabel='name' optionValue='id' onChange={(e) => { setExportObj((prev) => ({ ...prev, department: e.value })); setFirst(0); getFeedbackList(0, 10, globalFilterValue, exportObj?.category, exportObj?.subcategory, exportObj?.organization, exportObj?.fromDate, exportObj?.toDate, exportObj.status, e.value) }} placeholder='Select' />
                </div>
                <div className='mx-2'>
                    <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Status</h4>
                    <Dropdown style={{ border: "solid 1px #ae9d81", width: 170 }} showClear={exportObj?.status} value={exportObj?.status} options={dropdownObj?.activeFeedbackStatus} optionLabel='name' optionValue='id' onChange={(e) => { setExportObj((prev) => ({ ...prev, status: e.value })); setFirst(0); getFeedbackList(0, 10, globalFilterValue, exportObj?.category, exportObj?.subcategory, exportObj?.organization, exportObj?.fromDate, exportObj?.toDate, e.value, exportObj.department) }} placeholder='Select' />
                </div>
                <div className='mx-2'>
                    <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>From Date</h4>
                    <Calendar className='customeCalender' style={{ width: 170, }} value={exportObj?.fromDate} placeholder='Select' showButtonBar onChange={(e) => {
                        setFirst(0);
                        setExportObj((prev) => ({ ...prev, fromDate: e.value }));
                        getFeedbackList(0, 10, globalFilterValue, exportObj?.category, exportObj?.subcategory, exportObj?.organization, e.value, exportObj?.toDate, exportObj.status, exportObj.department)
                    }} />
                </div>
                <div className='mx-2'>
                    <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>To Date</h4>
                    <Calendar className="customeCalender" style={{ width: 170 }} value={exportObj?.toDate} placeholder='Select ' showButtonBar onChange={(e) => {
                        setFirst(0);
                        setExportObj((prev) => ({ ...prev, toDate: e.value }));
                        getFeedbackList(0, 10, globalFilterValue, exportObj?.category, exportObj?.subcategory, exportObj?.organization, exportObj?.fromDate, e.value, exportObj.status, exportObj.department)
                    }} />
                </div>
                <div className='mx-2'>
                    <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Search</h4>
                    <div className='search flex align-items-center' style={{ border: "solid 1px #ae9d81", borderRadius: "4px", height: "42px" }}>
                        <i className='pi pi-search px-2' style={{ color: 'black', fontWeight: '700', fontSize: '1rem' }} />
                        <InputText style={{ width: 120, border: 0, height: "38px" }} value={globalFilterValue} onChange={onGlobalFilterChange} placeholder='Search' />
                        {globalFilterValue && <i className="pi pi-times px-2" style={{ color: 'black', fontWeight: '400', fontSize: '1rem' }} onClick={() => { setGlobalFilterValue(""); onGlobalFilterChange(null) }} />}
                    </div>
                </div>
            </div>}
            {isLoading ?
                <div style={{ height: "100vh" }}>
                    <div className='spinner spinner-center' style={{ display: "flex", margin: "auto", justifyContent: "center", alignItems: "center" }}></div>
                </div>

                :
                <div style={{ margin: "auto", justifyContent: "center", width: "95%" }}>
                    <DataTable
                        value={feedbackList}
                        // tableStyle={{ width: '100%' }}
                        style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                        stripedRows rows={10} filters={filters}
                        globalFilterFields={['userName', 'mobileNo', 'email', "category", 'subCategory', 'location']}
                        emptyMessage={<><p style={{ textAlign: "center" }}>Feedback list is empty</p></>} >
                        <Column field='code' header='Feedback Id' ></Column>
                        <Column field='userName' header='Name' ></Column>
                        {/* <Column field='createdDate' header='Created Date' body={dateBodyTemplateTwo}></Column> */}
                        <Column field='createdDate' header='Created Date' ></Column>
                        <Column field='departmentName' header='Deparment Name'></Column>
                        <Column field='category' header='Category'></Column>
                        <Column field='subCategory' header='Sub Category'></Column>
                        <Column field='location' header='Location'></Column>
                        <Column
                            field='status'
                            header='Status'
                            body={statusBodyTemplate}
                        />
                        <Column field='action' header='Action' body={actionButtonTemplate1}></Column>
                    </DataTable>
                </div>
            }
            {isLoading || feedbackList?.length == 0 ? ""
                :
                <div style={{ justifyContent: "center", display: 'flex' }} >
                    <Paginator first={first} rows={10} totalRecords={totalCount} onPageChange={(e) => onFeedbackListPageChange(e, 1)} template={{ layout: 'RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport' }} className="justify-content-start" />
                </div>
            }
        </div>
    )
}

export default DTFeedback