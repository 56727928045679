import React, { useState, useEffect } from 'react';
import './Reports.scss';
import UseCookies from '../../../../Helpers/UseCookies';
import { useDispatch } from 'react-redux';
import { setNavVisibilty } from '../../../../Redux/AppSlice';
import { toast } from 'react-toastify';


import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';


// ----------Assets Import------------
import editIcon from "../../../../assets/images/editIcon.jpg";
import { GetInvoicesCountOfVIPUser, GetInvoicesReportOfVIPUser, GetPurchasedPantryCountOfVIPUser, GetPurchasedPantryReportOfVIPUser } from '../../../../Services/ReportsServices';
// -----------------------------------

function Reports() {
    const [cookies, setCookie, removeCookie] = UseCookies();
    const dispatch = useDispatch();
    const [searchFilterValue, setSearchFilterValue] = useState('');

    // Get the report type from the URL
    const reportType = window.location.pathname.split('/')[1] == "supervisor-pantry-report" ? "pantry" : "invoices";
    // Set loader
    const [isLoading, setIsLoading] = useState(false);

    // Invoices
    const [invoices, setInvoices] = useState([]);
    const [invoiceTotal, setInvoiceTotal] = useState(0);


    const getInvoicesCountOfVIPUser = async (searchTerm = "") => {
        await GetInvoicesCountOfVIPUser(searchTerm, cookies().bearerToken)
            .then((res) => {
                console.log(res);
                setInvoiceTotal(res?.data?.totalRecords);
                getInvoicesReportOfVIPUser(0, 10, searchTerm);
            })
            .catch((err) => { console.log(err); })
    };
    const getPurchasedPantryCountOfVIPUser = async (searchTerm = "") => {
        await GetPurchasedPantryCountOfVIPUser(searchTerm, cookies().bearerToken)
            .then((res) => {
                console.log(res);
                setInvoiceTotal(res?.data?.totalRecords);
                getPurchasedPantryReportOfVIPUser();

            })
            .catch((err) => { console.log(err); })
    };

    const getInvoicesReportOfVIPUser = async (skip = 0, take = 10) => {
        await GetInvoicesReportOfVIPUser(skip, take, cookies().bearerToken)
            .then((res) => {
                console.log(res);
                setInvoices(res?.data?.data);
            })
            .catch((err) => { console.log(err); })

    };

    const getPurchasedPantryReportOfVIPUser = async (skip = 0, take = 10) => {
        await GetPurchasedPantryReportOfVIPUser(skip, take, cookies().bearerToken)
            .then((res) => {
                console.log(res);
                setInvoices(res?.data?.data);
            })
            .catch((err) => { console.log(err); })
    }




    useEffect(() => {
        if (reportType == "pantry") {
            getPurchasedPantryCountOfVIPUser();
        } else {
            getInvoicesCountOfVIPUser();
        }


    }, [])


    return (
        <>
            <div className="Reports">
                <div className="header">
                    <div className="menubtn" style={{ padding: '20px' }}>
                        <i className="pi pi-align-justify" style={{ fontSize: '1rem', color: "#ae9d81", cursor: "pointer" }}
                            onClick={() => dispatch(setNavVisibilty({ sideNavVisibility: true }))}></i>
                    </div>
                    <div className="heading">
                        <p>{reportType == "pantry" ? "Pantry Report" : "Invoice Report"}</p>
                    </div>
                    <div className="right" style={{}}>
                        <div className="search-box">
                            <span className="p-input-icon-left p-input-icon-right">
                                <i className="pi pi-search" style={{ color: 'black', fontWeight: '700', fontSize: '1rem', width: 'fit-content' }} />
                                <InputText placeholder="Search" value={searchFilterValue} onChange={(e) => { setSearchFilterValue(e.target.value) }} />
                            </span>
                        </div>
                        <div className="search-button" onClick={""}>
                            <Button icon="pi pi-search" style={{ background: "#ae9d81", border: "none" }} loading={""} />
                            {/* {profileLoading ? <i className='pi pi-refresh'></i> : <i  className='pi pi-refresh'></i>} */}
                        </div>
                    </div>
                </div>
                {/* <div className="datatable">
                    {isLoading ?
                        <div className='spinner spinner-center'></div>
                        : <DataTable
                            value={reportType == "pantry" ? [] : invoices}
                            rows={10}
                            tableStyle={{
                                width: '90rem',
                            }}
                            style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                            stripedRows
                            emptyMessage={<><p style={{ textAlign: "center" }}>Reports list is empty</p></>}
                            globalFilterFields={['code', 'name', 'userName', "createdOn", "orgStatus"]}>
                            <Column field="code" header="Organization Code"></Column>
                            <Column field="name" header="Organization Name"></Column>
                            <Column field="createdOn" header="Created Date"></Column>
                            <Column field="userName" header="Created By"></Column>
                            <Column field="status" header="Status"></Column>
                            <Column rowEditor header="Action" ></Column>
                        </DataTable>}
                    {isLoading ?
                        ""
                        : <Paginator first={0} rows={10} totalRecords={reportType == "pantry" ? 0 : invoiceTotal} onPageChange={(e) => { }} />
                    }
                </div> */}
            </div>

        </>
    )
}

export default Reports