import React, { useEffect, useState } from "react";
import UseCookies from "./Helpers/UseCookies.jsx";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Dashboard from "./Components/Dashboard/Dashboard";
import Desk from "./Components/Desk/Desk";
import Items from "./Components/Items/Items";
import Login from "./Components/Login/Login";
import GuestServiceStaff from "./Components/GuestServiceStaff/GuestServiceStaff";
import Role from "./Components/Role/Role";
import FeedbackCategory from "./Components/FeedbackCategory/FeedbackCategory";
import FeedbackSubCategory from "./Components/FeedbackSubCategory/FeedbackSubCategory";
import Tenant from "./Components/Tenant/Tenant";
import Nationality from "./Components/Nationality/Nationality";
import MallInspection from "./Components/MallInspection/MallInspection";
import OrganizationMaster from "./Components/Organization/OrganizationMaster";
import Header from "./Components/Header/Header";
import SideNav from "./Components/SideNavBar/SideNav";
import UserManagement from "./Components/UserManagement/UserManagement";
import HireDetails from "./Components/HireDetails/HireDetails";
import HireDetailsView from "./Components/HireDetailsView/HireDetailsView";
import UserManagementView from "./Components/UserManagementView/UserManagementView";
import Inspection from "./Components/Inspection/Inspection";
import Department from "./Components/Department/Department";
import Feedback from "./Components/Feedback/Feedback";
import DepartmentStaffView from "./Components/DepartmentStaffView/DepartmentStaffView";
import CategoryConfiguration from "./Components/CategoryConfiguration/CategoryConfiguration";
import FeedbackView from "./Components/FeedbackView/FeedbackView";
import InspectionViewDetails from "./Components/InspectionViewDetail/InspectionViewDetails";
import DepartmentTeam from "./Components/DepartmentTeam/DepartmentTeam";
import GuestServiceStaffView from "./Components/GuestServiceStaffView/GuestServiceStaffView";
import MGTenentRelationship from "./Components/MGTenentRelationship/MGTenentRelationship";
import MGTenentRelationshipView from "./Components/MGTenentRelationshipView/MGTenentRelationshipView";
import GuestServiceSupervisor from "./Components/GuestServiceSupervisor/GuestServiceSupervisor";
// import GuestServiceStaffInspectionView from "./Components/GuestServiceStaffInspectionView/GuestServiceStaffView";
import GuestServiceSupervisorView from "./Components/GuestServiceSupervisorView/GuestServiceSupervisorView";
import GuestStaffInspection from "./Components/GuestStaffInspection/GuestStaffInspection";
import GuessStaffInspectionView from "./Components/GuessStaffInspectionView/GuessStaffInspectionView.jsx";
import LostAndFound from "./Components/LostAndFound/LostAndFound.jsx";
import AdminLost from "./Components/AdminLost/AdminLost.jsx";
import AdminFound from "./Components/AdminFound/AdminFound.jsx";
import StorageLocation from "./Components/StorageLocation/StorageLocation.jsx";
import { ToastContainer } from "react-toastify";
// import FeedbackView from "./Components/FeedbackView/FeedbackView";
import "react-toastify/dist/ReactToastify.css";

//Context
//-----------Context ---------------
import { useDispatch, useSelector } from "react-redux";
import TenantDescription from "./Components/TenantDescription/TenantDescription.jsx";
import TenantQuestions from "./Components/TenantQuestions/TenantQuestions.jsx";
import GuestStaffInspectionAdminView from "./Components/GuestStaffInspectionAdminView/GuestStaffInspectionAdminView.jsx";

import LostAndFoundCategory from "./Components/LostAndFoundCategory/LostAndFoundCategory.jsx";
import SecurityStaff from "./Components/SecurityStaff/SecurityStaff.jsx";
import CustomWebcam from "./Components/Camera/Camera.jsx";
import AdminLostView from "./Components/AdminLostView/AdminLostView.jsx";
import AdminFoundView from "./Components/AdminFoundView/AdminFoundView.jsx";
import AdminReturn from "./Components/AdminReturn/AdminReturn.jsx";
import AdminReturnView from "./Components/AdminReturnView/AdminReturnView.jsx";
import Footer from "./Components/Footer/Footer.jsx";
import AddFoundForm from "./Components/AddFoundForm/AddFoundForm.jsx";
import AdminLostAndFound from "./Components/AdminLostAndFound/AdminLostAndFound.jsx";
import TenantCategory from "./Components/TenantCategory/TenantCategory.jsx";
import EmailConfiguration from "./Components/EmailConfiguration/EmailConfiguration.jsx";
import FeedbackDepartmentCategory from "./Components/FeedbackDepartmentCategory/FeedbackDepartmentCategory.jsx";
import FeedbackForm from "./Components/FeedbackForm/FeedbackForm.jsx";
// import { clearState, setUserdata } from '../../Redux/AppSlice';
//----------------------------------------
// AuthGuard
import AuthRoutes from "./Helpers/AuthRoutes.js";
import UnAuthRoutes from "./Helpers/UnAuthRoutes.js";
import AuthGuard from "./Helpers/AuthGuard.js";
import UnAuthGuard from "./Helpers/UnAuthGuards.js";
import UrlRoutes from "./Helpers/UrlRoutes.js";

import { HttpTransportType, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { apiUrl } from "./Helpers/Axios";
import { updateConnectedSocketHubFlag, updateIsReceivedNotificationState } from "./Redux/AppSlice.jsx";
import { WebNotification } from "./Components/utili/WebNotification.jsx";
import Socket from "./Helpers/Sockket.jsx";


function Router() {
  const [cookies, setCookie, removeCookie] = UseCookies();
  const dispatch = useDispatch();
  const Context = useSelector((state) => state.AppContext);
  const hubConnection = async () => {

    const connection = new HubConnectionBuilder().withUrl(`${apiUrl}NotificationHub`, {

      skipNegotiation: true,

      transport: HttpTransportType.WebSockets

    }).configureLogging(LogLevel.Information).build();

    return connection;

  }

  const startConnection = async (hubConnection, email, userRoles) => {

    hubConnection.start()

      .then(() => {

        ////console.log("connected to web socket");

        dispatch(updateConnectedSocketHubFlag(true));

        // allow only the users when logging into our system. who are all able to create a check-in

        const roles = ["GUESTSERVICESTAFF", "SAD_DELIVERY_PARTNER", "HF_STORE_KEEPER", "HF_STORE"];

        const isToConnectWebSocket = roles.some(role => userRoles?.includes(role));

        if (isToConnectWebSocket) {

          hubConnection.invoke("HandShakeToNotificationHub", { UserEmail: email })

        }

      })

      .catch((error) => {

        console.error('Error connecting to hub:', error);

      });

  }

  const Notification = async (email, userRoles) => {

    try {

      const connection = await hubConnection();
      await startConnection(connection, email, userRoles);
      connection.on('ReceiveNotification', message => {

        //console.log("message", message);

        WebNotification(message);
        dispatch(updateIsReceivedNotificationState(true));
      });


    } catch (err) {
      console.error("Unhandled exception while connecting for socket :::", err);
    }

  }

  useEffect(() => {
    ////console.log("isConnectedToSocketHub ::", Context.isConnectedToSocketHub);

    if (Context.isConnectedToSocketHub == false || Context.isConnectedToSocketHub == undefined) {
      const userEmail = localStorage.getItem("userEmail");
      const rolesCSV = localStorage.getItem("roles");
      const rolesArray = rolesCSV ? rolesCSV.split(',') : [];
      ////console.log("rolesArray :::", rolesArray);
      Notification(userEmail, rolesArray);
    }
  }, [Context.isConnectedToSocketHub])


  return (
    <>
      <BrowserRouter>
        <SideNav />
        <Header />
        <ToastContainer />
        <Routes>
          {UrlRoutes}
          {/* {AuthRoutes} */}
          {/* {UnAuthRoutes} */}
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default Router;
