import React, { useState, useEffect } from 'react';
import UseCookies from '../../Helpers/UseCookies';
import './style.scss';
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'

// -------------Prime Recat Imports---------------
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Tag } from 'primereact/tag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faL } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Paginator } from 'primereact/paginator';
// -----------------------------------


// ----------Assets Import------------
import editIcon from '../../../src/assets/images/editIcon.jpg';
// -----------------------------------


//------Imports from other Components-----
import { AddOrganization, EditOrganization, DeleteOrganization, exportOrganization } from '../../Services/OrganizationService';
//----------------------------------------


//-----------Context ---------------
import { useDispatch, useSelector } from "react-redux";
import { addOrganizationList, setNavVisibilty } from '../../Redux/AppSlice';
import { exportDataInExcel } from '../../Services/ExportServices';
import { CreateAnEmailWithUserType, EditEmailUserData, GetEmailAllList, GetEmailUserDetails, GetEmailUserType } from '../../Services/EmailConfigurationService';
import { Calendar } from 'primereact/calendar';
//----------------------------------------

function EmailConfiguration() {
    const [cookies, setCookie, removeCookie] = UseCookies();
    const [isAddEmail, setIsAddEmail] = useState(false);

    const [email, setEmail] = useState({
        "id": 0, "email": "", "isActive": true, "createdBy": 0, "userType": ""
    })
    const [isAdd, setIsAdd] = useState(false);
    const navigate = useNavigate();
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const [isLoading, setIsLoading] = useState(true);
    const [isSubmmited, setIsSubmmited] = useState(false);
    // Pagination



    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        // name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        // userName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        isActive: { value: null, matchMode: FilterMatchMode.EQUALS }
    });
    const [emailUserType, setEmailUserType] = useState([]);
    const [emailList, setEmailList] = useState([]);
    const [emailDetails, setEmailDetails] = useState({
        email: "",
        userType: "",
        status: true,
        reportType: [],
    })
    const [reportTypeList, setReportTypeList] = useState([
        { name: "Inspection", id: 1 },
        { name: "Feedback", id: 2 },
        { name: "Tenant Inspection", id: 3 },
        { name: "Guest Staff Inspection", id: 4 }
    ]);
    const [isEmailExist, setIsEmailExist] = useState(false);
    const [isTriggered, setIsTriggered] = useState(false);
    const [isEmailInvalid, setIsEmailInvalid] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const header = () => {
        return <div className="heading" style={{ fontWeight: "700", fontSize: "23px", color: "#ae9d81", textAlign: 'center' }}>
            <p style={{ margin: '0px' }}>{isAdd ? "Add" : "Edit"} Email Details</p>
        </div>
    }
    const popup = (() => {
        return (
            <>
                <Dialog header={header} visible={isAddEmail} style={{ width: '40vw' }}
                    closable={false} onHide={() => {
                        setIsAddEmail(false); setEmail({
                            "id": 0, "email": "", "isActive": true, "userType": ""
                        });
                    }}>
                    <div className="popup " >
                        <div className="body"  >
                            <div className="input grid">
                                <div className="col-6">
                                    <p>Email<span style={{ color: "red" }}>*</span></p>
                                    <InputText placeholder="Enter Email" value={emailDetails?.email}
                                        onChange={(e) => {
                                            setEmailDetails((prev) => ({ ...prev, email: e.target.value }))
                                        }} />
                                    {isTriggered && !emailDetails?.email &&
                                        // <div>
                                        <p style={{ color: "red", margin: "2px" }}>Email Id is required</p>
                                        // </div>
                                    }{
                                        isEmailInvalid && emailDetails?.email &&
                                        <p style={{ color: "red", margin: "2px" }}>Email Id is invalid</p>
                                    }
                                    {isEmailExist && emailDetails?.email &&
                                        // <div>
                                        <p style={{ color: "red", margin: "2px" }}>Email Id is already exists</p>
                                        // </div>
                                    }
                                </div>
                                <div className="col-6" >
                                    <p>Status</p>
                                    <label className="switch ">
                                        <input type="checkbox" checked={emailDetails?.status} onChange={(e) => { setEmailDetails((prev) => ({ ...prev, status: e.target.checked })); }} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="col-6">
                                    <p>User Type <span style={{ color: "red" }}>*</span></p>
                                    <Dropdown value={emailDetails?.userType} onChange={(e) => setEmailDetails((prev) => ({ ...prev, userType: e.value }))} options={emailUserType} optionLabel="name"
                                        placeholder="Select User Type" className="w-full md:w-14rem" />
                                    {isTriggered && !emailDetails?.userType?.name &&
                                        // <div>
                                        <p style={{ color: "red", margin: "2px" }}>User type is required</p>
                                        // </div>
                                    }
                                </div>
                                {emailDetails?.userType?.name == "Report" && <div className="col-6">
                                    <p>Report Type <span style={{ color: "red" }}>*</span></p>
                                    {/* <Dropdown value={emailDetails?.reportType} onChange={(e) => setEmailDetails((prev) => ({ ...prev, reportType: e.value }))} options={reportTypeList} optionLabel="name"
                                        placeholder="Select User Type" className="w-full md:w-14rem" /> */}
                                    <MultiSelect
                                        value={emailDetails?.reportType} onChange={(e) => setEmailDetails((prev) => ({ ...prev, reportType: e.value }))
                                        } options={reportTypeList} optionLabel="name" display="chip"
                                        placeholder="Select User Type" style={{ border: "1px solid #AE9D81", width: "75%", height: "44px", borderRadius: "5px", boxShadow: "none" }} />
                                    {isTriggered && emailDetails?.reportType?.length == 0 &&
                                        // <div>
                                        <p style={{ color: "red", margin: "2px" }}>Report type is required</p>
                                        // </div>
                                    }
                                </div>}
                            </div>
                        </div>
                        <div className="footer flex  grid">
                            <div className="col flex justify-content-center ">
                                <Button label="Cancel"
                                    onClick={() => {
                                        onCanceling()
                                    }}
                                    style={{ backgroundColor: '#8E8E8E', border: "none", margin: "10px", height: '40px' }} />
                                <Button label={isAdd ? "Submit" : "Update"} loading={isSubmmited} onClick={() => { onSubmittingEmails() }} style={{ backgroundColor: '#ae9d81', border: "none", margin: "10px", height: '40px' }} />
                            </div>
                        </div>
                    </div>
                </Dialog>

            </>
        )
    })

    const back = () => {
        navigate('/dashboard');
    };

    const statusBodyTemplate = ((row) => {
        if (row.isActive) {
            return <p style={{ color: "#45AA08" }}  >Active</p>
        }
        else {
            return <p style={{ color: "#FF4700" }} >Inactive</p>
        }
    });



    const actionButtonTemplate = (row) => {
        return (
            <div onClick={(e) => {
                gettingUserDeatils(row?.id)
            }}>
                <img src={editIcon} style={{ cursor: 'pointer' }} />
            </div>
        );
    };
    const onGlobalFilterChange = (e) => {
        const value = e ? e.target.value : "";
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const IsEmailValid = (email) => {
        let isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        setIsEmailInvalid(isValid);
        return isValid
    }

    const getEmailList = async () => {

        await GetEmailAllList(cookies()?.bearerToken).then((response) => {
            if (response?.status == 200) {
                setEmailList(response?.data || []);
                return
            }
            toast.info(response?.response?.data);
        });
        setIsLoading(false);
    }

    const getUserType = async () => {
        await GetEmailUserType(cookies()?.bearerToken).then((response) => {
            if (response?.status == 200) {
                setEmailUserType(mapUserTypeObj(response?.data));
                return
            }
            toast.info(response?.response?.data);
        });
        setIsLoading(false);
    }

    const mapUserTypeObj = (data) => {
        if (!data) return [];

        return data.map(item => ({ name: item }));
    };
    const findEmailIsExist = () => {
        return emailList?.some(_ => _?.email == emailDetails?.email);
    }
    const checkUserData = () => {
        setIsTriggered(true);
        let isValid = true;
        if (isAdd) {
            if (findEmailIsExist()) {
                setIsEmailExist(true);
                isValid = false;
            } else {
                setIsEmailExist(false);
            }
        }

        if (!IsEmailValid(emailDetails?.email) || !emailDetails?.userType?.name) {
            isValid = false;
        }
        return isValid;
    }
    const gettingUserDeatils = async (id) => {
        setCurrentId(id);
        await GetEmailUserDetails(id, cookies()?.bearerToken)
            .then(response => {
                if (response?.status == 200) {
                    setIsAddEmail(true);
                    setIsAdd(false);
                    setEmailDetails({
                        email: response?.data[0]?.email,
                        status: response?.data[0]?.isActive ? true : "",
                        userType: emailUserType.filter((_) => _.name === response?.data[0]?.userType)[0],
                        id: response[0]?.data?.id,
                        reportType: response?.data[0]?.reportTypes
                    });
                    console.log(reportTypeList.filter((_) => response?.data[0]?.reportTypes?.includes(_.id)));

                }
            })
    }

    const onSubmittingEmails = async () => {
        if (emailDetails?.email === "" ||
            emailDetails?.userType == ""
        ) {
            setIsTriggered(true);
            return;
        }
        setIsTriggered(false);
        if (emailDetails?.userType?.name === "Report" && emailDetails?.reportType?.length == 0) {
            setIsTriggered(true);
            return;
        };
        setIsTriggered(false);
        let isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailDetails?.email);
        if (!isValid) {
            setIsEmailInvalid(!isValid);
            return;
        }
        setIsEmailInvalid(!isValid);
        // let emailExist = emailList?.some(_ => _?.email == emailDetails?.email);
        // if (emailExist && !isAdd) {
        //     setIsEmailExist(true);
        //     return;
        // }
        setIsEmailExist(false);
        let data = {
            id: currentId ? currentId : 0,
            email: emailDetails?.email,
            isActive: emailDetails?.status ? true : false,
            userType: emailDetails?.userType?.name,
            reportTypeIds: emailDetails?.userType?.name === "Report" ? emailDetails?.reportType?.map((_) => { return _?.id }) : [],
        }
        const apiConfig = isAdd ? CreateAnEmailWithUserType : EditEmailUserData;
        setIsSubmmited(true);
        await apiConfig(data, cookies()?.bearerToken)
            .then(response => {
                if (response?.status == 200) {
                    onCanceling();
                    toast.success(isAdd ? "Sucessfully added" : "Successfully updated");
                    getEmailList();
                }
                setIsSubmmited(false);
            })
            .catch(error => {
                toast?.error(error?.response?.data);
                setIsSubmmited(false);
            })
    };

    const onCanceling = () => {
        setIsAddEmail(false);
        setIsTriggered(false);
        setEmailDetails({
            email: "",
            userType: {},
            status: true,
            reportType: []
        });
        setIsEmailExist(false);
        setIsEmailInvalid(false);
        setCurrentId(null);
    }
    useEffect(() => {
        getEmailList();
        getUserType();
    }, [])

    const exportOrganization = () => {
        // exportDataInExcel('Organization', cookies().bearerToken);
    };
    // Export Filter
    const [exportFilterDialog, setExportFilterDialog] = useState(false);
    const [exportDetails, setExportDetails] = useState({
        startDate: "",
        endDate: "",
        organization: "",
        desk: "",
        status: true
    });

    return (
        <>
            <Dialog header={<h3>Export Filter</h3>} draggable={false} style={{ width: '50vw', height: "max-content" }} closable={true} onHide={() => setExportFilterDialog(false)} visible={exportFilterDialog} >
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="StartDate" className="font-bold block mb-2">Start Date
                        </label>
                        <Calendar
                            id="StartDate"
                            style={{ width: "95%" }}
                            value={exportDetails?.startDate}
                            placeholder="Choose start end"
                            onChange={((value) => setExportDetails(() => ({ ...exportDetails, startDate: value })))}
                        ></Calendar>
                    </div>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="EndDate" className="font-bold block mb-2">End Date
                        </label>
                        <Calendar
                            id="EndDate"
                            style={{ width: "95%" }}
                            value={exportDetails?.endDate}
                            placeholder="Choose end date"
                            onChange={((value) => setExportDetails(() => ({ ...exportDetails, endDate: value })))}
                        ></Calendar>
                    </div>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="organization" className="font-bold block mb-2">Organization
                        </label>
                        <InputText
                            id="organization"
                            style={{ width: "95%" }}
                            value={exportDetails?.organization}
                            onChange={((value) => setExportDetails(() => ({ ...exportDetails, organization: value })))}
                            placeholder="Enter organization" ></InputText>
                    </div>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="Status" className="font-bold block mb-2">Status
                        </label>
                        {/* <InputText
                        id="Status"
                        style={{ width: "95%" }}
                        value={exportDetails?.status}

                        placeholder="Enter organization" ></InputText> */}
                        <div className="input grid ">
                            <div className="col ">
                                <label className="switch ">
                                    <input type="checkbox" value={exportDetails?.status} checked={exportDetails?.status} onChange={((e) => setExportDetails(() => ({ ...exportDetails, status: e.target.checked })))} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>

                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "25px",
                    }}
                >
                    <Button
                        label="Submit"
                        onClick={''}
                        // icon={"pi pi-file-export"}
                        style={{
                            backgroundColor: " #ae9d81",
                            color: "#fff",
                            border: "none",
                            // borderRadius: "20px",
                            marginRight: "0",
                            cursor: "pointer",
                            padding: "0.6rem",
                        }}
                    />
                </div>


            </Dialog>
            <div className="emailConfiguration" >
                <div className="header">
                    <div style={{ display: "flex", flexDirection: "row", flexGrow: "3", justifyContent: "flex-start" }} className='flex align-items-center org'>
                        <i className="pi pi-arrow-circle-left nav-back-btn" onClick={back} style={{ marginLeft: '30px', cursor: 'pointer', fontSize: '1.5rem' }} />
                        <div className="heading">
                            <p>Email Configuration</p>
                        </div>
                    </div>
                    {/* <div>
                        <Button onClick={totalCount > 0 ? exportOrganization : null} label='Export' icon={"pi pi-file-export"} style={{ backgroundColor: 'transparent', color: '#ae9d81', border: '1px #ae9d81 solid', borderRadius: '20px', marginRight: '20px', cursor: totalCount > 0 ? 'pointer' : 'not-allowed' }} />
                    </div> */}
                    <div className='flex align-items-center'>
                        <div className='mx-2'>
                            <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Search</h4>
                            <div className="search">
                                <span className="p-input-icon-left p-input-icon-right">
                                    <i className="pi pi-search" style={{ color: 'black', fontWeight: '700', fontSize: '1rem' }} />
                                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" style={{ width: "220px" }} />
                                    <i className="pi pi-times" style={{ color: 'black', fontWeight: '400' }} onClick={() => { setGlobalFilterValue(""); onGlobalFilterChange() }} />
                                </span>
                            </div>
                        </div>
                        <div className="mx-2 align-self-end">
                            <Button label="Add New" onClick={() => { setIsAdd(true); setIsAddEmail(true) }} style={{ backgroundColor: '#ae9d81', border: 'none' }} />
                        </div>
                    </div>

                </div>

                <div className="datatable">
                    {isLoading ?
                        <div className='spinner spinner-center'></div>
                        : <DataTable
                            value={emailList}
                            rows={10}
                            paginator
                            filters={filters}
                            tableStyle={{
                                width: '90rem',
                            }}
                            style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                            stripedRows
                            emptyMessage={<><p style={{ textAlign: "center" }}>Email list is empty</p></>}
                            globalFilterFields={['email', 'userType']}>

                            <Column field="email" header="Email Id"></Column>
                            <Column field="userType" header="User Type"></Column>
                            <Column field="status" header="Status" body={statusBodyTemplate} ></Column>
                            <Column rowEditor header="Action" body={actionButtonTemplate}></Column>
                        </DataTable>}
                </div>
            </div>

            <div>
                {isAddEmail == true ? popup() : ""}
            </div>
        </>
    )
}

export default EmailConfiguration


