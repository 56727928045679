import React, { useEffect, useState } from "react";
import logoCover from "../../assets/images/LoginCover.jpg";
import logo from "../../assets/images/logo.jpg";
// import logo from '../../assets/images/ehd_logo2.png';
import "../Login/style.scss";
import { useNavigate, useLocation } from "react-router-dom";
import UseCookies from "../../Helpers/UseCookies";
import { apiUrl, frontendUrl } from "../../Helpers/Axios";

// import DropdownComponent from '../utilize/DropdownComponent';

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import {
  AdminLogin,
  GetOrganizationList,
  InspectionSignIn,
  GetUserSignInDetails,
  GetIdentity,
} from "../../Services/LoginService";
import { Dropdown } from "primereact/dropdown";

//Context
//-----------Context ---------------
import { useDispatch, useSelector } from "react-redux";
import { clearState, setUserdata, updateConnectedSocketHubFlag, updateIsReceivedNotificationState } from "../../Redux/AppSlice";
import { toast } from "react-toastify";
import { HttpTransportType, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { WebNotification } from "../utili/WebNotification";
import Socket from "../../Helpers/Sockket";
//----------------------------------------

function Login() {
  const [cookies, setCookie, removeCookie] = UseCookies();
  const [connect, close, send] = Socket();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [loginData, setLoginData] = useState({
    userName: "",
    Password: "",
  });
  const [organization, setOrganization] = useState();
  const Context = useSelector((state) => state.AppContext);
  const [desk, setDesk] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [organizationList, setOrganizationList] = useState([]);
  const [isorgFetched, setIsOrgFetched] = useState(false);
  const [invalidUser, setInvalidUser] = useState(false);
  const [logInLoading, setLogInLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const getUserOrg = () => {
    if (loginData?.userName == "") {
      return;
    }
    getOrganizationList();
  };

  const toForgotPassword = () => {
    navigate("/forgotpassword");
  };

  const getOrganizationList = () => {
    if (loginData?.userName == "") {
      return;
    }
    let email = loginData?.userName;
    GetOrganizationList(email)
      .then((res) => {
        console.log("Organization List", res);
        if (res.status === 200) {
          setOrganizationList(res?.data?.orgs);
          setIsOrgFetched(false);
        }
        else {
          setOrganizationList([]);
        }
        // dispatch(
        //   addOrganizationList({
        //     organizationList: res?.data
        //   })
        // );
      })
      .catch((error) => {
        //console.log(error);
      })
      .finally(() => {
        // //console.log(organizationList);
        // navigate("/dashboard");
      });
  };

  const checkUser = async () => {
    setLogInLoading(true);
    if (isAdmin) {
      await getSignInDetails();
    } else {
      await UserSignIn();
    }
  };

  // Login new functionality
  const getSignInDetails = async () => {
    if (loginData?.userName == "" || loginData?.Password == "") {
      setIsValid(true);
      setLogInLoading(false);
      return;
    }
    let data = {
      userName: loginData?.userName.trim(),
      password: loginData?.Password.trim(),
    };
    await AdminLogin(data)
      .then((res) => {
        //console.log(res, "admin");
        setLogInLoading(false);
        //console.log("Response", res);
        if (res?.response?.status === 400) {
          toast.warning(res?.response?.data, { position: "top-right" });
          setInvalidUser(true);
          return;
        }
        if (res?.status === 200) {
          console.log(res?.data);
          setCookie("bearerToken", res?.data?.token ? res?.data?.token : null, { path: "/", domain: location.hostname });
          if (cookies.roles) {
            setCookie("roles", null, { path: "/", domain: location.hostname });
          }
          setCookie("roles", res?.data?.roles.length ? res?.data?.roles : null, { path: "/", domain: location.hostname });
          setCookie("userId", btoa(res?.data?.id), { path: "/", domain: location.hostname });
          localStorage.setItem("userName", res?.data?.firstName);
          toast.success("Logged In successfully", { position: "top-right" });

          dispatch(
            setUserdata({
              email: res?.data?.email,
              name: res?.data?.firstName,
              roles: res?.data?.roles,
            })
          );
          if (
            localStorage.getItem("redirectUrl") !== "" &&
            localStorage.getItem("redirectUrl")
          ) {
            console.log("Redirection required");
            navigate(localStorage.getItem("redirectUrl"));
            return;
          }
          if (
            res?.data?.roles?.length !== 0 &&
            res?.data?.roles.includes("ADMIN")
          ) {
            navigate("/dashboard");
          }
        }
      })
      .catch((error) => {
        setInvalidUser(true);
        setLogInLoading(false);
      })
      .finally(() => { });
  };

  const UserSignIn = async () => {
    if (
      loginData?.userName == "" ||
      loginData?.Password == "" ||
      organization == ""
    ) {
      setIsValid(true);
      setLogInLoading(false);
      return;
    }

    let data = {
      userName: loginData?.userName.trim(),
      password: loginData?.Password.trim(),
      organizationId: organization?.id,
    };
    await GetUserSignInDetails(data)
      .then(async (res) => {
        if (res?.status === 200) {
          await getIdentity(res?.data);
        }
        if (res?.response?.status === 400) {
          toast.warning(res?.response?.data, { position: "top-right" });
          setInvalidUser(true);
        }
        if (res?.response?.status === 401) {
          toast.warning("Un-authorized User", { position: "top-right" });
          setInvalidUser(true);
        }
        setLogInLoading(false);
      })
      .catch((error) => {
        toast.error(
          "There is issue occured in logging in, could you please try again",
          { position: "top-right" }
        );
        setLogInLoading(false);
      });
  };

  const getIdentity = async (token) => {
    let data = {
      userName: loginData?.userName.trim(),
      password: loginData?.Password.trim(),
      organizationId: organization?.id,
    };
    await GetIdentity(data, token)
      .then((res) => {
        console.log("Login Response", res);
        setLogInLoading(false);
        if (res?.status === 400) {
          toast.warning(res?.response?.data, { position: "top-right" });
          setInvalidUser(true);
          setLogInLoading(false);
        } else if (res?.response?.status === 401) {
          // toast.warning(res?.response?.data, { position: "top-right" });
          toast.warning("Invalid User", { position: "top-right" });
          setLogInLoading(false);
        } else if (res?.status === 200) {
          console.log("res", res?.data);
          setCookie("bearerToken", res?.data?.token ? res?.data?.token : null, { path: "/" });
          if (cookies.roles) {
            setCookie("roles", null, { path: "/", domain: location.hostname });
          }
          setCookie("roles", res?.data?.roles.length ? res?.data?.roles : null, { path: "/", domain: location.hostname });
          setCookie("userId", btoa(res?.data?.logInUserId), { path: "/", domain: location.hostname });
          setCookie("organizationID", organization?.id, { path: "/", domain: location.hostname });
          localStorage.setItem("userName", res?.data?.firstName);
          localStorage.setItem("userId", btoa(res?.data?.logInUserId));
          dispatch(
            setUserdata({
              email: res?.data?.email,
              name: res?.data?.firstName,
              roles: res?.data?.roles,
            })
          );
          //Notification configuration start

          // Notification(res?.data?.email, res?.data?.roles.length ? res?.data?.roles : []);
          send(res?.data?.logInUserId);
          //End

          setLogInLoading(false);
          if (
            localStorage.getItem("redirectUrl") !== "" &&
            localStorage.getItem("redirectUrl")
          ) {
            console.log("Redirection required");
            navigate(localStorage.getItem("redirectUrl"));
            return;
          }
          if (res?.data?.roles?.includes("ADMIN")) {
            navigate("/dashboard");
            return;
          }
          else if (res?.data?.roles?.includes("GUESTSERVICESTAFF")) {
            navigate("/guestservicestaff");
            return;
          }
          else if (res?.data?.roles?.includes("DEPARTMENTTEAM")) {
            navigate("/departmentteam/feedback");
            return;
          }
          else if (res?.data?.roles?.includes("MGTENANTRELATIONSHIP")) {
            navigate("/mgtenentrelationship");
            return;
          }
          else if (res?.data?.roles?.includes("GUESTSTAFFSUPERVISOR")) {
            navigate("/guestservicesupervisor");
            return;
          }
          else if (res?.data?.roles?.includes("GSR")) {
            navigate("/lostandfound/lost");
            return;
          }
          else if (res?.data?.roles?.includes("SECURITYSTAFF")) {
            navigate("/security-staff/1");
            return;
          }
          else if (res?.data?.roles?.includes("VIP_SUPERVISOR")) {
            navigate("/vip-user-list");
            return;
          }
          else if (res?.data?.roles?.includes("LOUNGE_STAFF")) {
            navigate("/vip-user-list");
            return;
          }
          else if (res?.data?.roles?.includes("PANTRY_STAFF")) {
            navigate("/pantry-purchase");
            return;
          }
          else if (res?.data?.roles?.includes("SAD_DELIVERY_PARTNER")) {
            navigate("/shop-and-drop-track-deliveries");
            return;
          }
          else if (res?.data?.roles?.includes("HF_STORE")) {
            navigate("/shop-and-drop-track-deliveries");
            return;
          }
          else if (res?.data?.roles?.includes("HF_STORE_KEEPER")) {
            navigate("/shop-and-drop-track-deliveries");
            return;
          }
          else if (res?.data?.roles?.includes("DESKUSER")) {
            navigate("/hire-details");
            return;
          }
          else {
            removeCookie("bearerToken", { path: "/", domain: location.hostname });
            removeCookie("roles", { path: "/", domain: location.hostname });
            removeCookie("userId", { path: "/", domain: location.hostname });
            toast.warning("Invalid User", { position: "top-right" });
            // navigate("/");
            return;
          }
        }
      })
      .catch((error) => {
        setInvalidUser(true);
        setLogInLoading(false);
        toast.warning(error, { position: "top-right" });
        //console.log(error);
      })
      .finally(() => {
        // navigate("/dashboard");
      });
  };

  // 
  const Notification = async (email, userRoles) => {
    const connection = new HubConnectionBuilder().withUrl(`${apiUrl}NotificationHub`, {
      skipNegotiation: true,
      transport: HttpTransportType.WebSockets
    }).configureLogging(LogLevel.Information).build();

    // to invoice a method that is relying on the server
    try {

      connection.start()
        .then(() => {
          console.log('Connected to SignalR hub')
          // allow only the users when logging into our system. who are all able to create a check-in

          const roles = ["GUESTSERVICESTAFF", "SAD_DELIVERY_PARTNER", "HF_STORE_KEEPER", "HF_STORE"];
          const isToConnectWebSocket = roles.some(role => userRoles?.includes(role));

          if (isToConnectWebSocket) {
            connection.invoke("HandShakeToNotificationHub", { UserEmail: email })
          }
        })
        .catch(err => console.error('Error connecting to hub:', err));

      // allow this 
      connection.on('ReceiveNotification', message => {
        console.log('Received message:', message);
        WebNotification(message);
        dispatch(updateIsReceivedNotificationState(true));
      });

      connection.onclose((error) => {
        toast.error(`Socket connection has been disconnected because of ${error.message}`);
        setTimeout(() => {
          dispatch(updateConnectedSocketHubFlag(false));
        }, 5000);
      });

    } catch (err) {
      console.log(err);
    }
  };


  const navigateToRouteForLoggedInUser = () => {
    if (!cookies().bearerToken) {
      navigate("/");
      return;
    }
    if (
      localStorage.getItem("redirectUrl") !== "" &&
      localStorage.getItem("redirectUrl")
    ) {
      console.log("Redirection required");
      navigate(localStorage.getItem("redirectUrl"));
      return;
    }
    if (cookies()?.roles?.includes("ADMIN")) {
      navigate("/dashboard");
      return;
    }
    else if (cookies()?.roles?.includes("GUESTSERVICESTAFF")) {
      navigate("/guestservicestaff");
      return;
    }
    else if (cookies()?.roles?.includes("DEPARTMENTTEAM")) {
      navigate("/departmentteam/feedback");
      return;
    }
    else if (cookies()?.roles?.includes("MGTENANTRELATIONSHIP")) {
      navigate("/mgtenentrelationship");
      return;
    }
    else if (cookies()?.roles?.includes("GUESTSTAFFSUPERVISOR")) {
      navigate("/guestservicesupervisor");
      return;
    }
    else if (cookies()?.roles?.includes("GSR")) {
      navigate("/lostandfound/lost");
      return;
    }
    else if (cookies()?.roles?.includes("SECURITYSTAFF")) {
      navigate("/security-staff/1");
      return;
    }
    else if (cookies()?.roles?.includes("VIP_SUPERVISOR")) {
      navigate("/vip-user-list");
      return;
    }
    else if (cookies()?.roles?.includes("LOUNGE_STAFF")) {
      navigate("/vip-user-list");
      return;
    }
    else if (cookies()?.roles?.includes("PANTRY_STAFF")) {
      navigate("/pantry-purchase");
      return;
    }
    else if (cookies()?.roles?.includes("SAD_DELIVERY_PARTNER")) {
      navigate("/shop-and-drop-track-deliveries");
      return;
    }
    else if (cookies()?.roles?.includes("HF_STORE")) {
      navigate("/shop-and-drop-track-deliveries");
      return;
    }
    else if (cookies()?.roles?.includes("HF_STORE_KEEPER")) {
      navigate("/shop-and-drop-track-deliveries");
      return;
    }
    else if (cookies()?.roles?.includes("DESKUSER")) {
      navigate("/hire-details");
      return;
    }
    else {
      removeCookie("bearerToken", { path: "/", domain: location.hostname });
      removeCookie("roles", { path: "/", domain: location.hostname });
      removeCookie("userId", { path: "/", domain: location.hostname });
      navigate("/");
      return;
    }
  }



  useEffect(() => {
    // Notification();
    connect();
    navigateToRouteForLoggedInUser();
  }, [])



  return (
    <div className="loginpage">
      <div
        className="justify-responsive"
        style={{
          display: "flex",
          // justifyContent: "center"
          padding: "24px 20px",
        }}
      >
        <div className="lg:col-4 xl:col-4 sm:col-12 md:col-7">
          <div
            style={{
              // width: '500px',
              padding: "20px",
              color: "white",
              //  marginLeft: '900px',
              backgroundColor: "#ae9d81d0",
              borderRadius: "5px",
              // marginTop: '100px'
            }}
          >
            <div className="defaultWidth">
              <img
                alt="image"
                src={logo}
                style={{
                  width: "200px",
                  height: "64px",
                  display: "flex",
                  margin: "auto",
                }}
              />
              <p
                style={{
                  fontWeight: 700,
                  fontSize: 25,
                  color: "##2D6C8B",
                  textAlign: "center",
                  margin: 0,
                  padding: 15,
                }}
              >
                Log In
              </p>
            </div>
            <div
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  border: "solid 2px #fff",
                  display: "flex",
                  borderRadius: "4px",
                }}
              >
                <div
                  onClick={() => {
                    setInvalidUser(false);
                    setIsAdmin(true);
                    setLoginData((prev) => ({
                      ...prev,
                      userName: "",
                      Password: "",
                    }));
                    setOrganizationList([]);
                  }}
                  style={{
                    color: isAdmin ? "#ae9d81" : "#fff",
                    background: isAdmin ? "#fff" : "transparent",
                    border: "unset",
                    padding: "6px",
                    width: "100px",
                    cursor: "pointer",
                  }}
                >
                  Admin
                </div>
                <div
                  onClick={() => {
                    setIsAdmin(false);
                    setInvalidUser(false);
                    setLoginData((prev) => ({
                      ...prev,
                      userName: "",
                      Password: "",
                    }));
                    setOrganizationList([]);
                  }}
                  style={{
                    color: !isAdmin ? "#ae9d81" : "#fff",
                    background: !isAdmin ? "#fff" : "transparent",
                    border: "unset",
                    padding: "6px",
                    width: "100px",
                    cursor: "pointer",
                  }}
                >
                  User
                </div>
              </div>
            </div>
            <div>
              <div className="defaultWidth">
                <p className="defaultStyle">Username</p>
                <div className="textinputstyle">
                  <div
                    style={{
                      flex: 0.1,
                      alignItems: "center",
                      justifyContent: "center",
                      paddingHorizontal: 6,
                    }}
                  ></div>
                  <div style={{ flex: 0.9, flexGrow: 1 }}>
                    <InputText
                      className="textField"
                      placeholder="Enter Username"
                      placvalue={loginData.userName}
                      value={loginData.userName}
                      onChange={(e) => {
                        setInvalidUser(false);
                        setIsOrgFetched(false);
                        setLoginData((prev) => ({
                          ...prev,
                          userName: e.target.value,
                        }));
                      }}
                      // onMouseLeave={getOrganizationList()}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                {isValid && loginData?.userName?.length == 0 && (
                  <div>
                    <p style={{ color: "red", marginTop: 5, marginBottom: 0 }}>
                      Please enter the username
                    </p>
                  </div>
                )}
                {invalidUser && (
                  <div>
                    <p style={{ color: "red", marginTop: 5, marginBottom: 0 }}>
                      Invalid Username / Password
                    </p>
                  </div>
                )}
              </div>
              <div className="defaultWidth">
                <p className="defaultStyle">Password</p>
                <div className="textinputstyle">
                  <div
                    style={{
                      flex: 0.1,
                      alignItems: "center",
                      justifyContent: "center",
                      paddingHorizontal: 6,
                    }}
                  >
                    {/* <EvilIcons name='lock' style={{ fontSize: 30, alignItems: "center", textAlignVertical: "center", color: "#2d6c8b" }} /> */}
                  </div>
                  <div style={{ flex: 0.9, flexGrow: 1 }}>
                    <InputText
                      style={{ width: "100%" }}
                      className="textField"
                      placeholder="Enter Password"
                      type="password"
                      value={loginData.Password}
                      onChange={(e) => {
                        setInvalidUser(false);
                        setLoginData((prev) => ({
                          ...prev,
                          Password: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>
                {isValid && loginData?.Password?.length == 0 && (
                  <div>
                    <p style={{ color: "red", marginTop: 5, marginBottom: 0 }}>
                      Please enter the password
                    </p>
                  </div>
                )}
                {invalidUser && (
                  <div>
                    <p style={{ color: "red", marginTop: 5, marginBottom: 0 }}>
                      Invalid Username / Password
                    </p>
                  </div>
                )}
                <div
                  style={{
                    textAlign: "right",
                    fontSize: "15px",
                    marginTop: "6px",
                    fontWeight: "500",
                  }}
                >
                  <span onClick={toForgotPassword} style={{ cursor: "pointer" }}>
                    Forgot Password?
                  </span>
                </div>
              </div>
            </div>
            {!isAdmin && (
              <div className="defaultWidth" style={{ paddingBottom: 20 }}>
                <p className="defaultStyle" style={{ marginTop: 0 }}>
                  Organization
                </p>
                {/* <Button rounded text severity="danger" aria-label="success" loading={isorgFetched} /> */}
                <Dropdown
                  emptyMessage="Organization list is empty"
                  value={organization}
                  style={{
                    border: "2px solid white",
                    width: "100%",
                    height: "50px",
                    borderRadius: "5px",
                    boxShadow: "none",
                    backgroundColor: "transparent",
                    color: "white",
                    fontSize: "10px",
                  }}
                  className="dropdown"
                  onChange={(e) => {
                    setOrganization(e.target.value);
                    console.log(e.target.value);
                  }}
                  options={organizationList}
                  optionLabel="name"
                  onFocus={() => {
                    if (!isorgFetched) {
                      getUserOrg();
                    }
                  }}
                  placeholder="Please Select"
                />
                {isValid && organization?.length == 0 && (
                  <div>
                    <p style={{ color: "red", marginTop: 5, marginBottom: 0 }}>
                      Please select organization
                    </p>
                  </div>
                )}
              </div>
            )}

            <div className="defaultWidth">
              <Button
                style={{
                  border: "1px solid #AE9D81",
                  width: "100%",
                  height: "50px",
                  backgroundColor: "White",
                  color: "#ae9d81",
                  cursor: "pointer",
                  borderRadius: "5px",
                  boxShadow: "none",
                }}
                className="loginButton button"
                loading={logInLoading}
                onClick={checkUser}
              >
                Login
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Login;
