import React, { useEffect } from 'react';
import UseCookies from '../Helpers/UseCookies';
import { useNavigate } from 'react-router-dom';



const AuthGuard = ({ component, role, authorized, ismultiple, invalid }) => {
    const [cookies, setCookie] = UseCookies();
    const navigate = useNavigate();

    const checkMultipleRoles = (loggedInRoles = [], RouteRoles = []) => {
        let authorized = false;
        RouteRoles.forEach(element => {
            if (loggedInRoles?.filter((r) => r === element).length > 0) {
                authorized = true;
                return;
            }
        });
        // console.log(authorized);
        return authorized;
    }




    useEffect(() => {
        }, []);

    if (authorized) {
        console.log(cookies());
        if (cookies()?.bearerToken && cookies()?.bearerToken != null) {

            if (ismultiple && checkMultipleRoles(cookies()?.roles, role)) {
                return <React.Fragment>{component}</React.Fragment>
            }

            if (cookies()?.roles?.includes(role)) {
                return <React.Fragment>{component}</React.Fragment>
            }
            else if (cookies()?.roles?.includes("ADMIN")) {
                navigate("/dashboard")
                return;
            }
            else if (cookies()?.roles?.includes("GUESTSERVICESTAFF")) {
                navigate("/guestservicestaff");
                return;
            }
            else if (cookies()?.roles?.includes("DEPARTMENTTEAM")) {
                navigate("/departmentteam/feedback");
                return;
            }
            else if (cookies()?.roles?.includes("MGTENANTRELATIONSHIP")) {
                navigate("/mgtenentrelationship");
                return;
            }
            else if (cookies()?.roles?.includes("GUESTSTAFFSUPERVISOR")) {
                navigate("/guestservicesupervisor");
                return;
            }
            else if (cookies()?.roles?.includes("GSR")) {
                navigate("/lostandfound/lost");
                return;
            }
            else if (cookies()?.roles?.includes("SECURITYSTAFF")) {
                navigate("/security-staff/1");
                return;
            }
            else if (cookies()?.roles?.includes("VIP_SUPERVISOR")) {
                navigate("/vip-user-list");
                return;
            }
            else if (cookies()?.roles?.includes("LOUNGE_STAFF")) {
                navigate("/vip-user-list");
                return;
            }
            else if (cookies()?.roles?.includes("PANTRY_STAFF")) {
                navigate("/pantry-staff-inventory");
                return;
            }
            else if (cookies()?.roles?.includes("SAD_DELIVERY_PARTNER")) {
                navigate("/pantry-staff-inventory");
                return;
            }
            else if (cookies()?.roles?.includes("HF_STORE")) {
                navigate("/checkin-request");
                return;
            }
            else if (cookies()?.roles?.includes("HF_STORE_KEEPER")) {
                navigate("/checkin-request");
                return;
            }
        }
        else {
            if (window.location.pathname !== "/" || "/login" || "/forgotpassword") {
                localStorage.setItem("redirectUrl", window.location.pathname);
            }
            document.cookie="";
            navigate("/");
        }
    }
    else if (invalid) {
        document.cookie="";
        navigate("/");
    }
    else {
        return <React.Fragment>{component}</React.Fragment>;
    }

}


export default AuthGuard;