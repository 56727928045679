import React, { useState, useEffect } from 'react';
import UseCookies from '../../Helpers/UseCookies';
import Tenantcsv from '../../assets/files/Tenantcsv.csv'
import nationality from "../../assets/JSON/NationalityFlags.json";

import './style.scss'
// -------------Imports---------------
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Tag } from 'primereact/tag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { FileUpload } from 'primereact/fileupload';


import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { Paginator } from 'primereact/paginator';
import localAsset from '../../assets/files/Tenantcsv.csv';




// -----------------------------------


// ----------Assets Import------------
import editIcon from '../../../src/assets/images/editIcon.jpg';
// -----------------------------------

//------------Service Import-----------
import { GetTenantList, AddTenant, EditTenant, ImportTenant, GetTenantListBySearch } from '../../Services/TenantService';

// -----------------------------------  


//-------Context Import---------------
import { useDispatch } from 'react-redux';
import { setNavVisibilty } from '../../Redux/AppSlice';
import { ExportOpenInNewTab, exportDataInExcel, exportExcel, exportFilterdExcel, isShow } from '../../Services/ExportServices';
import Modalinfo from '../utili/Modalinfo';
import { Dropdown } from 'primereact/dropdown';
import { GetActiveTenantCategory } from '../../Services/TenantCategory';
import { Calendar } from 'primereact/calendar';
// -----------------------------------

function Tenant() {
    const [cookies, setCookie, removeCookie] = UseCookies();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [tenantList, setTenantList] = useState("");
    const [selectedTenant, setSelectedTenant] = useState([
        {
            tenantName: "",
            tenantStatus: false,
            tenantID: 0,
            groupName: "",
            unitNumber: "",
            mobileNumber: "",
            email: "",
            tenantCategoryId: null,
            countryCode: "+973"
        }
    ]);
    const [isAddTenant, setIsAddTenant] = useState(false);
    const [isImport, setIsImport] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [isInvalid, setIsInvalid] = useState(false);
    const [isRecordSubmitting, setIsRecordSubmitting] = useState(false);
    const [IsEmailInvalid, setIsEmailInvalid] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [tenantNameExists, setTenantNameExists] = useState(false);
    const [isImportInvalid, setIsImportInvalid] = useState(false);
    const [isImportInvalidFile, setIsImportInvalidFile] = useState(false);
    const [isFileValidating, setFileValidating] = useState(false);
    const [tenantCategoryList, setTenantCategoryList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({
        "english_name": "Bahrain",
        "arabic_name": "البحرين",
        "alpha2_code": "BH",
        "alpha3_code": "BHR",
        "phone_code": "+973",
        "flagImage": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BH.svg"
    });

    const [status, setStatus] = useState("");
    // Pagination
    const [first, setFirst] = useState(0)
    const [totalCount, setTotalRecords] = useState(0)


    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        // name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        // userName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        // status: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const onGlobalFilterChange = (e) => {

        const value = e ? e.target.value : "";
        let _filters = { ...filters };
        _filters['global'].value = value;
        // _filters['name'].value = value;
        // _filters['userName'].value = value;
        // _filters['status'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const onCancelClick = () => {
        setShowConfirmation(true);
    };

    const onHide = () => {
        setShowConfirmation(false);
        setIsAddTenant(false);
        setSelectedTenant({
            tenantName: "",
            tenantStatus: false,
            tenantID: 0,
            groupName: "",
            unitNumber: "",
            mobileNumber: "",
            email: "",
            tenantCategoryId: 0,
            countryCode: "+973"
        });
    };

    const handleConfirmation = (confirmed) => {
        if (confirmed) {
            onHide();
        } else {
            setShowConfirmation(false);
        }
    };


    const header = () => {
        return <div className="heading" style={{ fontWeight: "700", fontSize: "23px", color: "#ae9d81", textAlign: 'center' }}>
            <p style={{ margin: '0px' }}>{isAdd ? "Add" : "Edit"} Tenant Details</p>
        </div>
    }
    const popup = (() => {
        return (
            <>

                <Dialog header={header} visible={true} style={{ width: '50vw' }} closable={false} onHide={() => {
                    setIsAddTenant(false); setIsAdd(false); setSelectedTenant([{
                        tenantName: "",
                        tenantStatus: false,
                        tenantID: 0,
                    }]);
                }}>
                    <div className="popup " style={{ overflow: "auto", height: '70vh', overflowX: "hidden" }} >
                        <div className="body" style={{ justifyContent: 'center', alignContent: "center" }}>
                            <div>
                                <div className="label grid">
                                    <div className='col'>Tenant Name<span style={{ color: 'red' }}>*</span></div>
                                    <div className='col' style={{ left: '300px' }}>Status</div>
                                </div>
                                <div className="input grid ">
                                    <div className="col">
                                        <InputText placeholder="Enter Tenant Name" value={selectedTenant.tenantName}
                                            style={{ width: '330px', borderColor: '#ae9d81' }}
                                            onChange={(e) => {
                                                setSelectedTenant((prev) => ({ ...prev, tenantName: e.target.value }));
                                            }} />
                                        {tenantNameExists &&
                                            <p style={{ color: "red" }}>Tenant name is already exists</p>
                                        }
                                        {isInvalid && selectedTenant?.tenantName == "" &&
                                            <p style={{ color: "red" }}>Tenant name is required</p>
                                        }
                                    </div>
                                    <div className="col " style={{ left: '300px' }}>
                                        <label className="switch ">
                                            <input type="checkbox" checked={selectedTenant.tenantStatus} onChange={(e) => {
                                                setSelectedTenant((prev) => ({ ...prev, tenantStatus: e.target.checked }));
                                            }} />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="label grid">
                                    <div className='col'>Tenant Category<span style={{ color: 'red' }}>*</span></div>
                                    <div className='col' style={{ left: '300px' }}>Group Name<span style={{ color: 'red' }}>*</span></div>

                                </div>
                                <div className="input grid ">
                                    <div className="col">
                                        <Dropdown placeholder='Select Tenant Category' options={tenantCategoryList} optionLabel='name' optionValue='id'
                                            value={selectedTenant?.tenantCategoryId} style={{ width: '330px', borderColor: '#ae9d81' }}
                                            onChange={(e) => {
                                                setSelectedTenant((prev) => ({ ...prev, tenantCategoryId: e.target.value }));
                                            }} />
                                        {isInvalid && selectedTenant?.tenantCategoryId == "" &&
                                            <p style={{ color: "red" }}>Tenant Category is required</p>
                                        }
                                    </div>
                                    <div className="col" style={{ left: '300px' }}>
                                        <InputText placeholder="Enter Group Name" value={selectedTenant.groupName}
                                            style={{ width: '330px', borderColor: '#ae9d81' }}
                                            onChange={(e) => {
                                                setSelectedTenant((prev) => ({ ...prev, groupName: e.target.value }));
                                            }} />
                                        {isInvalid && selectedTenant?.groupName == "" &&
                                            <p style={{ color: "red" }}>Group name is required</p>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="label grid">
                                    <div className='col'>Unit Number<span style={{ color: 'red' }}>*</span></div>
                                    <div className='col' style={{ left: '300px' }}>Country Code<span style={{ color: 'red' }}>*</span></div>

                                </div>
                                <div className="input grid " style={{ flexWrap: "nowrap" }}>
                                    <div className="col">
                                        <InputText placeholder="Enter Unit Number" value={selectedTenant.unitNumber}
                                            style={{ width: '330px', borderColor: '#ae9d81' }}
                                            onChange={(e) => {
                                                setSelectedTenant((prev) => ({ ...prev, unitNumber: e.target.value }));
                                            }} />
                                        {isInvalid && selectedTenant?.unitNumber == "" &&
                                            <p style={{ color: "red" }}>Unit number is required</p>
                                        }
                                    </div>
                                    <div className="col">
                                        <Dropdown value={selectedCountry} placeholder='Select Country Code' style={{ border: "solid 1px #ae9d81", padding: "2px" }}
                                            onChange={(e) => { setSelectedCountry(e.value); handleCountryChange(e.value) }} options={nationality} optionLabel="english_name"
                                            valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate}
                                        />
                                        {isInvalid && selectedCountry &&
                                            <p style={{ color: "red" }}>Country code is required</p>
                                        }

                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="label grid">
                                    <div className='col'>Email<span style={{ color: 'red' }}>*</span></div>
                                    <div className='col' style={{ left: '300px' }}>Mobile Number<span style={{ color: 'red' }}>*</span></div>

                                </div>
                                <div className="input grid ">
                                    <div className="col">
                                        <InputText placeholder="Enter Email" type='email' value={selectedTenant.email} style={{ width: '330px', borderColor: '#ae9d81' }}
                                            onChange={(e) => {
                                                setSelectedTenant((prev) => ({ ...prev, email: e.target.value }));
                                            }} />
                                        {IsEmailInvalid &&
                                            // <div>
                                            <p style={{ color: "red" }}>Email is invalid.</p>
                                            // </div>
                                        }
                                        {isInvalid && selectedTenant?.email == "" &&
                                            // <div>
                                            <p style={{ color: "red" }}>Email is required</p>
                                            // </div>
                                        }
                                    </div>
                                    <div className="col">
                                        <InputText placeholder="Mobile Number" type='number' min={1} value={selectedTenant.mobileNumber}
                                            style={{ borderColor: '#ae9d81' }}
                                            onChange={(e) => {
                                                setSelectedTenant((prev) => ({ ...prev, mobileNumber: e.target.value }));
                                            }} />
                                        {isInvalid && selectedTenant?.mobileNumber == "" &&
                                            <p style={{ color: "red" }}>Mobile number is required</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer flex  grid">
                            <div className="col flex justify-content-center ">
                                <Button label="Cancel" disabled={isRecordSubmitting} onClick={() => {
                                    setIsAddTenant(false);
                                    setIsInvalid(false);
                                    setTenantNameExists(false);
                                    setSelectedTenant({
                                        tenantName: "",
                                        tenantStatus: false,
                                        tenantID: 0,
                                        tenantCategoryId: 0,
                                        unitNumber: "",
                                        mobileNumber: "",
                                        email: "",
                                        groupName: "",
                                        countryCode: "+973",
                                    });
                                }} style={{ backgroundColor: '#8E8E8E', border: "none", margin: "10px", height: '40px' }} />
                                <Button label={isAdd ? "Submit" : "Update"} loading={isRecordSubmitting} onClick={() => { isAdd ? addTenant() : editTenant() }} style={{ backgroundColor: '#ae9d81', border: "none", margin: "10px", height: '40px' }} />
                            </div>
                        </div>
                    </div>
                </Dialog>

            </>
        )
    });

    const IsEmailValid = (email) => {
        let isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        setIsEmailInvalid(isValid);
        return isValid
    }
    const onSearch = (e) => {
        setGlobalFilterValue(e ? e?.target?.value : "");
        // if (!e) {
        //     getTenantList(0, 10);
        //     return
        // }
        getTenantList(0, 10, e?.target?.value, status);
    }
    const onUpload = (e) => {
        //console.log(e.files[0].name);
        setFileValidating(true);
        if (e.files.length == 0) {
            setIsImportInvalid(true);
        }
        setIsImportInvalid(false);
        // if (e.files[0].name !== "Tenantcsv.csv") {
        //     setIsImportInvalidFile(true);
        //     e.files.pop()
        // }
        // else {
        setIsImportInvalidFile(false);
        const formData = new FormData();
        formData.append('file', e.files[0]);
        ImportTenant(formData, cookies().bearerToken)
            .then((res) => {
                //console.log(res);
                if (res?.response?.status === 400) {
                    toast.info(res?.response?.data);
                    return
                }
                if (res.status === 200) {
                    toast.success("Tenant details imported successfully");
                    setIsImport(false);
                    setFileValidating(false);
                    getTenantList(first, 10, globalFilterValue, status);
                }
            })
            .catch((err) => {
                //console.log(err);
                setFileValidating(false);
                if (err.response.status === 400) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error("Something went wrong");
                }
            });

        // }

    };


    const downloadFile = () => {
        // Path to your file

    };

    const importHeader = () => {
        return <div className="heading" style={{ fontWeight: "700", fontSize: "23px", color: "#ae9d81", textAlign: 'center' }}>
            <p style={{ margin: '0px' }}>Import Tenant Details</p>
        </div>
    }

    const importPopup = (() => {
        return (<>
            <Dialog header={importHeader} visible={true} style={{ width: '50vw' }} closable={true} onHide={() => { setIsImport(false) }}>
                <div className="popup " >
                    <div className="body">
                        <div className="col">
                            <div className="label grid">
                                <div className='col'>
                                    Sample Template
                                    <a href={localAsset} download target="_blank" style={{ margin: "0 10px" }}>
                                        <i className="pi pi-download" style={{ color: 'slateblue', cursor: "pointer" }}
                                            // on click i need to download a file 
                                            onClick={() => {
                                                window.open(Tenantcsv)
                                            }}
                                        ></i>
                                    </a>

                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="label grid">
                                <div className='col' style={{ display: "flex", alignItems: "center" }}>Select File<span style={{ color: 'red' }}>*</span> <Modalinfo /></div>
                            </div>
                            <div className="input grid ">
                                <div className="col">
                                    {/* <FileUpload mode="basic" name="demo[]" accept="*.csv" maxFileSize={1000000} onUpload={onUpload} chooseLabel="Browse" /> */}
                                    <FileUpload name="demo[]" accept=".csv" mode='basic' multiple={false} maxFileSize={1000000} customUpload uploadHandler={onUpload} emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>} />
                                    {/* {isFileValidating && <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i>} */}
                                    {isImportInvalid &&
                                        // <div>
                                        <p style={{ color: "red" }}>Please choose a file</p>
                                        // </div>
                                    }
                                    {isImportInvalidFile &&
                                        // <div>
                                        <p style={{ color: "red" }}>Name of the uploaded file should be same as the sample file.</p>
                                        // </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>)
    })

    const statusBodyTemplate = ((row) => {
        // //console.log(row);
        if (row.isDeleted === false) {
            return <p style={{ color: "green" }}  >Active</p>
        }
        else {
            return <p style={{ color: "red" }} >Inactive</p>
        }
    });

    // const actionButtonTemplate = ((row) => {
    //     return <div onClick={(e, row) => { //console.log(e.target, row); }} >
    //         <img src={editIcon} style={{ cursor: 'pointer' }} /></div>
    // })

    const back = () => {
        navigate('/dashboard');
    };

    const actionButtonTemplate = (row) => {
        return (
            <div onClick={(e) => {
                console.log(row, "rowrow");
                setIsAddTenant(true);
                setIsAdd(false);
                setSelectedTenant((prev) => ({
                    ...prev,
                    tenantName: row.name,
                    tenantStatus: !row.isDeleted,
                    tenantId: row.id,
                    groupName: row.groupName,
                    unitNumber: row.unitNumber,
                    mobileNumber: row.phone,
                    email: row.email,
                    tenantCategoryId: row.tenantCategoryId,
                    countryCode: row.countryCode
                }));
                if (row?.countryCode) {
                    setSelectedCountry(nationality.filter(country => country.phone_code === row.countryCode)[0]);
                }
                else {
                    setSelectedCountry({
                        "english_name": "Bahrain",
                        "arabic_name": "البحرين",
                        "alpha2_code": "BH",
                        "alpha3_code": "BHR",
                        "phone_code": "+973",
                        "flagImage": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BH.svg"
                    });
                }

            }}>
                <img src={editIcon} style={{ cursor: 'pointer' }} />
            </div>
        );
    };
    // const getTenantList = async () => {
    //     let data = await GetTenantList(10, 0, cookies.token);
    //     setTenantList(data.data);
    // }
    const getTenantList = (skip, take, searchValue = "", status) => {
        setIsLoading(true);
        // if (!searchValue) {
        GetTenantList(skip, take, cookies().bearerToken, searchValue, status ? (status == "In Active") ? "true" : "false" : false, isShow({ status: status, search: globalFilterValue }))
            .then((res) => {
                if (res?.status === 200) {
                    setTenantList(res?.data?.data);
                    setTotalRecords(res?.data?.count);
                }
            })
            .catch((err) => {
                //console.log(err);
            })
            .finally(() => {
                setIsLoading(false);
                // //console.log(organizationList);
            })
        // } else {
        //     GetTenantListBySearch(skip, take, searchValue, cookies().bearerToken).then((res) => {
        //         if (res?.status === 200) {
        //             setTenantList(res?.data?.data);
        //             setTotalRecords(res?.data?.count);
        //         }
        //     })
        //         .catch((err) => {
        //             //console.log(err);
        //         })
        //         .finally(() => {
        //             setIsLoading(false);
        //             // //console.log(organizationList);
        //         })
        // }
    };
    const getActiveTenantCategoryList = async () => {
        setIsLoading(true);
        await GetActiveTenantCategory(cookies().bearerToken)
            .then((res) => {
                if (res?.status === 200) {
                    setTenantCategoryList(res?.data);
                }
            })
            .catch((err) => {
                //console.log(err);
            })
            .finally(() => {
                setIsLoading(false);
                // //console.log(organizationList);
            })

    };
    const addTenant = () => {
        let data = {
            "name": selectedTenant?.tenantName,
            "groupName": selectedTenant?.groupName,
            "unitNumber": selectedTenant?.unitNumber,
            "isDeleted": !selectedTenant?.tenantStatus,
            "phone": selectedTenant?.mobileNumber,
            "email": selectedTenant?.email,
            "tenantCategoryId": selectedTenant?.tenantCategoryId,
            'countryCode': selectedTenant?.countryCode || selectedCountry?.phone_code || "+973"
        }
        if (selectedTenant?.tenantName === "" ||
            selectedTenant?.groupName === "" ||
            selectedTenant?.unitNumber === "" ||
            selectedTenant?.mobileNumber === "" ||
            selectedTenant?.email === "" ||
            selectedTenant?.tenantCategoryId === 0 ||
            selectedTenant?.countryCode === ""

        ) {
            setIsInvalid(true);
            return;
        }
        setIsInvalid(false);
        let isExist = tenantList.filter((tenant) => tenant.name === selectedTenant?.tenantName);
        //console.log(isExist);
        if (isExist?.length != 0) {
            setTenantNameExists(true);
            return;
        }
        setTenantNameExists(false);
        let emailValid = IsEmailValid(selectedTenant.email);
        if (!emailValid) {
            setIsEmailInvalid(true);
            return;
        };
        setIsEmailInvalid(false);
        setIsInvalid(false);
        setIsRecordSubmitting(true);
        AddTenant(data, cookies().bearerToken)
            .then((res) => {
                if (res?.status == 200) {
                    getTenantList(first, 10);
                    setIsAddTenant(false);
                    setIsAdd(false);
                    setSelectedTenant([{
                        tenantName: "",
                        tenantStatus: false,
                        tenantID: 0,
                    }]);
                    setSelectedCountry({
                        "english_name": "Bahrain",
                        "arabic_name": "البحرين",
                        "alpha2_code": "BH",
                        "alpha3_code": "BHR",
                        "phone_code": "+973",
                        "flagImage": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BH.svg"
                    });
                    toast.success("New Tenant is added successfully", { position: "top-right" });
                    setIsRecordSubmitting(false);
                }
                else if (res?.response?.status === 400) {
                    setIsRecordSubmitting(false);
                    toast.error(res?.message, { position: "top-right" });
                }
            })
            .catch((err) => {
                //console.log(err);
                setIsRecordSubmitting(false);
            })


    }

    const editTenant = () => {
        let data = {
            "name": selectedTenant?.tenantName,
            "isDeleted": !selectedTenant?.tenantStatus,
            "unitNumber": selectedTenant?.unitNumber,
            "groupName": selectedTenant?.groupName,
            "id": selectedTenant?.tenantId,
            "phone": selectedTenant?.mobileNumber,
            "email": selectedTenant?.email,
            "tenantCategoryId": selectedTenant?.tenantCategoryId,
            'countryCode': selectedTenant?.countryCode
        };
        console.log("Data:", data);
        if (selectedTenant?.tenantName === "" ||
            selectedTenant?.groupName === "" ||
            selectedTenant?.unitNumber === "" ||
            selectedTenant?.mobileNumber === "" ||
            selectedTenant?.email === "" ||
            selectedTenant?.tenantCategoryId === 0

        ) {
            setIsInvalid(true);
            return;
        }

        setIsInvalid(false);
        let isExist = tenantList.filter((tenant) => tenant.name === selectedTenant?.tenantName && tenant.id != selectedTenant?.tenantId);
        // //console.log(tenantList);
        //console.log("tenantNameExists", isExist);
        if (isExist?.length != 0) {
            setTenantNameExists(true);
            return;
        }
        setTenantNameExists(false);
        let emailValid = IsEmailValid(selectedTenant.email);
        if (!emailValid) {
            setIsEmailInvalid(true);
            return;
        };
        setIsEmailInvalid(false);
        setIsInvalid(false);
        setIsRecordSubmitting(true);
        EditTenant(data, cookies().bearerToken)
            .then((res) => {
                console.log(res);
                if (res?.status == 200) {
                    getTenantList(first, 10);
                    setIsAddTenant(false);
                    setIsAdd(false);
                    setSelectedTenant([{
                        tenantName: "",
                        tenantStatus: false,
                        tenantID: 0,
                    }]);
                    setSelectedCountry({
                        "english_name": "Bahrain",
                        "arabic_name": "البحرين",
                        "alpha2_code": "BH",
                        "alpha3_code": "BHR",
                        "phone_code": "+973",
                        "flagImage": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BH.svg"
                    });
                    toast.success("Tenant is updated successfully", { position: "top-right" });
                    setIsRecordSubmitting(false);
                }
                else if (res?.response?.status === 400) {
                    setIsRecordSubmitting(false);
                    toast.error(res, { position: "top-right" });
                }
            })
            .catch((err) => {
                //console.log(err);
                setIsRecordSubmitting(false);
            });
    }
    const onPageChange = (e, index) => {
        setFirst(e.first);
        getTenantList(e.first, 10, globalFilterValue, status);
        //console.log(e);
    };
    const exportTenantInfo = () => {
        if (globalFilterValue) {
            exportDataInExcel(`Tenant?searchTerm=${globalFilterValue}`, cookies().bearerToken);
            return
        }
        exportDataInExcel('Tenant', cookies().bearerToken);
    }
    const onExportTenant = () => {
        // setExportFilterDialog(true);
        let urlRoute = `Tenant/GetTenantInformations?searchTerm=${globalFilterValue}${status ? `&status=${status == "In Active"}&showAll=${isShow({ status: status, search: globalFilterValue })}` : ""}&forExport=true`;
        exportFilterdExcel(urlRoute, cookies().bearerToken);
        // ExportOpenInNewTab(urlRoute, cookies().bearerToken);
    }
    useEffect(() => {
        getTenantList(0, 10, "", status);
        getActiveTenantCategoryList();
    }, [])

    // Export Filter
    const [exportFilterDialog, setExportFilterDialog] = useState(false);
    const [exportDetails, setExportDetails] = useState({
        startDate: "",
        endDate: "",
        organization: "",
        desk: "",
        status: true
    });


    // Deepak - 13/8/24
    const selectedCountryTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <img alt={option.flagImage} src={option?.flagImage} style={{ width: '18px' }} />
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const countryOptionTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <img alt={option.name} src={option?.flagImage} style={{ width: '18px' }} />
                <div style={{ margin: "0px 5px" }}>{option?.english_name}({option?.arabic_name}){option?.phone_code}</div>
            </div>
        );
    };
    const handleCountryChange = (value) => {
        console.log("Change", value);
        setSelectedTenant((prev) => ({ ...prev, countryCode: value?.phone_code }))

    }
    // ----------------------------------------------------------------

    return (
        <>
            <Dialog header={<h3>Export Filter</h3>} draggable={false} style={{ width: '50vw', height: "max-content" }} closable={true} onHide={() => setExportFilterDialog(false)} visible={exportFilterDialog} >
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="StartDate" className="font-bold block mb-2">Start Date
                        </label>
                        <Calendar
                            id="StartDate"
                            style={{ width: "95%" }}
                            value={exportDetails?.startDate}
                            placeholder="Choose start end"
                            onChange={((value) => setExportDetails(() => ({ ...exportDetails, startDate: value })))}
                        ></Calendar>
                    </div>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="EndDate" className="font-bold block mb-2">End Date
                        </label>
                        <Calendar
                            id="EndDate"
                            style={{ width: "95%" }}
                            value={exportDetails?.endDate}
                            placeholder="Choose end date"
                            onChange={((value) => setExportDetails(() => ({ ...exportDetails, endDate: value })))}
                        ></Calendar>
                    </div>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="organization" className="font-bold block mb-2">Organization
                        </label>
                        <InputText
                            id="organization"
                            style={{ width: "95%" }}
                            value={exportDetails?.organization}
                            onChange={((value) => setExportDetails(() => ({ ...exportDetails, organization: value })))}
                            placeholder="Enter organization" ></InputText>
                    </div>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="Status" className="font-bold block mb-2">Status
                        </label>
                        {/* <InputText
                                id="Status"
                                style={{ width: "95%" }}
                                value={exportDetails?.status}

                                placeholder="Enter organization" ></InputText> */}
                        <div className="input grid ">
                            <div className="col ">
                                <label className="switch ">
                                    <input type="checkbox" value={exportDetails?.status} checked={exportDetails?.status} onChange={((e) => setExportDetails(() => ({ ...exportDetails, status: e.target.checked })))} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>

                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "25px",
                    }}
                >
                    <Button
                        label="Submit"
                        onClick={''}
                        // icon={"pi pi-file-export"}
                        style={{
                            backgroundColor: " #ae9d81",
                            color: "#fff",
                            border: "none",
                            // borderRadius: "20px",
                            marginRight: "0",
                            cursor: totalCount > 0 ? "pointer" : "not-allowed",
                            padding: "0.6rem",
                        }}
                    />
                </div>


            </Dialog>
            <div className="tenantMaster">
                <div className="header">
                    <div style={{ display: "flex", flexDirection: "row", flexGrow: "3", justifyContent: "flex-start" }} className='flex align-items-center org'>
                        <i className="pi pi-arrow-circle-left nav-back-btn" onClick={back} style={{ marginLeft: '30px', cursor: 'pointer', fontSize: '1.5rem' }} />
                        <div className="heading">
                            <p>Tenant</p>
                        </div>
                    </div>
                    <div className='mx-2'>
                        <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Status</h4>
                        <Dropdown value={status} options={[{ name: "Active" }, { name: "In Active" }]} optionLabel='name' optionValue='name' onChange={(e) => { setStatus(e.value); getTenantList(0, 10, globalFilterValue, e.value) }} placeholder='Select Status' style={{ border: "solid 1px #ae9d81", width: 180 }} showClear={status ? true : false} />
                    </div>
                    <div className='mx-2'>
                        <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Search</h4>
                        <div className="search">
                            <span className="p-input-icon-left">
                                <i className="pi pi-search" style={{ color: 'black', fontWeight: '700', fontSize: '1rem' }} />
                                <InputText placeholder="Search" value={globalFilterValue} onChange={onSearch} style={{ width: "180px" }} />
                                <i className="pi pi-times" style={{ color: 'black', fontWeight: '400' }} onClick={() => { setGlobalFilterValue(""); onSearch(null) }} />
                            </span>
                        </div>
                    </div>

                    <div className='mx-2 align-self-start mt-1'>
                        <Button label="Add New" onClick={() => {
                            setIsAddTenant(true); setIsAdd(true); setSelectedTenant({
                                tenantName: "",
                                tenantStatus: true,
                                tenantID: 0,
                                groupName: "",
                                unitNumber: "",
                                mobileNumber: "",
                                email: "",
                            }
                            );
                            setSelectedCountry({
                                "english_name": "Bahrain",
                                "arabic_name": "البحرين",
                                "alpha2_code": "BH",
                                "alpha3_code": "BHR",
                                "phone_code": "+973",
                                "flagImage": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BH.svg"
                            });
                        }} style={{ backgroundColor: '#ae9d81', border: "none" }} />
                    </div>
                    <div className='mx-2 align-self-start'>
                        <Button label='Export' onClick={() =>
                            onExportTenant()
                        } icon={"pi pi-file-export"} style={{ backgroundColor: 'transparent', color: '#ae9d81', border: '1px #ae9d81 solid', borderRadius: '20px', cursor: totalCount > 0 ? 'pointer' : 'not-allowed' }} />
                    </div>
                    <div className='mx-2 align-self-start'>
                        <Button label='Import' icon={"pi pi-file-import"} style={{ backgroundColor: 'transparent', color: '#ae9d81', border: '1px #ae9d81 solid', borderRadius: '20px' }} onClick={() => { setIsImport(true) }} />
                    </div>
                </div>

                <div className="datatable">
                    {isLoading ?
                        <div className='spinner spinner-center'></div>
                        :
                        <DataTable value={tenantList} tableStyle={{ width: '90rem' }} style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }} stripedRows
                            rows={10} filters={filters} globalFilterFields={['name', 'status', 'groupName', 'unitNumber', 'id', 'email', 'phone']}
                            emptyMessage={<><p style={{ textAlign: "center" }}>Tenant List is Empty</p></>}>
                            <Column field="id" header="Tenant Id" style={{ width: '20%' }}></Column>
                            <Column field="name" header="Tenant Name" style={{ width: '20%' }}></Column>
                            <Column field="unitNumber" header="Unit Number" style={{ width: '20%' }}></Column>
                            <Column field="groupName" header="Group Name" style={{ width: '20%' }}></Column>
                            <Column field="phone" header="Mobile Number" style={{ width: '20%' }}></Column>
                            <Column field="email" header="Email" style={{ width: '20%' }}></Column>
                            <Column field="isDeleted" header="Status" body={statusBodyTemplate} style={{ width: '20%' }} ></Column>
                            <Column rowEditor header="Action" body={actionButtonTemplate} style={{ width: '20%' }}></Column>
                        </DataTable>}
                </div>
                {isLoading ? ""
                    :
                    <div style={{ justifyContent: "center", display: 'flex' }} >
                        <Paginator first={first} rows={10} totalRecords={totalCount} onPageChange={(e) => onPageChange(e, 1)} template={{ layout: 'RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport' }} className="justify-content-start" />
                    </div>
                }
            </div>

            <div>
                {isAddTenant == true ? popup() : ""}
                {isImport == true ? importPopup() : ""}
            </div>
        </>
    )
}

export default Tenant



