import UseCookies from '../../Helpers/UseCookies';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import './style.scss';
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import FileView from '../utili/FileView';
import { Dropdown } from 'primereact/dropdown';
import { Paginator } from 'primereact/paginator';


// ----------Assets Import------------
import editIcon from '../../../src/assets/images/editIcon.jpg';
// -----------------------------------

//-----------Context ---------------
import { useDispatch, useSelector } from "react-redux";
import { setGuestInspectionsInfo, setFeedbackList, setNavVisibilty } from '../../Redux/AppSlice';
// -------------------------------------

// --------------Service Imports------------
import { GetGuestStaffInspectedInformations, GetSearchedGuestStaffInspectedInformations } from '../../Services/GuestStaffInspectionViewService';
import { EditGuestStaffInspectionStatus } from '../../Services/GuestStaffInspectionViewService';
import { GetGuestStaffInspectionsForAdmin } from '../../Services/DepartmentTeamService';
// -------------------------------------------------


function GuestStaffInspectionAdminView() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [cookies, setCookie, removeCookie] = UseCookies();
    const [inspectionRecordList, setInspectionRecordList] = useState([]);
    const [editPopUp, setEditPopUp] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [statusList, setStatusList] = useState([{ "id": 1, "status": "Need Action" }, { "id": 2, "status": "Ok" }]);
    const [selectedRecord, setSelectedRecord] = useState({
        "id": 0,
        "status": "",
    });
    const [seletecdInspectionId, setSeletecdInspectionId] = useState("");

    // Pagination
    const [first, setFirst] = useState(0)
    const [totalCount, setTotalRecords] = useState(0)


    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [isFilemodelView, setISFileModelView] = useState(false);
    const [imageList, setImageList] = useState([]);

    const onGlobalFilterChange = (e) => {
        if (!e) {
            getInspectionDetailForAdmin(0, 500)
            return
        }
        getInspectionDetailForAdmin(0, 500, e?.target?.value ? e?.target?.value : "");
        setGlobalFilterValue(e?.target?.value);
    };
    const statusBodyTemplateTab = ((row) => {
        return <p style={{ padding: "0 20px" }} onClick={() => { setISFileModelView(true); setImageList(row?.files) }}>
            <i className="pi pi-paperclip" style={{ color: '#708090' }}></i>
        </p>
    });
    const commentBodyTemplate = ((row) => {
        return <p style={{ padding: "0 20px", textWrap: 'wrap', fontWeight: 400 }} >
            {row?.guestInspectedInformationComment}
        </p>
    });
    const actionBodyTemplateTab = ((row) => {
        return <p style={{ padding: "0 20px" }}>
            {row.status !== "Need Action" ? <i className="pi pi-exclamation-triangle" style={{ color: '#FF0000' }}></i> :
                <img src={editIcon} onClick={() => { setSelectedRecord((prev) => ({ ...prev, id: row.inspectionStatusId, status: row.status })); setSeletecdInspectionId(row.id); setEditPopUp(true); }} style={{ cursor: 'pointer' }} />

            }
        </p>
    });

    const header = () => {
        return <div className="heading" style={{ fontWeight: "700", fontSize: "23px", color: "#ae9d81", textAlign: 'center' }}>
            <p style={{ margin: '0px' }}>Edit Inspection details</p>
        </div>
    }


    const getInspectionDetailForAdmin = (async (skip, take, searchValue = "") => {
        setIsLoading(true);
        await GetGuestStaffInspectionsForAdmin(take, skip, cookies().bearerToken, searchValue)
            .then((res) => {
                if (res?.status == 200) {
                    //console.log("Id", id);
                    //console.log("Res", res?.data?.data[0].guestInspectionId);
                    //console.log(res?.data?.data.filter((inspection) => inspection.guestInspectionId == id));
                    setInspectionRecordList(res?.data?.data.filter((inspection) => inspection.guestInspectionId == id)[0].guestInspectedInformations);
                    setTotalRecords(res?.data?.count);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                //console.log(error);
                setIsLoading(false);
            })

    })

    const editGuesStaffInspectionStatus = (async () => {
        await EditGuestStaffInspectionStatus(seletecdInspectionId, selectedRecord.id, true, cookies().bearerToken)
            .then(async res => {
                if (res?.status == 200) {
                    navigate("/departmentteam/inspection")
                }
            })
            .catch((error) => {
                //console.log(error);
            })

    })

    const onPageChange = (e, index) => {
        setFirst(e.first);
        getInspectionDetailForAdmin(e.first, 500, globalFilterValue);
        //console.log(e);
    };

    useEffect(() => {
        getInspectionDetailForAdmin(0, 500);
    }, [])



    return (
        <>
            <FileView visible={isFilemodelView} imagelist={imageList} setVisible={setISFileModelView} header={"Attachments"} />
            <Dialog header={header} visible={editPopUp} style={{ width: '30vw' }}
                closable={false} >
                <div className="popup " >
                    {/* <div className="heading">
                            <p>Add New details</p>
                        </div> */}
                    <div className="body"  >
                        <div className="label grid" >
                            <div className='col' >Inspection Status<span style={{ color: 'red' }}>*</span></div>
                        </div>
                        <div className="input grid ">
                            <div className="col-6">
                                {/* <InputText placeholder="Enter Name" value={""} onChange={(e) => { }} /> */}

                                <Dropdown value={selectedRecord} style={{ border: "1px solid #AE9D81", width: "100%", height: "50px", borderRadius: "5px", boxShadow: "none" }}
                                    onChange={(e) => {
                                        //console.log("selectedRecord", selectedRecord);
                                        //console.log(e.target.value);
                                        setSelectedRecord((prev) => ({
                                            ...prev, id: e.target
                                                .value?.id, status: e.target.value.status
                                        }))
                                    }}
                                    options={statusList} optionLabel="status"
                                    placeholder="Select Inspection Status" />
                            </div>
                        </div>
                    </div>
                    <div className="footer flex  grid">
                        <div className="col flex justify-content-center ">
                            <Button label="Cancel" onClick={() => {
                                setEditPopUp(false); setSelectedRecord({
                                    "id": 0,
                                    "status": ""
                                });
                            }} style={{ backgroundColor: '#8E8E8E', border: "none", margin: "10px", height: '40px' }} />
                            <Button label="Update" onClick={editGuesStaffInspectionStatus} style={{ backgroundColor: '#ae9d81', border: "none", margin: "10px", height: '40px' }} />
                        </div>
                    </div>
                </div>
            </Dialog>
            <div className="GuestStaffInspectionView">
                <div className="header" style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ display: "flex", flexDirection: "row", flexGrow: "3", justifyContent: "flex-start" }} className='flex align-items-center org'>
                        <div>
                            <FontAwesomeIcon icon={faArrowLeft} onClick={() => { navigate("/gueststaffinspection") }} style={{ marginLeft: '30px', width: '45px', cursor: "pointer" }} />
                        </div>
                        {/* <div className="menubtn" style={{ padding: '10px 0px 5px 20px' }}>
                            <i className="pi pi-align-justify" style={{ fontSize: '1rem', cursor: "pointer" }} onClick={() => {
                                dispatch(
                                    setNavVisibilty({ sideNavVisibility: true }))
                            }}></i>
                        </div> */}
                        <div className="heading">
                            <p>View Guest Staff Inspection Details</p>
                        </div>
                    </div>
                    {/* <div className="search "
                        style={{ border: "solid 1px #ae9d81", borderRadius: '5px', marginRight: "50px" }}
                    >
                        <span className="p-input-icon-left">
                            <i
                                className="pi pi-search"
                                style={{ color: 'black', fontWeight: '700', fontSize: '1rem' }} />
                            <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" style={{ border: "unset" }} />
                            <i className="pi pi-times" style={{
                                color: 'black', fontWeight: '400', fontSize: '16px', position: 'absolute',

                            }} onClick={() => { setGlobalFilterValue(""); onGlobalFilterChange(null) }} />
                        </span>
                    </div> */}
                </div>

                <div className="datatable">
                    {isLoading ?
                        <div className='spinner spinner-center' ></div>
                        :
                        <DataTable value={inspectionRecordList}
                            tableStyle={{ width: '90vw' }} stripedRows rows={10} style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                            emptyMessage={<><p style={{ textAlign: "center" }}>Inspection List is Empty</p></>}>
                            <Column field='gestInspectedInformationStatusId' header=' Id' ></Column>
                            <Column field='guestInspectedInformationCategory' header=' Category' ></Column>
                            <Column field='guestInspectedInformationDepartmentName' header='Department Name' ></Column>
                            <Column field='guestInspectedInformationDepartmentName' header='Location'></Column>
                            <Column field='guestInspectedInformationComment' header='Comment' body={commentBodyTemplate} ></Column>
                            <Column field='guestInspectedInformationStatus' header='Status' />
                            <Column header='Attachments' body={statusBodyTemplateTab} />
                        </DataTable>}
                </div>
                {isLoading ? ""
                    :
                    <div style={{ justifyContent: "center", display: 'flex' }} >
                        <Paginator first={first} rows={10} totalRecords={totalCount} onPageChange={(e) => onPageChange(e, 1)} template={{ layout: 'RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport' }} className="justify-content-start" />
                    </div>
                }
            </div>
        </>
    )
}

export default GuestStaffInspectionAdminView