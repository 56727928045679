import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./style.scss";

// Assets
import editIcon from "../../assets/images/editIcon.jpg";
// -----------------------------

// PrimeReact Components
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { TabView, TabPanel } from "primereact/tabview";
import { Calendar } from "primereact/calendar";
// -------------------------------------------------------------

// Context
import { useDispatch, useSelector } from "react-redux";
import { setNavVisibilty } from "../../Redux/AppSlice";
import {
  GetAllFoundedItems,
  GetAllLostItems,
  GetSpecificFoundItems,
} from "../../Services/LostandFoundService";
import { toast } from "react-toastify";
import { exportDataInLandD } from "../../Services/ExportServices";
import { GetAllImageFiles } from "../../Services/Files";
import { Dialog } from "primereact/dialog";
import UseCookies from "../../Helpers/UseCookies";
// -----------------------------------------------

function SecurityStaff() {
  const { tab } = useParams();
  const [cookies] = UseCookies();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [totalCount, setTotalRecords] = useState(0);
  const [nextTotalCount, setNextTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(tab - 1);
  const [searchedFoundedValue, setSearchedFoundedValue] = useState("");
  // Date
  const currentDate = new Date();
  const sixMonthsAgo = new Date(currentDate);
  sixMonthsAgo.setMonth(currentDate.getMonth() - 6);
  const [fromDate, setFormDate] = useState("");
  const [toDate, setToDate] = useState("");
  // Filters and Pagination state values
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [globalDashboardFilterValue, setGlobalDashboardFilterValue] =
    useState("");
  const [first, setFirst] = useState(0);
  const [nextFirst, setNextFirst] = useState(0);
  const [loastItemsList, setLoastItemList] = useState([]);
  const [foundedItemsList, setFoundedItemList] = useState([]);
  const [specificFoundList, setSpecificFoundList] = useState([]);
  const [specificLostListCount, setSpecificLostListCount] = useState([]);

  // --------------------------------------------------------------------
  const onSpecificPageChange = (e, index) => {
    setFirst(e.first);
    getSpecificFoundList(10, e.first, globalDashboardFilterValue);
  };
  const onPageChange = (e, index) => {
    setFirst(e.first);
    getAllLostItems(10, e.first, globalFilterValue);
  };

  const onNextPageChange = (e, index) => {
    setNextFirst(e.first);
    getAllFoundedItems(10, e.nextFirst, searchedFoundedValue);
  };
  // -------------------------------------------------------------



  const onFoundFilterChange = (e) => {
    const value = e ? e?.target?.value : "";
    getAllFoundedItems(10, 0, value);
    //
    // let _filters = { ...pricingFilters };
    // _filters['global'].value = value;
    // setPricingFilters(_filters);
    setSearchedFoundedValue(value);
  };
  // -------------------------------------------------------------

  // Dashboard
  const actionBodyTemplate = () => {
    return (
      <div
        onClick={() => {
          navigate("/editlostform/1");
        }}
      >
        <img src={editIcon} style={{ cursor: "pointer" }} />
      </div>
    );
  };
  const actionLostBodyTemplate = (item) => {
    return (
      <div
        onClick={() => {
          navigate(`/editlostitem/${item?.id}/s2`);
        }}
      >
        <img src={editIcon} style={{ cursor: "pointer" }} />
      </div>
    );
  };
  const actionLostBodyDateTemplate = (item) => {
    const dateTime = new Date(item?.lostDateTime);
    const year = dateTime.getFullYear();
    const month = dateTime.getMonth() + 1;
    const day = dateTime.getDate();
    const hour = dateTime.getHours();
    const minutes = dateTime.getMinutes();

    return (
      <div>
        <p style={{ fontSize: "1rem", fontWeight: 400 }}>
          {day + "/" + month + "/" + year + " " + hour + ":" + minutes}
        </p>
      </div>
    );
  };
  const actionFoundBodyTemplate = (item) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={editIcon}
          style={{ cursor: "pointer", margin: "15px", height: "auto" }}
          onClick={() => {
            navigate(`/editfounditem/${item?.id}/s3`);
          }}
        />
        {/* <Button label='Return' style={{ color: "#fff", background: "#ae9d81", border: "none", marginLeft: "20px", width: '100px', height: "30px", lineHeight: "30px", fontSize: "14px", fontWeight: 200 }} onClick={() => navigate(`/returnform/${item?.id}`)}></Button> */}
        <Button
          label={item?.status === "Pending" ? "Return" : item?.status === "Delivered" ? "Delivered" : "Completed"}
          disabled={item?.status === "Completed"}
          style={{
            color: "#fff",
            background: "#ae9d81",
            border: "none",
            marginLeft: "70px",
            height: "40px",
            cursor: item?.status !== "Completed" ? "pointer" : "not-allowed",
          }}
          onClick={() => {
            if (item?.status === "Pending") {
              navigate(`/returnform/${item?.id}/s3`);
            }
            else if (item?.status === "Delivered") {
              navigate(`/returnform/${item?.id}/s3?isReturned=true`);
            }
            else {
              toast.error("Item is already completed.");
            }
          }}
        ></Button>
      </div >
    );
  };
  const dateFoundBodytemplate = (row) => {
    const dateTime = new Date(row?.foundDateTime);
    const year = dateTime.getFullYear();
    const month = dateTime.getMonth() + 1;
    const day = dateTime.getDate();
    const hour = dateTime.getHours();
    const minutes = dateTime.getMinutes();

    return (
      <div>
        <p style={{ fontSize: "1rem", fontWeight: 400 }}>
          {day + "/" + month + "/" + year + " " + hour + ":" + minutes}
        </p>
      </div>
    );
  };
  const dateAllFoundBodytemplate = (row) => {
    const dateTime = new Date(row?.foundDateTime);
    const year = dateTime.getFullYear();
    const month = dateTime.getMonth() + 1;
    const day = dateTime.getDate();
    const hour = dateTime.getHours();
    const minutes = dateTime.getMinutes();

    return (
      <div>
        <p style={{ fontSize: "1rem", fontWeight: 400 }}>
          {day + "/" + month + "/" + year + " " + hour + ":" + minutes}
        </p>
      </div>
    );
  };
  const createdDateBodytemplate = (row) => {
    const [date, month, year] = row?.createdDate.split("/");
    return (
      <div>
        <p style={{ fontSize: "1rem", fontWeight: 400 }}>
          {date + "/" + month + "/" + year}
        </p>
      </div>
    );
  };
  const actionBodyFoundTemplate = (row) => {
    return (
      <div
        onClick={() => {
          navigate(`/editfounditem/${row?.id}/s1`);
        }}
      >
        <img src={editIcon} style={{ cursor: "pointer" }} />
      </div>
    );
  };
  const [isFilemodelView, setISFileModelView] = useState(false);

  const imageActionTemplate = (row) => {
    return (
      <>
        <div
          style={{ display: "flex" }}
          className="col-6"
          onClick={() => {
            getImage(row?.id);
          }}
        >
          <img
            src={"data:image/jpeg;base64," + row?.base64}
            alt="Founded item image"
            style={{ width: "100px", height: "100px" }}
          />
          {/* <img src={editIcon} style={{ cursor: 'pointer' }} /> */}
          {/* <i className='pi pi-eye' style={{ cursor: 'pointer', fontSize: "1rem", display: 'flex', alignItems: 'center', margin: "0px 5px" }}></i> */}
        </div>
        <div className="flex col-6'"></div>
      </>
    );
  };
  const imageBodyTemplate = (row) => {
    return (
      <div>
        <img
          src={"data:image/jpeg;base64," + row?.base64}
          alt="Founded item image"
          style={{ width: "100px", height: "100px" }}
        />
      </div>
    );
  };
  const [files, setFileList] = useState([]);
  const [isFilePreview, setisFilePreview] = useState(false);
  const [isFilePreviewImage, setisFilePreviewImage] = useState(false);
  const getImage = async (id) => {
    let data = {
      id: id,
      module: "Found",
      isForInspection: "",
      inspectionId: "",
    };
    await GetAllImageFiles(data, cookies().bearerToken)
      .then((res) => {
        if (res.status === 200) {
          setFileList(res?.data);
          setISFileModelView(true);
          //console.log(res?.data?.data);
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  const nameBodyTemplate = (rowData) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div>
          {rowData.firstName} {rowData.lastName}
        </div>
      </div>
    );
  };
  const dateAllBodytemplate = (row) => {
    const dateTime = new Date(row?.lostDateTime);
    const year = dateTime.getFullYear();
    const month = dateTime.getMonth() + 1;
    const day = dateTime.getDate();
    const hour = dateTime.getHours();
    const minutes = dateTime.getMinutes();

    return (
      <div>
        <p style={{ fontSize: "1rem", fontWeight: 400 }}>
          {day + "/" + month + "/" + year + " " + hour + ":" + minutes}
        </p>
      </div>
    );
  };
  const storageBodyTemplate = (rowData) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div>{rowData?.storageLocation}</div>
      </div>
    );
  };
  const actionFoundBodyDateTemplate = (item) => {
    const dateTime = new Date(item?.foundDateTime);
    const year = dateTime.getFullYear();
    const month = dateTime.getMonth() + 1;
    const day = dateTime.getDate();
    const hour = dateTime.getHours();
    const minutes = dateTime.getMinutes();

    return (
      <div>
        <p style={{ fontSize: "1rem", fontWeight: 400 }}>
          {day + "/" + month + "/" + year + " " + hour + ":" + minutes}
        </p>
      </div>
    );
  };

  const getSpecificFoundList = async (
    take,
    skip,
    searchTerm = "",
    From = "",
    to = ""
  ) => {
    setIsLoading(true);
    await GetSpecificFoundItems(
      take,
      skip,
      searchTerm,
      From,
      to,
      cookies()?.bearerToken
    )
      .then((res) => {
        if (res.status == 200) {
          setSpecificFoundList(res?.data?.data);
          setSpecificLostListCount(res?.data?.count);
        }
      })
      .catch((error) => {
        //console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getAllLostItems = async (take, skip, searchValue = "", fromDate = "", toDate = "") => {
    setIsLoading(true);
    await GetAllLostItems(take,
      skip,
      searchValue,
      fromDate,
      toDate,
      cookies()?.bearerToken)
      .then((res) => {
        if (res.status == 200) {
          setLoastItemList(res?.data?.data);
          setTotalRecords(res?.data?.count);
        }
      })
      .catch((error) => {
        //console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getAllFoundedItems = async (
    take,
    skip,
    searchValue = "",
    fromDate = "",
    toDate = ""
  ) => {
    setIsLoading(true);
    await GetAllFoundedItems(
      take,
      skip,
      searchValue,
      fromDate,
      toDate,
      cookies()?.bearerToken
    )
      .then((res) => {
        if (res.status == 200) {
          setFoundedItemList(res?.data?.data);
          setNextTotalRecords(res?.data?.count);
        }
      })
      .catch((error) => {
        //console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  // Filters
  const onGlobalFilterChange = (e) => {
    setGlobalFilterValue(e ? e.target.value : "");
    if (!e) {
      getAllLostItems(10, 0);
      return;
    }
    getAllLostItems(10, 0, e ? e.target.value : "");
  };
  const onGlobalDashboardFilterChange = (e) => {
    setGlobalDashboardFilterValue(e ? e.target.value : "");
    if (!e) {
      getSpecificFoundList(10, 0);
      return;
    }
    getSpecificFoundList(10, 0, e ? e.target.value : "");
  };

  const onSearchingFoundedItems = (e) => {
    let date1 = "";
    let date2 = "";
    if (fromDate && toDate) {
      date1 = new Date(fromDate);
      date2 = new Date(toDate);

      date1 = `${date1?.getMonth() + 1
        }/${date1?.getDate()}/${date1?.getFullYear()}`;
      date2 = `${date2?.getMonth() + 1
        }/${date2?.getDate()}/${date2?.getFullYear()}`;

    }
    setSearchedFoundedValue(e ? e.target.value : "", date1, date2);
    if (!e) {
      getAllFoundedItems(10, 0, "", date1, date2);
      return;
    }
    getAllFoundedItems(10, 0, e ? e.target.value : "", date1, date2);
  };
  const isValidateDate = () => {
    return new Date(fromDate) <= new Date(toDate);
  };
  const onDateChanging = () => {
    if (!isValidateDate()) {
      toast.info("End date must be greater than start date");
      return;
    }
    let date1 = new Date(fromDate);
    let date2 = new Date(toDate);

    date1 = `${date1?.getMonth() + 1
      }/${date1?.getDate()}/${date1?.getFullYear()}`;
    date2 = `${date2?.getMonth() + 1
      }/${date2?.getDate()}/${date2?.getFullYear()}`;

    // //console.log(date1, date2);
    if (tab == "2") {
      getAllLostItems(10, 0, globalFilterValue, date1, date2);
    }
    if (tab == "3") {
      getAllFoundedItems(10, 0, searchedFoundedValue, date1, date2);
    }
  };
  const onClearAllDate = () => {
    // if (!(fromDate && toDate)) {
    //     return;
    // }
    setFormDate("");
    setToDate("");
    getAllFoundedItems(10, 0, searchedFoundedValue);
  };
  const OnExportLostClick = async () => {
    let date1 = "";
    let date2 = "";
    if (fromDate && toDate) {
      date1 = new Date(fromDate);
      date2 = new Date(toDate);

      date1 = `${date1?.getMonth() + 1
        }/${date1?.getDate()}/${date1?.getFullYear()}`;
      date2 = `${date2?.getMonth() + 1
        }/${date2?.getDate()}/${date2?.getFullYear()}`;

    }
    let route = "LostDetails?";
    let param = `searchTerm=${globalFilterValue}&from=${date1}&to=${date2}`;
    exportDataInLandD(route, param);
  };
  const OnExportFoundClick = async () => {
    let date1 = "";
    let date2 = "";
    if (fromDate && toDate) {
      date1 = new Date(fromDate);
      date2 = new Date(toDate);

      date1 = `${date1?.getMonth() + 1
        }/${date1?.getDate()}/${date1?.getFullYear()}`;
      date2 = `${date2?.getMonth() + 1
        }/${date2?.getDate()}/${date2?.getFullYear()}`;

    }
    let route = "FoundDetails?";
    let param = `searchTerm=${searchedFoundedValue}&from=${date1}&to=${date2}`;
    exportDataInLandD(route, param);
  };
  const OnExportDashboardFoundClick = async () => {
    let route = "FoundDetails?";
    let param = `searchTerm=${searchedFoundedValue}`;
    exportDataInLandD(route, param);

  };


  // TabView
  const handleTabChange = (index) => {
    // setActiveIndex(index);
    setGlobalFilterValue("");
    setSearchedFoundedValue("");
    onGlobalFilterChange("");
    onFoundFilterChange("");
    if (index == 0) {
      navigate("/security-staff/1");
    } else if (index == 1) {
      navigate("/security-staff/2");
    } else if (index == 2) {
      navigate("/security-staff/3");
    }
  };


  useEffect(() => {
    console.log("Tab", tab);
    if (tab === "1") {
      setActiveIndex(0);
      getSpecificFoundList(10, 0);
    } else if (tab === "2") {
      setActiveIndex(1);
      getAllLostItems(10, 0);
    } else if (tab === "3") {
      setActiveIndex(2);
      getAllFoundedItems(10, 0);
    }
  }, [tab]);



  return (
    <>
      <Dialog
        header={"Attachments"}
        visible={isFilemodelView}
        // modal={false}
        style={{ width: "20vw" }}
        onHide={() => setISFileModelView(false)}
      >
        <>
          {files.length > 0 && (
            <div className="file-list">
              {files.map((file, index) => {
                return (
                  <div className="file-item" key={index}>
                    <div
                      className="file-name"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setisFilePreview(true);
                        setisFilePreviewImage(file?.base64);
                      }}
                    >
                      {file?.fileModule + file?.fileName}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {/* <img src={"data:image/jpeg;base64," + files[0]?.base64} alt="" srcset="" /> */}
        </>
      </Dialog>
      <Dialog
        // header={<div><p style={{ textAlign: "center" }}>Image Preview</p></div>}
        visible={isFilePreview}
        style={{ width: "80vw", height: "90vh" }}
        onHide={() => setisFilePreview(false)}
      >
        <div style={{ display: "flex" }}>
          <img
            style={{ margin: "auto", width: "100%" }}
            src={"data:image/jpeg;base64," + isFilePreviewImage}
            alt=""
            srcset=""
          />
        </div>
      </Dialog>
      <div className="lostandfound">
        <div className="header">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexGrow: "3",
              justifyContent: "flex-start",
            }}
            className="flex align-items-center org"
          >
            <div className="menubtn" style={{ padding: "10px 10px 5px 20px" }}>
              <i
                className="pi pi-align-justify"
                style={{
                  fontSize: "1rem",
                  color: "#ae9d81",
                  cursor: "pointer",
                }}
                onClick={() =>
                  dispatch(setNavVisibilty({ sideNavVisibility: true }))
                }
              ></i>
            </div>

            <div
              className="heading"
              style={{ paddingLeft: "15px", marginLeft: "40px" }}
            >
              <p
                style={{
                  color: "#2d6c8b",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "normal",
                  letterSpacing: "-0.3px",
                }}
              >
                Lost and Found - Security
              </p>
            </div>
          </div>
        </div>
        <div className="card" style={{ padding: "0 10px" }}>
          <TabView
            activeIndex={activeIndex}
            style={{ marginLeft: "20px" }}
            onTabChange={(e) => {
              handleTabChange(e.index);
              setGlobalFilterValue("");
              setSearchedFoundedValue("");
              onGlobalFilterChange("");
              onFoundFilterChange("");

            }}
          >
            <TabPanel
              header="Found List"
              className={activeIndex === 0 ? "active_tab tab_view" : "tab_view"}
            >
              <div className="tab_header">
                <div className="flex align-items-center" style={{ justifyContent: "unset", position: "absolute", top: "140px", right: "0px" }}>
                  <div className="mx-2">
                    <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Search</h4>
                    <div
                      className="search"
                      style={{
                        border: "solid 1px #ae9d81",
                        borderRadius: "5px",
                      }}
                    >
                      <span className="" style={{ display: "flex", alignItems: "center" }}>
                        <i
                          className="pi pi-search"
                          style={{
                            paddingLeft: 7,
                            color: "black",
                            fontWeight: "700",
                            fontSize: "1rem",
                          }}
                        />
                        <InputText
                          value={globalDashboardFilterValue}
                          onChange={onGlobalDashboardFilterChange}
                          placeholder="Search"
                          style={{ border: "unset" }}
                        />
                        <i
                          className="pi pi-times"
                          style={{
                            color: "black",
                            fontWeight: "400",
                            fontSize: "16px",
                            paddingRight: "10px"
                          }}
                          onClick={() => {
                            setGlobalFilterValue("");
                            onGlobalDashboardFilterChange(null);
                            getSpecificFoundList(0, 10);
                          }}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="mx-2 align-self-end">
                    <Button
                      label="Add Found Item"
                      onClick={() => {
                        navigate("/addfoundform/s1");
                      }}
                      style={{
                        backgroundColor: "#ae9d81",
                        color: "#fff",
                        border: "1px #ae9d81 solid",
                        cursor: "pointer",
                        height: "40px",
                      }}
                    />
                  </div>
                  <div className="mx-2 align-self-end">
                    <Button
                      label="Export"
                      onClick={OnExportDashboardFoundClick}
                      icon={"pi pi-file-export"}
                      style={{
                        backgroundColor: "transparent",
                        color: "#ae9d81",
                        border: "1px #ae9d81 solid",
                        borderRadius: "20px",
                        cursor: "pointer",
                        height: "40px",
                      }}
                    />
                  </div>
                </div>

              </div>
              {isLoading ? (
                <div
                  className="spinner spinner-center"
                  style={{ marginLeft: "750px" }}
                ></div>
              ) : (
                <>
                  <DataTable
                    value={specificFoundList}
                    tableStyle={{ width: "90rem" }}
                    style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
                    rows={10}
                    emptyMessage={
                      <>
                        <p style={{ textAlign: "center" }}>
                          Found item list is empty
                        </p>
                      </>
                    }
                  >
                    <Column field="foundCode" header="Found Id"></Column>
                    <Column header=" Name" body={nameBodyTemplate}></Column>
                    <Column field="category" header="Category"></Column>
                    <Column field="categoryAR" header="Category AR"></Column>
                    <Column
                      field="foundDateTime"
                      header="Found Date and Time"
                      body={dateAllFoundBodytemplate}
                    ></Column>
                    <Column
                      field="createdDate"
                      header="Created Date"
                      body={createdDateBodytemplate}
                    ></Column>
                    <Column
                      field="whereDidYouFind"
                      header="Found Location"
                    ></Column>
                    <Column header="Image" body={imageActionTemplate}></Column>
                    <Column field="status" header="Status"></Column>
                    <Column
                      header="Action"
                      body={actionBodyFoundTemplate}
                    ></Column>
                  </DataTable>
                </>
              )}
              {isLoading || specificFoundList.length == 0 ? (
                ""
              ) : (
                <div style={{ justifyContent: "center", display: "flex" }}>
                  <Paginator
                    first={first}
                    rows={10}
                    totalRecords={specificLostListCount}
                    onPageChange={(e) => onSpecificPageChange(e, 1)}
                    template={{
                      layout:
                        "RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport",
                    }}
                    className="justify-content-start"
                  />
                </div>
              )}
            </TabPanel>
            <TabPanel
              header="Lost Items"
              className={activeIndex === 1 ? "active_tab tab_view" : "tab_view"}
            >
              <div className="tab_header">
                <div className="flex align-items-center" style={{ justifyContent: "unset", position: "absolute", top: "140px", right: "0px" }}>
                  <div className="mx-2">
                    <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Search</h4>
                    <div
                      className="search"
                      style={{
                        border: "solid 1px #ae9d81",
                        borderRadius: "5px",
                        right: "35%",
                        width: "200px",
                      }}
                    >
                      <span className="" style={{ display: "flex", alignItems: "center" }}>
                        <i
                          className="pi pi-search"
                          style={{
                            color: "black",
                            fontWeight: "700",
                            fontSize: "1rem",
                            padding: "0 10px"
                          }}
                        />
                        <InputText
                          value={globalFilterValue}
                          onChange={onGlobalFilterChange}
                          placeholder="Search"
                          style={{ border: "unset", width: "100%" }}
                        />
                        <i
                          className="pi pi-times"
                          style={{
                            color: "black",
                            fontWeight: "400",
                            fontSize: "16px",
                            padding: "0 10px"
                          }}
                          onClick={() => {
                            setGlobalFilterValue("");
                            onGlobalFilterChange(null);
                            getAllLostItems(0, 10);
                          }}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="mx-2">
                    <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>From Date</h4>
                    <div className="calender">
                      <Calendar
                        value={fromDate}
                        placeholder="Start Date"
                        onChange={(date) => {
                          setFormDate(date?.value);
                        }}
                        showIcon
                        selectionMode="single"
                        style={{
                          color: "#fff",
                          cursor: "pointer",
                          height: "40px",
                          width: "150px",
                        }}
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                  <div className="mx-2">
                    <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>To Date</h4>
                    <div className="calender">
                      <Calendar
                        value={toDate}
                        placeholder="End Date"
                        onChange={(date) => setToDate(date?.value)}
                        selectionMode="single"
                        showIcon
                        style={{
                          color: "#fff",
                          cursor: "pointer",
                          height: "40px",
                          width: "150px",
                        }}
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                  <div className="mx-2 align-self-end">
                    <Button
                      onClick={onDateChanging}
                      disabled={fromDate == "" || toDate == ""}
                      style={{
                        color: "#fff",
                        backgroundClip: "#ae9d81",
                        backgroundColor: "#ae9d81",
                        border: "0px",
                        cursor: "pointer",
                        height: "38px",
                        lineHeight: "38px",
                        zIndex: 1
                      }}
                    >
                      Check
                    </Button>
                  </div>
                  <div className="mx-2  align-self-end">
                    <Button
                      onClick={() => {
                        onClearAllDate();
                      }}
                      style={{
                        color: "#fff",
                        backgroundColor: "#adadad",
                        backgroundClip: "#adadad",
                        cursor: "pointer",
                        height: "38px",
                        lineHeight: "38px",
                        border: "0px"
                      }}
                    >
                      Clear
                    </Button>
                  </div>
                  <div className="mx-2 align-self-end">
                    <Button
                      label="Export"
                      onClick={OnExportLostClick}
                      icon={"pi pi-file-export"}
                      style={{
                        backgroundColor: "transparent",
                        color: "#ae9d81",
                        border: "1px #ae9d81 solid",
                        borderRadius: "20px",
                        marginRight: "20px",
                        cursor: "pointer",
                        height: "40px",
                      }}
                    />
                  </div>
                </div>
                {/* <div>
                                    <Button label='Add Found Item' onClick={() => { navigate('/addfoundform/s2') }} style={{ backgroundColor: '#ae9d81', color: '#fff', border: '1px #ae9d81 solid', marginRight: '20px', cursor: 'pointer', position: 'absolute', top: 150, height: '40px', right: 10 }} />
                                </div> */}
              </div>
              {isLoading ? (
                <div
                  className="spinner spinner-center"
                  style={{ marginLeft: "750px" }}
                ></div>
              ) : (
                <>
                  <DataTable
                    value={loastItemsList}
                    tableStyle={{ width: "90rem" }}
                    style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
                    rows={10}
                    emptyMessage={
                      <>
                        <p style={{ textAlign: "center" }}>
                          Lost item list is empty
                        </p>
                      </>
                    }
                  >
                    <Column field="lostCode" header="Lost Id"></Column>
                    <Column field="category" header="Category"></Column>
                    <Column
                      field="whatDidYouLose"
                      header="Description"
                    ></Column>
                    <Column
                      field="createdDate"
                      header="Created Date"
                      body={createdDateBodytemplate}
                    ></Column>
                    <Column
                      field="lostDateTime"
                      header="Lost Date and Time"
                      body={actionLostBodyDateTemplate}
                    ></Column>
                    <Column
                      field="whereDidYouLose"
                      header="Lost Location"
                    ></Column>
                    <Column field="status" header="Status"></Column>
                    {/* <Column field='image' header='Attachment'></Column> */}
                    <Column
                      header="Action"
                      body={actionLostBodyTemplate}
                    ></Column>
                  </DataTable>
                </>
              )}
              {isLoading || loastItemsList.length == 0 ? (
                ""
              ) : (
                <div style={{ justifyContent: "center", display: "flex" }}>
                  <Paginator
                    first={first}
                    rows={10}
                    totalRecords={totalCount}
                    onPageChange={(e) => onPageChange(e, 1)}
                    template={{
                      layout:
                        "RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport",
                    }}
                    className="justify-content-start"
                  />
                </div>
              )}
            </TabPanel>
            <TabPanel
              header="My Found Items"
              className={activeIndex === 2 ? "active_tab tab_view" : "tab_view"}
            >

              <div className="tab_header" style={{ right: "100px" }}>
                <div className="flex align-items-center" style={{ justifyContent: "unset", position: "absolute", top: "140px", right: "0px" }}>
                  <div className="mx-2">
                    <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Search</h4>
                    <div>
                      <div
                        className="search"
                        style={{
                          border: "solid 1px #ae9d81",
                          borderRadius: "5px",
                          width: "200px",
                        }}
                      >
                        <span className="" style={{ display: "flex", alignItems: "center" }}>
                          <i
                            className="pi pi-search"
                            style={{
                              color: "black",
                              fontWeight: "700",
                              fontSize: "1rem",
                              padding: "0 10px"
                            }}
                          />
                          <InputText
                            value={searchedFoundedValue}
                            onChange={onSearchingFoundedItems}
                            placeholder="Search"
                            style={{ border: "unset", width: "100%" }}
                          />
                          <i
                            className="pi pi-times"
                            style={{
                              color: "black",
                              fontWeight: "400",
                              fontSize: "16px",
                              padding: "0 10px"
                            }}
                            onClick={() => {
                              setSearchedFoundedValue("");
                              onSearchingFoundedItems(null);
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="mx-2">
                    <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>From Date</h4>
                    <Calendar
                      value={fromDate}
                      placeholder="Start Date"
                      onChange={(date) => {
                        setFormDate(date?.value);
                      }}
                      showIcon
                      selectionMode="single"
                      style={{
                        color: "#fff",
                        cursor: "pointer",
                        height: "40px",
                        width: "150px",
                      }}
                      maxDate={new Date()}
                    />
                  </div>
                  <div className="mx-2">
                    <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>To Date</h4>
                    <Calendar
                      value={toDate}
                      placeholder="End Date"
                      onChange={(date) => setToDate(date?.value)}
                      selectionMode="single"
                      showIcon
                      style={{
                        color: "#fff",
                        cursor: "pointer",
                        height: "40px",
                        width: "150px",
                      }}
                      maxDate={new Date()}
                    />
                  </div>
                  <div className="mx-2 align-self-end">
                    <Button
                      onClick={onDateChanging}
                      disabled={fromDate == "" || toDate == ""}
                      style={{
                        color: "#fff",
                        backgroundClip: "#ae9d81",
                        backgroundColor: "#ae9d81",
                        cursor: "pointer",
                        height: "38px",
                        lineHeight: "38px",
                        zIndex: 1,
                        border: "0px"
                      }}
                    >
                      Check
                    </Button>
                  </div>
                  <div className="mx-2 align-self-end">
                    <Button
                      onClick={() => {
                        onClearAllDate();
                      }}
                      style={{
                        color: "#fff",
                        backgroundColor: "#adadad",
                        backgroundClip: "#adadad",
                        cursor: "pointer",
                        height: "38px",
                        lineHeight: "38px",
                        border: "0px"
                      }}
                    >
                      Clear
                    </Button>
                  </div>
                  <div className="mx-2 align-self-end">
                    <Button
                      label="Export"
                      onClick={OnExportFoundClick}
                      icon={"pi pi-file-export"}
                      style={{
                        backgroundColor: "transparent",
                        color: "#ae9d81",
                        border: "1px #ae9d81 solid",
                        borderRadius: "20px",
                        marginRight: "20px",
                        cursor: "pointer",
                        height: "40px",
                      }}
                    />
                  </div>
                </div>

                {/* <div>
                                    <Button label='Add Lost Item' onClick={""} style={{ backgroundColor: '#ae9d81', color: '#fff', border: '1px #ae9d81 solid', marginRight: '20px', cursor: 'pointer', position: 'absolute', right: 10, top: 150, height: "40px" }} />
                                </div> */}
              </div>
              {isLoading ? (
                <div
                  className="spinner spinner-center"
                  style={{ marginLeft: "750px" }}
                ></div>
              ) : (
                <>
                  <DataTable
                    value={foundedItemsList}
                    tableStyle={{ width: "90rem" }}
                    style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
                    rows={10}
                    emptyMessage={
                      <>
                        <p style={{ textAlign: "center" }}>
                          Found item list is empty
                        </p>
                      </>
                    }
                  >
                    <Column field="foundCode" header="Found Id"></Column>
                    <Column field="category" header="Category"></Column>
                    <Column field="categoryAR" header="Category AR"></Column>
                    <Column field="whatDidYouFind" header="Found Item"></Column>
                    <Column
                      field="createdDate"
                      header="Created Date"
                      body={createdDateBodytemplate}
                    ></Column>
                    <Column
                      field="foundDateTime"
                      header="Found Date and Time"
                      body={dateFoundBodytemplate}
                    ></Column>
                    <Column
                      field="storageLocationId"
                      header="Storage Location"
                      body={storageBodyTemplate}
                    ></Column>
                    <Column header="Image" body={imageActionTemplate}></Column>
                    <Column field="status" header="Status"></Column>
                    <Column
                      header="Action"
                      body={actionFoundBodyTemplate}
                    ></Column>
                  </DataTable>
                </>
              )}
              {isLoading || foundedItemsList.length == 0 ? (
                ""
              ) : (
                <div style={{ justifyContent: "center", display: "flex" }}>
                  <Paginator
                    first={nextFirst}
                    rows={10}
                    totalRecords={nextTotalCount}
                    onPageChange={(e) => onNextPageChange(e, 1)}
                    template={{
                      layout:
                        "RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport",
                    }}
                    className="justify-content-start"
                  />
                </div>
              )}
            </TabPanel>
          </TabView>
        </div>
      </div>
    </>
  );
}

export default SecurityStaff;

