import { useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import './style.scss';
import { Button } from 'primereact/button';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { GetActiveStorageLocation, GetFoundItem, GetStatusForFoundItem, EditFoundItemForGSR, AddFoundItem } from '../../Services/LostandFoundService';
import { CiImageOn } from 'react-icons/ci';
import { BulkUploadBase64Files, DownloadFile, getAttachmentFile } from '../../Services/Files';
import LostAndFoundCategory from '../LostAndFoundCategory/LostAndFoundCategory';
import { GetActiveCategory } from '../../Services/LostAndFoundCategoryService';
import CustomWebcam from '../Camera/Camera';
import { MdOutlineDriveFileRenameOutline } from 'react-icons/md';
import { toast } from 'react-toastify';
import IntlTelInput from 'react-intl-tel-input';
import CountryPicker from '../CountryPicker/CountryPicker';
import nationality from "../../assets/JSON/NationalityFlags.json"
import UseCookies from '../../Helpers/UseCookies';
import { FileUpload } from 'primereact/fileupload';



function AddFoundForm() {
    const { route } = useParams();
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = UseCookies();
    const [foundItem, setFoundItem] = useState({

        "id": 0,
        "firstName": "",
        "lastName": "",
        "email": "",
        "phoneNumber": "",
        "categoryId": "",
        "whatDidYouFind": "",
        "foundDateTime": new Date(),
        "whereDidYouFind": "",
        "statusId": "",
        "storageLocationId": "",
        "isDeleted": false,
        "files": [],
        "images": [],
        "countryCode": "+973"
    });
    const fileInputRef = useRef(null);
    const localizationValue = "en";
    const [storageLocation, setStorageLocation] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [invalid, setInvalid] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [image, setImage] = useState([]);
    const [imgSrc, setImgSrc] = useState("");
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);
    /** 
     * @author deepakraj
     * @description state for selected Country
     * @date 12/7/24
     **/

    const [selectedCountry, setSelectedCountry] = useState({
        "english_name": "Bahrain",
        "arabic_name": "البحرين",
        "alpha2_code": "BH",
        "alpha3_code": "BHR",
        "phone_code": "+973",
        "flagImage": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BH.svg"
    });
    const selectedCountryTemplate = (option, props) => {
        // console.log(option);
        if (option) {
            return (
                <div className="flex align-items-center">
                    <img alt={option.flagImage} src={option?.flagImage} style={{ width: '18px' }} />
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const countryOptionTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <img alt={option.name} src={option?.flagImage} style={{ width: '18px' }} />
                <div>{option?.english_name}({option?.arabic_name}){option?.phone_code}</div>
            </div>
        );
    };
    // ----------------------------------------------------------------

    // File preview-Deepak
    const [previewLoader, setPreviewLoader] = useState({
        id: "",
        isLoad: false,
    });
    const [previewAttachment, setPreviewAttachment] = useState({
        isOpen: false,
        attachmentUrl: "",
    });
    //   --------------------


    // Mobile number with conutry code - Deepak
    // const getMobileNumber = () => {
    //     let mobileNumber = inputRef?.current?.selectedCountryData?.dialCode + inputRef?.current?.state?.value
    //     assignValue("phoneNumber", "+" + mobileNumber)
    //     return mobileNumber;
    // };
    const assignValue = (key, value) => {
        setFoundItem((prevState) => {
            return {
                ...prevState,
                [key]: value
            };
        });
    };

    const handlePhoneChange = (value, countryData) => {
        if (
            value?.length <= 12 &&
            /^[0-9]+$|^$/.test(value) &&
            value !== foundItem?.phoneNumber
        ) {
            setFoundItem({
                ...foundItem,
                phoneNumber: value,
                countryCode: countryData?.dialCode,
            });
        }
        // setPhoneNumber(value);
    };

    // const getSelectedCountryName = () => {
    //     let selectedCountry = "bh";
    //     if (inputRef?.current?.countries && foundItem?.countryCode) {
    //         selectedCountry = [...inputRef?.current?.countries]?.find(
    //             (val) => val.dialCode === foundItem?.countryCode.split("+")[1]
    //         )?.iso2;
    //     }
    //     console.log("selectedCountry", selectedCountry);
    //     return selectedCountry ? selectedCountry : "bh";
    // };
    // ------------------------------


    // Deepak - Country Picker
    const handleChange = (value) => {
        console.log("Change", value);
        setFoundItem({
            ...foundItem,
            phoneNumber: value
        });
    }
    const handleCountryChange = (value) => {
        console.log("Change", value);
        setFoundItem({
            ...foundItem,
            countryCode: value?.phone_code.includes("+") ? value.phone_code : "+" + value.phone_code
        });
    }
    // -------------------------------

    const convertDate = (value) => {
        if (value === null || value === undefined || value === "")
            return "";
        else {
            const dateTime = new Date(value);
            const year = dateTime.getFullYear();
            const month = dateTime.getMonth() + 1;
            const day = dateTime.getDate();
            let date = year + "/" + month + "/" + day;
            return date;
        }
    };


    const handleSubmit = async () => {
        console.log(foundItem);
        if (foundItem.firstName == "" ||
            foundItem.lastName == "" ||
            foundItem.countryCode == "" ||
            // foundItem.email == "" ||
            foundItem.phoneNumber == "" ||
            foundItem.categoryId == "" ||
            foundItem.whatDidYouFind == "" ||
            foundItem.foundDateTime == "" ||
            foundItem.whereDidYouFind == "" ||
            foundItem.storageLocationId == "" ||
            foundItem?.files?.length == 0
        ) {
            setInvalid(true)
            return;
        }
        setInvalid(false);
        setIsFormSubmitting(true);
        let data = {
            "firstName": foundItem.firstName,
            "lastName": foundItem.lastName,
            "email": foundItem.email,
            "countryCode": foundItem.countryCode || selectedCountry?.phone_code,
            "phoneNumber": foundItem.phoneNumber,
            "categoryId": foundItem.categoryId,
            "whatDidYouFind": foundItem.whatDidYouFind,
            "foundDateTime": convertDate(foundItem.foundDateTime),
            "whereDidYouFind": foundItem.whereDidYouFind,
            "storageLocationId": foundItem.storageLocationId,
        }
        await AddFoundItem(data, cookies().bearerToken)
            .then((res) => {
                if (res.status === 200) {
                    toast.success("Found Item added successfully", { position: "top-right" })
                    AddAttachment(res?.data);
                    //console.log("Found Item", res?.data);
                }
            })
            .catch((err) => {
                //console.log(err);
            })
            .finally(() => {
                if (route == "g2") { navigate('/lostandfound/2') }
                if (route == "s1") { navigate('/security-staff/1') }

            })
    };




    const AddAttachment = async (id) => {
        let fileNameExtension = imgSrc.split(";base64,")[0].split("/")[1];
        let fileModule = "Found";
        let filedate = new Date().getFullYear() + new Date().getMonth() + new Date().getDate();
        let fileName = fileModule + filedate + "." + fileNameExtension;
        let data = {
            "id": id,
            "module": "Found",
            "inspectionId": 0,
            "files": foundItem?.files
        }
        //console.log("Data", data);
        await BulkUploadBase64Files(data, cookies().bearerToken)
            .then((res) => {
                if (res.status == 200) {
                    //console.log(res);
                    // toast.success("Attachments uploaded successfully", { position: "top-right" });
                }
            })
            .catch((err) => {
                //console.log(err);
            })
    };






    const downloadFile = (_) => {
        DownloadFile(_.fileName, _.blobUrl, cookies()?.bearerToken);
    };
    const previewImage = (url) => {
        console.log(url);
        setPreviewLoader({ id: url.moduleId, isLoad: true });
        let data = [
            {
                fileName: url.fileName,
                azureBlobUrl: url.blobUrl.replaceAll("\\", "/"),
            },
        ]
        getAttachmentFile(data, cookies()?.bearerToken)
            .then((res) => {
                console.log(res);
                setPreviewAttachment({
                    isOpen: true,
                    attachmentUrl: res?.data?.[0].base64,
                });
                setPreviewLoader({ id: "", isLoad: false });
            })
            .catch((err) => {
                console.log(err);
            });
    };


    const getActiveStorageLocation = async () => {
        await GetActiveStorageLocation(cookies()?.bearerToken)
            .then((res) => {
                if (res.status === 200) {
                    setStorageLocation(res?.data);
                }
            })
            .catch((err) => {
                //console.log(err);
            })
    };
    const getLostandFoundCategory = async () => {
        await GetActiveCategory(cookies()?.bearerToken)
            .then((res) => {
                if (res.status === 200) {
                    setCategoryList(res?.data);
                }
            })
            .catch((err) => {
                //console.log(err);
            })

    };
    const imageToBase64 = async (fileList) => {
        let imageList = [];
        if (fileList?.length > 0) {
            for (const image of fileList) {
                const imageData = await new Promise((resolve) => {
                    let reader = new FileReader();
                    reader.readAsDataURL(image);
                    reader.onload = () => {
                        resolve(reader.result);
                    };
                });
                imageList.push({
                    fileName: image?.name?.slice(0, 10) + "." + image?.name?.split(".")[1],
                    base64: imageData.split(";base64,")[1]
                });
                console.log("Upload files ", imageList);
                fileList.pop();
            }
        }
        return imageList;
    };
    const onUpload = async (e) => {
        if (e.files.length == 0) {
            return;
        }
        let imageList = await imageToBase64(e.files);
        setFoundItem((prev) => ({ ...prev, files: [...prev?.files, ...imageList] }))
    };
    const ConvertAndAddToImageList = (image) => {
        let base64 = image.split(";base64,")[1];
        let fileNameExtension = image.split(";base64,")[0].split("/")[1];
        let fileModule = "Found";
        let filedate = new Date().getFullYear().toString() + new Date().getMonth().toString() + new Date().getDate().toString();
        let fileName = fileModule + filedate + "." + fileNameExtension;
        let imageData =
        {
            "fileName": fileName,
            "base64": base64
        };
        setFoundItem((prev) => ({ ...prev, files: [...prev.files, imageData] }));
        setImgSrc("");

    };
    useEffect(() => {
        getActiveStorageLocation();
        getLostandFoundCategory();
    }, [])
    useEffect(() => {
        if (imgSrc) {
            ConvertAndAddToImageList(imgSrc);
        }
    }, [imgSrc])


    return (
        <div className="EditFoundItem ">
            <div className="header" style={{ backgroundColor: '#ae9d81', color: 'white' }}>
                <i className="pi pi-arrow-left" style={{ cursor: "pointer" }} onClick={() => { if (route == "g2") { navigate('/lostandfound/2') } if (route == "s1") { navigate('/security-staff/1') } }}></i>
                <div style={{ margin: 'auto', fontWeight: '600', fontSize: '18px' }}>Add Found Item</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0px 50px' }}>
                <div>
                    <p>First Name<span style={{ color: 'red' }}>*</span></p>
                    <div>
                        <InputText placeholder='Enter First Name' style={{ width: '350px' }} value={foundItem?.firstName} onChange={e => setFoundItem({ ...foundItem, firstName: e.target.value })} />
                    </div>
                    {invalid && foundItem.firstName == "" &&
                        <p style={{ color: 'red' }}>First name is required</p>
                    }
                </div>
                <div>
                    <p>Last Name<span style={{ color: 'red' }}>*</span></p>
                    <div>
                        <InputText placeholder='Enter Last Name' style={{ width: '350px' }} value={foundItem?.lastName} onChange={e => setFoundItem({ ...foundItem, lastName: e.target.value })} />
                    </div>
                    {invalid && foundItem.lastName == "" &&
                        <p style={{ color: 'red' }}>Last name is required</p>
                    }
                </div>
                <div>
                    <p>Email</p>
                    <div>
                        <InputText placeholder='Enter Email' style={{ width: '350px' }} value={foundItem?.email} onChange={e => setFoundItem({ ...foundItem, email: e.target.value })} />
                    </div>
                    {/* {invalid && foundItem.email == "" &&
                        <p style={{ color: 'red' }}>Email is required</p>
                    } */}
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 50px 0px 50px' }}>
                <div style={{ width: "350px", borderRadius: "6px" }} >
                    <p>Mobile Number<span style={{ color: 'red' }}>*</span></p>
                    <div className='p-inputtext p-0'>
                        <Dropdown value={selectedCountry} style={{ width: "100px !important", border: "none" }} onChange={(e) => { console.log(e.value); setSelectedCountry(e.value); handleCountryChange(e.value) }} options={nationality} optionLabel="english_name"
                            valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate}
                        />
                        <InputText style={{ width: '50%', border: "none" }} value={foundItem?.phoneNumber} type='tel' min={0} placeholder='Enter here' onChange={(e) => { handleChange(e.target.value) }}></InputText>


                    </div>
                    {invalid && foundItem.phoneNumber == "" &&
                        <p style={{ color: 'red' }}>Mobile number is required</p>
                    }
                </div>
                <div>
                    <p>Category<span style={{ color: 'red' }}>*</span></p>
                    <div>
                        <Dropdown style={{ width: '350px', border: "solid 1px #ae9d81" }} value={foundItem?.categoryId} options={categoryList} placeholder='Select Category' onChange={e => setFoundItem({ ...foundItem, categoryId: e.value })} optionLabel='name' optionValue='id' />
                    </div>
                    {invalid && foundItem.categoryId == "" &&
                        <p style={{ color: 'red' }}>Category is required</p>
                    }
                </div>
                <div>
                    <p>What did you Found?<span style={{ color: 'red' }}>*</span></p>
                    <div>
                        <InputText placeholder='Enter what did you found' style={{ width: '350px' }} value={foundItem?.whatDidYouFind} onChange={e => setFoundItem({ ...foundItem, whatDidYouFind: e.target.value })} />
                    </div>
                    {invalid && foundItem.whatDidYouFind == "" &&
                        <p style={{ color: 'red' }}>What did you found is required</p>
                    }
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 50px 0px 50px' }}>
                <div>
                    <p> When did you Found?(Date and Time)<span style={{ color: 'red' }}>*</span></p>
                    <div>
                        <Calendar placeholder='Select date' className="calendar" style={{ width: '350px', }} showIcon value={foundItem?.foundDateTime} onChange={e => setFoundItem({ ...foundItem, foundDateTime: e.target.value })} maxDate={new Date()} showTime hourFormat="24" />
                    </div>
                    {invalid && foundItem.foundDateTime == "" &&
                        <p style={{ color: 'red' }}>When did you found is required</p>
                    }
                </div>
                <div>
                    <p>Where did you Found?<span style={{ color: 'red' }}>*</span></p>
                    <div>
                        <InputText placeholder='Enter where did you found' style={{ width: '350px', }} value={foundItem?.whereDidYouFind} onChange={e => setFoundItem({ ...foundItem, whereDidYouFind: e.target.value })} />
                    </div>
                    {invalid && foundItem.whereDidYouFind == "" &&
                        <p style={{ color: 'red' }}>Where did you found is required</p>
                    }
                </div>
                <div >
                    <p> Storage Location<span style={{ color: 'red' }}>*</span></p>
                    <div>
                        <Dropdown options={storageLocation} placeholder='Select Storage Location' className="calendar" style={{ width: '350px', borderColor: '#ae9d81' }}
                            optionLabel='name' optionValue='id' value={foundItem?.storageLocationId} onChange={(e) => { setFoundItem({ ...foundItem, storageLocationId: e.value }) }} />
                    </div>
                    {invalid && foundItem.storageLocationId == "" &&
                        <p style={{ color: 'red' }}>Storage location is required</p>
                    }
                </div>

            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 50px 0px 50px' }}>
                <div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                        <div>
                            <p>Attachment<span style={{ color: 'red' }}>*</span>
                            </p>
                        </div>
                        {imgSrc == "" &&
                            <>
                                <FileUpload mode='basic' accept="image/*" className='fileUpload' maxFileSize={1000000} customUpload uploadHandler={onUpload} ref={fileInputRef}
                                    headerTemplate={<i className='pi pi-plus' style={{ color: "#ae9d81", margin: '0px 5px', width: "1.5rem", height: "1.4rem" }} ></i>} />
                                <i className='pi pi-camera' style={{ color: "#ae9d81", margin: '0px 5px' }} onClick={() => { setIsVisible(true); }}></i>
                            </>
                        }
                    </div>
                    <div className='defaultWidth'>
                        <div style={{
                            height: "auto",
                            overflow: "auto",
                            width: '350px',
                            border: "solid 1px rgba(201,194,181,1)",
                            borderRadius: '4px',
                            padding: "20px",
                            marginBottom: "20px",
                            height: "150px"
                        }}>

                            {
                                foundItem?.files?.map((image, index) => {
                                    return (
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <p style={{ fontWeight: 400, margin: "0px" }}>{index + 1}.  </p>
                                            <p style={{ fontWeight: 400, margin: "0px", textOverflow: "ellipsis", whiteSpace: "nowrap" }} > {image?.fileName}</p>
                                            <i className="pi pi-times" style={{ cursor: 'pointer', color: '#ae9d81', margin: 0, padding: 0, fontSize: '1rem', display: 'flex', justifyContent: "center", alignItems: 'center' }}
                                                onClick={() => {
                                                    setFoundItem((prev) => ({
                                                        ...prev,
                                                        files: prev?.files?.filter((item, i) => i !== index)
                                                    }))
                                                }} />
                                            {/* <CiImageOn style={{ fontSize: 20 }} />
                                            <p style={{ fontSize: '12px', padding: "0 10px", cursor: "pointer" }} onClick={() => {
                                                // downloadFile(_);
                                                previewImage(_);
                                            }}>{_.fileName}</p> */}
                                        </div>
                                    )
                                })
                            }

                            {isVisible && <CustomWebcam setIsVisible={setIsVisible} setImage={setImgSrc} />}
                            {imgSrc && <img src={imgSrc} style={{ width: '100px', height: '100px' }} ></img>}
                        </div>
                        {invalid && foundItem?.files?.length == 0 &&
                            <p style={{ color: 'red' }}>Attachment for found item is required</p>
                        }
                    </div>
                </div>
                <div style={{ width: "300px", height: "100px" }} >
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'end', gap: '20px', margin: "0px 50px 50px 0px" }}>
                <Button label='Cancel' className='cnl-btn' onClick={() => { if (route == "g2") { navigate('/lostandfound/2') } if (route == "s1") { navigate('/security-staff/1') } }} />
                {/* {foundItem?.statusId == 1 ? ( */}
                <Button label='Submit' className='smt-btn' loading={isFormSubmitting} onClick={() => { handleSubmit() }} />
                {/* ) : ""} */}

            </div>

        </div >
    )
}

export default AddFoundForm 
