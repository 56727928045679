import React, { useState } from "react";
import "./SupervisorInvoiceList.scss";
import UseCookies from "../../../../Helpers/UseCookies";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setNavVisibilty } from "../../../../Redux/AppSlice";
import { useNavigate } from "react-router-dom";

// Prime React Components
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { Dialog } from "primereact/dialog";

// ------------------------------

// ----------Assets Import------------
import editIcon from "../../../../assets/images/editIcon.jpg";
import approveIcon from "../../../../assets/images/approve.png";
import rejectIcon from "../../../../assets/images/reject.png";
import pendingIcon from "../../../../assets/images/Pending.png";
import reSubmitIcon from "../../../../assets/images/resubmit.png";
import { useEffect } from "react";
import {
  ApproveInvoice,
  GetVIPInvoices,
  RejectInvoice,
  ViewInvoiceDetail,
} from "../../../../Services/VIPLoungeSupervisor";
import { DownloadFile, GetAllImageFiles } from "../../../../Services/Files";
import { exportDataInExcel, exportExcel, exportFilterdExcel } from "../../../../Services/ExportServices";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
// -------------------------------------------------------------

function SupervisorInvoiceList() {
  const [cookies, setCookie, removeCookie] = UseCookies();
  var isSupervisor =
    JSON.parse(JSON.stringify(cookies()?.roles))?.filter(
      (role) => role === "VIP_SUPERVISOR"
    )?.length !== 0;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  // Pagination
  const [first, setFirst] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  // Action button
  const [isVisible, setIsVisible] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [view, setView] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  // Search && Filter
  const [searchFilterValue, setSearchFilterValue] = useState("");

  // Userlist
  const [InvoiceList, setInvoicesList] = useState([]);

  // Invoice Detail
  const [invoiceDetail, setInvoiceDetail] = useState({});
  const [invoiceDetailLoading, setInvoiceDetailLoading] = useState(false);
  // Invoice approval loader
  const [approveLoading, setApproveLoading] = useState(false);
  // Invoice reject loader
  const [rejectLoading, setRejectLoading] = useState(false);

  // Invoice Reject Dialog
  const [isRejectVisible, setIsRejectVisible] = useState(false);
  const [comments, setComments] = useState("");
  const [rejectError, setRejectError] = useState(false);
  const navigate = useNavigate();

  const [exportObj, setExportObj] = useState({
    fromDate: "",
    toDate: "",
    searchTerm: "",
    organization: "",
    isModalVisible: false
  });


  // Pagination functionality
  const onPageChange = (event) => {
    setFirst(event.first);
    getInvoicesList(event.first, 10, searchFilterValue, exportObj?.fromDate, exportObj?.toDate);
  };
  // Action buttons for view and edit
  const actionButtonTemplate = (row) => {
    return (
      <>
        <div style={{ display: "flex", gap: "15px", justifyContent: "center" }}>
          {isSupervisor && (
            <div
              onClick={(e) => {
                // viewInvoiceDetails(row?.id);
                navigate(`/invoice-details/${row?.id}/true`);
              }}
            >
              <i
                className="pi pi-eye"
                style={{ fontSize: "1rem", cursor: "pointer" }}
              />
            </div>
          )}
          {!isSupervisor && (
            <div
              onClick={(e) => {
                setIsVisible(true);
                viewInvoiceDetails(row?.id);
              }}
            >
              <img src={editIcon} style={{ cursor: "pointer" }} />
            </div>
          )}
        </div>
      </>
    );
  };
  const statusTemplate = (row) => {
    let status = row?.status;
    let imageIcon = "";
    if (status == "Submitted") {
      imageIcon = pendingIcon;
    } else if (status == "Rejected") {
      imageIcon = rejectIcon;
    } else if (status == "Approved") {
      imageIcon = approveIcon;
    }
    else if (status == "ReSubmitted") {
      imageIcon = pendingIcon;
      ;
    }

    return (
      <>
        {imageIcon && (
          <div style={{ width: "30px", height: "30px" }}>
            <img style={{ width: "100%", height: "100%" }} src={imageIcon} />
          </div>
        )}
      </>
    );
  };
  // Convert the status from server to the format for UI
  const statusBodyTemplate = (row) => {
    // //console.log(row);
    if (row.invoiceStatus === "Approved") {
      return <p style={{ color: "#45AA08" }}>Approved</p>;
    } else if (row?.invoiceStatus === "Submitted") {
      return <p style={{ color: "rgb(86 69 231)" }}>Submitted</p>;
    } else if (row?.invoiceStatus === "Rejected") {
      return <p style={{ color: "#FF0000" }}>Rejected</p>;
    }
    //  if (row.invoiceStatus === 3) {
    //     return <p style={{ color: "#45AA08" }}  >Approved</p>
    // }
    // else if (row?.invoiceStatus === 1) {
    //     return <p style={{ color: "rgb(86 69 231)" }} >InProgress</p>
    // }
    // else if (row?.invoiceStatus === 5) {
    //     return <p style={{ color: "#FF0000" }} >Rejected</p>
    // }
    // else if (row?.invoiceStatus === 2) {
    //     return <p style={{ color: "#FF0000" }} >Pending</p>
    // }
    // else if (row?.invoiceStatus === 4) {
    //     return <p style={{ color: "#FF0000" }} >Cancel</p>
    //
  };

  // Status color finctionality
  const statusColor = (invoiceStatus) => {
    if (invoiceStatus === "Approved") {
      return <p style={{ color: "#45AA08" }}>Approved</p>;
    } else if (invoiceStatus === "InProgress") {
      return <p style={{ color: "rgb(86 69 231)" }}>Submitted</p>;
    } else if (invoiceStatus === "Rejected") {
      return <p style={{ color: "#FF0000" }}>Rejected</p>;
    } else if (invoiceStatus === "Pending") {
      return <p style={{ color: "#FF0000" }}>Pending</p>;
    } else if (invoiceStatus === "Cancel") {
      return <p style={{ color: "#FF0000" }}>Cancel</p>;
    }
  };
  // Convert the date from server to the format date for UI
  const dateBodytemplate = (row) => {
    const dateTime = new Date(row?.createdDate);
    const year = dateTime.getFullYear();
    const month = dateTime.getMonth() + 1;
    const day = dateTime.getDate();
    return (
      <div>
        <p style={{ fontSize: "1rem", fontWeight: 400 }}>
          {day + "/" + month + "/" + year}
        </p>
      </div>
    );
  };
  const header = () => {
    return (
      <div className="container" style={{ textAlign: "center" }}>
        <h2>
          {" "}
          {view ? "View" : ""}
          {isEdit ? "Edit" : ""} Invoice Details
        </h2>
      </div>
    );
  };

  // Seacrch Invoices
  const onHandleSearchValueChange = (searchTerm) => {
    //console.log(searchFilterValue);
    getInvoicesList(0, 10, searchTerm, exportObj?.fromDate, exportObj?.toDate);
  };

  // Get Invoices List
  const getInvoicesList = async (
    skip = 0,
    take = 10,
    searchFilterValue = "",
    startDate = "",
    endDate = ""
  ) => {
    setIsLoading(true);
    const fromDate = !startDate ? "" : `${new Date(startDate)?.getFullYear()}-${(new Date(startDate)?.getMonth() + 1)?.toString()?.padStart(2, "0")}-${new Date(startDate)?.getDate()?.toString()?.padStart(2, "0")}`;
    const toDate = !endDate ? "" : `${new Date(endDate)?.getFullYear()}-${(new Date(endDate)?.getMonth() + 1)?.toString()?.padStart(2, "0")}-${new Date(endDate)?.getDate()?.toString()?.padStart(2, "0")}`;
    await GetVIPInvoices(skip, take, searchFilterValue, cookies().bearerToken, "false", fromDate, toDate)
      .then((res) => {
        console.log("Response", res?.data?.data);
        if (res?.status === 200) {
          setTotalCount(res?.data?.totalRecords);
          setInvoicesList(res?.data?.data);
        }
      })
      .catch((error) => {
        toast.error(
          "There is issue occured in fetching user, could you please try again",
          { position: "top-right" }
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // View Invoice Details
  const viewInvoiceDetails = async (invoiceId) => {
    setIsVisible(true);
    setInvoiceDetailLoading(true);
    await ViewInvoiceDetail(invoiceId, cookies().bearerToken)
      .then((res) => {
        //console.log("Response", res);
        if (res?.status === 200) {
          setInvoiceDetail(res?.data);
          if (res?.data?.fileDetails?.length != 0) {
            getImage(res?.data?.id);
          } else {
            setInvoiceDetailLoading(false);
          }
        }
      })
      .catch((error) => {
        toast.error(
          "There is issue occured in fetching user, could you please try again",
          { position: "top-right" }
        );
      })
      .finally(() => { });
  };
  // Approve Invoice
  const approveInvoice = async (invoiceId) => {
    setApproveLoading(true);
    await ApproveInvoice(invoiceId, cookies().bearerToken)
      .then((res) => {
        //console.log("Response", res);
        if (res?.status === 200) {
          toast.success("Invoice approved successfully", {
            position: "top-right",
          });
          getInvoicesList(0, 10, searchFilterValue, exportObj?.fromDate, exportObj?.toDate);
          setIsVisible(false);
          setComments("");
          setRejectError(false);
        }
      })
      .catch((error) => {
        toast.error(
          "There is issue occured in approving invoice, could you please try again",
          { position: "top-right" }
        );
      })
      .finally(() => {
        setApproveLoading(false);
      });
  };
  // Reject Invoice
  const rejectInvoice = async (invoiceId) => {
    if (comments === "") {
      toast.error("Please enter comments", { position: "top-right" });
      setRejectError(true);
      return;
    }
    setRejectLoading(true);
    setRejectError(false);
    await RejectInvoice(invoiceId, comments, cookies().bearerToken)
      .then((res) => {
        //console.log("Response", res);
        if (res?.status === 200) {
          toast.success("Invoice rejected successfully", {
            position: "top-right",
          });
          getInvoicesList(0, 10, searchFilterValue, exportObj?.fromDate, exportObj?.toDate);
          setIsRejectVisible(false);
          setComments("");
          setRejectError(false);
          setIsVisible(false);
        }
      })
      .catch((error) => {
        toast.error(
          "There is issue occured in rejecting invoice, could you please try again",
          { position: "top-right" }
        );
      })
      .finally(() => {
        setRejectLoading(false);
      });
  };
  const [isFilemodelView, setISFileModelView] = useState(false);
  const [files, setFileList] = useState([]);
  const [isFilePreview, setisFilePreview] = useState(false);
  const [isFilePreviewImage, setisFilePreviewImage] = useState(false);
  const getImage = async (id) => {
    let data = {
      id: id,
      module: "VIPInvoice",
      isForInspection: "",
      inspectionId: "",
    };
    await GetAllImageFiles(data, cookies().bearerToken)
      .then((res) => {
        if (res.status === 200) {
          console.log(res?.data);
          setFileList(res?.data);
          // setISFileModelView(true);
          //console.log(res?.data?.data);
        }
      })
      .catch((err) => {
        //console.log(err);
      })
      .finally(() => {
        setInvoiceDetailLoading(false);
      });
  };


  // const exportLoungeSupervisorInvoices = () => {
  // exportDataInExcel(`GetInvoicesForSupervisor`, cookies().bearerToken);
  // }
  const onExportSupervisorInvoice = () => {
    const fromDate = !exportObj?.fromDate ? "" : `${new Date(exportObj?.fromDate)?.getFullYear()}-${(new Date(exportObj?.fromDate)?.getMonth() + 1)?.toString()?.padStart(2, "0")}-${new Date(exportObj?.fromDate)?.getDate()?.toString()?.padStart(2, "0")}`;
    const toDate = !exportObj?.toDate ? "" : `${new Date(exportObj?.toDate)?.getFullYear()}-${(new Date(exportObj?.toDate)?.getMonth() + 1)?.toString()?.padStart(2, "0")}-${new Date(exportObj?.toDate)?.getDate()?.toString()?.padStart(2, "0")}`;
    let urlRoute = `User/GetVIPInvoices?from=${fromDate}&to=${toDate}&forExport=true&skip=${0}&take=${totalCount}`
    exportFilterdExcel(urlRoute, cookies().bearerToken);
  }
  useEffect(() => {
    getInvoicesList();
  }, []);
  return (
    <>
      <div className="SupervisorInvoiceList">
        <Dialog
          header={"Attachments"}
          visible={isFilemodelView}
          // modal={false}
          style={{ width: "45vw" }}
          onHide={() => setISFileModelView(false)}
        >
          <>
            {files.length > 0 && (
              <div className="file-list">
                {files.map((file, index) => {
                  return (
                    <div className="file-item" key={index}>
                      <div
                        className="file-name"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setisFilePreview(true);
                          setisFilePreviewImage(file?.base64);
                        }}
                      >
                        {file?.fileModule + file?.fileName}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {/* <img src={"data:image/jpeg;base64," + files[0]?.base64} alt="" srcset="" /> */}
          </>
        </Dialog>
        {/* Image Container */}
        <Dialog
          // header={<div><p style={{ textAlign: "center" }}>Image Preview</p></div>}
          visible={isFilePreview}
          style={{
            width: "50vw",
            height: "90vh",
            display: "flex",
            justifyContent: "center",
          }}
          onHide={() => setisFilePreview(false)}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                display: "flex",
                width: "500px",
                height: "500px",
                justifyContent: "center",
              }}
            >
              <img
                style={{ margin: "auto", width: "100%", height: "100%" }}
                src={"data:image/jpeg;base64," + isFilePreviewImage}
                alt=""
                srcset=""
              />
            </div>
          </div>
        </Dialog>

        <Dialog
          header={header}
          visible={isVisible}
          style={{ width: "45vw" }}
          onHide={() => {
            setIsVisible(false);
          }}
        >
          {invoiceDetailLoading ? (
            <div
              className="spinner spinner-center"
              style={{ margin: "auto  ", display: "flex" }}
            ></div>
          ) : (
            <>
              <div
                className="container"
                style={{ textAlign: "left", display: "flex", gap: "10px" }}
              >
                <div className="input-box">
                  <p style={{ fontWeight: 400 }}>
                    Invoice No<span style={{ color: "red" }}>*</span>
                  </p>
                  <InputText
                    placeholder="Invoice No"
                    disabled={isSupervisor}
                    value={invoiceDetail?.invoiceNumber}
                    onChange={(e) => {
                      "";
                    }}
                  />
                </div>
                <div className="input-box">
                  <p style={{ fontWeight: 400 }}>
                    Date<span style={{ color: "red" }}>*</span>
                  </p>
                  <InputText
                    placeholder="Invoice Date"
                    disabled={isSupervisor}
                    value={
                      new Date(invoiceDetail?.dateOfPurchase).getDate() +
                      "/" +
                      new Date(invoiceDetail?.dateOfPurchase).getMonth() +
                      "/" +
                      new Date(invoiceDetail?.dateOfPurchase).getFullYear()
                    }
                  />
                </div>
                <div className="input-box">
                  <p style={{ fontWeight: 400 }}>
                    Price<span style={{ color: "red" }}>*</span>
                  </p>
                  <InputText
                    placeholder="Invoice Price"
                    disabled={isSupervisor}
                    value={invoiceDetail?.purchaseAmount}
                    onChange={(e) => {
                      "";
                    }}
                  />
                </div>
              </div>
              <div
                className="container"
                style={{ display: "flex", gap: "10px" }}
              >
                <div>
                  <p style={{ fontWeight: 400 }}>
                    Attachments<span style={{ color: "red" }}>*</span>{" "}
                    {isSupervisor ? (
                      ""
                    ) : (
                      <i
                        className="pi pi-plus"
                        style={{
                          marginLeft: "90px",
                          color: "rgb(174, 157, 129)",
                          cursor: "pointer",
                          fontSize: "20px",
                          fontWeight: "500",
                        }}
                      ></i>
                    )}{" "}
                  </p>
                  <div
                    style={{
                      border: isSupervisor
                        ? "1px solid #cbc5bb"
                        : "1px solid #ae9d81",
                      width: "206px",
                      height: "75px",
                      borderRadius: "5px",
                      color: isSupervisor ? "#9399a1" : "",
                      overflow: "scroll",
                      overflowX: "hidden",
                    }}
                  >
                    {files?.map((item, index) => {
                      return (
                        <p
                          style={{
                            textAlign: "left",
                            margin: "5px",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            width: "max-content",
                          }}
                          onClick={() => {
                            setisFilePreview(true);
                            setisFilePreviewImage(item?.base64);
                          }}
                        >
                          {index + 1}.{item?.fileName}
                          {item?.id}
                        </p>
                      );
                    })}
                  </div>
                </div>
                {invoiceDetail?.invoiceStatus !== "Approved" && (
                  <div>
                    <p style={{ fontWeight: 400 }}>
                      Comments<span style={{ color: "red" }}>*</span>{" "}
                      {isSupervisor ? (
                        ""
                      ) : (
                        <i
                          className="pi pi-plus"
                          style={{
                            marginLeft: "90px",
                            color: "rgb(174, 157, 129)",
                            cursor: "pointer",
                            fontSize: "20px",
                            fontWeight: "500",
                          }}
                        ></i>
                      )}{" "}
                    </p>
                    <div
                      style={{
                        border: isSupervisor
                          ? "1px solid #cbc5bb"
                          : "1px solid #ae9d81",
                        width: "206px",
                        height: "75px",
                        borderRadius: "5px",
                        color: isSupervisor ? "#9399a1" : "",
                      }}
                    >
                      <p
                        style={{
                          textAlign: "left",
                          margin: "10px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {invoiceDetail?.commentsForRejection}
                      </p>
                    </div>
                  </div>
                )}
                <div className="input-box">
                  <p style={{ fontWeight: 400 }}>
                    Status<span style={{ color: "red" }}>*</span>
                  </p>
                  <div>
                    <div style={{}}>
                      {statusColor(invoiceDetail?.invoiceStatus)}
                      {/* <p style={{ textAlign: "left", margin: "10px", justifyContent: "center", alignItems: "center",color }}>{invoiceDetail?.invoiceStatus}</p> */}
                    </div>
                  </div>
                </div>
              </div>

              {/* Button to approve and decline in supervisor login */}
              <div className="btn-container">
                {!(
                  invoiceDetail?.invoiceStatus === "Approved" ||
                  invoiceDetail?.invoiceStatus == "Rejected"
                ) && (
                    <>
                      <Button
                        label="Decline"
                        className="p-button"
                        disabled={approveLoading}
                        style={{
                          background: "rgb(252 0 0)",
                          border: "none",
                          width: "100px",
                        }}
                        onClick={() => {
                          setIsRejectVisible(true);
                        }}
                      />
                    </>
                  )}
                <Button
                  label="Cancel"
                  className="p-button"
                  disabled={approveLoading}
                  style={{
                    background: "rgb(142, 142, 142)",
                    border: "none",
                    width: "100px",
                  }}
                  onClick={() => {
                    setIsVisible(false);
                  }}
                />
                {!(
                  invoiceDetail?.invoiceStatus === "Approved" ||
                  invoiceDetail?.invoiceStatus == "Rejected"
                ) && (
                    <>
                      <Button
                        label={approveLoading ? "" : "Approve"}
                        className="p-button"
                        loading={approveLoading}
                        style={{
                          background: "rgb(0 252 75)",
                          border: "none",
                          width: "100px",
                        }}
                        onClick={() => {
                          approveInvoice(invoiceDetail?.id);
                        }}
                      />
                    </>
                  )}
              </div>
            </>
          )}
        </Dialog>

        {/* Reject dialog box with comments */}
        <Dialog
          header={<p>Are sure you want to reject this invoice?</p>}
          visible={isRejectVisible}
          style={{ width: "30vw" }}
          onHide={() => {
            setIsRejectVisible(false);
          }}
        >
          <div
            className="container"
            style={{ textAlign: "left", display: "flex", gap: "10px" }}
          >
            <div className="input-box">
              <p style={{ fontWeight: 400 }}>
                Comments<span style={{ color: "red" }}>*</span>
              </p>
              <InputText
                placeholder="Comments"
                value={comments}
                onChange={(e) => {
                  setComments(e.target.value);
                }}
              />
              {rejectError && comments == "" && (
                <p style={{ color: "red" }}>Comments is required</p>
              )}
            </div>
          </div>
          <div className="btn-container">
            <Button
              label="Cancel"
              disabled={rejectLoading}
              className="p-button"
              style={{
                background: "rgb(142, 142, 142)",
                border: "none",
                width: "100px",
              }}
              onClick={() => {
                setIsRejectVisible(false);
              }}
            />
            <Button
              label={rejectLoading ? "" : "Submit"}
              loading={rejectLoading}
              className="p-button"
              style={{
                background: "rgb(174, 157, 129)",
                border: "none",
                width: "100px",
              }}
              onClick={() => {
                rejectInvoice(invoiceDetail?.id);
              }}
            />
          </div>
        </Dialog>
        <div className="header">
          <div style={{ display: "flex" }}>
            <div className="menubtn" style={{ padding: "20px" }}>
              <i
                className="pi pi-align-justify"
                style={{
                  fontSize: "1rem",
                  color: "#ae9d81",
                  cursor: "pointer",
                }}
                onClick={() =>
                  dispatch(setNavVisibilty({ sideNavVisibility: true }))
                }
              ></i>
            </div>
            <div className="heading">
              <p>Invoices Details</p>
            </div>
          </div>
          <div className="right" style={{}}>

            <div className="mx-2">
              <h4 className='mb-1 mt-0 relative' style={{ color: "#ae9d81" }}>From Date</h4>
              <Calendar className="customeCalender" style={{ width: "180px" }} value={exportObj?.fromDate} placeholder='Select from date' onChange={(e) => {
                setExportObj((prev) => ({ ...prev, fromDate: e.value }));
                getInvoicesList(0, 10, searchFilterValue, e.value, exportObj?.toDate);
              }} />
            </div>
            <div className="mx-2">
              <h4 className='mb-1 mt-0 relative' style={{ color: "#ae9d81" }}>To Date</h4>
              <Calendar className="customeCalender" style={{ width: "180px" }} value={exportObj?.toDate} placeholder='Select to date' onChange={(e) => {
                setExportObj((prev) => ({ ...prev, toDate: e.value }));
                getInvoicesList(0, 10, searchFilterValue, exportObj?.fromDate, e.value);
              }} />
            </div>
            <div>
              <h4 className='mb-1 mt-0 relative' style={{ color: "#ae9d81" }}>Search</h4>
              <div className='flex align-items-center' style={{ border: "solid 1px #AE9D81", borderRadius: "4px" }}>
                <i className="pi pi-search px-2" style={{ color: 'black', fontWeight: '700', fontSize: '1rem' }} />
                <InputText value={searchFilterValue}
                  style={{ width: "170px" }}
                  onChange={(e) => {
                    setSearchFilterValue(e.target.value.trimStart());
                    onHandleSearchValueChange(e.target.value.trimStart());
                  }}
                  placeholder="Search" className='border-0 customeSearch' />
                {searchFilterValue !== "" && (
                  <i className="pi pi-times px-2" style={{ color: 'black', fontWeight: '400', fontSize: '1rem', cursor: 'pointer', position: "block" }} onClick={() => {
                    setSearchFilterValue("");
                    onHandleSearchValueChange("");
                  }} />
                )}
              </div>
              {/* <span className="p-input-icon-left">
                <i
                  className="pi pi-search"
                  style={{
                    color: "black",
                    fontWeight: "700",
                    fontSize: "1rem",
                  }}
                />
                <InputText
                  placeholder="Search"
                  value={searchFilterValue}
                  onChange={(e) => {
                    setSearchFilterValue(e.target.value.trimStart());
                    onHandleSearchValueChange(e.target.value.trimStart());
                  }}
                />
                {searchFilterValue !== "" && (
                  <i
                    className="pi pi-times"
                    style={{
                      color: "black",
                      fontWeight: "400",
                      fontSize: "1rem",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setSearchFilterValue("");
                      onHandleSearchValueChange("");
                    }}
                  />
                )}
              </span> */}
            </div>
            {isSupervisor ? (
              ""
            ) : (
              <div className="mx-2 align-self-end">
                <Button
                  label="Add New"
                  onClick={() => {
                    setIsVisible(true);
                  }}
                  style={{ backgroundColor: "#ae9d81", border: "none" }}
                />
              </div>
            )}

            <div className="mx-2 align-self-end">
              <Button style={{ backgroundColor: "#ae9d81", border: 0 }} onClick={() => {
                setExportObj(prev => ({ ...prev, fromDate: "", toDate: "", }));
                getInvoicesList();
              }}>
                Clear All
              </Button>
            </div>
            <div className="mx-2 align-self-end">
              <Button
                label="Export"
                icon={"pi pi-file-export"}
                style={{
                  backgroundColor: "transparent",
                  color: "#ae9d81",
                  border: "1px #ae9d81 solid",
                  borderRadius: "20px",
                  cursor: "pointer",
                  height: "46px"
                }}
                onClick={() => { onExportSupervisorInvoice() }}
              />
            </div>

          </div>
        </div>

        <div className="datatable">
          {isLoading ? (
            <div
              className="spinner spinner-center"
              style={{ display: "flex", margin: "auto", marginTop: "200px" }}
            ></div>
          ) : (
            <DataTable
              value={InvoiceList}
              style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
              tableStyle={{ width: "90rem", margin: "10px 39px" }}
              stripedRows
              rows={10}
              emptyMessage={
                <>
                  <p style={{ textAlign: "center" }}>List is empty</p>
                </>
              }
            >
              <Column field="vipCode" header="Vip code"></Column>
              <Column field="referenceCode" header="Reference Number"></Column>
              <Column field="createdDate" header="Uploaded Date"></Column>
              <Column field="createdBy" header="Created By"></Column>
              {/* body={dateBodytemplate} */}
              <Column
                field="overAllInvoiceAmount"
                header="Total Amount"
              ></Column>
              <Column
                field="uploadedInvoicesCount"
                header="Invoice Count"
              ></Column>
              <Column
                field="Status"
                header="Status"
                body={statusTemplate}
              ></Column>
              <Column
                field=""
                header="Action"
                style={{ width: "5%" }}
                body={actionButtonTemplate}
              ></Column>
            </DataTable>
          )}
        </div>

        {isLoading ? (
          ""
        ) : (
          <div style={{ justifyContent: "center", display: "flex" }}>
            <Paginator
              first={first}
              rows={10}
              totalRecords={totalCount}
              onPageChange={(e) => onPageChange(e, 1)}
              template={{
                layout:
                  "RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport",
              }}
              className="justify-content-start"
            />
          </div>
        )}
        <Dialog visible={exportObj?.isModalVisible} header={`Export Invoice Details`} headerStyle={{ color: "#ae9d81", textAlign: "center" }} onHide={() => { setExportObj((prev) => ({ ...prev, isModalVisible: false })) }}>
          <div className='flex align-items-center flex-wrap'>

            <div className='mx-2'>
              <h4 className='mb-2'>From Date</h4>
              <Calendar value={exportObj?.fromDate} placeholder='Select from date' onChange={(e) => {
                setExportObj((prev) => ({ ...prev, fromDate: e.value }));
              }} />
            </div>
            <div className='mx-2'>
              <h4 className='mb-2'>To Date</h4>
              <Calendar value={exportObj?.toDate} placeholder='Select to date' onChange={(e) => {
                setExportObj((prev) => ({ ...prev, toDate: e.value }));
              }} />
            </div>
          </div>
          <div>
          </div>

          <div className='flex justify-content-center my-4'>
            <Button className='border-0'
              style={{ backgroundColor: "#ae9d81", width: 180, height: 40, display: "flex", justifyContent: "center" }}
              onClick={() => { onExportSupervisorInvoice() }}>
              Submit
            </Button>
          </div>
        </Dialog>

      </div>
    </>
  );
}

export default SupervisorInvoiceList;
