import React, { useState, useEffect } from "react";
// import "./style.scss";

// -------------Prime Recat Imports---------------
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { MdQrCodeScanner } from "react-icons/md";
import { Tag } from "primereact/tag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faL } from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Paginator } from "primereact/paginator";

// -----------------------------------

// ----------Assets Import------------
import editIcon from "../../../src/assets/images/editIcon.jpg";
// -----------------------------------

//------Imports from other Components-----
import {
  AddOrganization,
  GetOrganizationList,
  EditOrganization,
  DeleteOrganization,
  GetOrganizationQR,
  exportOrganization,
} from "../../Services/OrganizationService";
import { GetAllImageFiles, DownloadBase64File } from "../../Services/Files";
//----------------------------------------

//-----------Context ---------------
import { useDispatch, useSelector } from "react-redux";
import { addOrganizationList, setNavVisibilty } from "../../Redux/AppSlice";
import { exportDataInExcel } from "../../Services/ExportServices";
import UseCookies from "../../Helpers/UseCookies";
//----------------------------------------

function QRGeneration() {
  const Context = useSelector((state) => state.AppContext);
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = UseCookies();
  const [isAddOrganization, setIsAddOrganization] = useState(false);
  const [organizationList, setOrganizationList] = useState("");
  const [organization, setOrganization] = useState({
    orgName: "",
    orgStatus: true,
    orgId: 0,
  });
  const [orgNameExists, setOrgNameExists] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const navigate = useNavigate();
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isOrgInvalid, setIsOrgInvalid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // Pagination
  const [isQRCode, setIsQRCode] = useState({
    isOpen: false,
    QRUrl: "",
    organizationName: "",
  });
  const [totalCount, setTotalRecords] = useState(0);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // userName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    orgStatus: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const onCancelClick = () => {
    setShowConfirmation(true);
  };

  const onHide = () => {
    setShowConfirmation(false);
    setIsAddOrganization(false);
    setOrganization({
      orgName: "",
      orgStatus: true,
      orgId: 0,
    });
  };

  const handleConfirmation = (confirmed) => {
    if (confirmed) {
      onHide();
      setOrgNameExists(false);
      setIsOrgInvalid(false);
      setIsAddOrganization(false);
      setOrganization({
        orgName: "",
        orgStatus: true,
        orgId: 0,
      });
    } else {
      setShowConfirmation(false);
    }
  };

  const header = () => {
    return (
      <div
        className="heading"
        style={{
          fontWeight: "700",
          fontSize: "23px",
          color: "#ae9d81",
          textAlign: "center",
        }}
      >
        <p style={{ margin: "0px" }}>
          {isAdd ? "Add" : "Edit"} Organization Details
        </p>
      </div>
    );
  };
  const popup = () => {
    return (
      <>
        <Dialog
          header={header}
          visible={isAddOrganization}
          style={{ width: "30vw" }}
          closable={false}
          onHide={() => {
            setIsAddOrganization(false);
            setOrganization({
              orgName: "",
              orgStatus: true,
              orgId: 0,
            });
          }}
        >
          <div className="popup ">
            {/* <div className="heading">
                            <p>Add New Details</p>
                        </div> */}
            <div className="body">
              <div className="label grid">
                <div className="col">
                  Organization Name<span style={{ color: "red" }}>*</span>
                </div>
                <div
                  className="col"
                  style={{ position: "absolute", left: "240px" }}
                >
                  Status
                </div>
              </div>
              <div className="input grid ">
                <div className="col">
                  <InputText
                    placeholder="Enter Name"
                    value={organization?.orgName}
                    onChange={(e) => {
                      setOrgNameExists(false);
                      setOrganization((prev) => ({
                        ...prev,
                        orgName: e.target.value,
                      }));
                    }}
                  />
                  {
                    isOrgInvalid && organization?.orgName == "" && (
                      // <div>
                      <p style={{ color: "red" }}>
                        Organization name is required
                      </p>
                    )
                    // </div>
                  }
                  {
                    orgNameExists && (
                      // <div>
                      <p style={{ color: "red" }}>
                        Organization name is already exists
                      </p>
                    )
                    // </div>
                  }
                </div>

                <div className="col ">
                  <label className="switch ">
                    <input
                      type="checkbox"
                      checked={organization?.orgStatus}
                      onChange={(e) => {
                        setOrganization((prev) => ({
                          ...prev,
                          orgStatus: e.target.checked,
                        }));
                      }}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="footer flex  grid">
              <div className="col flex justify-content-center ">
                <Button
                  label="Cancel"
                  onClick={() => {
                    setOrgNameExists(false);
                    setIsAddOrganization(false);
                    setOrganization({
                      orgName: "",
                      orgStatus: true,
                      orgId: 0,
                    });
                  }}
                  style={{
                    backgroundColor: "#8E8E8E",
                    border: "none",
                    margin: "10px",
                    height: "40px",
                  }}
                />
                <Button
                  label={isAdd ? "Submit" : "Update"}
                  onClick={() => {
                    isAdd ? addOrganization() : editOrganization();
                  }}
                  style={{
                    backgroundColor: "#ae9d81",
                    border: "none",
                    margin: "10px",
                    height: "40px",
                  }}
                />
              </div>
            </div>
          </div>
        </Dialog>
      </>
    );
  };
  const getOrganizationList = async () => {
    setIsLoading(true);
    await GetOrganizationList(cookies().bearerToken)
      .then((res) => {
        //console.log("Res", res);
        //console.log("status", res.status);
        //console.log("status", res?.data);
        if (res?.status === 200) {
          //console.log("Fetching Organization List", res?.data);
          setOrganizationList(
            res?.data?.data.map((_) => {
              return {
                id: _?.id,
                name: _?.name,
                code: _?.code,
                createdOn: _?.createdOn,
                userName: _?.userName,
                status: _?.status,
                orgStatus: _?.status ? "Inactive" : "Active",
              };
            })
          );
          setTotalRecords(res?.data?.count);
        }
      })
      .catch((error) => {
        //console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
        // //console.log(organizationList);
      });
  };

  const addOrganization = () => {
    if (organization?.orgName == "") {
      setIsOrgInvalid(true);
      return;
    }
    let data = {
      name: organization?.orgName,
      isDeleted: !organization?.orgStatus,
    };
    setIsAdd(true);
    let orgNameExist = organizationList.filter(
      (org) => org.name == organization?.orgName
    );
    if (orgNameExist.length > 0) {
      setOrgNameExists(true);
      return;
    }
    setOrgNameExists(false);
    let token = cookies()?.bearerToken;
    //console.log(organization);
    setIsAddOrganization(false);
    AddOrganization(data, token)
      .then((res) => {
        //console.log(res);
        if (res?.status === 200) {
          getOrganizationList();

          setOrganization({
            orgName: "",
            orgStatus: true,
            orgId: 0,
          });
          toast.success("New Organization is added successfully", {
            position: "top-right",
          });
        } else {
          setOrganization({
            orgName: "",
            orgStatus: true,
            orgId: 0,
          });
          // if (res?.response?.data === "Violation of UNIQUE KEY constraint 'Unique_Org_Name'. Cannot insert duplicate key in object 'dbo.QRGeneration'. The duplicate key value is (Marassi).") {
          //     toast.error("Organization name already exists", { position: "top-right" });
          // }
          // else {
          toast.error(res?.response?.data, { position: "top-right" });
          // }
        }
        setIsAdd(false);
        setIsAddOrganization(false);
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const editOrganization = () => {
    if (organization?.orgName == "") {
      setIsOrgInvalid(true);
      return;
    }
    let data = {
      name: organization?.orgName,
      organizationId: organization?.orgId,
      isDeleted: !organization?.orgStatus,
      id: organization?.orgId,
    };
    //console.log("Edit Org Data", data);
    let orgNameExist = organizationList.filter(
      (org) =>
        org?.name == organization?.orgName && org?.id != organization?.orgId
    );
    //console.log(orgNameExist);
    if (orgNameExist.length > 0) {
      setOrgNameExists(true);
      return;
    }
    setOrgNameExists(false);
    let token = cookies()?.bearerToken;
    // //console.log(data, token);
    EditOrganization(data, token, organization?.orgId)
      .then((res) => {
        //console.log(res);
        getOrganizationList();
        setIsAddOrganization(false);
        setOrganization({
          orgName: "",
          orgStatus: true,
          orgId: 0,
        });
        toast.success("Organization is updated successfully", {
          position: "top-right",
        });
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const deleteOrganization = (checked) => {
    let data = {
      id: organization?.orgId,
      isDeleted: checked,
    };
    let token = cookies()?.bearerToken;
    DeleteOrganization(data, token)
      .then((res) => {
        //console.log(res);
        getOrganizationList();
        setIsAddOrganization(false);
        setOrganization({
          orgName: "",
          orgStatus: true,
          orgId: 0,
        });
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const back = () => {
    navigate("/dashboard");
  };

  const statusBodyTemplate = (row) => {
    // //console.log(row);
    if (row.status !== true) {
      return <p style={{ color: "#45AA08" }}>Active</p>;
    } else {
      return <p style={{ color: "#FF4700" }}>Inactive</p>;
    }
  };

  // const actionButtonTemplate = ((row) => {
  //     return <div  onClick={(e,row)=>{//console.log(e.target,row);}} >
  //         <img src={editIcon} style={{cursor:'pointer'}}  /></div>
  // })

  const generateQRFunction = async (data) => {
    console.log(data);
    await GetOrganizationQR(data.id, cookies()?.bearerToken)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          setIsQRCode({
            isOpen: true,
            QRUrl: res.data,
            organizationName: data.name,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const downloadQRFunction = async () => {
    const link = document.createElement("a");
    link.href = isQRCode.QRUrl;
    link.setAttribute("download", "Organization-" + isQRCode.organizationName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const actionButtonTemplate = (row) => {
    return (
      <div
      // onClick={(e) => {
      //   setIsAddOrganization(true);
      //   setIsAdd(false);
      //   setOrganization((prev) => ({
      //     ...prev,
      //     orgName: row?.name,
      //     orgStatus: !row?.status,
      //     orgId: row?.id,
      //   }));
      //   // //console.log(organization);
      // }}
      >
        <MdQrCodeScanner
          style={{ cursor: "pointer", color: "blue", fontSize: "1.5rem" }}
          onClick={() => {
            generateQRFunction(row);
          }}
        />
      </div>
    );
  };
  const onGlobalFilterChange = (e) => {
    const value = e ? e.target.value : "";
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  useEffect(() => {
    // //console.log("Cookies", cookies);
    // //console.log("Context", Context);
    // if (Context?.organizationList?.length == 0)
    getOrganizationList();
    // else {
    // setOrganizationList(Context.organizationList);
    // }
  }, []);
  const sideNavVisible = () => {
    //console.log("Side Nav", Context.sideNavVisibility);
    dispatch(setNavVisibilty({ sideNavVisibility: true }));
  };
  const getPage = (event) => {
    //console.log("Page change", event);
  };
  const exportOrganization = () => {
    exportDataInExcel("Organization", cookies().bearerToken);
  };

  return (
    <>
      <div className="organizationMaster">
        {/* <ToastContainer /> */}
        <div className="header">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexGrow: "3",
              justifyContent: "flex-start",
            }}
            className="flex align-items-center org"
          >
            <i
              className="pi pi-arrow-circle-left nav-back-btn"
              onClick={back}
              style={{
                marginLeft: "30px",
                cursor: "pointer",
                fontSize: "1.5rem",
              }}
            />
            <div className="heading">
              <p>Hands-Free Check-in QR Generation</p>
            </div>
          </div>
          <div className="flex align-items-center">
            <div className="mx-2">
              <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Search</h4>
              <div className="search">
                <span className="p-input-icon-left p-input-icon-right">
                  <i
                    className="pi pi-search"
                    style={{ color: "black", fontWeight: "700", fontSize: "1rem" }}
                  />
                  <InputText
                    value={globalFilterValue}
                    onChange={onGlobalFilterChange}
                    placeholder="Search"
                  />
                  <i
                    className="pi pi-times"
                    style={{ color: "black", fontWeight: "400" }}
                    onClick={() => {
                      setGlobalFilterValue("");
                      onGlobalFilterChange();
                    }}
                  />
                </span>
              </div>
            </div>
          </div>

        </div>

        <div className="datatable">
          {isLoading ? (
            <div className="spinner spinner-center"></div>
          ) : (
            <DataTable
              value={organizationList}
              rows={10}
              paginator
              filters={filters}
              tableStyle={{
                width: "90rem",
              }}
              style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
              stripedRows
              emptyMessage={
                <>
                  <p style={{ textAlign: "center" }}>
                    Organization list is empty
                  </p>
                </>
              }
              globalFilterFields={[
                "code",
                "name",
                "userName",
                "createdOn",
                "orgStatus",
              ]}
            >
              <Column field="code" header="Organization Code"></Column>
              <Column field="name" header="Organization Name"></Column>
              <Column field="createdOn" header="Created Date"></Column>
              <Column field="userName" header="Created By"></Column>
              <Column
                field="status"
                header="Status"
                body={statusBodyTemplate}
              ></Column>
              <Column
                rowEditor
                header="Action"
                body={actionButtonTemplate}
              ></Column>
            </DataTable>
          )}
        </div>
      </div>

      <div>{isAddOrganization == true ? popup() : ""}</div>
      <Dialog
        className="preview-Attachment-Popup"
        visible={isQRCode.isOpen}
        onHide={() => {
          setIsQRCode({ isOpen: false, QRUrl: "", organizationName: "" });
        }}
        style={{ width: "50%" }}
      // header={
      //   <h3 style={{ textAlign: "center", color: "#ae9d81", margin: 0 }}>
      //     QR Preview
      //   </h3>
      // }
      >
        <div style={{ width: "100%" }}>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <img src={isQRCode.QRUrl} style={{ height: 400, width: 400 }}></img>
          </div>
          <div style={{ textAlign: "center" }}>
            <Button
              style={{ backgroundColor: "#ae9d81", border: "none" }}
              onClick={() => downloadQRFunction()}
            >
              Download
            </Button>
            {/* <a href={isQRCode.QRUrl}>Download</a> */}
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default QRGeneration;
