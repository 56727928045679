import React, { useState, useEffect } from 'react';
import UseCookies from '../../Helpers/UseCookies';
import './style.scss'

// -------------Prime React Imports---------------
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'


// ----------Assets Import------------
import editIcon from '../../../src/assets/images/editIcon.jpg';
// ----------------------------------


//-----------Services ---------------
import { AddTenantCategory, EditTenantCategory, GetTenantCategory, GetTenantCategoryList } from '../../Services/TenantCategory';
import { Calendar } from 'primereact/calendar';
//----------------------------------------

function TenantCategory() {
    const [cookies, setCookie, removeCookie] = UseCookies();
    const [isAddTenant, setIsAddTenant] = useState(false);
    const [tenantCategoryList, setTenantCategoryList] = useState("");
    const [tenantCategory, setTenantCategory] = useState({
        tenantName: "", tenantStatus: true, tenantId: 0,
    })
    const [tenantNameExists, setTenantNameExists] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const navigate = useNavigate();
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [isTenantInvalid, setIsTenantInvalid] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    // Pagination
    const [first, setFirst] = useState(0)
    const [totalCount, setTotalRecords] = useState(0)

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        // name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        // userName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        status: { value: null, matchMode: FilterMatchMode.EQUALS }
    });

    const header = () => {
        return <div className="heading" style={{ fontWeight: "700", fontSize: "23px", color: "#ae9d81", textAlign: 'center' }}>
            <p style={{ margin: '0px' }}>{isAdd ? "Add" : "Edit"} Tenant Category Details</p>
        </div>
    }
    const popup = (() => {
        return (
            <>
                <Dialog header={header} visible={isAddTenant} style={{ width: '45vw' }}
                    closable={false} onHide={() => {
                        setIsAddTenant(false); setTenantCategory({
                            tenantName: "", tenantStatus: true, tenantId: 0,
                        });
                    }}>
                    <div className="popup " >
                        <div className="body"  >
                            <div className="label grid" >
                                <div className='col' >Tenant Category<span style={{ color: 'red' }}>*</span></div>
                                <div className='col' style={{ position: 'absolute', left: '350px' }}>Status</div>
                            </div>
                            <div className="input grid ">
                                <div className="col">
                                    <InputText placeholder="Enter Tenant Category Name" style={{ width: "230px" }} value={tenantCategory?.tenantName} onChange={(e) => { setTenantNameExists(false); setTenantCategory((prev) => ({ ...prev, tenantName: e.target.value })) }} />
                                    {isTenantInvalid && tenantCategory?.tenantName == "" &&
                                        // <div>
                                        <p style={{ color: "red" }}>Tenant category is required</p>
                                        // </div>
                                    }
                                    {tenantNameExists &&
                                        // <div>
                                        <p style={{ color: "red" }}>Tenant category is already exists</p>
                                        // </div>
                                    }
                                </div>

                                <div className="col ">
                                    <label className="switch ">
                                        <input type="checkbox" checked={tenantCategory?.tenantStatus} onChange={(e) => { setTenantCategory((prev) => ({ ...prev, tenantStatus: e.target.checked })) }} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>

                            </div>
                        </div>
                        <div className="footer flex  grid">
                            <div className="col flex justify-content-center ">
                                <Button label="Cancel"
                                    onClick={() => {
                                        setIsAddTenant(false); setTenantCategory({
                                            tenantName: "", tenantStatus: true, tenantId: 0,
                                        });

                                    }}
                                    style={{ backgroundColor: '#8E8E8E', border: "none", margin: "10px", height: '40px' }} />
                                <Button label={isAdd ? "Submit" : "Update"} onClick={() => { isAdd ? addTenantCategory() : editTenantCategory(); }} style={{ backgroundColor: '#ae9d81', border: "none", margin: "10px", height: '40px' }} />
                            </div>
                        </div>
                    </div>
                </Dialog>

            </>
        )
    });


    const addTenantCategory = (async () => {
        if (tenantCategory?.tenantName == "") {
            setIsTenantInvalid(true);
            return;
        }
        let data = {
            "name": tenantCategory?.tenantName,
            "isActive": !tenantCategory?.tenantStatus,

        };
        let tenantNameExist = tenantCategoryList.filter((tenant) => tenant.name == tenantCategory?.tenantName);
        if (tenantNameExist.length > 0) {
            setTenantNameExists(true);
            return;
        }
        setTenantNameExists(false);
        let token = cookies()?.bearerToken
        //console.log(organization);
        setIsAddTenant(false);
        await AddTenantCategory(data, token)
            .then((res) => {
                //console.log(res);
                if (res?.status === 200) {
                    getTenantCategoryList();
                    setTenantCategory({
                        tenantName: "", tenantStatus: true, tenantId: 0,
                    });
                    toast.success("New Tenant Category is added successfully", { position: "top-right" });
                }
                else {
                    setTenantCategory({
                        tenantName: "", tenantStatus: true, tenantId: 0,
                    });
                    // if (res?.response?.data === "Violation of UNIQUE KEY constraint 'Unique_Org_Name'. Cannot insert duplicate key in object 'dbo.OrganizationMaster'. The duplicate key value is (Marassi).") {
                    //     toast.error("Organization name already exists", { position: "top-right" });
                    // }
                    // else {
                    toast.error(res?.response?.data, { position: "top-right" });
                    // }

                }
                setIsAddTenant(false);
                setIsAdd(false);
            })
            .catch((error) => {
                //console.log(error);
            })
    })

    const editTenantCategory = (async () => {
        if (tenantCategory?.tenantName == "") {
            setIsTenantInvalid(true);
            return;
        }
        let data = {
            "name": tenantCategory?.tenantName,
            "isActive": !tenantCategory?.tenantStatus,
            "id": tenantCategory?.tenantId
        };
        let tenantNameExist = tenantCategoryList.filter((tenant) => tenant?.name == tenantCategory?.tenantName && tenant?.id != tenantCategory?.tenantId);
        if (tenantNameExist.length > 0) {
            setTenantNameExists(true);
            return;
        };
        setTenantNameExists(false);
        let token = cookies()?.bearerToken;
        await EditTenantCategory(data, token)
            .then((res) => {
                //console.log(res);
                if (res?.status === 200) {
                    getTenantCategoryList();
                    setIsAddTenant(false);
                    setTenantCategory({
                        tenantName: "", tenantStatus: true, tenantId: 0,
                    })
                    toast.success("Tenant Category is updated successfully", { position: "top-right" });
                }
                else {
                    setTenantCategory({
                        tenantName: "", tenantStatus: true, tenantId: 0,
                    });
                    toast.error(res?.response?.data, { position: "top-right" });
                }
            })
            .catch((error) => {
                //console.log(error);
            })

    });



    const back = () => {
        navigate('/dashboard');
    };




    const statusBodyTemplate = ((row) => {
        // //console.log(row);
        if (row.isActive !== true) {
            return <p style={{ color: "#45AA08" }}  >Active</p>
        }
        else {
            return <p style={{ color: "#FF4700" }} >Inactive</p>
        }
    });
    const actionButtonTemplate = (row) => {
        return (
            <div onClick={async (e) => {
                // //console.log(e.target, row);

                await GetTenantCategory(row?.id, cookies().bearerToken)
                    .then((res) => {
                        if (res?.status === 200) {
                            setIsAddTenant(true);
                            setIsAdd(false);
                            setTenantCategory({
                                tenantName: res?.data?.name,
                                tenantStatus: !res?.data?.isActive,
                                tenantId: res?.data?.id
                            })
                        }
                        else {
                            toast.error(res?.statusText, { position: "top-right" });
                        }
                    })
                    .catch((error) => { console.log(error); })
            }}>
                <img src={editIcon} style={{ cursor: 'pointer' }} />
            </div>
        );
    };
    const onGlobalFilterChange = (e) => {
        const value = e ? e.target.value : "";
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const getTenantCategoryList = (async () => {
        setIsLoading(true);
        await GetTenantCategoryList(cookies().bearerToken)
            .then((res) => {
                if (res?.status === 200) {
                    // setTenantCategoryList(res?.data?.data.map((_) => {
                    //     return {
                    //         "id": _?.id,
                    //         "name": _?.name,
                    //         "code": _?.code,
                    //         "createdOn": _?.createdOn,
                    //         "userName": _?.userName,
                    //         "status": _?.status,
                    //         "orgStatus": _?.status ? "Inactive" : "Active",

                    //     }
                    // }
                    // ));
                    setTenantCategoryList(res?.data);
                    // setTotalRecords(res?.data?.count);
                }

            })
            .catch((error) => {
                //console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
                // //console.log(organizationList);
            })
    })



    useEffect(() => {
        getTenantCategoryList();
    }, [])

    // Export Filter
    const [exportFilterDialog, setExportFilterDialog] = useState(false);
    const [exportDetails, setExportDetails] = useState({
        startDate: "",
        endDate: "",
        organization: "",
        desk: "",
        status: true
    });

    return (
        <>
            <Dialog header={<h3>Export Filter</h3>} draggable={false} style={{ width: '50vw', height: "max-content" }} closable={true} onHide={() => setExportFilterDialog(false)} visible={exportFilterDialog} >
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="StartDate" className="font-bold block mb-2">Start Date
                        </label>
                        <Calendar
                            id="StartDate"
                            style={{ width: "95%" }}
                            value={exportDetails?.startDate}
                            placeholder="Choose start end"
                            onChange={((value) => setExportDetails(() => ({ ...exportDetails, startDate: value })))}
                        ></Calendar>
                    </div>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="EndDate" className="font-bold block mb-2">End Date
                        </label>
                        <Calendar
                            id="EndDate"
                            style={{ width: "95%" }}
                            value={exportDetails?.endDate}
                            placeholder="Choose end date"
                            onChange={((value) => setExportDetails(() => ({ ...exportDetails, endDate: value })))}
                        ></Calendar>
                    </div>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="organization" className="font-bold block mb-2">Organization
                        </label>
                        <InputText
                            id="organization"
                            style={{ width: "95%" }}
                            value={exportDetails?.organization}
                            onChange={((value) => setExportDetails(() => ({ ...exportDetails, organization: value })))}
                            placeholder="Enter organization" ></InputText>
                    </div>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="Status" className="font-bold block mb-2">Status
                        </label>
                        {/* <InputText
                                id="Status"
                                style={{ width: "95%" }}
                                value={exportDetails?.status}

                                placeholder="Enter organization" ></InputText> */}
                        <div className="input grid ">
                            <div className="col ">
                                <label className="switch ">
                                    <input type="checkbox" value={exportDetails?.status} checked={exportDetails?.status} onChange={((e) => setExportDetails(() => ({ ...exportDetails, status: e.target.checked })))} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>

                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "25px",
                    }}
                >
                    <Button
                        label="Submit"
                        onClick={''}
                        // icon={"pi pi-file-export"}
                        style={{
                            backgroundColor: " #ae9d81",
                            color: "#fff",
                            border: "none",
                            // borderRadius: "20px",
                            marginRight: "0",
                            cursor: totalCount > 0 ? "pointer" : "not-allowed",
                            padding: "0.6rem",
                        }}
                    />
                </div>


            </Dialog>
            <div className="tenantcategory" >
                {/* <ToastContainer /> */}
                <div className="header">
                    <div style={{ display: "flex", flexDirection: "row", flexGrow: "3", justifyContent: "flex-start" }} className='flex align-items-center org'>
                        <i className="pi pi-arrow-circle-left nav-back-btn" onClick={back} style={{ marginLeft: '30px', cursor: 'pointer', fontSize: '1.5rem' }} />
                        <div className="heading">
                            <p>Tenant Category</p>
                        </div>
                    </div>
                    <div className='flex align-items-center'>
                        <div className='mx-2'>
                            <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Search</h4>
                            <div className="search">
                                <span className="p-input-icon-left p-input-icon-right">
                                    <i className="pi pi-search" style={{ color: 'black', fontWeight: '700', fontSize: '1rem' }} />
                                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" style={{ width: "180px" }} />
                                    <i className="pi pi-times" style={{ color: 'black', fontWeight: '400' }} onClick={() => { setGlobalFilterValue(""); onGlobalFilterChange() }} />
                                    {/* <i className="pi pi-times" style={{ color: 'black', fontWeight: '400' }} onClick={() => { setGlobalFilterValue(""); onGlobalFilterChange() }} /> */}
                                </span>
                            </div>
                        </div>
                        <div className="mx-2 align-self-end">
                            <Button label="Add New" onClick={() => { setIsAddTenant(true); setIsAdd(true); setIsTenantInvalid(false); }} style={{ backgroundColor: '#ae9d81', border: 'none' }} />
                        </div>
                    </div>
                </div>

                <div className="datatable">
                    {isLoading ?
                        <div className='spinner spinner-center'></div>
                        : <DataTable
                            value={tenantCategoryList}
                            rows={10}
                            paginator
                            filters={filters}
                            tableStyle={{
                                width: '90rem',
                            }}
                            style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                            stripedRows
                            emptyMessage={<><p style={{ textAlign: "center" }}>Tenant category list is empty</p></>}
                            globalFilterFields={["id", 'name', "status"]}>

                            <Column field="name" header="Tenant Category"></Column>
                            <Column field="status" header="Status" body={statusBodyTemplate} ></Column>
                            <Column rowEditor header="Action" body={actionButtonTemplate}></Column>
                        </DataTable>}
                </div>
            </div>

            <div>
                {isAddTenant == true ? popup() : ""}
            </div>
        </>
    )
}

export default TenantCategory


