import React, { useState, useEffect } from 'react';
import UseCookies from '../../Helpers/UseCookies';
import './style.scss'

// -------------Prime Recat Imports---------------
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Tag } from 'primereact/tag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'



// -------------Service imports----------

import { GetAllDescriptions, EditDescription, AddDescription } from '../../Services/TenantDescriptionService';
// -----------------------------------


// ----------Assets Import------------
import editIcon from '../../../src/assets/images/editIcon.jpg';
// -----------------------------------


//------Imports from other Components-----
//----------------------------------------


//-----------Context ---------------
import { useDispatch, useSelector } from "react-redux";
import { setNavVisibilty } from '../../Redux/AppSlice';
import { Calendar } from 'primereact/calendar';

//----------------------------------------

function TenantDescription() {
    const Context = useSelector((state) => state.AppContext);
    const dispatch = useDispatch();
    const [cookies, setCookie, removeCookie] = UseCookies();
    const [isAdd, setIsAdd] = useState(false);
    const navigate = useNavigate();
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const [isAddDescription, setIsAddDescription] = useState(false);
    const onGlobalFilterChange = (e) => {

        const value = e ? e.target.value : "";
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const [tenantDescriptionList, setTenantDescriptionList] = useState("");
    const [description, setDescription] = useState({
        "name": "",
        "tenantDescriptionId": 0,
        "isDeleted": true
    });
    const [isInvalid, setIsInvalid] = useState(false);
    const [isdescriptionExists, setIsdescriptionExists] = useState(false);




    const header = () => {
        return <div className="heading" style={{ fontWeight: "700", fontSize: "23px", color: "#ae9d81", textAlign: 'center' }}>
            <p style={{ margin: '0px' }}>{isAdd ? "Add" : "Edit"} Tenant Description Details</p>
        </div>
    };
    const statusBodyTemplate = ((row) => {
        // //console.log(row);
        if (row.isDeleted !== true) {
            return <p style={{ color: "#45AA08" }}  >Active</p>
        }
        else {
            return <p style={{ color: "#FF4700" }} >Inactive</p>
        }
    });
    const actionButtonTemplate = (row) => {
        return (
            <div onClick={(e) => {
                setIsAddDescription(true);
                setIsAdd(false);
                setDescription((prev) => ({
                    ...prev,
                    id: row.id,
                    name: row.name,
                    tenantDescriptionId: row.id,
                    isDeleted: !row.isDeleted,
                    createDate: row.createDate,
                }));
            }}>
                <img src={editIcon} style={{ cursor: 'pointer' }} />
            </div>
        );
    };
    const dateBodyTemplate = ((row) => {
        const monthAbbreviations = {
            Jan: '01',
            Feb: '02',
            Mar: '03',
            Apr: '04',
            May: '05',
            Jun: '06',
            Jul: '07',
            Aug: '08',
            Sep: '09',
            Oct: '10',
            Nov: '11',
            Dec: '12'
        };
        //console.log(row);
        const parts = row?.createdDate.split('/');
        const day = parts[0];
        const monthAbbreviation = parts[1];
        const year = parts[2];

        const month = monthAbbreviations[monthAbbreviation];

        const outputDate = `${day}/${month}/${year}`;

        return outputDate;
    });
    const popup = (() => {
        return (
            <>


                <Dialog header={header} visible={isAddDescription} style={{ width: '30vw' }}
                    closable={false} >
                    <div className="popup " >
                        {/* <div className="heading">
                            <p>Add New Details</p>
                        </div> */}
                        <div className="body"  >
                            <div className="label grid" >
                                <div className='col' >Description<span style={{ color: 'red' }}>*</span></div>
                                <div className='col' style={{ position: 'absolute', left: '240px' }}>Status</div>
                            </div>
                            <div className="input grid ">
                                <div className="col">
                                    <InputText placeholder="Enter Description" value={description?.name} onChange={(e) => { setDescription((prev) => ({ ...prev, name: e.target.value })) }} />
                                    {isInvalid && description.name == "" &&
                                        <p style={{ color: "red" }}>Description is required</p>
                                    }
                                    {isdescriptionExists &&
                                        <p style={{ color: "red" }}>Description value is already exists</p>
                                    }
                                </div>

                                <div className="col ">

                                    <label className="switch ">
                                        <input type="checkbox" checked={description?.isDeleted} onChange={(e) => {
                                            setDescription((prev) => ({ ...prev, isDeleted: e.target.checked }))

                                        }} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>

                            </div>
                        </div>
                        <div className="footer flex  grid">
                            <div className="col flex justify-content-center ">
                                <Button label="Cancel"
                                    onClick={() => {
                                        setIsAddDescription(false);
                                        setIsAdd(false);
                                        setDescription({
                                            "name": "",
                                            "tenantDescriptionId": 0,
                                            "isDeleted": true
                                        })

                                    }}
                                    style={{ backgroundColor: '#8E8E8E', border: "none", margin: "10px", height: '40px' }} />
                                <Button label={isAdd ? "Submit" : "Update"} onClick={() => { isAdd ? addTenantDescription() : editTenantDescription() }}
                                    style={{ backgroundColor: '#ae9d81', border: "none", margin: "10px", height: '40px' }} />
                            </div>
                        </div>
                    </div>
                </Dialog>

            </>
        )
    })


    const getTenantDescriptionList = async () => {
        let take = 50;
        let skip = 0;
        await GetAllDescriptions(take, skip, cookies().bearerToken)
            .then((res) => {
                if (res?.status === 200) {
                    setTenantDescriptionList(res?.data?.data);
                    setTenantDescriptionList(res?.data?.data?.map((_) => {
                        return {
                            "id": _?.id,
                            "name": _?.name,
                            "isDeleted": _?.isDeleted,
                            "createdDate": _?.createdDate,
                            "status": _?.isDeleted ? "Inactive" : "Active"
                        }
                    }
                    ))
                }
            })
            .catch((err) => {
                //console.log(err);

            })

    };
    const editTenantDescription = async () => {
        if (description.name == "") {
            setIsInvalid(true);
            return;
        }
        setIsInvalid(false);
        let filterdDescription = tenantDescriptionList?.filter((item) => item.name == tenantDescriptionList.name && item.tenantDescriptionId != tenantDescriptionList.tenantDescriptionId);
        if (filterdDescription.length > 0) {
            setIsdescriptionExists(true);
            return;
        }
        setIsdescriptionExists(false);


        let data = {
            "name": description?.name,
            "tenantDescriptionId": description?.tenantDescriptionId,
            "isDeleted": !description?.isDeleted
        }
        await EditDescription(data, cookies().bearerToken)
            .then((res) => {
                if (res?.status === 200) {
                    getTenantDescriptionList();
                    setIsAddDescription(false);
                    setIsAdd(false);
                    setDescription({
                        "name": "",
                        "tenantDescriptionId": 0,
                        "isDeleted": true
                    })
                    toast.success("Tenant Description is Updated Successfully", { position: "top-right" });

                }
            })
            .catch((err) => {
                //console.log(err);

            })

    };
    const addTenantDescription = async () => {
        if (description.name == "") {
            setIsInvalid(true);
            return;
        }
        setIsInvalid(false);
        let filterdDescription = tenantDescriptionList.filter((item) => item.name == description.name);
        if (filterdDescription.length > 0) {
            setIsdescriptionExists(true);
            return;
        }
        setIsdescriptionExists(false);
        let data = {
            "name": description?.name,
            "isDeleted": !description?.isDeleted,
        }
        await AddDescription(data, cookies().bearerToken)
            .then((res) => {
                if (res?.status === 200) {
                    getTenantDescriptionList();
                    setIsAddDescription(false);
                    setIsAdd(false);
                    setDescription({
                        "name": "",
                        "tenantDescriptionId": 0,
                        "isDeleted": true
                    })
                    toast.success("New Tenant Description is added Successfully", { position: "top-right" });

                }
            })
            .catch((err) => {
                //console.log(err);

            })

    };
    const back = () => {
        navigate('/dashboard');
    };

    useEffect(() => {
        getTenantDescriptionList();
    }, [])

    // Export Filter
    const [exportFilterDialog, setExportFilterDialog] = useState(false);
    const [exportDetails, setExportDetails] = useState({
        startDate: "",
        endDate: "",
        organization: "",
        desk: "",
        status: true
    });


    return (
        <div className='TenantDescription'>
            <Dialog header={<h3>Export Filter</h3>} draggable={false} style={{ width: '50vw', height: "max-content" }} closable={true} onHide={() => setExportFilterDialog(false)} visible={exportFilterDialog} >
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="StartDate" className="font-bold block mb-2">Start Date
                        </label>
                        <Calendar
                            id="StartDate"
                            style={{ width: "95%" }}
                            value={exportDetails?.startDate}
                            placeholder="Choose start end"
                            onChange={((value) => setExportDetails(() => ({ ...exportDetails, startDate: value })))}
                        ></Calendar>
                    </div>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="EndDate" className="font-bold block mb-2">End Date
                        </label>
                        <Calendar
                            id="EndDate"
                            style={{ width: "95%" }}
                            value={exportDetails?.endDate}
                            placeholder="Choose end date"
                            onChange={((value) => setExportDetails(() => ({ ...exportDetails, endDate: value })))}
                        ></Calendar>
                    </div>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="organization" className="font-bold block mb-2">Organization
                        </label>
                        <InputText
                            id="organization"
                            style={{ width: "95%" }}
                            value={exportDetails?.organization}
                            onChange={((value) => setExportDetails(() => ({ ...exportDetails, organization: value })))}
                            placeholder="Enter organization" ></InputText>
                    </div>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="Status" className="font-bold block mb-2">Status
                        </label>
                        {/* <InputText
                        id="Status"
                        style={{ width: "95%" }}
                        value={exportDetails?.status}

                        placeholder="Enter organization" ></InputText> */}
                        <div className="input grid ">
                            <div className="col ">
                                <label className="switch ">
                                    <input type="checkbox" value={exportDetails?.status} checked={exportDetails?.status} onChange={((e) => setExportDetails(() => ({ ...exportDetails, status: e.target.checked })))} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>

                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "25px",
                    }}
                >
                    <Button
                        label="Submit"
                        onClick={''}
                        // icon={"pi pi-file-export"}
                        style={{
                            backgroundColor: " #ae9d81",
                            color: "#fff",
                            border: "none",
                            // borderRadius: "20px",
                            marginRight: "0",
                            cursor: "pointer",
                            padding: "0.6rem",
                        }}
                    />
                </div>


            </Dialog>
            {/* <ToastContainer /> */}
            <div className="header">
                <div style={{ display: "flex", flexDirection: "row", flexGrow: "3", justifyContent: "flex-start" }} className='flex align-items-center org'>
                    <i className="pi pi-arrow-circle-left nav-back-btn" onClick={back} style={{ marginLeft: '30px', cursor: 'pointer', fontSize: '1.5rem' }} />
                    <div className="heading">
                        <p>Tenant Description</p>
                    </div>
                </div>
                {/* <div style={{ display: "flex", flexDirection: "row", flexGrow: "3", justifyContent: "flex-start" }} className='flex align-items-center org'>
                    <div className="menubtn" style={{ padding: '10px 10px 5px 20px' }}>
                        <i className="pi pi-align-justify" style={{ fontSize: '1rem', color: "#ae9d81", cursor: "pointer" }}
                            onClick={() => dispatch(setNavVisibilty({ sideNavVisibility: true }))}></i>
                    </div>
                    <div className="heading">
                        <p>Tenant Description</p>
                    </div>
                </div> */}
                {/* <div className="search">
                    <span className="p-input-icon-left p-input-icon-right">
                        <i className="pi pi-search" style={{ color: 'black', fontWeight: '700', fontSize: '1rem' }} />
                        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                        <i className="pi pi-times" style={{ color: 'black', fontWeight: '400', fontSize: '1rem', cursor: "pointer" }} onClick={() => { setGlobalFilterValue(""); onGlobalFilterChange() }} />
                    </span>
                </div> */}
                <div className='flex align-items-center'>
                    <div className="mx-2">
                        <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Search</h4>
                        <div className='flex align-items-center' style={{ border: "solid 1px #AE9D81", borderRadius: "4px" }}>
                            <i className="pi pi-search mx-2" style={{ color: 'black', fontWeight: '700', fontSize: '1rem' }} />
                            <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" className='border-0' />
                            <i className="pi pi-times mx-2" style={{ color: 'black', fontWeight: '400', fontSize: '1rem', cursor: 'pointer' }} onClick={() => { setGlobalFilterValue(""); onGlobalFilterChange() }} />
                        </div>
                    </div>
                    <div className="mx-2 align-self-end">
                        <Button label="Add New" onClick={() => { setIsAddDescription(true); setIsAdd(true) }} style={{ backgroundColor: '#ae9d81', border: 'none' }} />
                    </div>
                </div>
            </div>

            <div className="datatable">
                <DataTable value={tenantDescriptionList} paginator rows={10} filters={filters} tableStyle={{
                    width: '90rem',
                }} style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }} stripedRows
                    emptyMessage={<><p style={{ textAlign: "center" }}>Tenant description list is empty</p></>}
                    globalFilterFields={['name', "createdDate", "id", "status"]}>
                    <Column field="id" header="Description Id"></Column>
                    <Column field="name" header="Description"></Column>
                    <Column field="createdDate" header="Created Date" body={dateBodyTemplate}></Column>
                    <Column field="status" header="Status" body={statusBodyTemplate} ></Column>
                    <Column rowEditor header="Action" body={actionButtonTemplate}></Column>
                </DataTable>
            </div>


            <div>
                {isAddDescription == true ? popup() : ""}
            </div>
        </div>
    )
}

export default TenantDescription