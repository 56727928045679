import React, { useState, useEffect } from "react";
import "./VIPSearchstyle.scss";
import UseCookies from "../../../../Helpers/UseCookies";
import { useDispatch } from "react-redux";
import { setNavVisibilty } from "../../../../Redux/AppSlice";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";

// ----------Assets Import------------
import editIcon from "../../../../assets/images/editIcon.jpg";
import { toast } from "react-toastify";
import {
  GetVIPUser,
  GetSubscriptionDetail,
} from "../../../../Services/VIPLoungeSupervisor";
import {
  GetVIPUserDetail,
  GetVipIds,
} from "../../../../Services/VIPLoungeStaffService";
import { extendExpiryDate } from "../../../../Services/VIPLoungeSupervisor";
import { DownloadBase64File } from "../../../../Services/Files";
import { AutoComplete } from "primereact/autocomplete";
import moment from "moment/moment";
import { LiaIdCardSolid } from "react-icons/lia";
import { MdAccountBalanceWallet } from "react-icons/md";
import { FaRegBell } from "react-icons/fa";
// -----------------------------------

function VIPSearch() {
  const [cookies, setCookie, removeCookie] = UseCookies();
  const dispatch = useDispatch();

  const userInitialValue = {
    id: 0,
    fullName: "",
    emailAddress: "",
    mobileNumber: "",
    vipcode: "",
    nationality: "",
    countryOfResidence: "",
    companyName: "",
    designation: "",
    dateOfRegistration: "",
    dateOfBirth: "",
    status: "",
    expirationStatus: "",
    azureBlobUrl: "",
    profilePicture: ""

  };
  const [userDetails, setUserDetails] = useState({ ...userInitialValue });
  const [isUserFetched, setIsUserFetched] = useState(false);

  // Search && Filter
  const [searchFilterValue, setSearchFilterValue] = useState("");
  const [expiryDate, setExpirayDate] = useState("");

  // Popup
  const [membershipPopup, setMembershipPopup] = useState(false);
  const [memberShipPopupErrMessage, setMemberShipPopupErrMessage] = useState(false);
  const [isBirthday, setIsBirthday] = useState(false);

  // Profile loading
  const [profileLoading, setProfileLoading] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Popup header
  const header = () => {
    return (
      <div className="popup-header">
        <p style={{ textAlign: "center", color: "#2D6C8B", fontWeight: 700 }}>
          Membership Details
        </p>
      </div>
    );
  };

  // Edit Handle Click
  const editHandleClick = () => {
    setMembershipPopup(true);
    setMemberShipPopupErrMessage(false);
    setExpirayDate(new Date(userDetails?.dateOfExpiry));
  };
  // Search Vip User
  const searchVipUser = async () => {
    setIsLoading(true);
    setProfileLoading(true);
    if (cookies()?.roles.includes("LOUNGE_STAFF")) {
      await GetVIPUserDetail(searchFilterValue.trim(), cookies()?.bearerToken)
        .then(async (res) => {
          console.log(res);
          if (res?.status === 200) {
            setUserDetails(res.data);
            setIsUserFetched(true);
            console.log(res?.data?.isBirthdayToday);

            if (res?.data?.isBirthdayToday) {
              setIsBirthday(true);
            }
            if (res?.data?.profilePicture !== "") {
              await DownloadBase64File(
                res?.data?.profilePicture.replace("\\", "/"),
                cookies()?.bearerToken
              )
                .then((res) => {
                  console.log(res);
                  if (res?.status === 200) {
                    setUserDetails((prev) => ({
                      ...prev,
                      profilePicture: res?.data ? res?.data : "",
                    }));

                  }
                })
                .catch((err) => {
                  console.log(err);
                })
                .finally(() => { });
            } else {
              setUserDetails((prev) => ({ ...prev, profilePicture: "" }));
            }
            // toast.success("User Found", { position: "top-right" });
          } else {
            toast.error("User Not Found", { position: "top-right" });
            setIsUserFetched(false);
            setUserDetails(null);
          }
          setIsLoading(false);
          setSearchFilterValue("");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setProfileLoading(false);
          setIsLoading(false);
        });
    } else {
      await GetVIPUser(searchFilterValue.trim(), cookies().bearerToken)
        .then(async (res) => {
          if (res?.status === 200) {
            // toast.success("User Found", { position: "top-right" });
            setUserDetails({ ...res.data });
            console.log(res?.data?.isBirthdayToday);
            if (res?.data?.isBirthdayToday) {
              setIsBirthday(true);
            }
            // await GetSubscriptionDetail(id.id, cookies().bearerToken)
            //   .then((date) => {
            //     console.log(date);
            //     setUserDetails({ ...res.data, ...date?.data?.response });
            //     setIsLoading(false);
            //   })
            //   .catch((err) => {
            //     console.log(err);
            //   });
            setIsLoading(false);
            setIsUserFetched(true);
            setSearchFilterValue("");
            if (res?.data?.azureBlobUrl !== "") {
              await DownloadBase64File(
                res?.data?.azureBlobUrl.replace("\\", "/"),
                cookies().bearerToken
              )
                .then((res) => {
                  console.log(res);
                  if (res?.status === 200) {
                    setUserDetails((prev) => ({
                      ...prev,
                      profilePicture: res?.data,
                    }));
                  }
                })
                .catch((err) => {
                  console.log(err);
                })
                .finally(() => { });
            } else {
              setUserDetails((prev) => ({ ...prev, profilePicture: "" }));
            }
          } else {
            toast.error("User Not Found", { position: "top-right" });
            setIsUserFetched(false);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setProfileLoading(false);
          setIsLoading(false);
        });
    }
  };

  const renewalDateFunction = async () => {
    if (!new Date(expiryDate)?.getFullYear()) {
      setMemberShipPopupErrMessage(true);
      return
    }
    await extendExpiryDate(
      userDetails?.vipcode,
      new Date(expiryDate).toISOString(),
      cookies().bearerToken
    )
      .then(async (res) => {
        console.log(res);
        if (res?.data?.httpStatusCode === 400) {
          toast.warning(
            "Extending expiry should be greater than current expiration datetime",
            {
              position: "top-right",
            }
          );
        } else {
          toast.success("Expiry date extended successfully", {
            position: "top-right",
          });
          setUserDetails({ ...userDetails, dateOfExpiry: expiryDate });
          await searchVipUser();
        }
      })
      .catch((err) => console.log(err));

    setMembershipPopup(false);
    setMemberShipPopupErrMessage(false);
  };
  const getAllVipID = async (searchTerm) => {
    let data = [];
    await GetVipIds(searchTerm || "", cookies()?.bearerToken).then((res) => {
      // console.log(res?.data);
      res.data?.forEach((id) => data.push({ id: id }));
    });
    setFilteredItems([...data]);
  };
  const searchItems = (event) => {
    getAllVipID(event.query);
  };
  const subscriptionStatus = (status) => {
    if (status == "Active") {
      return "green";
    }
    else {
      return "red";
    }
  }


  useEffect(() => {
    getAllVipID();
  }, []);

  return (
    <>
      {/* <Dialog
        visible={membershipPopup}
        header={header}
        style={{ width: "30vw" }}
        onHide={() => {
          setMembershipPopup(false);
        }}
      >
        <div className="membershipPopup">
          <p>Renewal Date</p>
          <Calendar
            placeholder="Select Date of Expiry"
            className="calendar"
            dateFormat="dd/mm/yy"
            style={{ width: "350px" }}
            showIcon
            value={userDetails?.dateOfExpiry}
            minDate={new Date()}
            onChange={(e) => {
              console.log(e);
            }}
          />
        </div>
        <div className="popup-footer">
          <Button
            label="Cancel"
            className="p-button-text"
            style={{ background: "rgb(142, 142, 142)", color: "white" }}
            onClick={() => {
              setMembershipPopup(false);
            }}
          />
          <Button
            label="Submit"
            className="p-button-text"
            style={{ background: "#ae9d81", color: "white" }}
            onClick={() => {
              setMembershipPopup(false);
            }}
          />
        </div>
      </Dialog> */}

      <Dialog header={
        <div style={{ display: 'flex', justifyContent: "center" }}>
          <h2 style={{ color: " #00488f" }}>Birthday Card</h2>
        </div>
      }
        visible={isBirthday}
        style={{ width: "30%" }}
        onHide={() => setIsBirthday(false)}
      >
        <div className="birthday-card">
          <h2 style={{ color: "#ae9d81" }}>The user has a birthday today!!.</h2>
        </div>
      </Dialog>

      <Dialog
        header={
          <h3 style={{ textAlign: "center", color: "#ae9d81", margin: 0 }}>
            Membership Details
          </h3>
        }
        visible={membershipPopup}
        style={{ width: "30%" }}
        onHide={() => {
          setMembershipPopup(false);
        }}
      >
        <div className="invoice-modal">
          <div className="invoice-modal-content">
            <p style={{ paddingBottom: "5px" }}>Renewal Date</p>
            <Calendar
              placeholder="Select Date of Expiry"
              className="p-inputtext-sm"
              dateFormat="dd/mm/yy"
              showOnFocus={false}
              style={{ width: "100%", background: "transparent" }}
              showIcon
              value={expiryDate}
              minDate={new Date()}
              onChange={(e) => {
                console.log(e);
                setExpirayDate(e.value);
              }}
            />
            {memberShipPopupErrMessage && <p style={{ color: "red" }}>Renewal date is required</p>}
          </div>
          <div className="invoice-modal-btn">
            <Button
              label="Cancel"
              className="p-button-text"
              style={{
                backgroundColor: "rgb(142, 142, 142)",
                color: "white",
                border: "none",
                width: "100px",
                padding: "5px 0px",
              }}
              onClick={() => {
                setMembershipPopup(false);
              }}
            />
            <Button
              label="Submit"
              className="p-button-text"
              style={{
                backgroundColor: "#ae9d81",
                color: "white",
                border: "none",
                width: "100px",
                padding: "5px 0px",
              }}
              onClick={() => {
                renewalDateFunction();

              }}
            />
          </div>
        </div>
      </Dialog>

      <div className="VIPUserSearch">
        <div className="header h-auto p-0">
          <div className="menubtn" style={{ padding: "20px" }}>
            <i
              className="pi pi-align-justify"
              style={{ fontSize: "1rem", color: "#ae9d81", cursor: "pointer" }}
              onClick={() =>
                dispatch(setNavVisibilty({ sideNavVisibility: true }))
              }
            ></i>
          </div>
          <div
            style={{
              width: "100%",
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <div className="heading">
              <p>Search VIP User</p>
            </div>
            <div className="flex align-items-center mx-2">
              <div>
                <h4 className='mb-1 mt-0 relative' style={{ color: "#ae9d81" }}>Vip id / Mobile number</h4>
                <div className='flex align-items-center mx-2' style={{ border: "solid 1px #AE9D81", borderRadius: "4px" }}>
                  <i className="pi pi-search px-2" style={{ color: 'black', fontWeight: '700', fontSize: '1rem' }} />
                  <InputText value={searchFilterValue} onChange={(e) => setSearchFilterValue(e.target.value)} placeholder="Search" className='border-0' />
                  {searchFilterValue !== "" && (
                    <i className="pi pi-times px-2" style={{ color: 'black', fontWeight: '400', fontSize: '1rem', cursor: 'pointer', position: "block" }} onClick={() => { setSearchFilterValue(""); setUserDetails({ ...userInitialValue }); setIsUserFetched(false); }} />
                  )}
                </div>
              </div>
              <div
                className="search-button align-self-end"
                onClick={() => searchFilterValue && searchVipUser()}
              >
                <Button
                  icon="pi pi-search"
                  style={{ background: "#ae9d81", border: "none" }}
                  loading={profileLoading}
                />
              </div>
            </div>
          </div>
        </div>
        {isLoading ? (
          <div
            className="spinner spinner-center"
            style={{ display: "flex", margin: "auto", marginTop: "200px" }}
          ></div>
        ) : (
          isUserFetched && (
            <>
              <div className="details-container" style={{ marginBottom: "50px" }}>
                <div style={{ width: '20%', margin: '5%' }}>
                  <div className="profile-pic" style={{ backgroundColor: subscriptionStatus(userDetails?.status) }}>
                    {userDetails?.profilePicture == "" ? (
                      <img srcSet="https://toppng.com/uploads/preview/donna-picarro-dummy-avatar-115633298255iautrofxa.png" />
                    ) : (
                      <img
                        src={
                          "data:image/jpeg;base64," + userDetails?.profilePicture
                        }
                      />
                    )}
                  </div>
                </div>
                <hr
                  style={{
                    width: "2px",
                    background: "red",
                    // height: "660px",
                    padding: 0,
                    margin: "0px 20px 0px 0px",
                  }}
                />
                <div className="details-box">
                  <div className="details-content ">
                    <div className="details-label">
                      <span>
                        <i
                          className="pi pi-user"
                          style={{ color: "#e2aca2", margin: "5px" }}
                        ></i>
                        Name
                      </span>
                    </div>
                    <div className="details-text">
                      <p className="m-0">: {userDetails?.fullName}</p>
                    </div>
                  </div>
                  <div className="details-content ">
                    <div className="details-label">
                      <span>
                        <i
                          className="pi pi-calendar"
                          style={{ color: "#e2aca2", margin: "5px" }}
                        ></i>
                        Date of Birth
                      </span>
                    </div>
                    <div className="details-text">
                      <p className="m-0">: {userDetails?.dateOfBirth}</p>
                    </div>
                  </div>
                  <div className="details-content ">
                    <div className="details-label">
                      <span>
                        <i
                          className="pi pi-envelope"
                          style={{ color: "#e2aca2", margin: "5px" }}
                        ></i>
                        Email Id
                      </span>
                    </div>
                    <div className="details-text">
                      <p className="m-0">: {userDetails?.emailAddress}</p>
                    </div>
                  </div>
                  <div className="details-content ">
                    <div className="details-label">
                      <span>
                        <i
                          className="pi pi-credit-card"
                          style={{ color: "#e2aca2", margin: "5px" }}
                        ></i>
                        VIP Number
                      </span>
                    </div>
                    <div className="details-text">
                      <p className="m-0">: {userDetails?.vipcode}</p>
                    </div>
                  </div>
                  <div className="details-content ">
                    <div className="details-label">
                      <span>
                        <i
                          className="pi pi-phone"
                          style={{ color: "#e2aca2", margin: "5px" }}
                        ></i>
                        Mobile Number
                      </span>
                    </div>
                    <div className="details-text">
                      <p className="m-0">: {userDetails?.mobileNumber}</p>
                    </div>
                  </div>
                  <div className="details-content ">
                    <div className="details-label">
                      <span>
                        <i
                          className="pi pi-users"
                          style={{ color: "#e2aca2", margin: "5px" }}
                        ></i>
                        {/* <LiaIdCardSolid style={{ color: "#e2aca2", margin: "5px",fontSize:20 }}/> */}
                        Start Subscription
                      </span>
                    </div>
                    <div className="details-text">
                      <p className="m-0">:{" "}
                        {userDetails?.subscriptionInfomation
                          ?.subscriptionStartDate
                          ? moment(
                            userDetails?.subscriptionInfomation
                              ?.subscriptionStartDate
                          ).format("DD/MM/YYYY") : "-"}</p>
                    </div>
                  </div>

                  <div className="details-content ">
                    <div className="details-label">
                      <span>
                        <i
                          className="pi pi-calendar-minus"
                          style={{ color: "#e2aca2", margin: "5px" }}
                        ></i>
                        Date of Expiry
                      </span>
                    </div>
                    <div className="details-text">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        {/* <p style={{ margin: "16px 0px" }}> */}
                        <p className="m-0">
                          :{" "}
                          {userDetails?.subscriptionInfomation
                            ?.subscriptionExpiryOn
                            ? moment(
                              userDetails?.subscriptionInfomation
                                ?.subscriptionExpiryOn
                            ).format("DD/MM/YYYY")
                            : // new Date(
                            //   userDetails?.subscriptionInfomation?.subscriptionExpiryOn
                            // ).getDate() +
                            // 1 +
                            // "-" +
                            // (new Date(
                            //   userDetails?.subscriptionInfomation?.subscriptionExpiryOn
                            // ).getMonth() +
                            //   1) +
                            // "-" +
                            // new Date(
                            //   userDetails?.subscriptionInfomation?.subscriptionExpiryOn
                            // ).getFullYear()
                            "Waiting for subscription"}
                        </p>
                        {JSON.parse(JSON.stringify(cookies()?.roles))?.filter(
                          (role) => role === "VIP_SUPERVISOR"
                        )?.length !== 0 &&
                          userDetails?.subscriptionInfomation
                            ?.subscriptionExpiryOn ? (
                          <img
                            src={editIcon}
                            onClick={() => {
                              editHandleClick();
                            }}
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="details-content ">
                    <div className="details-label">
                      <span>
                        <i
                          className="pi pi-bell"
                          style={{ color: "#e2aca2", margin: "5px" }}
                        ></i>
                        Card Number
                      </span>
                    </div>
                    <div className="details-text">
                      <p className="m-0">: {userDetails?.cardNumber || "-"} </p>
                    </div>
                  </div>
                </div>
                <div className="details-box">
                  {userDetails?.subscriptionInfomation?.subscriptionCategory
                    == "Invoice" &&
                    <div className="details-content ">
                      <div className="details-label">
                        <span>
                          <i
                            className="pi pi-wallet"
                            style={{ color: "#e2aca2", margin: "5px" }}
                          ></i>
                          Credit Balance
                        </span>
                      </div>
                      <div className="details-text">
                        <p className="m-0">: {userDetails?.subscriptionInfomation?.creditBalance || "-"}</p>
                      </div>
                    </div>
                  }
                  <div className="details-content ">
                    <div className="details-label">
                      <span>
                        <i
                          className="pi pi-bell"
                          style={{ color: "#e2aca2", margin: "5px" }}
                        ></i>
                        Subscription Type
                      </span>
                    </div>
                    <div className="details-text">
                      <p className="m-0">: {userDetails?.subscriptionInfomation?.subscriptionType || "-"} </p>
                    </div>
                  </div>
                  <div className="details-content ">
                    <div className="details-label">
                      <span>
                        <i
                          className="pi pi-shield"
                          style={{ color: "#e2aca2", margin: "5px" }}
                        ></i>
                        Brand Or Make
                      </span>
                    </div>
                    <div className="details-text">
                      <p className="m-0">: {userDetails?.vehicleInformation?.brandOrMake || "-"}</p>
                    </div>
                  </div>
                  <div className="details-content ">
                    <div className="details-label">
                      <span>
                        <i
                          className="pi pi-car"
                          style={{ color: "#e2aca2", margin: "5px" }}
                        ></i>
                        Care Model
                      </span>
                    </div>
                    <div className="details-text">
                      <p className="m-0">: {userDetails?.vehicleInformation?.careModel || "-"}</p>
                    </div>
                  </div>
                  <div className="details-content ">
                    <div className="details-label">
                      <span>
                        <i
                          className="pi pi-credit-card"
                          style={{ color: "#e2aca2", margin: "5px" }}
                        ></i>
                        Plate Prefix
                      </span>
                    </div>
                    <div className="details-text">
                      <p className="m-0">: {userDetails?.vehicleInformation?.platePrefix || "-"}</p>
                    </div>
                  </div>
                  <div className="details-content ">
                    <div className="details-label">
                      <span>
                        <i
                          className="pi pi-id-card"
                          style={{ color: "#e2aca2", margin: "5px" }}
                        ></i>
                        Plate Number
                      </span>
                    </div>
                    <div className="details-text">
                      <p className="m-0">: {userDetails?.vehicleInformation?.plateNo || "-"}</p>
                    </div>
                  </div>
                  <div className="details-content ">
                    <div className="details-label">
                      <span>
                        <i
                          className="pi pi-flag"
                          style={{ color: "#e2aca2", margin: "5px" }}
                        ></i>
                        Plate Country
                      </span>
                    </div>
                    <div className="details-text">
                      <p className="m-0">: {userDetails?.vehicleInformation?.plateCountry || "-"}</p>
                    </div>
                  </div>

                </div>
              </div>
            </>
          )
        )}
      </div >
    </>
  );
}

export default VIPSearch;
