import React, { useState, useEffect } from "react";
import "./LoungeStaffInvoiceList.scss";
import UseCookies from "../../../../Helpers/UseCookies";
import { useDispatch } from "react-redux";
import { setNavVisibilty } from "../../../../Redux/AppSlice";
import { toast } from "react-toastify";

// Prime React Components
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { Dialog } from "primereact/dialog";
import { Badge } from "primereact/badge";
import moment from "moment/moment";

// ------------------------------

// ----------Assets Import------------
import editIcon from "../../../../assets/images/editIcon.jpg";
import approveIcon from "../../../../assets/images/approve.png";
import rejectIcon from "../../../../assets/images/reject.png";
import pendingIcon from "../../../../assets/images/Pending.png";
import {
  AddInvoice,
  DeleteInvoice,
  EditInvoice,
  GetLoungeStaffInvoices,
  GetVipIds,
  ViewInvoice,
} from "../../../../Services/VIPLoungeStaffService";
import { GetActiveStores } from "../../../../Services/ShopAndDropService";
import { Calendar } from "primereact/calendar";
import { CiImageOn } from "react-icons/ci";
import CustomWebcam from "../../../Camera/Camera";
import {
  BulkUploadBase64Files,
  DownloadFile,
  getAttachmentFile,
} from "../../../../Services/Files";
import { AutoComplete } from "primereact/autocomplete";
import Axios from "../../../../Helpers/Axios";
import { GetVIPUserDetail } from "../../../../Services/VIPLoungeStaffService";
import { exportDataInExcel, exportExcel, exportFilterdExcel } from "../../../../Services/ExportServices";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
// -------------------------------------------------------------

function LoungeStaffInvoiceList() {
  const [cookies, setCookie, removeCookie] = UseCookies();
  var isSupervisor =
    JSON.parse(JSON.stringify(cookies()?.roles))?.filter(
      (role) => role === "VIP_SUPERVISOR"
    )?.length !== 0;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  // Pagination
  const [first, setFirst] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  // Action button
  const [isVisible, setIsVisible] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [view, setView] = useState(false);
  const [fileModal, setFileModal] = useState({
    isOpen: false,
    attachments: [],
  });
  const [previewAttachment, setPreviewAttachment] = useState({
    isOpen: false,
    attachmentUrl: "",
  });
  const [isEdit, setIsEdit] = useState(false);
  const [creditBalance, setCreditBalance] = useState(null);

  // Search && Filter
  const [searchFilterValue, setSearchFilterValue] = useState("");

  // Userlist
  const [InvoiceList, setInvoicesList] = useState([]);
  const [tempInvoiceList, setTempInvoicesList] = useState([]);
  const [isValidErrMsg, setIsValidErrMsg] = useState(false);
  const [previewLoader, setPreviewLoader] = useState({
    id: "",
    isLoad: false,
  });

  // Invoice Detail
  const invoiceDetailInitialValue = {
    vipId: "",
    vipnumber: "",
    name: "",
    email: "",
    invoiceNumber: "",
    vipCodeOrMobileNumber: null,
    dateOfPurchase: new Date(),
    purchaseAmount: "",
    vipName: "",
    fileDetails: [],
    storeName: "",
    mobileNumber: "",
    tempID: 1,
  };
  const [invoiceDetail, setInvoiceDetail] = useState({
    ...invoiceDetailInitialValue,
  });
  const [invalid, setInvalid] = useState(false);
  console.log("invoiceDetail", invoiceDetail);

  // Add Invoice Loader
  const [addLoading, setAddLoading] = useState(false);
  const [viewLoading, setViewLoading] = useState(false);

  // Attachments and  Camera
  const [image, setImage] = useState([]);
  const [imgSrc, setImgSrc] = useState("");
  const [isCameraVisible, setIsCameraVisible] = useState(false);
  const [VIPUsersIDs, setVIPUsersIDs] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [minDate, setMinDate] = useState(new Date().setMonth() - 3);


  //exportObj

  const [exportObj, setExportObj] = useState({
    fromDate: "",
    toDate: "",
    searchTerm: "",
    organization: "",
    isModalVisible: false
  });
  // Pagination functionality
  const onPageChange = (event) => {
    setFirst(event.first);
    getInvoicesList(event.first, event.rows, searchFilterValue, exportObj?.fromDate, exportObj?.toDate);
  };

  // Get Invoices List
  const getInvoicesList = async (skip = 0, take = 10, searchTerm = "", startDate = "", endDate = "") => {
    setIsLoading(true);
    const formattedStartDate = startDate
      ? startDate.getFullYear() + '/' +
      (startDate.getMonth() + 1).toString().padStart(2, '0') + '/' +
      startDate.getDate().toString().padStart(2, '0')
      : "";

    const formatedEndDate = endDate
      ? endDate.getFullYear() + '/' +
      (endDate.getMonth() + 1).toString().padStart(2, '0') + '/' +
      endDate.getDate().toString().padStart(2, '0')
      : "";
    await GetLoungeStaffInvoices(skip, take, searchTerm, cookies().bearerToken, formattedStartDate, formatedEndDate)
      .then((res) => {
        //console.log("Response", res);
        if (res?.status === 200) {
          setTotalCount(res?.data?.totalRecords);
          setInvoicesList(res?.data?.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        toast.error(
          "There is issue occured in fetching user, could you please try again",
          { position: "top-right" }
        );
      });
  };

  const statusTemplate = (row) => {
    let status = row?.status;
    let imageIcon = "";
    if (status == "Submitted") {
      imageIcon = pendingIcon;
    } else if (status == "Rejected") {
      imageIcon = rejectIcon;
    } else if (status == "Approved") {
      imageIcon = approveIcon;
    }

    return (
      <>
        {imageIcon && (
          <div style={{ width: "30px", height: "30px" }}>
            <img style={{ width: "100%", height: "100%" }} src={imageIcon} />
          </div>
        )}
      </>
    );
  };

  const viewInvoiceDetails = async (invoiceId) => {
    setViewLoading(true);
    await ViewInvoice(invoiceId, cookies().bearerToken)
      .then((res) => {
        if (res?.status === 200) {
          console.log("Response", res);
          let tempArr = [];
          res?.data?.uploadedInvoices?.forEach((list) => {
            tempArr.push({
              vipnumber: list.vipnumber,
              invoiceNumber: list.invoiceNumber,
              dateOfPurchase: list.dateOfPurchase,
              purchaseAmount: list.purchaseAmount,
              storeName: { storeName: list.tenantName },
              fileDetails: list.fileDetails,
              vipcode: res?.data?.vipCode,
            });
          });
          setTempInvoicesList((prev) => [...tempArr]);
          setCreditBalance(res?.data?.creditBalance);
        }
      })
      .catch((error) => {
        toast.error(
          "There is issue occured in fetching user, could you please try again",
          { position: "top-right" }
        );
      })
      .finally(() => {
        setViewLoading(false);
      });
  };
  // Edit Invoice
  const editInvoice = async () => {
    if (
      invoiceDetail.vipnumber == "" ||
      invoiceDetail.invoiceNumber == "" ||
      invoiceDetail.dateOfPurchase == "" ||
      invoiceDetail.purchaseAmount == 0 ||
      invoiceDetail.storeName == "" ||
      invoiceDetail.fileDetails.length == 0
    ) {
      setInvalid(true);
      return;
    }
    setInvalid(false);
    setAddLoading(true);
    let data = {
      invoiceNumber: invoiceDetail?.vipnumber.toString(),
      dateOfPurchase: invoiceDetail?.dateOfPurchase,
      purchaseAmount: invoiceDetail?.purchaseAmount,
      storeName: invoiceDetail?.storeName,
      invoiceDetailId: invoiceDetail.id,
    };
    await EditInvoice(invoiceDetail?.id, data, cookies().bearerToken)
      .then((res) => {
        if (res?.status === 200) {
          toast.success("Invoice edited successfully", {
            position: "top-right",
          });
        }
      })
      .catch((error) => {
        toast.error(
          "There is issue occured in fetching user, could you please try again",
          { position: "top-right" }
        );
      })
      .finally(() => {
        setIsAdd(false);
        setIsVisible(false);
        setAddLoading(false);
        getInvoicesList();
      });
  };

  // Delete Invoice
  const deleteInvoice = async (invoiceId) => {
    await DeleteInvoice(invoiceId, cookies().bearerToken)
      .then((res) => {
        //console.log("Response", res);
        if (res?.status === 200) {
          toast.success("Invoice deleted successfully", {
            position: "top-right",
          });
          getInvoicesList();
          setIsVisible(false);
        }
      })
      .catch((error) => {
        toast.error(
          "There is issue occured in deleting invoice, could you please try again",
          { position: "top-right" }
        );
      });
  };
  const AddAttachments = async (id, moduleId, attachments) => {
    console.log("Image Date", invoiceDetail?.fileDetails);
    let data = {
      id: parseInt(id),
      module: "VIPInvoice",
      inspectionId: 0,
      files: [],
    };
    tempInvoiceList.forEach(async (inv) => {
      if (inv.invoiceNumber == moduleId) {
        inv.fileDetails?.forEach((file) => {
          data.files.push({
            fileName: file?.fileName,
            base64: file?.base64,
          });
        });
      }
    });
    await BulkUploadBase64Files(data, cookies().bearerToken)
      .then((res) => {
        if (res.status == 200) {
          //console.log(res);
          setInvoiceDetail((prev) => ({
            ...prev,
            ...invoiceDetailInitialValue,
          }));
          setTempInvoicesList([]);
          setIsVisible(false);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  const removeInvoiceFunction = (id) => {
    let removedList = tempInvoiceList.filter((rem) => rem.tempID !== id);
    setTempInvoicesList([...removedList]);
  };

  // Action buttons for view and edit

  const header = () => {
    return (
      <div className="container" style={{ textAlign: "center" }}>
        <h2>
          {" "}
          {view ? "View" : ""}
          {isEdit ? "Edit" : ""} Invoice Details
        </h2>
      </div>
    );
  };
  const getAllVipID = async (searchTerm) => {
    let vipIds = [];
    await GetVipIds(searchTerm || "", cookies()?.bearerToken).then((res) => {
      console.log(res?.data);
      res?.data?.forEach((id) => vipIds.push({ id: id }));
      setIsLoading(false);
    });
    setVIPUsersIDs([...vipIds]);
  };
  const getAllStoreList = async (searchTerm) => {
    let StoreList = [];
    await GetActiveStores(0, 100, searchTerm, cookies()?.bearerToken).then(
      (res) => {
        console.log(res?.data);
        res?.data?.data.forEach((id) =>
          StoreList.push({ id: id.id, storeName: id.name })
        );
        setIsLoading(false);
      }
    );
    setStoreList([...StoreList]);
  };

  const addInvoiceFunction = () => {
    if (
      !invoiceDetail?.vipId ||
      invoiceDetail?.invoiceNumber == "" ||
      invoiceDetail?.dateOfPurchase == "" ||
      invoiceDetail?.purchaseAmount == 0 ||
      invoiceDetail?.fileDetails.length == 0 ||
      !invoiceDetail?.storeName
    ) {
      setInvalid(true);
      return;
    } else {
      if (tempInvoiceList.length > 0) {
        let invoiceNumCondition = tempInvoiceList.some(
          (invoice) => invoice.invoiceNumber === invoiceDetail.invoiceNumber
        );
        if (invoiceNumCondition) {
          setInvalid(true);
        } else {
          setInvalid(false);
          setTempInvoicesList([...tempInvoiceList, invoiceDetail]);
          setInvoiceDetail({
            ...invoiceDetailInitialValue,
            vipId: invoiceDetail.vipId,
            tempID: invoiceDetail.tempID + 1,
          });
        }
      } else {
        setInvalid(false);
        setTempInvoicesList([...tempInvoiceList, invoiceDetail]);
        setInvoiceDetail({
          ...invoiceDetailInitialValue,
          vipId: invoiceDetail.vipId,
          tempID: invoiceDetail.tempID + 1,
          mobileNumber: invoiceDetail.mobileNumber,
          email: invoiceDetail.email,
          name: invoiceDetail.name,
        });
      }
    }
  };

  const deleteInvoiceFunction = (index) => {
    let filterInvoices = invoiceDetail.fileDetails.filter(
      (_, i) => i !== index
    );
    setInvoiceDetail({ ...invoiceDetail, fileDetails: [...filterInvoices] });
  };

  const submitAllInvoiceFunction = async () => {

    let tempArr = tempInvoiceList?.map((inv) => {
      return {
        invoiceNumber: inv.invoiceNumber,
        dateOfPurchase: inv.dateOfPurchase,
        purchaseAmount: parseFloat(inv.purchaseAmount),
        tenantID: inv?.storeName?.id,
      };
    });

    if (tempArr?.length == 0) {
      toast.info("Invoice can not be Empty");
      return
    }
    // setViewLoading(true);
    setAddLoading(true);
    setIsLoading(true);
    await AddInvoice(invoiceDetail?.vipId, [...tempArr], cookies().bearerToken)
      .then((res) => {
        //console.log("Response", res);
        if (res?.status === 200) {
          if (res?.data?.httpStatusCode === 400) {
            toast.warning(res?.data?.message, {
              position: "top-right",
            });
          } else {
            setInvoiceDetail(res?.data);
            // AddAttachments(res?.data?.id);
            setIsVisible(false);
            Object.keys(res?.data?.id)?.forEach((_, index) => {
              AddAttachments(
                _,
                res?.data?.id[_],
                tempInvoiceList?.[index]?.fileDetails
              );
            });
            toast.success("Invoice added successfully", {
              position: "top-right",
            });
          }
        }
      })
      .catch((error) => {
        toast.error(
          "There is issue occured in fetching user, could you please try again",
          { position: "top-right" }
        );
      })
      .finally(() => {
        setIsAdd(false);
        // setIsVisible(false);
        setIsLoading(false);
        setAddLoading(false);
        getInvoicesList();
      });
  };

  const searchVIPId = (event) => {
    getAllVipID(event.query);
  };
  const searchStore = (event) => {
    getAllStoreList(event.query);
  };

  useEffect(() => {
    // setIsLoading(true);
    getInvoicesList(0, 10, searchFilterValue);
    // setMinDate(minDate?.setMonth(minDate?.getMonth() - 3));
  }, []);

  useEffect(() => {
    console.log("Base64", imgSrc.split(";base64,"));
    if (imgSrc) {
      let imageData = imgSrc.split(";base64,")[1];
      let fileNameExtension = imgSrc.split(";base64,")[0].split("/")[1];
      let fileModule = "VIPInvoice";
      let filedate =
        new Date().getFullYear().toString() +
        new Date().getMonth().toString() +
        new Date().getDate().toString();
      let length =
        invoiceDetail.fileDetails.length > 0
          ? Math.max(...invoiceDetail.fileDetails.map((o) => o.id)) + 1
          : 1;
      let fileName =
        fileModule + "-" + length + "-" + filedate + "." + fileNameExtension;
      setInvoiceDetail((prev) => ({
        ...prev,
        fileDetails: [
          ...prev.fileDetails,
          {
            fileName: fileName,
            base64: imageData,
            id:
              prev.fileDetails.length > 0
                ? Math.max(...prev.fileDetails.map((o) => o.id)) + 1
                : 1,
          },
        ],
      }));
    } else {
      setInvoiceDetail((prev) => ({ ...prev, fileDetails: [] }));
    }
    //console.log("Image ", imgSrc);
  }, [imgSrc]);


  const downloadFile = (_) => {
    DownloadFile(_.fileName, _.blobUrl, cookies()?.bearerToken);
  };


  const previewFileFunction = (url) => {
    console.log(url);
    setPreviewLoader({ id: url.fileName, isLoad: true });
    let data = [
      {
        fileName: url.fileName,
        azureBlobUrl: url.blobUrl.replaceAll("\\", "/"),
      },
    ];
    getAttachmentFile(data, cookies()?.bearerToken)
      .then((res) => {
        console.log(res);
        setPreviewAttachment({
          isOpen: true,
          attachmentUrl: res?.data?.[0].base64,
        });
        setPreviewLoader({ id: "", isLoad: false });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const actionButtonTemplate = (row) => {
    return (
      <>
        <div style={{ display: "flex", gap: "15px", justifyContent: "center" }}>
          <div
            onClick={(e) => {
              setIsVisible(true);
              setView(true);
              setIsEdit(false);
              viewInvoiceDetails(parseFloat(row?.id));
            }}
          >
            <i
              style={{ fontSize: "1rem", cursor: "pointer" }}
              className="pi pi-eye"
            />
          </div>
          {row?.invoiceStatus == "Rejected" && (
            <div
              onClick={(e) => {
                setIsVisible(true);
                setIsEdit(true);
                setView(false);
                viewInvoiceDetails(row?.id);
              }}
            >
              <img src={editIcon} style={{ cursor: "pointer" }} />
            </div>
          )}
        </div>
      </>
    );
  };

  const getStatusSeverity = (row) => {
    let statusSeverity = "";
    if (row.invoiceStatus === "Submitted") {
      statusSeverity = "info";
    } else if (row.invoiceStatus === "Rejected") {
      statusSeverity = "danger";
    } else if (row.invoiceStatus === "Approved") {
      statusSeverity = "success";
    }
    return statusSeverity;
  };
  // Convert the status from server to the format for UI
  const statusBodyTemplate = (row) => {
    return (
      <div className="delivery-status">
        <Badge value={row.invoiceStatus} severity={getStatusSeverity(row)} />
      </div>
    );
  };
  // Convert the date from server to the format date for UI
  const attachmentsBodytemplate = (row) => {
    return (
      <div>
        <p style={{ fontSize: "1rem", fontWeight: 400 }}>
          {/* {row.fileDetails?.[0]?.fileName}
          {row.fileDetails.length > 1 ? (
            <span style={{ fontSize: "13px" }}>
              {" ..+" + (row.fileDetails.length - 1)}
            </span>
          ) : null}
          {row.fileDetails.length > 1 ? (
            <i
              className="pi pi-file"
              style={{ color: "red", fontSize: "10px" }}
            ></i>
          ) : null} */}
          <i
            className="pi pi-paperclip"
            style={{ color: "red", fontSize: "1rem", cursor: "pointer" }}
            onClick={() =>
              setFileModal({ isOpen: true, attachments: row.fileDetails })
            }
          ></i>
        </p>
      </div>
    );
  };
  const addInvoiceActionBodyTemplate = (row) => {
    return (
      <div>
        {!view ? (
          <i
            className="pi pi-trash"
            style={{ color: "red", fontSize: "1rem", cursor: "pointer" }}
            onClick={() => removeInvoiceFunction(row.tempID)}
          ></i>
        ) : (
          <i
            className="pi pi-eye"
            style={{ color: "green", fontSize: "1rem", cursor: "pointer" }}
          />
        )}
      </div>
    );
  };
  const dateBodytemplate = (row) => {
    let createDate = row.createdDate?.split("/");
    let purchaseDate = view
      ? row.dateOfPurchase?.split("/")
      : row.dateOfPurchase;
    const dateTime = row.createdDate
      ? new Date(`${createDate[2]}-${createDate[1]}-${createDate[0]}`)
      : view
        ? new Date(`${purchaseDate[2]}-${purchaseDate[1]}-${purchaseDate[0]}`)
        : new Date(purchaseDate);

    const year = dateTime.getFullYear();
    const month = dateTime.getMonth() + 1;
    const day = dateTime.getDate();
    return (
      <div>
        <p style={{ fontSize: "1rem", fontWeight: 400 }}>
          {day + "/" + month + "/" + year}
        </p>
      </div>
    );
  };
  const StoreBodytemplate = (row) => {
    return (
      <div>
        <p style={{ fontSize: "1rem", fontWeight: 400 }}>
          {row.storeName.storeName}
        </p>
      </div>
    );
  };

  const fileNameBodyTemp = (row) => {
    return (
      <p
        style={{ cursor: "pointer" }}
        onClick={() => {
          downloadFile(row);
        }}
      >
        {row.fileName}
      </p>
    );
  };
  const attachmentsActionBodyTemp = (row) => {
    console.log(row);
    return (
      <div>
        {previewLoader.id == row.fileName && previewLoader.isLoad ? (
          <i className="pi pi-spin pi-spinner" style={{ fontSize: "1rem" }}></i>
        ) : (
          <i
            className="pi pi-eye"
            style={{ fontSize: "1rem", cursor: "pointer" }}
            onClick={() => {
              view
                ? previewFileFunction(row)
                : setPreviewAttachment({
                  isOpen: true,
                  attachmentUrl: row.base64,
                });
            }}
          />
        )}
      </div>
    );
  };

  const getTotalAmount = () => {
    let total = 0;
    tempInvoiceList.forEach((val) => {
      if (val && val?.purchaseAmount) {
        total += Number(val?.purchaseAmount);
      }
    });

    return total;
  };

  const getvipDetails = async (vipCodeOrMobileNumber) => {
    await GetVIPUserDetail(vipCodeOrMobileNumber, cookies()?.bearerToken)
      .then((res) => {
        console.log("vip res", res);
        if (res?.status) {
          setInvoiceDetail((prev) => ({
            ...prev,
            vipId: res?.data?.vipcode,
            name: res?.data?.fullName,
            email: res?.data?.emailAddress,
            mobileNumber: res?.data?.mobileNumber,
          }));
          setIsValidErrMsg(false);
        } else {
          toast.warning("User Not Found", {
            position: "top-right",
          });
          setInvoiceDetail((prev) => ({
            ...prev,
            vipId: null,
            name: "",
            email: "",
            mobileNumber: "",
          }));
          setIsValidErrMsg(true);
        }
      })
      .catch((err) => {
        toast.error(
          "There is issue occured in fetching user, could you please try again",
          { position: "top-right" }
        );
      });
  };

  // const exportLoungeSupervisorInvoices = () => {
  //   exportDataInExcel(`GetInvoiceReportForLoungeStaff`, cookies().bearerToken);
  // }
  const onExportInvoice = () => {
    const fromDate = !exportObj?.fromDate ? "" : `${new Date(exportObj?.fromDate)?.getFullYear()}-${(new Date(exportObj?.fromDate)?.getMonth() + 1)?.toString()?.padStart(2, "0")}-${new Date(exportObj?.fromDate)?.getDate()?.toString()?.padStart(2, "0")}`;
    const toDate = !exportObj?.toDate ? "" : `${new Date(exportObj?.toDate)?.getFullYear()}-${(new Date(exportObj?.toDate)?.getMonth() + 1)?.toString()?.padStart(2, "0")}-${new Date(exportObj?.toDate)?.getDate()?.toString()?.padStart(2, "0")}`;

    // let urlRoute=`GetInvoiceReportForLoungeStaff?strUserId=${cookies()?.userId}&from=${fromDate}&to=${toDate}`;
    // console.log(urlRoute);
    // exportExcel(urlRoute);

    const urlRoute = `VIPLounge/VIPLoungeStaff/ViewInvoiceReferences?searchTerm=${searchFilterValue || ""}&skip=0&take=${totalCount}&startDate=${fromDate}&toDate=${toDate}&forExport=true`;
    exportFilterdExcel(urlRoute, cookies()?.bearerToken);
  }

  function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }
  const onUpload = (e) => {
    if (e.files.length == 0) {
      return
    }
    if (e.files.length > 1) {
      e.files.forEach((_) => {
        blobToBase64(_)
          .then((res) => {
            setImgSrc(res);
          })
          .catch(() => {
            // setImgSrc("");
          });
        e.files.pop();
      })
    }
    else {
      blobToBase64(e.files[0])
        .then((res) => {
          setImgSrc(res);
        })
        .catch(() => {
          setImgSrc("");
        });
      e.files.pop();
    }
  };
  return (
    <>
      <div className="SupervisorInvoiceList">
        {/* <Dialog
          header={header}
          visible={isVisible}
          style={{ width: "45vw" }}
          onHide={() => {
            setIsVisible(false);
            setInvoiceDetail(invoiceDetailInitialValue);
            setInvalid(false);
            setView(false);
            setIsEdit(false);
            setIsAdd(false);
          }}
        >
          {viewLoading ? (
            <div
              className="spinner spinner-center"
              style={{ display: "flex", margin: "auto" }}
            ></div>
          ) : (
            <>
              <div
                className="container"
                style={{
                  textAlign: "left",
                  display: "flex",
                  gap: "10px",
                  flexWrap: "wrap",
                }}
              >
                <div className="input-box">
                  <p style={{ fontWeight: 400 }}>
                    VIP Id<span style={{ color: "red" }}>*</span>
                  </p>
                  <InputText
                    placeholder="Enter VIP id"
                    disabled={view}
                    value={invoiceDetail?.vipnumber}
                    onChange={(e) => {
                      setInvoiceDetail((prev) => ({
                        ...prev,
                        vipnumber: e.target.value,
                      }));
                    }}
                  />
                  {invalid && invoiceDetail?.vipnumber == "" && (
                    <p style={{ color: "red" }}>VIP id is required</p>
                  )}{" "}
                </div>
                <div className="input-box">
                  <p style={{ fontWeight: 400 }}>
                    Invoice No<span style={{ color: "red" }}>*</span>
                  </p>
                  <InputText
                    placeholder="Enter invoice No"
                    disabled={view}
                    value={invoiceDetail?.invoiceNumber}
                    onChange={(e) => {
                      setInvoiceDetail((prev) => ({
                        ...prev,
                        invoiceNumber: e.target.value,
                      }));
                    }}
                  />
                  {invalid && invoiceDetail?.invoiceNumber == "" && (
                    <p style={{ color: "red" }}>Invoice number is required</p>
                  )}
                </div>
                <div className="input-box">
                  <p style={{ fontWeight: 400 }}>
                    Date<span style={{ color: "red" }}>*</span>
                  </p>
                  <Calendar
                    placeholder="Select Invoice date"
                    disabled={view}
                    className="calendar"
                    style={{ width: "206px" }}
                    showIcon
                    value={new Date(invoiceDetail?.dateOfPurchase)}
                    onChange={(e) =>
                      setInvoiceDetail((prev) => ({
                        ...prev,
                        dateOfPurchase: e.value,
                      }))
                    }
                    maxDate={new Date()}
                  />
                  {invalid && invoiceDetail?.dateOfPurchase == "" && (
                    <p style={{ color: "red" }}>Invoice date is required</p>
                  )}
                </div>
                <div className="input-box">
                  <p style={{ fontWeight: 400 }}>
                    Brand/Store Name<span style={{ color: "red" }}>*</span>
                  </p>
                  <InputText
                    placeholder="Enter Brand/Store name"
                    disabled={view}
                    style={{ width: "206px" }}
                    showIcon
                    value={invoiceDetail?.storeName}
                    onChange={(e) =>
                      setInvoiceDetail((prev) => ({
                        ...prev,
                        storeName: e.target.value,
                      }))
                    }
                  />
                  {invalid && invoiceDetail?.storeName == "" && (
                    <p style={{ color: "red" }}>Store/Brand name is required</p>
                  )}
                </div>
                <div className="input-box">
                  <p style={{ fontWeight: 400 }}>
                    Price<span style={{ color: "red" }}>*</span>
                  </p>
                  <InputText
                    placeholder="Enter invoice Price"
                    disabled={view}
                    value={invoiceDetail?.purchaseAmount}
                    type="number"
                    min={0}
                    onChange={(e) => {
                      setInvoiceDetail((prev) => ({
                        ...prev,
                        purchaseAmount: e.target.value,
                      }));
                    }}
                  />
                  {invalid && invoiceDetail?.purchaseAmount == 0 && (
                    <p style={{ color: "red" }}>Invoice price is required</p>
                  )}
                </div>
              </div>
              <div className="container">
                <p style={{ fontWeight: 400 }}>
                  Attachments<span style={{ color: "red" }}>*</span>
                  
                  {!view && (
                    <i
                      className="pi pi-camera"
                      style={{
                        marginLeft: "10px",
                        color: "#ae9d81",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setIsCameraVisible(true);
                      }}
                    ></i>
                  )}
                </p>

                <div
                  style={{
                    border: isSupervisor
                      ? "1px solid #cbc5bb"
                      : "1px solid #ae9d81",
                    width: "206px",
                    height: "75px",
                    borderRadius: "5px",
                    color: isSupervisor ? "#9399a1" : "",
                  }}
                >
                  
                  {invoiceDetail?.fileDetails?.map((item, index) => {
                    return (
                      <p
                        style={{
                          textAlign: "left",
                          margin: "10px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {index + 1}.{item?.fileName}
                      </p>
                    );
                  })}
                
                </div>
                {invalid && invoiceDetail?.fileDetails?.length == 0 && (
                  <p style={{ color: "red" }}>File is required</p>
                )}
              </div>
              <div className="btn-container">
                <Button
                  label="Cancel"
                  className="p-button"
                  style={{
                    background: "rgb(174, 157, 129)",
                    border: "none",
                    width: "120px",
                  }}
                  onClick={() => {
                    setIsVisible(false);
                    setInvoiceDetail((prev) => ({
                      ...prev,
                      ...invoiceDetailInitialValue,
                    }));
                    setInvalid(false);
                    setIsAdd(false);
                    setView(false);
                  }}
                />
                {!view && (
                  <Button
                    label="Submit"
                    loading={addLoading}
                    className="p-button"
                    style={{
                      background: "rgb(142, 142, 142)",
                      border: "none",
                      width: "120px",
                    }}
                    onClick={isAdd ? addInvoice : isEdit ? editInvoice : ""}
                  />
                )}
              </div>
            </>
          )}
        </Dialog> */}
        <Dialog
          className="preview-Attachment-Popup"
          visible={previewAttachment.isOpen}
          onHide={() => {
            setPreviewAttachment({ isOpen: false, attachmentUrl: "" });
          }}
          style={{ width: "50%" }}
          header={
            <h3 style={{ textAlign: "center", color: "#ae9d81", margin: 0 }}>
              Preview
            </h3>
          }
        >
          <img
            src={"data:image/jpeg;base64," + previewAttachment.attachmentUrl}
            style={{ height: 400, width: 600 }}
          ></img>
        </Dialog>
        <Dialog
          className="add-invoice-popup"
          visible={fileModal.isOpen}
          onHide={() => {
            setFileModal({ isOpen: false, attachments: [] });
          }}
          style={{ width: "50%" }}
          header={
            <h3 style={{ textAlign: "center", color: "#ae9d81", margin: 0 }}>
              Attachments
            </h3>
          }
        >
          <DataTable value={fileModal?.attachments}>
            <Column
              field="fileName"
              header="File Name"
              body={fileNameBodyTemp}
            />
            <Column
              field="fileName"
              header="Preview"
              body={attachmentsActionBodyTemp}
            />
          </DataTable>
        </Dialog>
        <Dialog
          className="add-invoice-popup"
          visible={isVisible}
          onHide={() => {
            setIsVisible(false);
            setInvoiceDetail((prev) => ({
              ...prev,
              ...invoiceDetailInitialValue,
            }));
            setInvalid(false);
            setIsAdd(false);

            setIsValidErrMsg(false);
            setView(false);
            setTempInvoicesList([]);
            setIsLoading(false);
          }}
          style={{ width: "80%" }}
          header={
            <h3 style={{ textAlign: "center", color: "#ae9d81", margin: 0 }}>
              {view ? "View " : "Add "}Invoice Details
            </h3>
          }
        >
          <div className="Invoice-details-modal">
            {!view && <div className="common-details-content"></div>}
            {!view && (
              <div className="Invoice-details-content">
                <div className="input-box">
                  <div className="input-label">
                    <p style={{ fontWeight: 400 }}>
                      Vip id / Mobile number
                      <span style={{ color: "red" }}>*</span>
                    </p>
                  </div>
                  <div className="input-text" style={{ position: "relative" }}>
                    {/* <AutoComplete
                      value={invoiceDetail?.vipId}
                      suggestions={VIPUsersIDs}
                      completeMethod={searchVIPId}
                      virtualScrollerOptions={{ itemSize: 38 }}
                      field="id"
                      dropdown
                      onChange={(e) => {
                        console.log("e", e);
                        setInvoiceDetail((prev) => ({
                          ...prev,
                          vipId: e.value,
                        }));
                        let tempInvoice = tempInvoiceList.map((inv) => {
                          inv.vipId = e.value;
                          return inv;
                        });
                        setTempInvoicesList([...tempInvoiceList]);
                      }}
                    /> */}
                    <div
                      style={{ width: "100%", display: "flex", gap: "10px" }}
                    >
                      <InputText
                        style={{ width: "85%" }}
                        placeholder="Enter here"
                        disabled={view}
                        value={invoiceDetail?.vipCodeOrMobileNumber}
                        onChange={(e) => {
                          setIsValidErrMsg(false);
                          setInvoiceDetail((prev) => ({
                            ...prev,
                            vipCodeOrMobileNumber: e?.target?.value,
                          }));
                        }}
                      />
                      <Button
                        icon="pi pi-search"
                        style={{
                          color: "black",
                          background: "transparent",
                          border: "none",
                          backgroundColor: "#ae9d81",
                          color: "white",
                        }}
                        onClick={() =>
                          invoiceDetail?.vipCodeOrMobileNumber &&
                          getvipDetails(invoiceDetail?.vipCodeOrMobileNumber)
                        }
                      // loading={profileLoading}
                      />
                    </div>
                    {invalid && (
                      <p style={{ color: "red" }}>
                        {!invoiceDetail?.vipCodeOrMobileNumber
                          ? "VIP id is required"
                          : isValidErrMsg && !invoiceDetail?.vipId
                            ? "Enter a valid id or mobile number"
                            : ""}
                      </p>
                    )}
                  </div>
                </div>
                <div className="vipDeatils">
                  <div className="">
                    <p className="vipDeatilsLabel">Name</p>
                    <p className="vipDeatilsLabel">Mobile number</p>
                  </div>
                  <div className="">
                    <p className="vipDeatilsLabel">
                      {": " + invoiceDetail?.name}
                    </p>
                    <p className="vipDeatilsLabel">
                      {": " + invoiceDetail?.mobileNumber}
                    </p>
                  </div>
                  {/* <div className="input-text" style={{ position: "relative" }}>
                    <InputText disabled value={invoiceDetail?.name} />
                  </div> */}
                </div>
                <div className="vipDeatils">
                  <div className="">
                    <p className="vipDeatilsLabel">VIP code</p>
                    <p className="vipDeatilsLabel">Email id</p>
                  </div>
                  <div className="">
                    <p className="vipDeatilsLabel">
                      {invoiceDetail?.vipId
                        ? ": " + invoiceDetail?.vipId
                        : ": "}
                    </p>
                    <p className="vipDeatilsLabel">
                      {": " + invoiceDetail?.email}
                    </p>
                  </div>
                  {/* <div className="input-text" style={{ position: "relative" }}>
                    <InputText disabled value={invoiceDetail?.email} />
                  </div> */}
                </div>
                <div className="input-box">
                  <div className="input-label">
                    <p style={{ fontWeight: 400 }}>
                      Tenant name<span style={{ color: "red" }}>*</span>
                    </p>
                  </div>
                  <div className="input-text">
                    <AutoComplete
                      value={invoiceDetail?.storeName}
                      suggestions={storeList}
                      completeMethod={searchStore}
                      virtualScrollerOptions={{ itemSize: 38 }}
                      field="storeName"
                      dropdown
                      onChange={(e) => {
                        console.log("e", e);
                        setInvoiceDetail((prev) => ({
                          ...prev,
                          storeName: e.value,
                        }));
                        setTempInvoicesList([...tempInvoiceList]);
                      }}
                    />
                    {/* <InputText
                      placeholder="Enter Store Name"
                      disabled={view}
                      value={invoiceDetail?.storeName}
                      onChange={(e) => {
                        setInvoiceDetail((prev) => ({
                          ...prev,
                          storeName: e.target.value,
                        }));
                      }}
                    /> */}
                    {invalid && invoiceDetail?.storeName == "" && (
                      <p style={{ color: "red" }}>Tenant name is required</p>
                    )}
                  </div>
                </div>
                <div className="input-box">
                  <div className="input-label">
                    <p style={{ fontWeight: 400 }}>
                      Invoice number<span style={{ color: "red" }}>*</span>
                    </p>
                  </div>
                  <div className="input-text">
                    <InputText
                      placeholder="Enter Invoice Number"
                      disabled={view}
                      value={invoiceDetail?.invoiceNumber}
                      onChange={(e) => {
                        setInvoiceDetail((prev) => ({
                          ...prev,
                          invoiceNumber: e.target.value,
                        }));
                      }}
                    />
                    {invalid && invoiceDetail?.invoiceNumber == "" && (
                      <p style={{ color: "red" }}>Invoice number is required</p>
                    )}
                    {invalid &&
                      tempInvoiceList.some(
                        (inv) =>
                          inv.invoiceNumber === invoiceDetail.invoiceNumber
                      ) && (
                        <p style={{ color: "red" }}>
                          Invoice number is already exists
                        </p>
                      )}
                  </div>
                </div>

                <div className="input-box">
                  <div className="input-label">
                    <p style={{ fontWeight: 400 }}>
                      Date of invoice<span style={{ color: "red" }}>*</span>
                    </p>
                  </div>
                  <div className="input-text">
                    <Calendar
                      placeholder="Select Invoice date"
                      disabled={view}
                      className="calendar"
                      // style={{ width: "206px" }}
                      showIcon
                      value={
                        invoiceDetail?.dateOfPurchase
                          ? new Date(invoiceDetail?.dateOfPurchase)
                          : new Date()
                      }
                      onChange={(e) =>
                        setInvoiceDetail((prev) => ({
                          ...prev,
                          dateOfPurchase: new Date(e.value).toISOString(),
                        }))
                      }
                      minDate={new Date(minDate)}
                      maxDate={new Date()}
                    />
                    {invalid && invoiceDetail?.dateOfPurchase == "" && (
                      <p style={{ color: "red" }}>Invoice date is required</p>
                    )}
                  </div>
                </div>
                <div className="input-box">
                  <div className="input-label">
                    <p style={{ fontWeight: 400 }}>
                      Purchase amount<span style={{ color: "red" }}>*</span>
                    </p>
                  </div>
                  <div className="input-text">
                    <InputText
                      placeholder="Enter invoice Price"
                      disabled={view}
                      value={invoiceDetail?.purchaseAmount}
                      type="number"
                      min={0}
                      onChange={(e) => {
                        setInvoiceDetail((prev) => ({
                          ...prev,
                          purchaseAmount: e.target.value,
                        }));
                      }}
                    />
                    {invalid && invoiceDetail?.purchaseAmount == 0 && (
                      <p style={{ color: "red" }}>Invoice price is required</p>
                    )}
                  </div>
                </div>
                <div className="input-box">
                  <div className="input-label">
                    <p
                      style={{
                        fontWeight: 400,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Attachments<span style={{ color: "red" }}>*</span>
                      {!view && (
                        <i
                          className="pi pi-camera"
                          style={{
                            marginLeft: "10px",
                            color: "#ae9d81",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setIsCameraVisible(true);
                          }}
                        ></i>
                      )}
                      {!view && (
                        <div className="mx-4">
                          <FileUpload className="customeUpload" chooseLabel="File upload" mode='basic' accept="image/*" multiple={true} maxFileSize={1000000} customUpload uploadHandler={onUpload} />
                        </div>
                      )}
                    </p>
                  </div>
                  <div
                    className="input-text"
                    style={{
                      display: "flex",
                      height:
                        invoiceDetail?.fileDetails?.length < 5
                          ? invoiceDetail?.fileDetails?.length > 3
                            ? "auto"
                            : "76px"
                          : "120px",
                    }}
                  >
                    <div
                      style={{
                        border: isSupervisor
                          ? "1px solid #cbc5bb"
                          : "1px solid #ae9d81",
                        width: "100%",
                        borderRadius: "5px",
                        color: isSupervisor ? "#9399a1" : "",
                        height:
                          invoiceDetail?.fileDetails?.length < 5
                            ? "auto"
                            : "120px",
                        overflow: "auto",
                      }}
                    >
                      {/* <p style={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}>No File Selected</p> */}
                      {invoiceDetail?.fileDetails?.map((item, index) => {
                        return (
                          <span
                            style={{
                              margin: "3px 10px",
                              alignItems: "center",
                              display: "flex",
                              fontSize: "15px",
                            }}
                          >
                            {index + 1}.{item?.fileName}
                            <i
                              className="pi pi-times-circle"
                              style={{
                                fontSize: "1em",
                                margin: "5px 0px 0px 10px",
                                color: "red",
                                cursor: "pointer",
                              }}
                              onClick={() => deleteInvoiceFunction(index)}
                            ></i>
                          </span>
                        );
                      })}
                      {/* <p style={{ textAlign: "left", margin: "10px" }}>1.attachment<span style={{ marginLeft: "5px" }}>x</span></p> */}
                    </div>
                  </div>
                  {invalid && invoiceDetail?.fileDetails?.length == 0 && (
                    <p style={{ color: "red" }}>Attachments is required</p>
                  )}
                </div>
              </div>
            )}
            {!view && (
              <div className="Invoice-add">
                <Button
                  label="Add"
                  icon="pi pi-plus"
                  onClick={() => addInvoiceFunction()}
                  style={{
                    backgroundColor: "#ae9d81",
                    border: "none",
                    width: "100px",
                    padding: "5px 20px",
                  }}
                />
              </div>
            )}
            <div>
              {viewLoading ? (
                <div
                  className="spinner spinner-center"
                  style={{
                    display: "flex",
                    margin: "auto",
                    marginTop: "200px",
                  }}
                ></div>
              ) : (
                <div
                  className="Invoice-data"
                  style={{
                    overflowY: tempInvoiceList?.length > 3 ? "auto" : "visible",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    height: tempInvoiceList?.length > 3 ? "165px" : "auto",
                    width: "100%",
                  }}
                >
                  <DataTable
                    value={tempInvoiceList}
                    style={{
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      width: "100%",
                    }}
                    tableStyle={{ width: "100%" }}
                    stripedRows
                    rows={10}
                    emptyMessage={
                      <>
                        <p style={{ textAlign: "center" }}>
                          Invoice list is empty
                        </p>
                      </>
                    }
                  >
                    {
                      <Column
                        field={view ? "vipcode" : "vipId"}
                        header="VIP code"
                        style={{ width: "15%" }}
                      ></Column>
                    }
                    <Column
                      field="invoiceNumber"
                      header="Invoice no"
                      style={{ width: "15%" }}
                    ></Column>
                    <Column
                      field="storeName"
                      header="Tenant name"
                      style={{ width: "15%" }}
                      body={StoreBodytemplate}
                    ></Column>
                    <Column
                      field="dateOfPurchase"
                      header="Date of invoice"
                      style={{ width: "15%" }}
                      body={dateBodytemplate}
                    ></Column>
                    <Column
                      field="purchaseAmount"
                      header="Purchase amount"
                      style={{ width: "15%" }}
                    ></Column>
                    <Column
                      field="company"
                      header="Attachments"
                      style={{ width: "10%" }}
                      body={attachmentsBodytemplate}
                    ></Column>
                    {/* <Column
                  field="invoiceStatus"
                  header="InvoiceStatus"
                  style={{ width: "15%" }}
                  body={statusBodyTemplate}
                ></Column> */}
                    {!view && (
                      <Column
                        field="company"
                        header="Action"
                        style={{ width: "10%" }}
                        body={addInvoiceActionBodyTemplate}
                      ></Column>
                    )}
                  </DataTable>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      padding: "10px 0px",
                      gap: "10px",
                    }}
                  >
                    <div>
                      <span style={{ color: "#ae9d81", fontWeight: 700 }}>
                        Total amount :{" "}
                      </span>
                      <span>{getTotalAmount()}</span>
                    </div>
                    {view && (
                      <div>
                        <span style={{ color: "#ae9d81", fontWeight: 700 }}>
                          Balance credit :{" "}
                        </span>
                        <span>{creditBalance ? creditBalance : 0}</span>
                      </div>
                    )}
                  </div>
                  <div className="Invoice-details-footer">
                    <Button
                      label="Cancel"
                      className="p-button"
                      style={{
                        background: "rgb(142, 142, 142)",
                        border: "none",
                        width: "100px",
                        padding: "5px 0px",
                      }}
                      onClick={() => {
                        setIsVisible(false);
                        setInvoiceDetail((prev) => ({
                          ...prev,
                          ...invoiceDetailInitialValue,
                        }));
                        setInvalid(false);
                        setIsAdd(false);
                        setView(false);
                        setTempInvoicesList([]);
                        setIsLoading(false);
                      }}
                    />
                    {!view && (
                      <Button
                        label={addLoading ? "" : "Submit"}
                        loading={addLoading}
                        className="p-button"
                        style={{
                          background: "rgb(174, 157, 129)",
                          border: "none",
                          width: "100px",
                          padding: "5px 0px",
                        }}
                        onClick={
                          // isAdd
                          // ?
                          submitAllInvoiceFunction
                          // : isEdit
                          // ? editInvoice
                          // : ""
                        }
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </Dialog>

        {isCameraVisible ? (
          <CustomWebcam
            setIsVisible={setIsCameraVisible}
            setImage={setImgSrc}
          />
        ) : null}
        {/* {imgSrc && <img src={imgSrc} style={{ width: '100px', height: '100px' }} ></img>} */}

        <div className="header">
          <div style={{ display: "flex" }}>
            <div className="menubtn" style={{ padding: "20px" }}>
              <i
                className="pi pi-align-justify"
                style={{
                  fontSize: "1rem",
                  color: "#ae9d81",
                  cursor: "pointer",
                }}
                onClick={() =>
                  dispatch(setNavVisibilty({ sideNavVisibility: true }))
                }
              ></i>
            </div>
            <div className="heading">
              <p>Invoices Details</p>
            </div>
          </div>
          <div className="right" style={{}}>

            <div className='mx-2'>
              <h4 className='mb-1 mt-0 relative' style={{ color: "#ae9d81" }}>From Date</h4>
              <Calendar className="customeInputText customeCalender" style={{ width: 170 }} value={exportObj?.fromDate} placeholder='Select' onChange={(e) => {
                setExportObj((prev) => ({ ...prev, fromDate: e.value }));
                getInvoicesList(0, 10, searchFilterValue, e.value, exportObj?.toDate);
              }} />
            </div>
            <div className='mx-2'>
              <h4 className='mb-1 mt-0 relative' style={{ color: "#ae9d81" }}>To Date</h4>
              <Calendar className="customeInputText customeCalender" style={{ width: 170 }} value={exportObj?.toDate} placeholder='Select' onChange={(e) => {
                setExportObj((prev) => ({ ...prev, toDate: e.value }));
                getInvoicesList(0, 10, searchFilterValue, exportObj?.fromDate, e.value);
              }} />
            </div>
            <div className="mx-2">
              <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Search</h4>
              <div className='flex align-items-center' style={{ border: "solid 1px #AE9D81", borderRadius: "4px" }}>
                <i className="pi pi-search px-2" style={{ color: 'black', fontWeight: '700', fontSize: '1rem' }} />
                <InputText value={searchFilterValue}
                  style={{ width: "170px", paddingLeft: 0 }}
                  onChange={(e) => {
                    setSearchFilterValue(e.target.value);
                    getInvoicesList(0, 10, e.target.value, exportObj?.fromDate, exportObj?.toDate);
                  }} placeholder="Search" className='border-0 customeSearch' />
                {searchFilterValue !== "" && (
                  <i className="pi pi-times px-2" style={{ color: 'black', fontWeight: '400', fontSize: '1rem', cursor: 'pointer', position: "block" }} onClick={() => { setSearchFilterValue(""); getInvoicesList(0, 10, "", exportObj?.fromDate, exportObj?.toDate); }} />
                )}
              </div>
              {/* <span className="p-input-icon-left p-input-icon-right">
                  <i
                    className="pi pi-search"
                    style={{
                      color: "black",
                      fontWeight: "700",
                      fontSize: "1rem",
                    }}
                  />
                  <InputText
                    placeholder="Search"
                    value={searchFilterValue}
                    onChange={(e) => {
                      setSearchFilterValue(e.target.value);
                      getInvoicesList(0, 10, e.target.value,exportObj?.fromDate,exportObj?.toDate);
                    }}
                  />
                  {searchFilterValue !== "" && (
                    <i
                      className="pi pi-times"
                      style={{
                        color: "black",
                        fontWeight: "400",
                        fontSize: "1rem",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSearchFilterValue("");getInvoicesList(0, 10, "",exportObj?.fromDate,exportObj?.toDate);
                      }}
                    />
                  )}
                </span> */}
            </div>
            <div className="mx-2 align-self-end">
              <Button
                label="Add Invoice"
                onClick={() => {
                  setIsLoading(true);
                  setIsVisible(true);
                  setIsAdd(true);
                  setIsEdit(false);
                  setInvoiceDetail((prev) => ({
                    ...prev,
                    ...invoiceDetailInitialValue,
                  }));
                }}
                style={{ backgroundColor: "#ae9d81", border: "none" }}
              />
            </div>
            <div className="mx-2 align-self-end">
              <Button style={{ backgroundColor: "#ae9d81", border: 0 }} onClick={() => {
                getInvoicesList(0, 10, "");
                setExportObj((prev) => ({ ...prev, fromDate: "", toDate: "" }));
                setSearchFilterValue("");
              }}>
                Clear All
              </Button>
            </div>
            <div className="mx-2 align-self-end">
              <Button
                label="Export"
                icon={"pi pi-file-export"}
                style={{
                  backgroundColor: "transparent",
                  color: "#ae9d81",
                  border: "1px #ae9d81 solid",
                  borderRadius: "20px",
                  cursor: "pointer",
                  height: "46px"
                }}
                onClick={() => { onExportInvoice() }}
              />
            </div>
          </div>
        </div>

        <div className="datatable">
          {isLoading ? (
            <div
              className="spinner spinner-center"
              style={{ display: "flex", margin: "auto", marginTop: "200px" }}
            ></div>
          ) : (
            <DataTable
              value={InvoiceList}
              style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
              tableStyle={{ width: "90rem", margin: "10px 39px" }}
              stripedRows
              rows={10}
              emptyMessage={
                <>
                  <p style={{ textAlign: "center" }}>Invoice list is empty</p>
                </>
              }
            >
              <Column field="vipCode" header="Vip Code"></Column>
              <Column field="referenceCode" header="Reference Number"></Column>
              {/* <Column field="vipcode" header="Reference Number"></Column> */}
              <Column
                field="createdDate"
                header="Uploaded Date"
                body={dateBodytemplate}
              ></Column>
              <Column field="createdBy" header="Created By"></Column>
              <Column
                field="overAllInvoiceAmount"
                header="Total Amount"
              ></Column>
              <Column
                field="uploadedInvoicesCount"
                header="Invoice Count"
              ></Column>
              <Column
                field="Status"
                header="Status"
                body={statusTemplate}
              ></Column>
              <Column
                field="uploadedInvoicesCount"
                header="Action"
                body={actionButtonTemplate}
                style={{ width: "5%" }}
              ></Column>
            </DataTable>
          )}
        </div>
        {isLoading ? (
          ""
        ) : (
          <div style={{ justifyContent: "center", display: "flex" }}>
            <Paginator
              first={first}
              rows={10}
              totalRecords={totalCount}
              onPageChange={(e) => onPageChange(e, 1)}
              template={{
                layout:
                  "RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport",
              }}
              className="justify-content-start"
            />
          </div>
        )}
        <Dialog visible={exportObj?.isModalVisible} header={`Export Invoice Details`} headerStyle={{ color: "#ae9d81", textAlign: "center" }} onHide={() => { setExportObj((prev) => ({ ...prev, isModalVisible: false })) }}>
          <div className='flex align-items-center flex-wrap'>

            <div className='mx-2'>
              <h4 className='mb-2'>From Date</h4>
              <Calendar value={exportObj?.fromDate} placeholder='Select from date' onChange={(e) => {
                setExportObj((prev) => ({ ...prev, fromDate: e.value }));
              }} />
            </div>

            <div className='mx-2'>
              <h4 className='mb-2'>To Date</h4>
              <Calendar value={exportObj?.toDate} placeholder='Select to date' onChange={(e) => {
                setExportObj((prev) => ({ ...prev, toDate: e.value }));
              }} />
            </div>
          </div>

          <div className='flex justify-content-center my-4'>
            <Button className='border-0'
              style={{ backgroundColor: "#ae9d81", width: 180, height: 40, display: "flex", justifyContent: "center" }}
              onClick={() => { onExportInvoice() }}>
              Submit
            </Button>
          </div>
        </Dialog>
      </div>
    </>
  );
}

export default LoungeStaffInvoiceList;
