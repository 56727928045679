import React, { useState, useEffect } from 'react';
import UseCookies from '../../Helpers/UseCookies';
import './style.scss'
// -------------Imports---------------
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Tag } from 'primereact/tag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'

// -----------------------------------


// ----------Assets Import------------
import editIcon from '../../../src/assets/images/editIcon.jpg';
// -----------------------------------


//----------------Service Imports--------------------------
//------------------------------------------------------------------

//------------Context------------------------

import { useDispatch, useSelector } from "react-redux";
import { addFeedbackChannelList, setNavVisibilty } from '../../Redux/AppSlice';
import { baseUrl } from '../../Helpers/Axios';
import { ExportOpenInNewTab, exportDataInExcel, exportExcel } from '../../Services/ExportServices';
import { FileUpload } from 'primereact/fileupload';
import Modalinfo from '../utili/Modalinfo';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { AddFeedbackChannel, ChannelNameExists, EditFeedbackChannel, GetFeedbackChannelList } from '../../Services/FeedbackChannelServices';

//------------------------------------------------------------------


function FeedbackChannel() {
    const [cookies, setCookie, removeCookie] = UseCookies();
    const Context = useSelector((state) => state.AppContext);
    const dispatch = useDispatch();
    const [feedbackChannelList, setFeedbackChannelList] = useState([]);
    const [isAddFeedbackChannelPopupOpen, setIsAddFeedbackChannelPopupOpen] = useState(false)
    const [isAddFeedbackChannel, setIsAddFeedbackChannel] = useState({
        "name": "",
        "nameInArabic": "",
        "isDeleted": false
    });
    const [isInvalid, setIsInvalid] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const navigate = useNavigate();
    const [isChannelAlreadyExist, setIsChannelAlreadyExist] = useState(false);
    const [isChannelARExist, setIsChannelARExist] = useState(false);

    const [isChannelValid, setIsChannelValid] = useState(false);
    const [isLoading, setIsLoading] = useState(true);




    // Export Filter
    const [exportFilterDialog, setExportFilterDialog] = useState(false);
    const [exportDetails, setExportDetails] = useState({
        startDate: "",
        endDate: "",
        organization: "",
        desk: "",
        status: true
    });


    // Filters
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [status, setStatus] = useState("");
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [totalCount, setTotalRecords] = useState(0)
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        // name: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const [isImportInvalid, setIsImportInvalid] = useState(false);
    const [isImport, setIsImport] = useState(false);
    const [isImportInvalidFile, setIsImportInvalidFile] = useState(false);
    const [isFileValidating, setFileValidating] = useState(false);
    const onCancelClick = () => {
        setShowConfirmation(true);
    };

    const onHide = () => {
        setShowConfirmation(false);
        setIsAddFeedbackChannelPopupOpen(false);
        setIsAddFeedbackChannel({
            "name": "",
            "nameInArabic": "",
            "isDeleted": false
        });
    };

    const handleConfirmation = (confirmed) => {
        if (confirmed) {
            onHide();
        } else {
            setShowConfirmation(false);
        }
    };

    const onGlobalFilterChange = (e) => {

        const value = e ? e.target.value : "";
        setGlobalFilterValue(value);
        getFeedbackChannelList(0, 10, value, status);
    };
    const onStatusFilterChange = (e) => {
        setStatus(e.target.value);
        getFeedbackChannelList(0, 10, globalFilterValue, e.target.value);
    };

    const back = () => {
        navigate('/dashboard');
    };



    const addFeedbackChannel = (() => {

        let data = {
            "name": isAddFeedbackChannel?.name,
            "nameInArabic": isAddFeedbackChannel?.nameInArabic,
            "isDeleted": isAddFeedbackChannel?.isDeleted
        }

        if (isAddFeedbackChannel?.name === "" || isAddFeedbackChannel?.nameInArabic === "") {
            setIsChannelValid(true);
            return;
        }
        setIsChannelValid(false);
        AddFeedbackChannel(data, cookies().bearerToken)
            .then(res => {
                if (res?.status == 200) {
                    setIsAddFeedbackChannelPopupOpen(false);
                    // getFeedbackChannelList();
                    // setIsAddFeedbackChannelPopupOpen(false);
                    setIsAddFeedbackChannel({
                        "name": "",
                        "nameInArabic": "",
                        "isDeleted": false,
                        "id": 0
                    });
                    setIsChannelAlreadyExist(false);
                    setIsChannelARExist(false);
                    setIsChannelValid(false);
                    getFeedbackChannelList();
                    toast.success("New Feedback Category is added Successfully", { position: "top-right" });
                } else {
                    if (res?.response?.data?.name == isAddFeedbackChannel?.name && res?.response?.data?.nameInArabic == isAddFeedbackChannel?.nameInArabic) {
                        toast.error("Feedback channel  is already exists.", { position: "top-right" });
                        setIsChannelAlreadyExist(true);
                        setIsChannelARExist(true);
                    }
                    else if (res?.response?.data?.nameInArabic == isAddFeedbackChannel?.nameInArabic) {
                        setIsChannelARExist(true);
                        toast.error("Feedback channel name(AR) is already exists.", { position: "top-right" });
                    }
                    else if (res?.response?.data?.name == isAddFeedbackChannel?.name) {
                        setIsChannelAlreadyExist(true);
                        toast.error("Feedback channel name is already exists.", { position: "top-right" });
                    }
                    else {
                        toast.error("Failed to add feedback channel. Please try after some time.", { position: "top-right" });
                    }
                }
            })
            .catch(err => {
                //console.log(err);
            })
    });

    const editFeedbackChannel = (() => {

        let data = {
            "name": isAddFeedbackChannel?.name,
            "nameInArabic": isAddFeedbackChannel?.nameInArabic,
            "isDeleted": isAddFeedbackChannel?.isDeleted,
            "id": isAddFeedbackChannel?.id
        }

        if (isAddFeedbackChannel?.categoryName === "" || isAddFeedbackChannel?.categoryNameInAr === "") {
            setIsChannelValid(true);
            return;
        }
        setIsChannelValid(false);
        EditFeedbackChannel(data, cookies().bearerToken)
            .then(res => {

                if (res?.status == 200) {
                    // setIsAddFeedbackChannelPopupOpen(false);
                    // getFeedbackChannelList();
                    setIsAddFeedbackChannel({
                        "name": "",
                        "nameInArabic": "",
                        "isDeleted": false,
                        "id": 0
                    })
                    setIsAddFeedbackChannelPopupOpen(false);
                    setIsChannelAlreadyExist(false);
                    setIsChannelARExist(false);
                    getFeedbackChannelList();
                    toast.success("Feedback Category is updated successfully", { position: "top-right" });
                } else {
                    if (res?.response?.data?.name == isAddFeedbackChannel?.name && res?.response?.data?.nameInArabic == isAddFeedbackChannel?.nameInArabic) {
                        toast.error("Feedback channel  is already exists.", { position: "top-right" });
                        setIsChannelAlreadyExist(true);
                        setIsChannelARExist(true);
                    }
                    else if (res?.response?.data?.nameInArabic == isAddFeedbackChannel?.nameInArabic) {
                        setIsChannelARExist(true);
                        toast.error("Feedback channel name(AR) is already exists.", { position: "top-right" });
                    }
                    else if (res?.response?.data?.name == isAddFeedbackChannel?.name) {
                        setIsChannelAlreadyExist(true);
                        toast.error("Feedback channel name is already exists.", { position: "top-right" });
                    }
                    else {
                        toast.error("Failed to edit feedback channel. Please try after some time.", { position: "top-right" });
                    }
                }
            })
            .catch(err => {
                //console.log(err);
            })

    })

    const statusBodyTemplate = ((row) => {
        // //console.log(row);
        if (row.isDeleted === false) {
            return <p style={{ color: "green" }}  >Active</p>
        }
        else {
            return <p style={{ color: "red" }} >Inactive</p>
        }
    });

    const actionButtonTemplate = (row) => {
        return (
            <div onClick={(e) => {
                setIsAdd(false);
                setIsAddFeedbackChannelPopupOpen(true);
                setIsAddFeedbackChannel({
                    "name": row.name,
                    "nameInArabic": row.nameInArabic,
                    "isDeleted": row.isDeleted,
                    "id": row.id

                })
            }}>
                <img src={editIcon} style={{ cursor: 'pointer' }} />
            </div>
        );
    };
    const header = () => {
        return <div className="heading" style={{ fontWeight: "700", fontSize: "23px", color: "#ae9d81", textAlign: 'center' }}>
            <p style={{ margin: '0px' }}>{isAdd ? "Add" : "Edit"} Feedback Category Details</p>
        </div>
    }
    const popup = (() => {
        return (
            <>
                <Dialog header={header} closable={false} visible={true} style={{ width: '30vw' }} onHide={() => {
                    setIsChannelAlreadyExist(false);
                    setIsChannelARExist(false); setIsAddFeedbackChannelPopupOpen(false); setIsChannelValid(false);
                    setIsAddFeedbackChannel({
                        "name": "",
                        "nameInArabic": "",
                        "isDeleted": false
                    });
                }}>
                    <div className="popup " >
                        {/* <div className="heading">
                            <p>Add New Details</p>
                        </div> */}
                        <div className="body">
                            <div className="label grid">
                                <div className='col'>Feedback Channel Name<span style={{ color: 'red' }}>*</span></div>
                                <div className='col' style={{ position: 'absolute', left: '240px' }}>Status</div>
                            </div>
                            <div className="input grid ">
                                <div className="col">
                                    <InputText placeholder="Enter Name" value={isAddFeedbackChannel.name} onChange={(e) => { setIsAddFeedbackChannel((prev) => ({ ...prev, name: e.target.value })); setIsChannelAlreadyExist(false) }} />
                                    {isChannelAlreadyExist &&
                                        // <div>
                                        <p style={{ color: "red" }}>Feedback channel name is already exists.</p>
                                        // </div>
                                    }
                                    {isChannelValid && isAddFeedbackChannel?.categoryName == "" &&
                                        // <div>
                                        <p style={{ color: "red" }}>Feedback channel name is required.</p>
                                        // </div>
                                    }
                                </div>
                                <div className="col ">

                                    <label className="switch ">
                                        <input type="checkbox" checked={!isAddFeedbackChannel.isDeleted} onChange={
                                            (e) => {
                                                setIsAddFeedbackChannel((prev) => ({ ...prev, isDeleted: !e.target.checked }))
                                            }

                                        } />
                                        <span className="slider round"></span>
                                    </label>
                                </div>

                            </div>
                            <div style={{ marginBottom: '10px' }}>Feedback Channel Name AR<span style={{ color: 'red' }}>*</span></div>
                            <div >
                                <InputText placeholder="Enter Name AR" style={{ direction: 'rtl', textAlign: 'right' }} value={isAddFeedbackChannel.nameInArabic} onChange={(e) => { setIsChannelARExist(false); setIsAddFeedbackChannel((prev) => ({ ...prev, nameInArabic: e.target.value })) }} />
                                {isChannelARExist &&
                                    // <div>
                                    <p style={{ color: "red" }}>Feedback channel name (AR) is already exists.</p>
                                    // </div>
                                }
                                {isChannelValid && isAddFeedbackChannel?.nameInArabic == "" &&
                                    // <div>
                                    <p style={{ color: "red" }}>Feedback channel name (AR) is required.</p>
                                    // </div>
                                }
                            </div>
                        </div>
                        <div className="footer flex  grid">
                            <div className="col flex justify-content-center ">
                                <Button label="Cancel" onClick={() => {
                                    setIsChannelAlreadyExist(false);
                                    setIsChannelARExist(false);
                                    setIsAddFeedbackChannelPopupOpen(false);
                                    setIsChannelValid(false);
                                    setIsAddFeedbackChannel({
                                        "name": "",
                                        "nameInArabic": "",
                                        "isDeleted": false
                                    });
                                }} style={{ backgroundColor: '#8E8E8E', border: "none", margin: "10px", height: '40px' }} />
                                <Button label={isAdd ? "Submit" : "Update"} onClick={() => { isAdd ? addFeedbackChannel() : editFeedbackChannel() }} style={{ backgroundColor: '#ae9d81', border: "none", margin: "10px", height: '40px' }} />
                            </div>
                        </div>
                    </div>
                </Dialog>

            </>
        )
    });
    const getFeedbackChannelList = (skip, take, searchTerm, status = "", isExport = false) => {
        setIsLoading(true);
        GetFeedbackChannelList(cookies().bearerToken, skip, take, searchTerm, status, isExport)
            .then(res => {
                // //console.log(res);
                setFeedbackChannelList(res.data);
                setTotalRecords(res?.data?.count);

            })
            .catch((err) => {
                console.warn(err);
                return;

            })
            .finally(() => {
                setIsLoading(false);
            })
    };
    const exportFeedbackChannel = () => {
        // window.open(baseUrl)
        exportDataInExcel('FeedbackChannel', cookies().bearerToken);

    }



    const onExportFeedCategory = (g = "", s = "") => {
        // setExportFilterDialog(true);
        let urlRoute = `FeedbackChannel/GetList?searchTerm=${g}&status=${s}&forExport=true`;
        ExportOpenInNewTab(urlRoute);
    }



    useEffect(() => {
        getFeedbackChannelList();
    }, [])
    return (
        <>
            <Dialog header={<h3>Export Filter</h3>} draggable={false} style={{ width: '50vw', height: "max-content" }} closable={true} onHide={() => setExportFilterDialog(false)} visible={exportFilterDialog} >
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="StartDate" className="font-bold block mb-2">Start Date
                        </label>
                        <Calendar
                            id="StartDate"
                            style={{ width: "95%" }}
                            value={exportDetails?.startDate}
                            placeholder="Choose start end"
                            onChange={((value) => setExportDetails(() => ({ ...exportDetails, startDate: value })))}
                        ></Calendar>
                    </div>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="EndDate" className="font-bold block mb-2">End Date
                        </label>
                        <Calendar
                            id="EndDate"
                            style={{ width: "95%" }}
                            value={exportDetails?.endDate}
                            placeholder="Choose end date"
                            onChange={((value) => setExportDetails(() => ({ ...exportDetails, endDate: value })))}
                        ></Calendar>
                    </div>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="organization" className="font-bold block mb-2">Organization
                        </label>
                        <InputText
                            id="organization"
                            style={{ width: "95%" }}
                            value={exportDetails?.organization}
                            onChange={((value) => setExportDetails(() => ({ ...exportDetails, organization: value })))}
                            placeholder="Enter organization" ></InputText>
                    </div>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="Status" className="font-bold block mb-2">Status
                        </label>
                        {/* <InputText
                                id="Status"
                                style={{ width: "95%" }}
                                value={exportDetails?.status}

                                placeholder="Enter organization" ></InputText> */}
                        <div className="input grid ">
                            <div className="col ">
                                <label className="switch ">
                                    <input type="checkbox" value={exportDetails?.status} checked={exportDetails?.status} onChange={((e) => setExportDetails(() => ({ ...exportDetails, status: e.target.checked })))} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>

                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "25px",
                    }}
                >
                    <Button
                        label="Submit"
                        onClick={''}
                        // icon={"pi pi-file-export"}
                        style={{
                            backgroundColor: " #ae9d81",
                            color: "#fff",
                            border: "none",
                            // borderRadius: "20px",
                            marginRight: "0",
                            cursor: totalCount > 0 ? "pointer" : "not-allowed",
                            padding: "0.6rem",
                        }}
                    />
                </div>


            </Dialog>
            <div className="feedbackChannelMaster">
                <div className="header">
                    <div style={{ display: "flex", flexDirection: "row", flexGrow: "3", justifyContent: "flex-start" }} className='flex align-items-center org'>
                        <i className="pi pi-arrow-circle-left nav-back-btn" onClick={back} style={{ marginLeft: '30px', cursor: 'pointer', fontSize: '1.5rem' }} />
                        <div className="heading">
                            <p>Feedback Channel</p>
                        </div>
                    </div>
                    <div className='mx-2'>
                        <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Search</h4>
                        <div className="search">
                            <span className="p-input-icon-left">
                                <i className="pi pi-search" style={{ color: 'black', fontWeight: '700', fontSize: '1rem' }} />
                                <InputText placeholder="Search" value={globalFilterValue} onChange={onGlobalFilterChange} />
                                {globalFilterValue && <i className="pi pi-times" style={{ color: 'black', fontWeight: '400' }} onClick={() => { setGlobalFilterValue(""); onGlobalFilterChange() }} />}
                            </span>
                        </div>
                    </div>
                    <div className='mx-2'>
                        <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Status</h4>
                        <div className="search">
                            <span className="p-input-icon-left">
                                <Dropdown placeholder="Status" showClear={status != null} options={[{ "label": "Active", "value": false }, { "label": "In Active", "value": true }]} value={status} optionLabel='label' optionValue='value' onChange={onStatusFilterChange} />
                            </span>
                        </div>
                    </div>

                    <div className="align-self-start mt-1 mx-2">
                        <Button label="Add New" onClick={() => { setIsAddFeedbackChannelPopupOpen(true); setIsAdd(true) }
                        } style={{ backgroundColor: '#ae9d81', border: 'none' }} />
                    </div>
                    <div className='align-self-start mx-2'>
                        <Button label='Export' onClick={() => { onExportFeedCategory(globalFilterValue, status) }} icon={"pi pi-file-export"} style={{ backgroundColor: 'transparent', color: '#ae9d81', border: '1px #ae9d81 solid', borderRadius: '20px', cursor: feedbackChannelList?.length > 0 ? 'pointer' : 'not-allowed' }} />
                    </div>
                    {/* <div className='align-self-start mx-2'>
                        <Button label='Import' icon={"pi pi-file-import"} style={{ backgroundColor: 'transparent', color: '#ae9d81', border: '1px #ae9d81 solid', borderRadius: '20px' }} onClick={() => { setIsImport(true) }} />
                    </div> */}
                </div>

                <div className="datatable">
                    {isLoading ?
                        <div className='spinner spinner-center'></div>
                        :
                        <DataTable value={feedbackChannelList?.length > 0 ? feedbackChannelList : []} tableStyle={{ width: '90rem' }} style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }} stripedRows
                            globalFilterFields={['categoryName']} filters={filters}
                            emptyMessage={<><p style={{ textAlign: "center" }}>No feedback categories found</p></>} paginator rows={10}>

                            <Column field="name" header="Category Name " style={{ width: '25%' }}></Column>
                            <Column field="nameInArabic" header="Category Name(AR)" style={{ width: '25%' }}></Column>
                            <Column field="isDeleted" header="Status" body={statusBodyTemplate} style={{ width: '25%' }} ></Column>
                            <Column rowEditor header="Action" body={actionButtonTemplate} style={{ width: '25%' }}></Column>


                        </DataTable>}
                </div>
            </div>

            <div>
                {isAddFeedbackChannelPopupOpen == true ? popup() : ""}
            </div>
        </>
    )
}

export default FeedbackChannel