import { toast } from "react-toastify";
import "./UserRegistration.scss";
import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import UseCookies from "../../../../Helpers/UseCookies";
import { useDispatch } from "react-redux";
import { setNavVisibilty } from "../../../../Redux/AppSlice";
import IntlTelInput from "react-intl-tel-input";
import nationalityListJSON from "../../../../assets/JSON/NationalityFlags.json";
import TermsandConditions from '../../../../assets/files/MG-VIP Loung T&C.pdf'


// Prime React Components
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { AutoComplete } from "primereact/autocomplete";
// ------------------------------

// ----------Assets Import------------
import editIcon from "../../../../assets/images/editIcon.jpg";
import {
  ApproveInvoice,
  GetVIPUser,
  GetSubscription,
  ApproveVIPUser,
  GetVIPUserList,
  RegisterVIPUser,
  RejectInvoice,
  RegisterVIPUserByLoungeStaff,
  EditVIPUser,
} from "../../../../Services/VIPLoungeSupervisor";
import {
  GetActiveOrganizations,
  GetPlateCountry,
  GetSubcription,
  GetVehicleBrands,
} from "../../../../Services/VIPLoungeMaster";
import nationality from "../../../../assets/JSON/Nationality.json";
import { MultiSelect } from "primereact/multiselect";
import Signature from "../../../utili/signature";
import CountryPicker from "../../../CountryPicker/CountryPicker";
// -----------------------------------

//signature

function UserRegistration() {
  const [cookies, setCookie, removeCookie] = UseCookies();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [inValid, setInvalid] = useState(false);
  const [allSubscriptions, setAllSubscription] = useState([]);
  const [allOrganizations, setAllOrganizations] = useState([]);
  const [nationalityList, setNationalityList] = useState([...nationality]);
  const [countriesList, setCountriesList] = useState([...nationality]);
  const navigate = useNavigate();
  const vipCode = useParams().id;
  const inputRef = useRef(null);
  const isApproveScreen = useParams().isApproveScreen;

  //dropdownList
  const [brandList, setBrandList] = useState([]);
  const [countryPlateList, setCountryPlateList] = useState([]);
  //end
  // Loader
  const [registrationloader, setLoader] = useState(false);

  // Action button

  const [view, setView] = useState(false);

  // Form Switch
  const [formPage, setFormPage] = useState("one");

  //rejection
  const [rejectModal, setRejectModal] = useState({
    isOPen: false,
    comment: "",
    invoiceNumber: null,
  });
  const [approveModal, setApproveModel] = useState({
    isOpen: false,
    subscription: [],
    subscriptionId: null,
  });

  // Initial state values
  const userInitialValue = {
    firstName: "",
    lastName: "",
    nationality: "",
    dateOfBirth: new Date(),
    countryOfResidence: "",
    emailAddress: "",
    mobileNumber: "",
    countryCode: "",
    secondaryMobileNuber: "",
    companyName: "",
    designation: "",
    preferredModeOfContact: "",
    informedOfEvents: "",
    favoriteBrand: "",
    favoriteAttraction: "",
    favoriteRestaurantCafe: "",
    visitFrequency: "",
    visitFrequencyOthers: "",
    visitDaysOfWeek: "",
    visitWith: "",
    visitWithOthers: "",
    participateInPromotions: "",
    attractivePrizes: "",
    valetParkingFrequency: "",
    aiisitanceService: "",
    carWashServiceUsage: "",
    porterServiceFrequency: "",
    lockerRoomFrequency: "",
    inMallTaxiFrequency: "",
    otherServicesUsage: "",
    cardNumber: "",
    referredBy: "",
    subscriptionDetails: "",
    organizationDetails: "",
    plateNumber: "",
    vehicleBrand: "",
    prefix: "",
    emirate: "",
    model: "",
    signatureImageUrl: "",
    reciveEmarEmail: false,
    termsAndConditions: false,
  };
  const preferredModeOfContactOptions = [
    { name: "Email", id: "Email" },
    { name: "Phone", id: "Phone" },
    { name: "Both", id: "Both" },
  ];

  const days = [
    { name: "Monday", id: "Monday" },
    { name: "Tuesday", id: "Tuesday" },
    { name: "Wednesday", id: "Wednesday" },
    { name: "Thursday", id: "Thursday" },
    { name: "Friday", id: "Friday" },
    { name: "Saturday", id: "Saturday" },
    { name: "Sunday", id: "Sunday" },
  ];

  const [userDetails, setUserDetails] = useState({ ...userInitialValue });
  const [isCardNumberChanged, setIsCardNumberChanged] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedSecondaryCountry, setSelectedSecondaryCountry] = useState(null);

  const registerUser = async () => {
    setView(false);
    let validation = false;
    let isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userDetails?.emailAddress);
    if (!isValid) {
      toast.warning("Email is not valid");
      return;
    }
    setInvalid(!userDetails?.subscriptionDetails);

    if (
      userDetails?.firstName == "" ||
      userDetails?.lastName == "" ||
      userDetails?.nationality == "" ||
      userDetails?.dateOfBirth == "" ||
      userDetails?.countryOfResidence == "" ||
      userDetails?.emailAddress == "" ||
      userDetails?.mobileNumber == "" ||
      // userDetails?.secondaryMobileNuber == "" ||
      // userDetails?.countryCode == null ||
      // userDetails?.secondaryCountryCode == null ||
      selectedCountry == null ||
      selectedSecondaryCountry == null ||
      // userDetails?.companyName == "" ||
      // userDetails?.designation == "" ||
      userDetails?.preferredModeOfContact?.name == "" ||
      userDetails?.informedOfEvents == "" ||
      userDetails?.favoriteBrand == "" ||
      userDetails?.favoriteAttraction == "" ||
      userDetails?.favoriteRestaurantCafe == "" ||
      userDetails?.visitFrequency == "" ||
      userDetails?.visitDaysOfWeek.length == 0 ||
      userDetails?.visitWith == "" ||
      userDetails?.participateInPromotions == "" ||
      (userDetails?.participateInPromotions == "Yes" &&
        userDetails?.attractivePrizes == "") ||
      userDetails?.valetParkingFrequency == "" ||
      // userDetails?.porterServiceFrequency == "" ||
      userDetails?.aiisitanceService == "" ||
      userDetails?.carWashServiceUsage == "" ||
      // userDetails?.otherServicesUsage == "" ||
      // userDetails?.cardNumber == "" ||
      !userDetails?.subscriptionDetails ||
      !userDetails?.termsAndConditions
    ) {
      setInvalid(true);
      validation = true;
      return;
    }
    if (
      (userDetails?.visitFrequency == "Others" &&
        userDetails?.visitFrequencyOthers == "") ||
      (userDetails?.visitWith == "Others" && userDetails?.visitWithOthers == "")
    ) {
      setInvalid(true);
      validation = true;
      return;
    }
    setInvalid(false);
    setLoader(true);
    let data = {
      firstName: userDetails?.firstName,
      lastName: userDetails?.lastName,
      nationality: userDetails?.nationality?.english_name,
      dateOfBirth: userDetails?.dateOfBirth,
      countryOfResidence: userDetails?.countryOfResidence?.english_name,
      emailAddress: userDetails?.emailAddress,
      mobileNumber: userDetails?.mobileNumber,
      countryCode: selectedCountry?.phone_code,
      secondaryMobileNuber: userDetails?.secondaryMobileNuber,
      secondaryCountryCode: selectedSecondaryCountry?.phone_code,
      companyName: userDetails?.companyName,
      designation: userDetails?.designation,
      preferredModeOfContact: userDetails?.preferredModeOfContact?.name,
      informedOfEvents: userDetails?.informedOfEvents == "Yes" ? true : false,
      vehicleDetail: {
        brandOrMake: userDetails?.vehicleBrand,
        careModel: userDetails?.model,
        platePrefix: userDetails?.prefix,
        plateNo: userDetails?.plateNumber,
        plateCountry: userDetails?.emirate,
      },
      additionalInformation: {
        favoriteBrand: userDetails?.favoriteBrand,
        favoriteAttraction: userDetails?.favoriteAttraction,
        favoriteRestaurantCafe: userDetails?.favoriteRestaurantCafe,
        visitFrequency:
          userDetails?.visitFrequency == "Others"
            ? userDetails?.visitFrequencyOthers
            : userDetails?.visitFrequency,
        visitDaysOfWeek: userDetails?.visitDaysOfWeek.join(),
        visitWith:
          userDetails?.visitWith == "Others"
            ? userDetails?.visitWithOthers
            : userDetails?.visitWith,

        participateInPromotions: userDetails?.participateInPromotions,
        attractivePrizes: userDetails?.attractivePrizes,
        valetParkingServiceUsage: userDetails?.valetParkingFrequency.toLocaleLowerCase(),
        assistanceServiceUsage: userDetails?.aiisitanceService.toLocaleLowerCase(),
        carWashServiceUsage: userDetails?.carWashServiceUsage.toLocaleLowerCase(),
        otherServicesUsage: userDetails?.otherServicesUsage,
      },
      referredBy: userDetails?.referredBy,
      cardNumber: userDetails?.cardNumber,
      userMapping: {
        organizationID: 0,
        subscriptionID: userDetails?.subscriptionDetails?.id,
      },
    };
    console.log("Data", data);
    if (!validation) {
      setIsLoading(true);
      if (cookies()?.roles.includes("LOUNGE_STAFF")) {
        await RegisterVIPUserByLoungeStaff(data, cookies()?.bearerToken)
          .then((res) => {
            if (res?.response?.status === 400) {
              setLoader(false);
              toast.warning(res?.response?.data?.message, {
                position: "top-right",
              });
              return;
            }
            if (res?.status === 200) {
              if (res?.data?.httpStatusCode === 400) {
                setLoader(false);
                if (res?.data?.message == "EmailAddress Already Exists")
                  toast.warning("Email address already exists", { position: "top-right" });
                else if (res?.data?.message == "MobileNumber Already Exists")
                  toast.warning("Mobile number already exists", { position: "top-right" });
                else if (res?.data?.message == "VIP User Exists")
                  toast.warning(res?.data?.message, { position: "top-right" });
                else
                  toast.warning(res?.data?.message, { position: "top-right" });


                return;
              }
              if (res?.data?.httpStatusCode === 500) {
                setLoader(false);
                toast.error(res?.data?.message, { position: "top-right" });
                return;
              } else {
                toast.success("VIP User Registered Succesfully", {
                  position: "top-right",
                });
                setLoader(false);
                setUserDetails({ ...userInitialValue });
                navigate("/vip-user-list");
              }
            }
          })
          .catch((error) => {
            toast.error(
              "There is issue occured in registering user, could you please try again",
              { position: "top-right" }
            );
          })
          .finally(() => {
            setIsLoading(false);
            setView(false);
            setLoader(false);
          });
      } else {
        await RegisterVIPUser(data, cookies()?.bearerToken)
          .then((res) => {
            if (res?.response?.status === 400) {
              setLoader(false);
              toast.warning(res?.response?.data?.message, {
                position: "top-right",
              });
              return;
            }
            if (res?.status === 200) {
              if (res?.data?.httpStatusCode === 400) {
                setLoader(false);
                toast.warning(res?.data?.message, { position: "top-right" });
                return;
              }
              if (res?.data?.httpStatusCode === 500) {
                setLoader(false);
                toast.error(res?.data?.message, { position: "top-right" });
                return;
              } else {
                toast.success("VIP User Registered Succesfully", {
                  position: "top-right",
                });
                setLoader(false);
                setUserDetails({ ...userInitialValue });
                navigate("/vip-user-list");
              }
            }
          })
          .catch((error) => {
            toast.error(
              "There is issue occured in registering user, could you please try again",
              { position: "top-right" }
            );
          })
          .finally(() => {
            setIsLoading(false);
            setView(false);
            setLoader(false);
          });
      }
    }
  };

  // Get details of VIP user by vipCode
  const getVIPUser = async (vipUserCode) => {
    setIsLoading(true);
    await GetVIPUser(vipUserCode, cookies().bearerToken)
      .then(async (res) => {
        //console.log("Response", res);
        if (res?.status === 200) {
          let parts = res?.data?.dateOfBirth.split("/");
          setUserDetails((prev) => ({
            ...prev,
            ...res?.data,
            isApproved: res?.data?.isApproved,
            dateOfBirth: new Date(`${parts[2]}-${parts[1]}-${parts[0]}`),
            countryCode: res?.data?.countryCode?.includes("+") ? res?.data?.countryCode : "+" + res?.data?.countryCode,
            secondaryMobileNuber: res?.data?.secondaryMobileNumber,
            secondaryCountryCode: res?.data?.secondaryCountryCode?.includes("+") ? res?.data?.secondaryCountryCode : "+" + res?.data?.secondaryCountryCode,
            nationality: nationality?.filter((e) => {
              return e.english_name == res?.data?.nationality;
            })[0],
            countryOfResidence: nationality.filter((e) => {
              return e.english_name == res?.data?.countryOfResidence;
            })[0],

            preferredModeOfContact: preferredModeOfContactOptions.filter(
              (e) => {
                return e.name == res?.data?.preferredModeOfContact;
              }
            )[0],
            vehicleBrand: res?.data?.vehicleInformation?.brandOrMake,
            model: res?.data?.vehicleInformation?.careModel,
            prefix: res?.data?.vehicleInformation?.platePrefix,
            plateNumber: res?.data?.vehicleInformation?.plateNo,
            emirate: res?.data?.vehicleInformation?.plateCountry,
            favoriteBrand: res?.data?.additionalInformation?.favoriteBrand,
            favoriteAttraction:
              res?.data?.additionalInformation?.favoriteAttraction,
            favoriteRestaurantCafe:
              res?.data?.additionalInformation?.favoriteRestaurantCafe,
            visitFrequency: res?.data?.additionalInformation?.visitFrequency,
            visitDaysOfWeek: res?.data?.additionalInformation?.visitDaysOfWeek,
            visitWith: res?.data?.additionalInformation?.visitWith,
            participateInPromotions:
              res?.data?.additionalInformation?.participateInPromotions,
            attractivePrizes:
              res?.data?.additionalInformation?.attractivePrizes,
            valetParkingFrequency:
              res?.data?.additionalInformation?.valetParkingServiceUsage.toLocaleLowerCase(),
            aiisitanceService:
              res?.data?.additionalInformation?.assistanceServiceUsage.toLocaleLowerCase(),
            carWashServiceUsage:
              res?.data?.additionalInformation?.carWashServiceUsage.toLocaleLowerCase(),
            otherServicesUsage:
              res?.data?.additionalInformation?.otherServicesUsage,
            subscriptionDetails: res?.data?.subscriptionInfomation,
            organizationDetails: res?.data?.organizationInformation,
          }));
          // console.log(res?.data?.visitDaysOfWeek?.split(",").map((e, index) => {
          //     return {
          //         "id": index, "name": e, "id": e
          //     }
          // }));
          let tempSelectedCountry = await nationalityListJSON?.filter(country => country.phone_code == res?.data?.countryCode)[0]
          let tempSecondarySelectedCountry = await nationalityListJSON?.filter(country => country.phone_code == res?.data?.secondaryCountryCode)[0]
          console.log(tempSelectedCountry, res?.data?.countryCode);
          console.log(tempSecondarySelectedCountry, res?.data?.secondaryCountryCode);
          setSelectedCountry(tempSelectedCountry);
          setSelectedSecondaryCountry(tempSecondarySelectedCountry);
          setIsLoading(false);
        } else {
          toast.error(
            "There is issue occured in fetching user, could you please try again",
            { position: "top-right" }
          );
        }
      })
      .catch((error) => { })
      .finally((res) => {
        setIsLoading(false);
      });
  };

  // get form page name

  const emailValidation = () => {
    var emailFormat = /^([^\s@]+)@([A-Za-z])+\.([A-Za-z])+$/;
    let isValidEmail = emailFormat.test(userDetails?.emailAddress);
    return isValidEmail;
  };

  const getFormPageName = (isNext) => {
    if (isNext) {
      if (formPage === "one") {
        if (
          !vipCode &&
          (userDetails?.firstName == "" ||
            userDetails?.lastName == "" ||
            userDetails?.nationality == "" ||
            userDetails?.dateOfBirth == "" ||
            userDetails?.countryOfResidence == "" ||
            userDetails?.emailAddress == "" ||
            !emailValidation() ||
            userDetails?.mobileNumber == "" ||
            userDetails?.preferredModeOfContact?.name == "" ||
            userDetails?.informedOfEvents == ""
            // userDetails?.cardNumber == ""
          )
        ) {
          setInvalid(true);
        } else {
          setFormPage("two");
          setInvalid(false);
        }
      } else if (formPage === "two") {
        if (
          !vipCode &&
          (userDetails?.favoriteBrand == "" ||
            userDetails?.favoriteAttraction == "" ||
            userDetails?.favoriteRestaurantCafe == "" ||
            userDetails?.visitFrequency == "" ||
            userDetails?.visitDaysOfWeek.length == 0 ||
            userDetails?.visitWith == "" ||
            userDetails?.participateInPromotions == "" ||
            (userDetails?.participateInPromotions == "Yes" &&
              userDetails?.attractivePrizes == "") ||
            userDetails?.valetParkingFrequency == "" ||
            // userDetails?.porterServiceFrequency == "" ||
            userDetails?.aiisitanceService == "" ||
            userDetails?.carWashServiceUsage == "")
        ) {
          setInvalid(true);
        } else {
          setFormPage("three");
          setInvalid(false);
        }
      }
    } else {
      if (formPage === "three") {
        setFormPage("two");
      } else if (formPage === "two") {
        setFormPage("one");
      }
    }
  };

  // approve function

  const getSubscriptionFunction = async () => {
    // navigate("/supervisor-user-approval");
    await GetSubscription(0, 10, cookies().bearerToken)
      .then((res) => {
        setApproveModel({
          ...approveModal,
          isOpen: true,
          subscription: res?.data?.data,
        });
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleReject = () => {
    navigate("/supervisor-user-approval");
  };

  // get date of birth

  const getDateOfBirth = () => {
    let date = null;
    if (userDetails?.dateOfBirth) {
      date = new Date(userDetails?.dateOfBirth.split("/").reverse().join("/"));
    }
    return date;
  };

  const getAllSubscription = async () => {
    await GetSubcription(cookies().bearerToken, "", 0, 1000)
      .then((res) => {
        setAllSubscription(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllOrganizations = async () => {
    await GetActiveOrganizations(cookies().bearerToken)
      .then((res) => {
        setAllOrganizations(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (vipCode) {
      getVIPUser(vipCode);
      setView(true);
    }
    getAllSubscription();
    getAllOrganizations();
  }, []);

  const userApproveFunction = async () => {
    setLoader(true);

    await ApproveVIPUser(userDetails.id, cookies()?.bearerToken)
      .then((res) => {
        setLoader(false);
        toast.success("VIP User Approved Successfully", {
          position: "top-right",
        });
        setApproveModel({ ...approveModal, isOpen: false });
        navigate("/supervisor-user-approval");
      })
      .catch((err) => console.log(err));
  };

  const getSelectedCountryName = () => {
    let selectedCountry = "bh";
    console.log("Current", userDetails?.countryCode);
    console.log("countries", nationalityListJSON);
    if (userDetails?.countryCode) {
      selectedCountry = nationalityListJSON?.filter(
        (val) => val.phone_code === userDetails?.countryCode
      )[0]?.alpha2_code.toLocaleLowerCase();
    }
    console.log("selectedCountry", selectedCountry);
    return selectedCountry ? selectedCountry : "bh";
  };

  const handlePhoneChange = (value, countryData) => {
    if (value !== userDetails?.mobileNumber) {
      if (/^[0-9]+$|^$/.test(value)) {
        setUserDetails((prev) => ({
          ...prev,
          mobileNumber: value,
          countryCode: countryData?.dialCode,
        }));
      }
    }
    // setPhoneNumber(value);
  };

  const searchNationality = (event) => {
    // getAllStoreList(event.query);
    let filterArr = nationality.filter((obj) =>
      obj.english_name
        .toLocaleLowerCase()
        .includes(event.query.toLocaleLowerCase())
    );
    setNationalityList([...filterArr]);
  };
  const searchCountries = (event) => {
    // getAllStoreList(event.query);
    let filterArr = nationality.filter((obj) =>
      obj.english_name
        .toLocaleLowerCase()
        .includes(event.query.toLocaleLowerCase())
    );
    setCountriesList([...filterArr]);
  };

  const getSelectedData = (id, data) => {
    let selecetdData = null;

    if (data?.length) {
      selecetdData = [...data].find((val) => val.id == id);
    }

    return selecetdData;
  };




  // Deepak - Country Picker
  const handleChange = (value) => {
    console.log("Change", value);
    setUserDetails({
      ...userDetails,
      mobileNumber: value
    });
  }
  const handleCountryChange = (value) => {
    console.log("Change", value);
    setUserDetails({
      ...userDetails,
      countryCode: value?.phone_code.includes("+") ? value.phone_code : "+" + value.phone_code
    });
  }
  const handleSecondaryCountryChange = (value) => {
    console.log("Change", value);
    setUserDetails({
      ...userDetails,
      secondaryCountryCode: value?.phone_code.includes("+") ? value.phone_code : "+" + value.phone_code
    });
  }
  const selectedCountryTemplate = (option, props) => {
    // console.log(option);
    if (option) {
      return (
        <div className="flex align-items-center">
          <img alt={option.flagImage} src={option?.flagImage} style={{ width: '18px' }} />
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img alt={option.name} src={option?.flagImage} style={{ width: '18px' }} />
        <div>{option?.english_name}({option?.arabic_name}){option?.phone_code}</div>
      </div>
    );
  };
  // -------------------------------//
  const getBrandList = async () => {
    await GetVehicleBrands(cookies()?.bearerToken).then((res) => {
      if (res?.status == 200) {
        setBrandList(res?.data || []);
      }
    })
  }
  const getPlateCountry = async () => {
    await GetPlateCountry(cookies()?.bearerToken).then(res => {
      if (res?.status == 200) {
        setCountryPlateList(res?.data || []);
      }
    })
  };

  const editVipUser = async () => {
    if (userDetails?.cardNumber == "") {
      return;
    }
    let data = {
      "cardNumber": userDetails?.cardNumber,
      "id": userDetails?.id
    };
    setIsLoading(true);
    await EditVIPUser(data, cookies().bearerToken)
      .then((res) => {
        if (res.status === 200 && res.data?.httpStatusCode === 200) {
          toast.success(res.data?.message, { position: 'top-right' });
          setIsCardNumberChanged(false);
        }
        else {
          toast.error(res.data?.message, { position: 'top-right' });
        }
      })
      .catch((err) => {
        console.log("Error", err?.message);
      })
      .finally(() => {
        setIsLoading(false);
      })

  }
  useEffect(() => {
    getBrandList();
    getPlateCountry();
  }, [])

  useEffect(() => {
    let tempSelectedCountry = nationalityListJSON?.filter(country => country.phone_code == "+973")[0]
    let tempSecondarySelectedCountry = nationalityListJSON?.filter(country => country.phone_code == "+973")[0]
    console.log(tempSelectedCountry);
    console.log(tempSecondarySelectedCountry);
    setSelectedCountry(tempSelectedCountry);
    setSelectedSecondaryCountry(tempSecondarySelectedCountry);
  }, [])
  return (
    <>
      <Dialog
        header={
          <h3 style={{ textAlign: "center", color: "#ae9d81", margin: 0 }}>
            Comments
          </h3>
        }
        visible={rejectModal.isOPen}
        style={{ width: "30%" }}
        onHide={() => {
          setRejectModal({
            isOPen: false,
            comment: "",
            invoiceNumber: null,
          });
        }}
      >
        <div className="p-grid grid-modal attachment-modal">
          <div className="attachment-modal-content">
            <InputTextarea
              style={{
                width: "100%",
                height: "100px",
                borderColor: "#ae9d81",
                padding: "10px",
              }}
              value={rejectModal.comment}
              onChange={(e) => {
                setRejectModal({
                  ...rejectModal,
                  comment: e.target.value.trimStart(),
                });
              }}
            />
          </div>
          <div
            className="p-col-12"
            style={{
              margin: "20px 0px 10px 0px",
              display: "flex",
              justifyContent: "center",
              width: "100%",
              gap: "10px",
            }}
          >
            <Button
              label="Submit"
              onClick={() => {
                handleReject();
              }}
              style={{
                background: "#ae9d81",
                color: "white",
                border: "none",
                width: "100px",
                padding: "5px 0px",
              }}
            />
          </div>
        </div>
      </Dialog>
      {/* <Dialog
        header={
          <h3 style={{ textAlign: "center", color: "#ae9d81", margin: 0 }}>
            Confirmation
          </h3>
        }
        visible={approveModal.isOpen}
        style={{ width: "30%" }}
        onHide={() => {
          setApproveModel({
            isOpen: false,
            subscription: [],
            subscriptionId: null,
          });
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div>
            <p className="input-label">Vip Code</p>
            <InputText
              placeholder="Enter Plate Country"
              disabled
              value={vipCode}
            />
          </div>
          <div>
            <p className="input-label">
              Subcription<span style={{ color: "red" }}>*</span>
            </p>
            <Dropdown
              value={approveModal?.subscriptionId}
              onChange={(e) => {
                console.log(e);
                setApproveModel((prev) => ({
                  ...prev,
                  subscriptionId: e.value,
                }));
              }}
              options={approveModal?.subscription}
              optionLabel="name"
              placeholder="Select a subscription"
              className="w-full md:w-14rem"
              style={{ width: "" }}
            />
          </div>
        </div>
       
        <div className="flex justify-content-center gap-3">
          <Button
            style={{
              backgroundColor: "#adadad",
              border: "none",
              margin: "10px",
              width: "105px",
            }}
          >
            Cancel
          </Button>
          <Button
            style={{
              backgroundColor: "#ae9d81",
              border: "none",
              margin: "10px",
              width: "105px",
            }}
            loading={registrationloader}
            onClick={() => userApproveFunction()}
          >
            Submit
          </Button>
        </div>
      </Dialog> */}

      <Dialog
        header={<h3 style={{ textAlign: "center", color: "#ae9d81", margin: 0 }}>
          Confirmation
        </h3>}
        style={{ width: "30%" }}
        visible={false}
        closable={false}>
        <div style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
          <p>Are you sure you want to close, because you have a unsaved data of the card number?</p>
        </div>
        <div style={{ display: "flex", justifyContent: "center", justifyContent: "space-evenly" }}>
          <Button label="Cancel"
            style={{
              backgroundColor: "#8e8e8e",
              border: "none",
              borderRadius: "3px",
              width: "120px",
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></Button>
          <Button label="Submit"
            style={{
              backgroundColor: "#ae9d81",
              border: "none",
              borderRadius: "3px",
              width: "120px",
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></Button>
        </div>

      </Dialog>
      <Dialog
        header={
          <h3 style={{ textAlign: "center", color: "#ae9d81", margin: 0 }}>
            Confirmation
          </h3>
        }
        visible={approveModal.isOpen}
        style={{ width: "30%" }}
        onHide={() => {
          setApproveModel({
            isOpen: false,
            subscription: [],
            subscriptionId: null,
          });
        }}
      >
        <div className="track-delivery-view-success-modal">
          <div className="success-modal-content">
            <p style={{ fontSize: "18px" }}>Are you sure want to approve?</p>
          </div>
          <div className="success-modal-btn" style={{ gap: "10px" }}>
            <Button
              label={"No"}
              style={{
                backgroundColor: "#8e8e8e",
                border: "none",
                borderRadius: "3px",
                width: "120px",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                setApproveModel({
                  isOpen: false,
                  subscription: [],
                  subscriptionId: null,
                });
              }}
            />
            <Button
              label={"Yes"}
              // loading={}
              style={{
                backgroundColor: "#ae9d81",
                border: "none",
                borderRadius: "3px",
                width: "120px",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                userApproveFunction();
              }}
            />
          </div>
        </div>
      </Dialog>
      {isLoading ? (
        <div
          className="spinner spinner-center"
          style={{ display: "flex", margin: "auto", marginTop: "200px" }}
        ></div>
      ) : (
        <div className="UserRegistration">
          <div className="header-title">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexGrow: "3",
                justifyContent: "flex-start",
              }}
              className="flex align-items-center org"
            >
              <i
                className="pi pi-arrow-circle-left nav-back-btn"
                onClick={() => {
                  if (isApproveScreen === "true") {
                    navigate("/supervisor-user-approval");
                  } else {
                    navigate("/vip-user-list");
                  }
                }}
                style={{
                  marginLeft: "30px",
                  cursor: "pointer",
                  fontSize: "1.5rem",
                }}
              />
              <div className="heading" style={{ paddingLeft: "10px" }}>
                <p className="text-center">
                  {view ? "View" : ""} User{" "}
                  {isApproveScreen === "true"
                    ? "Approve"
                    : view
                      ? "Details"
                      : "Registration"}{" "}
                  Page
                </p>
              </div>
            </div>
          </div>
          {/* {formPage == "one" ? (
        ""
      ) : (
        <p className="col text-center p-0">
          We would like to know more about you, so that we may serve you even
          better
        </p>
      )} */}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h2 style={{ margin: 0, color: "#ae9d81", fontWeight: 600 }}>
              {formPage === "three" ? "Subscription" : "Guest"} Information
            </h2>
          </div>
          <div
            className="container"
            style={{
              textAlign: "left",
              display: "flex",
              gap: "10px",
              margin: "10px 8px",
            }}
          >
            {formPage == "one" ? (
              <div
                className="form-1"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  // padding: "15px",
                }}
              >
                <div className="input">
                  <div className="input-box">
                    <p className="input-label">
                      First Name<span style={{ color: "red" }}>*</span>
                    </p>
                    <InputText
                      placeholder="First Name"
                      disabled={view}
                      value={userDetails?.firstName}
                      onChange={(e) => {
                        setUserDetails((prev) => ({
                          ...prev,
                          firstName: e.target.value,
                        }));
                      }}
                    />
                    {inValid && userDetails?.firstName == "" && (
                      <p className="err-msg" style={{ color: "red" }}>
                        Fisrt Name is required
                      </p>
                    )}
                  </div>
                </div>
                <div className="input">
                  <div className="input-box">
                    <p className="input-label">
                      Last Name<span style={{ color: "red" }}>*</span>
                    </p>
                    <InputText
                      placeholder="Last Name"
                      disabled={view}
                      value={userDetails?.lastName}
                      onChange={(e) => {
                        setUserDetails((prev) => ({
                          ...prev,
                          lastName: e.target.value,
                        }));
                      }}
                    />
                    {inValid && userDetails?.lastName == "" && (
                      <p className="err-msg" style={{ color: "red" }}>
                        Last Name is required
                      </p>
                    )}
                  </div>
                </div>
                <div className="input">
                  <div className="input-box">
                    <p className="input-label">
                      Nationality<span style={{ color: "red" }}>*</span>
                    </p>
                    {/* <Dropdown
                      disabled={view}
                      value={userDetails?.nationality}
                      onChange={(e) =>
                        setUserDetails((prev) => ({
                          ...prev,
                          nationality: e.value,
                        }))
                      }
                      options={nationality}
                      optionLabel="english_name"
                      placeholder="Select a Nationality"
                      // className="w-full md:w-14rem"
                      style={{ width: "100%" }}
                    /> */}
                    <AutoComplete
                      value={userDetails?.nationality}
                      suggestions={nationalityList}
                      disabled={view}
                      completeMethod={searchNationality}
                      placeholder="Select Nationality"
                      virtualScrollerOptions={{ itemSize: 38 }}
                      field="english_name"
                      dropdown
                      onChange={(e) => {
                        setUserDetails((prev) => ({
                          ...prev,
                          nationality: e.value,
                        }));
                        // setTempInvoicesList([...tempInvoiceList]);
                      }}
                    />
                    {inValid && userDetails?.nationality == "" && (
                      <p className="err-msg" style={{ color: "red" }}>
                        Nationality is required
                      </p>
                    )}
                  </div>
                </div>
                <div className="input">
                  <div className="input-box">
                    <p className="input-label">
                      Date Of Birth<span style={{ color: "red" }}>*</span>
                    </p>
                    <Calendar
                      placeholder="Select DOB"
                      disabled={view}
                      className="calendar"
                      showIcon
                      dateFormat="dd/mm/yy"
                      value={
                        userDetails?.dateOfBirth
                          ? new Date(userDetails?.dateOfBirth)
                          : new Date()
                      }
                      maxDate={new Date()}
                      onChange={(e) => {
                        console.log(e.value);
                        setUserDetails((prev) => ({
                          ...prev,
                          dateOfBirth: e.value,
                        }));
                      }}
                    />
                    {inValid && userDetails?.dateOfBirth == "" && (
                      <p className="err-msg" style={{ color: "red" }}>
                        Date of birth is required
                      </p>
                    )}
                    {/* <InputText placeholder="Name" value={userDetails?.dateOfBirth} onChange={(e) => { setUserDetails((prev) => ({ ...prev, dateOfBirth: e.target.value })) }} /> */}
                  </div>
                </div>
                <div className="input">
                  <div className="input-box">
                    <p className="input-label">
                      Country of Residence
                      <span style={{ color: "red" }}>*</span>
                    </p>
                    {/* <Dropdown
                      disabled={view}
                      value={userDetails?.countryOfResidence}
                      onChange={(e) =>
                        setUserDetails((prev) => ({
                          ...prev,
                          countryOfResidence: e.value,
                        }))
                      }
                      options={nationality}
                      optionLabel="english_name"
                      placeholder="Country of Residence"
                      className="w-full md:w-14rem"
                      style={{ width: "224px" }}
                    /> */}
                    <AutoComplete
                      value={userDetails?.countryOfResidence}
                      suggestions={countriesList}
                      disabled={view}
                      completeMethod={searchCountries}
                      virtualScrollerOptions={{ itemSize: 38 }}
                      placeholder="Select Country Of Residence"
                      field="english_name"
                      dropdown
                      onChange={(e) => {
                        setUserDetails((prev) => ({
                          ...prev,
                          countryOfResidence: e.value,
                        }));
                        // setTempInvoicesList([...tempInvoiceList]);
                      }}
                    />
                    {/* <InputText placeholder="Country of Residence" disabled={view} value={userDetails?.countryOfResidence} onChange={(e) => { setUserDetails((prev) => ({ ...prev, countryOfResidence: e.target.value })) }} /> */}
                    {inValid && userDetails?.countryOfResidence == "" && (
                      <p className="err-msg" style={{ color: "red" }}>
                        Country of residence is required
                      </p>
                    )}
                  </div>
                </div>

                <div className="input">
                  <div className="input-box">
                    <p className="input-label">
                      Email Address<span style={{ color: "red" }}>*</span>
                    </p>
                    <InputText
                      placeholder="Email Address"
                      disabled={view}
                      value={userDetails?.emailAddress}
                      onChange={(e) => {
                        setUserDetails((prev) => ({
                          ...prev,
                          emailAddress: e.target.value,
                        }));
                      }}
                    />

                    {inValid && (
                      <p className="err-msg" style={{ color: "red" }}>
                        {!userDetails?.emailAddress
                          ? "Email id is required"
                          : !emailValidation()
                            ? "Enter a valid email id"
                            : ""}
                      </p>
                    )}
                  </div>
                </div>

                <div className="input ">
                  <div className="input-box">
                    <p className="input-label">
                      Mobile Number<span style={{ color: "red" }}>*</span>
                    </p>
                    <div style={{ border: "1px solid #ae9d81", width: "70%" }}>
                      <Dropdown disabled={view} value={selectedCountry} style={{ width: "100px !important", border: "none" }} onChange={(e) => { console.log(e.value); setSelectedCountry(e.value); handleCountryChange(e.value) }} options={nationalityListJSON} optionLabel="english_name"
                        valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate}
                      />
                      <InputText disabled={view} style={{ width: '50%', border: "none" }} value={userDetails?.mobileNumber} type='tel' min={0} placeholder='Enter here' onChange={(e) => { handleChange(e.target.value) }}></InputText>
                      {/* <input value={mobile} type='tel' min={0} placeholder='Enter here' onChange={(e) => { handleChange(e.target.value) }} ></input> */}
                    </div>
                    {inValid && userDetails?.mobileNumber == "" && (
                      <p className="err-msg" style={{ color: "red" }}>
                        Mobile number is required
                      </p>
                    )}
                  </div>
                </div>
                <div className="input">
                  <div className="input-box">
                    <p className="input-label">Secondary Mobile Number</p>
                    <div style={{ border: "1px solid #ae9d81", width: "70%" }}>
                      <Dropdown disabled={view} value={selectedSecondaryCountry} style={{ width: "100px !important", border: "none" }} onChange={(e) => { console.log(e.value); setSelectedSecondaryCountry(e.value); handleSecondaryCountryChange(e.value) }} options={nationalityListJSON} optionLabel="english_name"
                        valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate}
                      />
                      <InputText disabled={view} style={{ width: '50%', border: "none" }} value={userDetails?.secondaryMobileNuber} type='tel' min={0} placeholder='Enter here' onChange={(e) => {
                        setUserDetails((prev) => ({
                          ...prev,
                          secondaryMobileNuber: e.target.value,
                        }));
                      }}></InputText>
                      {/* <input value={mobile} type='tel' min={0} placeholder='Enter here' onChange={(e) => { handleChange(e.target.value) }} ></input> */}
                    </div>
                    {/* <InputText
                      placeholder="Mobile Number"
                      disabled={view}
                      type="number"
                      style={{ width: "70% !important" }}
                      min={0}
                      value={userDetails?.secondaryMobileNuber}
                      onChange={(e) => {
                        setUserDetails((prev) => ({
                          ...prev,
                          secondaryMobileNuber: e.target.value,
                        }));
                      }}
                    /> */}
                  </div>
                </div>
                <div className="input">
                  <div className="input-box">
                    <p className="input-label">
                      Membership Card Number
                      {/* <span style={{ color: "red" }}>*</span> */}
                    </p>
                    <InputText
                      placeholder="Membership Card Number"
                      // disabled={view}
                      value={userDetails?.cardNumber}
                      onChange={(e) => {
                        if (!isCardNumberChanged) {
                          setIsCardNumberChanged(true);
                        }
                        setUserDetails((prev) => ({
                          ...prev,
                          cardNumber: e.target.value,
                        }));
                      }}
                    />
                    {userDetails.cardNumber != "" && isCardNumberChanged && view && <Button label="Update" style={{
                      backgroundColor: "#ae9d81",
                      border: "none",
                      margin: "0px 10px",
                      width: "105px",
                    }}
                      onClick={editVipUser}
                    ></Button>}

                    {/* {inValid && userDetails?.cardNumber == "" && (
                      <p className="err-msg" style={{ color: "red" }}>
                        Membership card number is required
                      </p>
                    )} */}
                  </div>
                </div>

                <div className="input">
                  <div className="input-box">
                    <p className="input-label">
                      Prefered Mode Of Contact
                      <span style={{ color: "red" }}>*</span>
                    </p>
                    {/* <InputText placeholder="Prefer Mode of Contact" disabled={view} value={userDetails?.preferredModeOfContact} onChange={(e) => { setUserDetails((prev) => ({ ...prev, preferredModeOfContact: e.target.value })) }} /> */}
                    <Dropdown style={{ width: "70% !important" }}
                      value={userDetails?.preferredModeOfContact}
                      disabled={view}
                      onChange={(e) => {
                        setUserDetails((prev) => ({
                          ...prev,
                          preferredModeOfContact: e.value,
                        }));
                      }}
                      options={preferredModeOfContactOptions}
                      optionLabel="name"
                      placeholder="Select a Mode of Contact"
                      className="w-full md:w-14rem"
                    />
                    {inValid && userDetails?.preferredModeOfContact == "" && (
                      <p className="err-msg" style={{ color: "red" }}>
                        Prefer mode of contact is required
                      </p>
                    )}
                  </div>
                </div>
                {/* <div className="input">
              <div className="input-box">
                <p style={{ fontWeight: 400 }}>Company Name</p>
                <InputText
                  placeholder="Company Name"
                  disabled={view}
                  value={userDetails?.companyName}
                  onChange={(e) => {
                    setUserDetails((prev) => ({
                      ...prev,
                      companyName: e.target.value,
                    }));
                  }}
                />
               
              </div>
            </div>
            <div className="input">
              <div className="input-box">
                <p style={{ fontWeight: 400 }}>Designation</p>
                <InputText
                  placeholder="Designation"
                  disabled={view}
                  value={userDetails?.designation}
                  style={{ width: "224px" }}
                  onChange={(e) => {
                    setUserDetails((prev) => ({
                      ...prev,
                      designation: e.target.value,
                    }));
                  }}
                />
              
              </div>
            </div>
            <div className="input">
              <div className="input-box">
                <p style={{ fontWeight: 400 }}>Referred By</p>
                <InputText
                  placeholder="Referred By"
                  disabled={view}
                  value={userDetails?.referredBy}
                  onChange={(e) => {
                    setUserDetails((prev) => ({
                      ...prev,
                      referredBy: e.target.value,
                    }));
                  }}
                />
                
              </div>
            </div> */}

                <div className="input">
                  <div className="input-box">
                    <p style={{ fontWeight: 400 }}>
                      Would you like to be informed of the Marassi Galleria Mall
                      events and other information
                      <span style={{ color: "red" }}>*</span>
                    </p>
                    <div className="flex flex-wrap justify-content-start gap-3">
                      <div className="flex align-items-center">
                        <Checkbox
                          inputId="ingredient1"
                          disabled={view}
                          name="pizza"
                          value="Yes"
                          onChange={(e) => {
                            setUserDetails((prev) => ({
                              ...prev,
                              informedOfEvents: e.target.value,
                            }));
                          }}
                          checked={
                            userDetails?.informedOfEvents == "Yes" ||
                              userDetails?.informedOfEvents === true
                              ? true
                              : false
                          }
                        />
                        <label htmlFor="ingredient1" className="ml-2">
                          Yes
                        </label>
                      </div>
                      <div className="flex align-items-center">
                        <Checkbox
                          inputId="ingredient2"
                          disabled={view}
                          name="pizza"
                          value="No"
                          onChange={(e) => {
                            setUserDetails((prev) => ({
                              ...prev,
                              informedOfEvents: e.target.value,
                            }));
                          }}
                          checked={
                            userDetails?.informedOfEvents == "No" ||
                              userDetails?.informedOfEvents === false
                              ? true
                              : false
                          }
                        />
                        <label htmlFor="ingredient2" className="ml-2">
                          No
                        </label>
                      </div>
                    </div>
                    {inValid && userDetails?.informedOfEvents == "" && (
                      <p className="err-msg" style={{ color: "red" }}>
                        Please select an option
                      </p>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {formPage == "two" ? (
              <div
                className="form-2 col-12"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: "90%",
                  margin: "auto",
                }}
              >
                <p className="">
                  We would like to know more about you, so that we may serve you
                  even better
                </p>
                <div style={{ display: "flex", width: "100%" }}>
                  <div className="input-box col-6 p-0">
                    <p style={{ fontWeight: 400 }}>
                      1.What is/are favourite brand(s) at the Marassi Galleria
                      Mall?
                      <span style={{ color: "red" }}>*</span>
                    </p>
                    <InputText
                      style={{ width: "70%" }}
                      placeholder="Enter favourite brand"
                      disabled={view}
                      value={userDetails?.favoriteBrand}
                      onChange={(e) => {
                        setUserDetails((prev) => ({
                          ...prev,
                          favoriteBrand: e.target.value,
                        }));
                      }}
                    />
                    {inValid && userDetails?.favoriteBrand == "" && (
                      <p className="err-msg" style={{ color: "red" }}>
                        Favourite brand is required
                      </p>
                    )}
                  </div>
                  <div className="input-box col-6 p-0">
                    <p style={{ fontWeight: 400 }}>
                      2.What is your favourite attraction at the Marassi
                      Galleria Mall?<span style={{ color: "red" }}>*</span>
                    </p>
                    <InputText
                      style={{ width: "70%" }}
                      placeholder="Enter favourite attraction"
                      disabled={view}
                      value={userDetails?.favoriteAttraction}
                      onChange={(e) => {
                        setUserDetails((prev) => ({
                          ...prev,
                          favoriteAttraction: e.target.value,
                        }));
                      }}
                    />
                    {inValid && userDetails?.favoriteAttraction == "" && (
                      <p className="err-msg" style={{ color: "red" }}>
                        Favourite attraction is required
                      </p>
                    )}
                  </div>
                </div>
                <div style={{ display: "flex", width: "100%" }}>
                  <div className="input-box col-6 p-0">
                    <p style={{ fontWeight: 400 }}>
                      3.What is your restraurant/cafe at the Marassi Galleria
                      Mall?
                      <span style={{ color: "red" }}>*</span>
                    </p>
                    <InputText
                      style={{ width: "70%" }}
                      placeholder="Enter restraurant/cafe"
                      disabled={view}
                      value={userDetails?.favoriteRestaurantCafe}
                      onChange={(e) => {
                        setUserDetails((prev) => ({
                          ...prev,
                          favoriteRestaurantCafe: e.target.value,
                        }));
                      }}
                    />
                    {inValid && userDetails?.favoriteRestaurantCafe == "" && (
                      <p className="err-msg" style={{ color: "red" }}>
                        Restaurant/Cafe is required
                      </p>
                    )}
                  </div>
                  <div className="input-box col-6 p-0">
                    <p style={{ fontWeight: 400 }}>
                      4.How often you visit the Marassi Galleria Mall?
                      <span style={{ color: "red" }}>*</span>
                    </p>
                    <div className="flex flex-wrap justify-content-start gap-3">
                      <div className="flex align-items-center">
                        <Checkbox
                          inputId="visitDaily"
                          disabled={view}
                          name="daily"
                          value="Daily"
                          onChange={(e) => {
                            setUserDetails((prev) => ({
                              ...prev,
                              visitFrequency: e.target.value,
                            }));
                          }}
                          checked={
                            userDetails?.visitFrequency == "Daily"
                              ? true
                              : false
                          }
                        />
                        <label htmlFor="visitDaily" className="ml-2">
                          Daily
                        </label>
                      </div>
                      <div className="flex align-items-center">
                        <Checkbox
                          inputId="visitWeekly"
                          disabled={view}
                          name="weekly"
                          value="Weekly"
                          onChange={(e) => {
                            setUserDetails((prev) => ({
                              ...prev,
                              visitFrequency: e.target.value,
                            }));
                          }}
                          checked={
                            userDetails?.visitFrequency == "Weekly"
                              ? true
                              : false
                          }
                        />
                        <label htmlFor="visitWeekly" className="ml-2">
                          Weekly
                        </label>
                      </div>
                      <div className="flex align-items-center">
                        <Checkbox
                          inputId="visitMonthly"
                          disabled={view}
                          name="monthly"
                          value="Monthly"
                          onChange={(e) => {
                            setUserDetails((prev) => ({
                              ...prev,
                              visitFrequency: e.target.value,
                            }));
                          }}
                          checked={
                            userDetails?.visitFrequency == "Monthly"
                              ? true
                              : false
                          }
                        />
                        <label htmlFor="visitMonthly" className="ml-2">
                          Monthly
                        </label>
                      </div>
                      <div className="flex align-items-center">
                        <Checkbox
                          inputId="visitOthers"
                          disabled={view}
                          name="others"
                          value="Others"
                          onChange={(e) => {
                            setUserDetails((prev) => ({
                              ...prev,
                              visitFrequency: e.target.value,
                            }));
                          }}
                          checked={
                            userDetails?.visitFrequency != "Daily" &&
                              userDetails?.visitFrequency != "Weekly" &&
                              userDetails?.visitFrequency != "Monthly" &&
                              userDetails?.visitFrequency != ""
                              ? true
                              : false
                          }
                        />
                        <label htmlFor="visitOthers" className="ml-2">
                          Others(Please Specify)
                        </label>
                      </div>
                    </div>
                    {userDetails?.visitFrequency != "Daily" &&
                      userDetails?.visitFrequency != "Weekly" &&
                      userDetails?.visitFrequency != "Monthly" &&
                      userDetails?.visitFrequency != "" && (
                        <InputText
                          className="mt-2"
                          placeholder="Others"
                          disabled={view}
                          value={
                            vipCode
                              ? userDetails?.visitFrequency
                              : userDetails?.visitFrequencyOthers
                          }
                          onChange={(e) => {
                            setUserDetails((prev) => ({
                              ...prev,
                              visitFrequencyOthers: e.target.value,
                            }));
                          }}
                        />
                      )}
                    {inValid && userDetails?.visitFrequency == "" && (
                      <p className="err-msg" style={{ color: "red" }}>
                        Visit Frequency is required
                      </p>
                    )}
                  </div>
                </div>
                <div style={{ display: "flex", width: "100%" }}>
                  <div className="input-box col-6 p-0">
                    <p style={{ fontWeight: 400 }}>
                      5.What days of the week do you visit the Marassi Galleria
                      Mall?<span style={{ color: "red" }}>*</span>
                    </p>
                    {/* {view ? <p style={{ fontWeight: 400 }}>{userDetails?.visitDaysOfWeek?.map((e) => { return e.name })?.join(",")}</p>
                                : */}
                    {view ? (
                      <InputText
                        className="mt-2"
                        placeholder=""
                        disabled={true}
                        value={userDetails?.visitDaysOfWeek}
                      />
                    ) : (
                      <MultiSelect
                        value={userDetails?.visitDaysOfWeek}
                        display="chip"
                        onChange={(e) => {
                          setUserDetails((prev) => ({
                            ...prev,
                            visitDaysOfWeek: e.target.value,
                          }));
                        }}
                        options={days}
                        optionLabel="name"
                        optionValue="name"
                        placeholder="Select a days"
                        className="w-full md:w-14rem"
                      />
                    )}
                    {inValid && userDetails?.visitDaysOfWeek == "" && (
                      <p className="err-msg" style={{ color: "red" }}>
                        Visit Days is required
                      </p>
                    )}
                  </div>
                  <div className="input-box col-6 p-0">
                    <p style={{ fontWeight: 400 }}>
                      6.Who do you visit the Marassi Galleria mall with?
                      <span style={{ color: "red" }}>*</span>
                    </p>
                    <div className="flex flex-wrap justify-content-start gap-3">
                      <div className="flex align-items-center">
                        <Checkbox
                          inputId="visitWithFamily"
                          disabled={view}
                          name="Family"
                          value="Family"
                          onChange={(e) => {
                            setUserDetails((prev) => ({
                              ...prev,
                              visitWith: e.target.value,
                            }));
                          }}
                          checked={
                            userDetails?.visitWith == "Family" ? true : false
                          }
                        />
                        <label htmlFor="visitWithFamily" className="ml-2">
                          Family
                        </label>
                      </div>
                      <div className="flex align-items-center">
                        <Checkbox
                          inputId="visitWithFriends"
                          disabled={view}
                          name="Friends"
                          value="Friends"
                          onChange={(e) => {
                            setUserDetails((prev) => ({
                              ...prev,
                              visitWith: e.target.value,
                            }));
                          }}
                          checked={
                            userDetails?.visitWith == "Friends" ? true : false
                          }
                        />
                        <label htmlFor="visitWithFriends" className="ml-2">
                          Friends
                        </label>
                      </div>
                      <div className="flex align-items-center">
                        <Checkbox
                          inputId="visitWithAlone"
                          disabled={view}
                          name="Alone"
                          value="Alone"
                          onChange={(e) => {
                            setUserDetails((prev) => ({
                              ...prev,
                              visitWith: e.target.value,
                            }));
                          }}
                          checked={
                            userDetails?.visitWith == "Alone" ? true : false
                          }
                        />
                        <label htmlFor="visitWithAlone" className="ml-2">
                          Alone
                        </label>
                      </div>
                      <div className="flex align-items-center">
                        <Checkbox
                          inputId="visitWithOthers"
                          disabled={view}
                          name="Others"
                          value="Others"
                          onChange={(e) => {
                            setUserDetails((prev) => ({
                              ...prev,
                              visitWith: e.target.value,
                            }));
                          }}
                          checked={
                            userDetails?.visitWith != "Family" &&
                              userDetails?.visitWith != "Friends" &&
                              userDetails?.visitWith != "Alone" &&
                              userDetails?.visitWith != ""
                              ? true
                              : false
                          }
                        />
                        <label htmlFor="visitWithOthers" className="ml-2">
                          Other(Please Specify)
                        </label>
                      </div>
                    </div>
                    {userDetails?.visitWith != "Family" &&
                      userDetails?.visitWith != "Friends" &&
                      userDetails?.visitWith != "Alone" &&
                      userDetails?.visitWith != "" && (
                        <InputText
                          className="mt-2"
                          placeholder="Other(Please Specify)"
                          disabled={view}
                          value={
                            vipCode
                              ? userDetails?.visitWith
                              : userDetails?.visitWithOthers
                          }
                          onChange={(e) => {
                            setUserDetails((prev) => ({
                              ...prev,
                              visitWithOthers: e.target.value,
                            }));
                          }}
                        />
                      )}
                    {inValid && userDetails?.visitWith == "" && (
                      <p className="err-msg" style={{ color: "red" }}>
                        Who do you visit the Marassi Galleria mall with is
                        required
                      </p>
                    )}
                  </div>
                </div>
                <div style={{ display: "flex", width: "100%" }}>
                  <div className="input-box col-6 p-0">
                    <p style={{ fontWeight: 400 }}>
                      7.Do you participate in the Marassi Galleri Mall's Spend
                      and Win's promotions?
                      <span style={{ color: "red" }}>*</span>
                    </p>
                    <div
                      className="flex flex-wrap justify-content-start gap-3"
                      style={{ padding: "0px 0px 25px 0px" }}
                    >
                      <div className="flex align-items-center">
                        <Checkbox
                          inputId="pricesYes"
                          disabled={view}
                          name="pizza"
                          value="Yes"
                          onChange={() => {
                            setUserDetails((prev) => ({
                              ...prev,
                              participateInPromotions: "Yes",
                            }));
                          }}
                          checked={
                            userDetails?.participateInPromotions == "Yes"
                              ? true
                              : false
                          }
                        />
                        <label htmlFor="pricesYes" className="ml-2">
                          Yes
                        </label>
                      </div>
                      <div className="flex align-items-center">
                        <Checkbox
                          inputId="pricesNo"
                          disabled={view}
                          name="pizza"
                          value="No"
                          onChange={() => {
                            setUserDetails((prev) => ({
                              ...prev,
                              participateInPromotions: "No",
                            }));
                          }}
                          checked={
                            userDetails?.participateInPromotions == "No"
                              ? true
                              : false
                          }
                        />
                        <label htmlFor="pricesNo" className="ml-2">
                          No
                        </label>
                      </div>
                    </div>
                    {inValid && userDetails?.participateInPromotions == "" && (
                      <p className="err-msg" style={{ color: "red" }}>
                        Participate in Promotions is required
                      </p>
                    )}
                  </div>
                  {userDetails?.participateInPromotions == "Yes" ? (
                    <div className="input-box col-6 p-0">
                      <p style={{ fontWeight: 400 }}>
                        7(a).What prices would attract you to participate in a
                        promotion?
                        <span style={{ color: "red" }}>*</span>
                      </p>
                      <InputText
                        style={{ width: "70%" }}
                        placeholder="Enter prices"
                        disabled={view}
                        value={userDetails?.attractivePrizes}
                        onChange={(e) => {
                          setUserDetails((prev) => ({
                            ...prev,
                            attractivePrizes: e.target.value,
                          }));
                        }}
                      />
                      {inValid &&
                        userDetails?.participateInPromotions == "Yes" &&
                        userDetails?.attractivePrizes == "" && (
                          <p className="err-msg" style={{ color: "red" }}>
                            Interested In Prices is required
                          </p>
                        )}
                    </div>
                  ) : null}
                </div>
                {/* <div style={{ }}> */}
                <div className="input-box col-6 p-0">
                  <p style={{ fontWeight: 400 }}>
                    8.Among the following services available at the Marassi
                    Galleria Mall, which do you use, and how?
                    <span style={{ color: "red" }}>*</span>
                  </p>
                  <div className="flex flex-wrap  gap-3">
                    <p
                      style={{ fontWeight: 400, width: "30%", margin: "8px 0" }}
                    >
                      a) Valet Parking<span style={{ color: "red" }}>*</span>
                    </p>
                    <div className="flex align-items-center">
                      <Checkbox
                        inputId="valetOften"
                        disabled={view}
                        name="pizza"
                        value="often"
                        onChange={(e) => {
                          setUserDetails((prev) => ({
                            ...prev,
                            valetParkingFrequency: e.target.value,
                          }));
                        }}
                        checked={
                          userDetails?.valetParkingFrequency == "often"
                            ? true
                            : false
                        }
                      />
                      <label htmlFor="valetOften" className="ml-2">
                        Often
                      </label>
                    </div>
                    <div className="flex align-items-center">
                      <Checkbox
                        inputId="valetSometimes"
                        disabled={view}
                        name="pizza"
                        value="sometimes"
                        onChange={(e) => {
                          setUserDetails((prev) => ({
                            ...prev,
                            valetParkingFrequency: e.target.value,
                          }));
                        }}
                        checked={
                          userDetails?.valetParkingFrequency == "sometimes"
                            ? true
                            : false
                        }
                      />
                      <label htmlFor="valetSometimes" className="ml-2">
                        Sometimes
                      </label>
                    </div>
                    <div className="flex align-items-center">
                      <Checkbox
                        inputId="valetNever"
                        disabled={view}
                        name="pizza"
                        value="never"
                        onChange={(e) => {
                          setUserDetails((prev) => ({
                            ...prev,
                            valetParkingFrequency: e.target.value,
                          }));
                        }}
                        checked={
                          userDetails?.valetParkingFrequency == "never"
                            ? true
                            : false
                        }
                      />
                      <label htmlFor="valetNever" className="ml-2">
                        Never
                      </label>
                    </div>
                  </div>
                  {inValid && userDetails?.valetParkingFrequency == "" && (
                    <p
                      className="err-msg"
                      style={{ color: "red", marginLeft: "20px" }}
                    >
                      Valet Parking is required
                    </p>
                  )}
                  <div className="flex flex-wrap  gap-3">
                    <p
                      style={{ fontWeight: 400, width: "30%", margin: "8px 0" }}
                    >
                      b) Assistance service for carrying items{" "}
                      <span style={{ color: "red" }}>*</span>
                    </p>
                    <div className="flex align-items-center">
                      <Checkbox
                        inputId="assistanceOften"
                        disabled={view}
                        name="pizza"
                        value="often"
                        onChange={(e) => {
                          setUserDetails((prev) => ({
                            ...prev,
                            aiisitanceService: e.target.value,
                          }));
                        }}
                        checked={
                          userDetails?.aiisitanceService == "often"
                            ? true
                            : false
                        }
                      />
                      <label htmlFor="assistanceOften" className="ml-2">
                        Often
                      </label>
                    </div>
                    <div className="flex align-items-center">
                      <Checkbox
                        inputId="assistanceSometimes"
                        disabled={view}
                        name="pizza"
                        value="sometimes"
                        onChange={(e) => {
                          setUserDetails((prev) => ({
                            ...prev,
                            aiisitanceService: e.target.value,
                          }));
                        }}
                        checked={
                          userDetails?.aiisitanceService == "sometimes"
                            ? true
                            : false
                        }
                      />
                      <label htmlFor="assistanceSometimes" className="ml-2">
                        Sometimes
                      </label>
                    </div>
                    <div className="flex align-items-center">
                      <Checkbox
                        inputId="assistanceNever"
                        disabled={view}
                        name="pizza"
                        value="never"
                        onChange={(e) => {
                          setUserDetails((prev) => ({
                            ...prev,
                            aiisitanceService: e.target.value,
                          }));
                        }}
                        checked={
                          userDetails?.aiisitanceService == "never"
                            ? true
                            : false
                        }
                      />
                      <label htmlFor="assistanceNever" className="ml-2">
                        Never
                      </label>
                    </div>
                  </div>
                  {inValid && userDetails?.aiisitanceService == "" && (
                    <p
                      className="err-msg"
                      style={{ color: "red", marginLeft: "20px" }}
                    >
                      Assistance service is required
                    </p>
                  )}
                  <div className="flex flex-wrap  gap-3">
                    <p
                      style={{ fontWeight: 400, width: "30%", margin: "8px 0" }}
                    >
                      c) Car wash service inside the complex
                      <span style={{ color: "red" }}>*</span>
                    </p>
                    <div className="flex align-items-center">
                      <Checkbox
                        inputId="carOften"
                        disabled={view}
                        name="pizza"
                        value="often"
                        onChange={(e) => {
                          setUserDetails((prev) => ({
                            ...prev,
                            carWashServiceUsage: e.target.value,
                          }));
                        }}
                        checked={
                          userDetails?.carWashServiceUsage == "often"
                            ? true
                            : false
                        }
                      />
                      <label htmlFor="carOften" className="ml-2">
                        Often
                      </label>
                    </div>
                    <div className="flex align-items-center">
                      <Checkbox
                        inputId="carSometimes"
                        disabled={view}
                        name="pizza"
                        value="sometimes"
                        onChange={(e) => {
                          setUserDetails((prev) => ({
                            ...prev,
                            carWashServiceUsage: e.target.value,
                          }));
                        }}
                        checked={
                          userDetails?.carWashServiceUsage == "sometimes"
                            ? true
                            : false
                        }
                      />
                      <label htmlFor="carSometimes" className="ml-2">
                        Sometimes
                      </label>
                    </div>
                    <div className="flex align-items-center">
                      <Checkbox
                        inputId="carNever"
                        disabled={view}
                        name="pizza"
                        value="never"
                        onChange={(e) => {
                          setUserDetails((prev) => ({
                            ...prev,
                            carWashServiceUsage: e.target.value,
                          }));
                        }}
                        checked={
                          userDetails?.carWashServiceUsage == "never"
                            ? true
                            : false
                        }
                      />
                      <label htmlFor="carNever" className="ml-2">
                        Never
                      </label>
                    </div>
                  </div>
                  {inValid && userDetails?.carWashServiceUsage == "" && (
                    <p
                      className="err-msg"
                      style={{ color: "red", marginLeft: "20px" }}
                    >
                      Car wash service is required
                    </p>
                  )}
                  {/* <div className="flex flex-wrap  gap-3">
                <p style={{ fontWeight: 400, width: "30%", margin: "8px 0" }}>
                  b.Porter Service <span style={{ color: "red" }}>*</span>
                </p>
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="ingredient1"
                    disabled={view}
                    name="pizza"
                    value="often"
                    onChange={(e) => {
                      setUserDetails((prev) => ({
                        ...prev,
                        porterServiceFrequency: e.target.value,
                      }));
                    }}
                    checked={
                      userDetails?.porterServiceFrequency == "often"
                        ? true
                        : false
                    }
                  />
                  <label htmlFor="ingredient1" className="ml-2">
                    Often
                  </label>
                </div>
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="ingredient2"
                    disabled={view}
                    name="pizza"
                    value="sometimes"
                    onChange={(e) => {
                      setUserDetails((prev) => ({
                        ...prev,
                        porterServiceFrequency: e.target.value,
                      }));
                    }}
                    checked={
                      userDetails?.porterServiceFrequency == "sometimes"
                        ? true
                        : false
                    }
                  />
                  <label htmlFor="ingredient2" className="ml-2">
                    Sometimes
                  </label>
                </div>
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="ingredient2"
                    disabled={view}
                    name="pizza"
                    value="never"
                    onChange={(e) => {
                      setUserDetails((prev) => ({
                        ...prev,
                        porterServiceFrequency: e.target.value,
                      }));
                    }}
                    checked={
                      userDetails?.porterServiceFrequency == "never"
                        ? true
                        : false
                    }
                  />
                  <label htmlFor="ingredient2" className="ml-2">
                    Never
                  </label>
                </div>
              </div>
              <div className="flex flex-wrap  gap-3">
                <p style={{ fontWeight: 400, width: "30%", margin: "8px 0" }}>
                  c.Locker Room<span style={{ color: "red" }}>*</span>
                </p>
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="ingredient1"
                    disabled={view}
                    name="pizza"
                    value="often"
                    onChange={(e) => {
                      setUserDetails((prev) => ({
                        ...prev,
                        lockerRoomFrequency: e.target.value,
                      }));
                    }}
                    checked={
                      userDetails?.lockerRoomFrequency == "often" ? true : false
                    }
                  />
                  <label htmlFor="ingredient1" className="ml-2">
                    Often
                  </label>
                </div>
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="ingredient2"
                    disabled={view}
                    name="pizza"
                    value="sometimes"
                    onChange={(e) => {
                      setUserDetails((prev) => ({
                        ...prev,
                        lockerRoomFrequency: e.target.value,
                      }));
                    }}
                    checked={
                      userDetails?.lockerRoomFrequency == "sometimes"
                        ? true
                        : false
                    }
                  />
                  <label htmlFor="ingredient2" className="ml-2">
                    Sometimes
                  </label>
                </div>
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="ingredient2"
                    disabled={view}
                    name="pizza"
                    value="never"
                    onChange={(e) => {
                      setUserDetails((prev) => ({
                        ...prev,
                        lockerRoomFrequency: e.target.value,
                      }));
                    }}
                    checked={
                      userDetails?.lockerRoomFrequency == "never" ? true : false
                    }
                  />
                  <label htmlFor="ingredient2" className="ml-2">
                    Never
                  </label>
                </div>
              </div>
              <div className="flex flex-wrap  gap-3">
                <p style={{ fontWeight: 400, width: "30%", margin: "8px 0" }}>
                  d.In-Mall Taxi<span style={{ color: "red" }}>*</span>
                </p>
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="ingredient1"
                    disabled={view}
                    name="pizza"
                    value="often"
                    onChange={(e) => {
                      setUserDetails((prev) => ({
                        ...prev,
                        inMallTaxiFrequency: e.target.value,
                      }));
                    }}
                    checked={
                      userDetails?.inMallTaxiFrequency == "often" ? true : false
                    }
                  />
                  <label htmlFor="ingredient1" className="ml-2">
                    Often
                  </label>
                </div>
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="ingredient2"
                    disabled={view}
                    name="pizza"
                    value="sometimes"
                    onChange={(e) => {
                      setUserDetails((prev) => ({
                        ...prev,
                        inMallTaxiFrequency: e.target.value,
                      }));
                    }}
                    checked={
                      userDetails?.inMallTaxiFrequency == "sometimes"
                        ? true
                        : false
                    }
                  />
                  <label htmlFor="ingredient2" className="ml-2">
                    Sometimes
                  </label>
                </div>
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="ingredient2"
                    disabled={view}
                    name="pizza"
                    value="never"
                    onChange={(e) => {
                      setUserDetails((prev) => ({
                        ...prev,
                        inMallTaxiFrequency: e.target.value,
                      }));
                    }}
                    checked={
                      userDetails?.inMallTaxiFrequency == "never" ? true : false
                    }
                  />
                  <label htmlFor="ingredient2" className="ml-2">
                    Never
                  </label>
                </div>
              </div> */}
                  <div className="flex" style={{ marginLeft: "20px" }}>
                    <p
                      style={{ fontWeight: 400, width: "47%", margin: "8px 0" }}
                    >
                      d) Others(Please Specify)
                    </p>
                    <InputText
                      className="mt-1"
                      placeholder="Enter text"
                      disabled={view}
                      value={userDetails?.otherServicesUsage}
                      onChange={(e) => {
                        setUserDetails((prev) => ({
                          ...prev,
                          otherServicesUsage: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>
                {/* </div> */}
              </div>
            ) : (
              ""
            )}

            {formPage == "three" ? (
              <div
                className="form-3"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  padding: "0px 15px",
                }}
              >
                <div className="input">
                  <div className="input-box">
                    <p style={{ fontWeight: 400 }}>Subscription  <span style={{ color: "red" }}>*</span></p>
                    <Dropdown style={{ width: "70% !important" }}
                      value={getSelectedData(
                        userDetails?.subscriptionDetails?.subscriptionId ||
                        userDetails?.subscriptionDetails?.id,
                        allSubscriptions
                      )}
                      disabled={view}
                      onChange={(e) => {
                        setUserDetails((prev) => ({
                          ...prev,
                          subscriptionDetails: e?.value,
                        }));
                      }}
                      options={allSubscriptions}
                      optionLabel="name"
                      placeholder="Select here"
                      className="w-full md:w-14rem"
                    />
                    {inValid && !userDetails?.subscriptionDetails && (
                      <p className="err-msg" style={{ color: "red" }}>
                        Subscription is required
                      </p>
                    )}
                  </div>
                </div>
                {/* <div className="input">
                  <div className="input-box">
                    <p style={{ fontWeight: 400 }}>Organization</p>
                    <Dropdown style={{width: "70% !important"}}
                      value={getSelectedData(
                        userDetails?.organizationDetails?.organizationId ||
                          userDetails?.organizationDetails?.id,
                        allOrganizations
                      )}
                      disabled={view}
                      onChange={(e) => {
                        setUserDetails((prev) => ({
                          ...prev,
                          organizationDetails: e?.value,
                        }));
                      }}
                      options={allOrganizations}
                      optionLabel="name"
                      placeholder="Select here"
                      className="w-full md:w-14rem"
                      style={{ width: "" }}
                    />
                  </div>
                </div>
                <div
                  className="input"
                  style={{ width: "100%", margin: "15px" }}
                >
                    </div>*/}
                <div className="input" style={{ width: "100%" }}>
                  <div className="input-box">
                    <h2
                      style={{
                        margin: 0,
                        color: "#ae9d81",
                        textAlign: "center",
                        fontWeight: 600,
                      }}
                    >
                      Vehicle Information
                    </h2>
                  </div>
                </div>
                <div className="input">
                  <div className="input-box">
                    <p style={{ fontWeight: 400 }}>Vehicle Brand / Make</p>
                    {/* <InputText
                      placeholder="Enter Vehicle Brand / Make"
                      disabled={view}
                      value={userDetails?.vehicleBrand}
                      onChange={(e) => {
                        setUserDetails((prev) => ({
                          ...prev,
                          vehicleBrand: e.target.value,
                        }));
                      }}
                    /> */}
                    <Dropdown disabled={view} options={brandList?.length > 0 ? brandList : []} placeholder="Select vehicle brand / make" optionLabel="name" optionValue="name" value={userDetails?.vehicleBrand} onChange={(e) => {
                      setUserDetails((prev) => ({
                        ...prev,
                        vehicleBrand: e.value,
                      }));
                    }} />
                  </div>
                </div>
                <div className="input">
                  <div className="input-box">
                    <p style={{ fontWeight: 400 }}>Model</p>
                    <InputText
                      placeholder="Enter Model"
                      disabled={view}
                      value={userDetails?.model}
                      onChange={(e) => {
                        setUserDetails((prev) => ({
                          ...prev,
                          model: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="input">
                  <div className="input-box">
                    <p style={{ fontWeight: 400 }}>Prefix</p>
                    <InputText
                      placeholder="Enter Prefix"
                      disabled={view}
                      value={userDetails?.prefix}
                      onChange={(e) => {
                        setUserDetails((prev) => ({
                          ...prev,
                          prefix: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="input">
                  <div className="input-box">
                    <p style={{ fontWeight: 400 }}>Plate Number</p>
                    <InputText
                      placeholder="Enter Plate Number"
                      disabled={view}
                      value={userDetails?.plateNumber}
                      onChange={(e) => {
                        setUserDetails((prev) => ({
                          ...prev,
                          plateNumber: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="input">
                  <div className="input-box">
                    <p style={{ fontWeight: 400 }}>Plate Country</p>
                    {/* <InputText
                      placeholder="Enter Plate Country"
                      disabled={view}
                      value={userDetails?.emirate}
                      onChange={(e) => {
                        setUserDetails((prev) => ({
                          ...prev,
                          emirate: e.target.value,
                        }));
                      }}
                    /> */}
                    <Dropdown disabled={view} options={countryPlateList} placeholder="Select Plate Country" optionLabel="name" optionValue="name" value={userDetails?.emirate} onChange={(e) => {
                      setUserDetails((prev) => ({
                        ...prev,
                        emirate: e.value,
                      }));
                    }} />
                  </div>
                </div>
                {vipCode === "" && (
                  <div className="check">
                    <div className="check-box">
                      <Checkbox
                        disabled={view}
                        checked={userDetails?.reciveEmarEmail}
                        onChange={(e) => {
                          setUserDetails((prev) => ({
                            ...prev,
                            reciveEmarEmail: e.target.checked,
                          }));
                        }}
                      />
                      <p style={{ fontWeight: 400 }}>
                        I'd like to receive Marassi Galleria Mall emails (optional)
                      </p>
                    </div>
                  </div>
                )}
                {!view &&
                  <div className="check">
                    <div className="check-box">
                      <Checkbox
                        disabled={view}
                        checked={userDetails?.termsAndConditions}
                        onChange={(e) => {
                          setUserDetails((prev) => ({
                            ...prev,
                            termsAndConditions: e.target.checked,
                          }));
                        }}
                      />
                      <p style={{ fontWeight: 400 }}>
                        By using this service ,you acknowledge and agree that
                        you have read, understood and consent to these{" "}
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            window.open(TermsandConditions)
                            // var downloadLink = document.createElement("a");
                            // downloadLink.href = TermsandConditions;
                            // downloadLink.download = "TermsandConditions.pdf";
                            // document.body.appendChild(downloadLink);
                            // downloadLink.click();
                            // document.body.removeChild(downloadLink);
                          }}>
                          Terms and Conditions</span> agree to be bound by
                        them.
                        <span style={{ color: "red" }}>*</span>
                      </p>
                    </div>
                    {inValid && !userDetails?.termsAndConditions && (
                      <p className="err-msg" style={{ color: "red" }}>
                        Accepting Terms and Conditions is required
                      </p>
                    )}
                  </div>}

              </div>
            ) : (
              ""
            )}
          </div>
          <div className="buttons">
            <div
              className="flex justify-content-center gap-3"
              style={{ margin: "10px 20px 20px 20px" }}
            >
              <>
                {formPage !== "one" && (
                  <Button
                    label="Previous"
                    style={{
                      backgroundColor: "#ae9d81",
                      border: "none",
                      margin: "10px",
                      width: "105px",
                    }}
                    onClick={() => {
                      getFormPageName(false);
                    }}
                  />
                )}
                <Button
                  label="Cancel"
                  style={{
                    backgroundColor: "#adadad",
                    border: "none",
                    margin: "10px",
                    width: "105px",
                  }}
                  onClick={() => {
                    setInvalid(false);
                    setView(false);

                    setUserDetails({ ...userInitialValue });
                    if (isApproveScreen === "true") {
                      navigate("/supervisor-user-approval");
                    } else {
                      navigate("/vip-user-list");
                    }
                  }}
                />

                {isApproveScreen === "true" ? (
                  <>
                    {/* {formPage === "three" && !userDetails.isApproved && (
                      <Button
                        label="Reject"
                        style={{
                          backgroundColor: "#cf5555",
                          border: "none",
                          margin: "10px",
                          width: "105px",
                        }}
                        onClick={() => {
                          handleReject();
                        }}
                      />
                    )} */}
                    {formPage !== "three" && (
                      <Button
                        label="Next"
                        style={{
                          backgroundColor:
                            formPage === "three" ? "#6cc76c" : "#ae9d81",
                          border: "none",
                          margin: "10px",
                          width: "105px",
                        }}
                        onClick={() => {
                          getFormPageName(true);
                        }}
                      />
                    )}
                    {formPage === "three" && !userDetails.isApproved && (
                      <Button
                        label="Approve"
                        loading={registrationloader}
                        style={{
                          backgroundColor:
                            formPage === "three" ? "#6cc76c" : "#ae9d81",
                          border: "none",
                          margin: "10px",
                          width: "105px",
                        }}
                        onClick={() => {
                          getSubscriptionFunction();
                        }}
                      />
                    )}
                  </>
                ) : (
                  !(formPage === "three" && view) && (
                    <Button
                      label={
                        registrationloader
                          ? ""
                          : formPage === "three" && !view
                            ? "Submit"
                            : "Next"
                      }
                      loading={registrationloader}
                      style={{
                        backgroundColor: "#ae9d81",
                        border: "none",
                        margin: "10px",
                        width: "105px",
                      }}
                      onClick={() => {
                        if (formPage === "three") {
                          registerUser();
                        } else {
                          getFormPageName(true);
                        }
                      }}
                    />
                  )
                )}

                {/* {inValid && (
                  <p style={{ color: "red" }}>
                    Please fill the required fields
                  </p>
                )} */}
              </>
            </div>
          </div>

          {/* <Dialog
        visible={isModalOpen}
        // modal={false}
        showHeader={false}
        style={{ width: "30%" }}
      >
        <div className="signature-modal">
          <div className="signature-modal-heading">
            <h3>Attachments</h3>
          </div>
          <div className="signature-modal-content">
            <Signature imageUrl={imageUrl} />
          </div>
        </div>
      </Dialog> */}
        </div >
      )
      }
    </>
  );
}

export default UserRegistration;
