import Axios from "../Helpers/Axios"

export function GetFeedbackDepartmentByFeedbackId(id, token) {
    return Axios(`GuestServiceStaff/GetFeedbackDepartmentByFeedbackId?feedbackId=${id}`, "get", null, token)
}
export function GetStatusForFeedback(id, token) {
    return Axios(`GuestServiceStaff/GetStatusForFeedback?feedbackId=${id}`, 'get', null, token)
}
export function UpdateFeedback({ feedbackId, statusId, departmentId }, token) {
    return Axios(`GuestServiceStaff/UpdateFeedback?feedbackId=${feedbackId}&statusId=${statusId}&departmentId=${departmentId}`, 'patch', null, token)
}
export function getDepartmentslist(token) {
    return Axios('Master/Department', 'get', null, token)
}
// GuestStaffSupervisor/EditFeedback?feedbackId=2&statusId=1