import style from "./style.module.scss";
import React, { useEffect, useState } from "react";
import UseCookies from "../../Helpers/UseCookies";
// -------------Imports---------------
// import { Button } from 'primereact/button'
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { CiImageOn } from "react-icons/ci";
import CustomWebcam from "../Camera/Camera";
import "./DepartmentStaffView.scss";
// -----------------------------------

// -----------------------------------

//------Imports from other Components-----
import {
    GetChatList,
    GetFeedbackById,
    GetFeedbackList,
} from "../../Services/FeedbackService";
import { NewChat } from "../../Services/FeedbackService";
//----------------------------------------

//-----------Context ---------------
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import {
    GetFeedbackDepartmentByFeedbackId,
    GetStatusForFeedback,
    UpdateFeedback,
} from "../../Services/GuestSatffInspection";
import { BulkUploadBase64Files, GetAllFiles, UploadBase64Files } from "../../Services/Files";
import { EditFeedback } from "../../Services/DepartmentTeamService";

import { DownloadFile } from "../../Services/Files";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { toast } from "react-toastify";

function DepartmentStaffView() {
    const { id, status } = useParams();
    const Context = useSelector((state) => state.AppContext);
    const [isAddOrganization, setIsAddOrganization] = useState(false);
    const [selectedFeedback, setSelectedFeedback] = useState([]);
    const [chatList, setChatList] = useState([]);
    const [chat, setChat] = useState("");
    const [cookies, setCookie, removeCookie] = UseCookies();
    const [chatError, setChatError] = useState("");
    const navigate = useNavigate();
    const [feedbackData, setFeedbackData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [departmentDetails, setDepartmentDetails] = useState({});
    const [statusList, setstatusList] = useState([
        {
            id: 0,
            name: "",
        },
    ]);
    const [isInvalid, setIsInvalid] = useState(false);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [isCommentsValid, setIsCommentsValid] = useState(false);
    const [value, setValue] = useState({ comments: "", attachments: [] });
    const [imgSrc, setImgSrc] = useState("");
    const [fileList, setFileList] = useState([]);
    const [activeStatus, setActiveStatus] = useState({});
    const formatDate = (timestamp) => {
        const timestampDate = new Date(timestamp);

        return timestampDate.toLocaleString("en-US", {
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        });
    };



    const checkStatus = () => {
        return (
            feedbackData[0]?.status === "Assigned" ||
            feedbackData[0]?.status === "Reassign"
        );
    };
    const sendChat = async (value) => {
        if (!value.trim()) {
            // setChatError("No messages found")
            return;
        }
        const data = {
            feedbackId: id,
            message: value,
        };
        NewChat(data, cookies().bearerToken)
            .then((res) => {
                setChatList((prev) => {
                    return [
                        ...prev,
                        {
                            id: -1,
                            messageFrom: localStorage.getItem("userName"),
                            message: chat.trim(),
                            messageDate: new Date()?.toString(),
                        },
                    ];
                });
                setChat("");
                // dispatch(
                //     addChatList({
                //         chatList: res?.data
                //     }))
            })
            .catch((error) => {
                //console.log(error);
            })
            .finally(() => {
                // //console.log(chatList);
            });
    };

    const closePopup = () => {
        setIsAddOrganization(false);
        navigate("/departmentteam/feedback");
    };

    const header = () => {
        return (
            <div
                className="heading"
                style={{ fontWeight: "700", fontSize: "25px", color: "#ae9d81" }}
            ></div>
        );
    };
    const getChatList = () => {
        setIsLoading(true);
        GetChatList(cookies().bearerToken, id)
            .then((res) => {
                if (res?.status == 200) {
                    setChatList(res?.data);
                }
            })
            .catch((error) => {
                //console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    const getFeedbackDepartmentByFeedbackId = async () => {
        await GetFeedbackDepartmentByFeedbackId(id, cookies().bearerToken).then(
            (res) => {
                if (res?.status == 200) {
                    setDepartmentDetails(res?.data);
                    if ([res?.data?.length === 1]) {
                        setDepartmentDetails(res?.data);
                    }
                }
            }
        );
    };
    const getStatusForFeedback = async () => {
        await GetStatusForFeedback(id, cookies()?.bearerToken).then((res) => {
            if (res?.status == 200) {
                setstatusList(res?.data);
            }
        });
    };
    const getAllFiles = async () => {
        await GetAllFiles(
            { id: id, inspectionId: "0", isForInspection: false, module: "Feedback" },
            cookies()?.bearerToken
        ).then((res) => {
            if (res?.status == 200) {
                setFileList(res?.data);
            }
        });
    };
    // //console.log(departmentDetails, "departmentDetails");
    // const updateFeedback = async () => {
    //     await UpdateFeedback({statusId: activeStatus?.id, feedbackId: id }, cookies()?.bearerToken).then((res) => {
    //         if (res?.status == 200) {
    //             //console.log("updated");
    //         }
    //     })
    // }

    const handleFeedbackSubmit = () => {
        if (value?.comments == "") {
            setIsCommentsValid(true);
            return;
        }
        setIsCommentsValid(false);
        editFeedback();
    }

    const AddMultipleAttachment = async (id, files = []) => {
        let date = new Date().getFullYear() + new Date().getMonth();
        files?.forEach(async (file, index) => {
            let data = {
                "id": id,
                "module": "Feedback",
                "inspectionId": 0,
                "files": [
                    {
                        "fileName": date + file?.name,
                        "base64": file?.base64
                    }
                ]
            }
            console.log("Attachment Data", data);
            await BulkUploadBase64Files(data, cookies().bearerToken)
                .then((res) => {
                    if (res.status == 200) {
                        console.log(res);
                        // toast.success("Attachments uploaded successfully", { position: "top-right" });
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                })
        })
    };
    const editFeedback = async () => {
        if (Object.keys(activeStatus).length == 0) {
            setIsInvalid(true);
            return;
        }
        setIsInvalid(false);
        setIsLoading(true);
        if (activeStatus?.id == "5") {
            await EditFeedback(
                { feedbackId: id, statusId: activeStatus?.id },
                cookies()?.bearerToken
            ).then(async (res) => {
                if (res?.status == 200) {
                    let attachment = [...value?.attachments]
                    if (imgSrc) {
                        attachment.push({
                            name: imgSrc.split(";base64,")[0].split("/")[1],
                            base64: imgSrc?.split(',')[1],
                            type: "image"
                        })
                    }
                    await AddMultipleAttachment(id, attachment);
                    await sendChat(value?.comments);
                    navigate("/departmentteam/feedback");
                }
            })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    setIsLoading(false);
                    setValue({ comments: "", attachments: [] })
                })

        }
        else {
            await EditFeedback(
                { feedbackId: id, statusId: activeStatus?.id },
                cookies()?.bearerToken
            ).then((res) => {
                if (res?.status == 200) {
                    navigate("/departmentteam/feedback");
                }
                if (res?.response?.status == 400) {
                    toast.error(res?.response?.data || "Feedback status cannot be updated", { position: "top-right" });
                }
            })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    setIsLoading(false);
                })
        }
    };


    useEffect(() => {
        getChatList();
        getFeedbackDepartmentByFeedbackId();
        getStatusForFeedback();
        getAllFiles();
    }, []);

    const downloadFile = async (filename, filePath) => {
        DownloadFile(filename, filePath, cookies()?.bearerToken);
    };

    const getFeedbackById = async (feedbackId) => {
        await GetFeedbackById(cookies().bearerToken, feedbackId)
            .then((res) => {
                if (res?.status === 200) {
                    setFeedbackData(res?.data);
                    // setFeedbackData(Context.feedbackList?.filter((_) => _?.id == id));
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getFeedbackById(id);

    }, []);

    const mergeAttachment = () => {
        if (imgSrc !== "") {
            setValue({
                ...value,
                attachments: [
                    ...value.attachments,
                    {
                        name:
                            "Image" +
                            (value.attachments.length + 1) +
                            "." +
                            imgSrc.split(";base64,")[0].split("/")[1],
                    },
                ],
            });
            setImgSrc("");
        }
    };

    //   const onUpload = (e) => {
    //     console.log(e);
    //     if (value.attachments.length > 0) {
    //       let mergeAttachment = value.attachments.concat([...e]);
    //       setValue({ ...value, attachments: [...mergeAttachment] });
    //     } else {
    //       setValue({ ...value, attachments: [...e] });
    //     }
    //     e = "";
    //   };
    // console.log(imgSrc, "imgsrc");
    const onUpload = (e) => {
        if (e.files.length == 0) {
            return;
        }
        console.log(e.files[0]?.objectURL, "fileeeee", e.files[0]);
        if (value.attachments.length > 0) {
            let mergeAttachment = value.attachments.concat([...e?.files]);
            setValue({ ...value, attachments: [...mergeAttachment] });
        } else {
            setValue({ ...value, attachments: [...e?.files] });
        }

        e.files.pop();
    };
    const handleFileChange = (event) => {
        const files = event.files;
        if (files.length > 0) {
            files.forEach(file => {
                console.log("File", file);
                const reader = new FileReader();
                reader.onloadend = () => {
                    if (reader.result) {
                        const base64String = reader.result.split(',')[1];
                        setValue((prev) => ({
                            ...prev, attachments: [...prev?.attachments, {
                                name: file?.name,
                                base64: base64String
                            }]
                        }))
                    } else {
                        console.error('Failed to convert file to base64');
                    }
                };
                reader.onerror = () => {
                    console.error('FileReader encountered an error');
                };
                reader.readAsDataURL(file);
                files.pop();
                console.log(files.length);
            })
        }
    }
    const onRemoveImage = (label, index) => {
        if (label == "file") {
            // setValue((prev) => ({ ...prev, attachments: value?.attachments?.filter(_ => _?.name !== fileName) }))
            // remove a particular array element with its index from the array
            setValue((prev) => ({ ...prev, attachments: value?.attachments?.filter((_, i) => i !== index) }))
        } else {
            setImgSrc("");
        }

    }
    return (
        <div>
            <Dialog
                header="Department User Review "
                visible={dialogVisible}
                style={{ width: "50vw" }}
                onHide={() => {
                    setDialogVisible(false);
                }}
            >
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div style={{ width: "100%" }}>
                        <label htmlFor="comments" className="font-bold block mb-2">
                            Comments
                            <span style={{ color: 'red' }}>*</span>
                        </label>
                        <InputTextarea
                            style={{ width: "100%" }}
                            id="comments"
                            autoResize
                            value={value.comments}
                            onChange={(e) => setValue({ ...value, comments: e.target.value })}
                            rows={3}
                        //   cols={30}
                        />
                        {isCommentsValid && value?.comments == "" &&
                            <p style={{ color: 'red' }}>Comments is required</p>}
                    </div>
                    <div className="departmentComments" style={{ width: "100%" }}>
                        <label htmlFor="comments" className="font-bold block mb-2">
                            Attachments
                        </label>
                        <div style={{ display: "flex" }}>
                            <i
                                className="pi pi-camera"
                                style={{ color: "#ae9d81" }}
                                onClick={() => {
                                    setIsVisible(true);
                                }}
                            ></i>
                            <FileUpload mode='basic' accept="*" multiple={false} maxFileSize={1000000} customUpload uploadHandler={handleFileChange} className="px-4" />
                        </div>
                        {isVisible && (
                            <CustomWebcam
                                setIsVisible={setIsVisible}
                                setImage={setImgSrc}
                                setAttachments={setValue}
                                value={value}
                                files={value}
                                isMultiplePhotos={true}
                            />
                        )}
                    </div>
                    <div style={{ width: "100%" }}>
                        {value.attachments.length > 0
                            ? value.attachments.map((file, index) => {
                                return (
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <i className="pi pi-file px-1" style={{ fontSize: 14 }}></i>
                                        <span style={{ width: "50%" }} key={index}>
                                            {file.name}
                                        </span>
                                        <i className="pi pi-times px-1" style={{ fontSize: 14 }} onClick={() => onRemoveImage("file", index)}></i>
                                    </div>
                                );
                            })
                            : null}
                        {imgSrc ? (
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <i className="pi pi-file px-1" style={{ fontSize: 14 }}></i>
                                <span style={{ width: "50%" }}>
                                    Image{value.attachments.length + 1}.
                                    {imgSrc.split(";base64,")[0].split("/")[1]}
                                </span>
                                <i className="pi pi-times px-1" style={{ fontSize: 14 }} onClick={() => onRemoveImage("camera", "")}></i>
                            </div>
                        ) : null}
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "25px",
                        }}
                    >
                        <Button
                            style={{
                                cursor: "pointer", backgroundColor: "#8C8C8C",
                                color: "white",
                                margin: "0px 6px",
                                width: "100px",
                                // height: "30px",
                                border: "none",
                                borderRadius: "5px",
                                fontSize: "15px"
                            }}
                            disabled={isLoading}
                            onClick={() => {
                                setValue({ comments: "", attachments: [] });
                                setDialogVisible(false);
                                setIsCommentsValid(false);
                            }}
                        >
                            Cancel
                        </Button>

                        <Button
                            loading={isLoading}
                            style={{
                                backgroundColor: "#AE9D81",
                                color: "white",
                                margin: "0px 6px",
                                width: "100px",
                                // height: "30px",
                                border: "none",
                                borderRadius: "5px",
                                fontSize: "15px", cursor: "pointer"
                            }}
                            onClick={() => { handleFeedbackSubmit() }}
                        >  Submit
                        </Button>
                    </div>
                </div>
            </Dialog >
            <div
                header={header}
                visible={true}
                style={{}}
                onHide={() => {
                    setIsAddOrganization(false);
                }}
            >
                <div className={style.head}>
                    <FontAwesomeIcon
                        icon={faArrowLeft}
                        className={style.arrowIcon}
                        onClick={closePopup}
                    />
                    Edit Feedback Details
                </div>
                <div
                    className={classNames(style.flex)}
                    style={{ padding: "0 20px", flexWrap: "wrap" }}
                >
                    <div
                        className="flex-1"
                    // p-4 flexbox-1"
                    >
                        <div className="defaultWidth">
                            <p className={style.defaultStyle}>Name{Context.name}</p>
                            <InputText
                                className={style.border}
                                value={`${feedbackData[0]?.userName}`}
                                readOnly
                            />
                        </div>
                        <div className="defaultWidth">
                            <p className={style.defaultStyle}>Mobile number</p>
                            <InputText
                                className={style.border}
                                value={feedbackData[0]?.mobileNumber}
                                readOnly
                            />
                        </div>
                        <div className="defaultWidth">
                            <p className={style.defaultStyle}>Email</p>
                            <InputText
                                className={style.border}
                                value={feedbackData[0]?.email}
                                readOnly
                            />
                        </div>
                        <div className="defaultWidth">
                            <p className={style.defaultStyle}>Nationality</p>
                            <InputText
                                className={style.border}
                                value={feedbackData[0]?.nationality}
                            />
                        </div>
                        <div className="defaultWidth">
                            <p className={style.defaultStyle}>Attachments</p>
                            <div
                                style={{
                                    height: "100px",
                                    overflow: "auto",
                                    width: "350px",
                                    border: "solid 1px #AE9D81",
                                    borderRadius: "4px",
                                    padding: "10px",
                                }}
                            >
                                {fileList?.map((_) => {
                                    return (
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <CiImageOn style={{ fontSize: 20 }} />
                                            <p
                                                style={{
                                                    fontSize: "12px",
                                                    padding: "0 10px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => downloadFile(_?.fileName, _?.blobUrl)}
                                            >
                                                {_.fileName}
                                            </p>
                                        </div>
                                    );
                                })}
                            </div>
                            {/* <InputText className={classNames(style.border, style.attachment)} /> */}
                        </div>
                        {/* <p className={style.heading}>Guest Service Details</p> */}
                        <div className="defaultWidth">
                            <p className={style.defaultStyle}>Department</p>
                            <Dropdown
                                placeholder="Select Department"
                                optionLabel="departmentName"
                                optionValue="departmentName"
                                options={[departmentDetails]}
                                disabled={true}
                                className={style.border}
                                value={departmentDetails?.departmentName}
                            />
                        </div>
                    </div>
                    <div
                        className="flex-1"
                    //  p-4 flexbox-2"
                    >
                        <div className="defaultWidth">
                            <p className={style.defaultStyle}>Category</p>
                            <InputText
                                className={style.border}
                                value={feedbackData[0]?.category}
                                readOnly
                            />
                        </div>
                        <div className="defaultWidth">
                            <p className={style.defaultStyle}>Sub Category</p>
                            <InputText
                                className={style.border}
                                value={feedbackData[0]?.subCategory}
                                readOnly
                            />
                        </div>
                        <div className="defaultWidth">
                            <p className={style.defaultStyle}>Location</p>
                            <InputText
                                className={style.border}
                                value={feedbackData[0]?.location}
                                readOnly
                            />
                        </div>
                        <div className={style.feedbackBox}>
                            <p className={style.defaultStyle}>Feedback</p>
                            {/* <InputText
                className={classNames(style.border, style.feedback)}
                value={feedbackData[0]?.comment}
                readOnly
              /> */}
                            <p
                                className={classNames(style.border, style.feedback)}
                                style={{
                                    fontWeight: "100",
                                    border: "1px solid #ae9d81",
                                    width: "350px",
                                    padding: "10px",
                                    borderRadius: "5px",
                                    wordWrap: "break-word",
                                    overflow: "scroll",
                                    overflowX: "hidden",
                                    marginTop: "0px",
                                }}
                            >
                                {feedbackData[0]?.comment}
                            </p>
                        </div>
                        <div
                            className={classNames(style.defaultwidth, style.status)}
                            style={{ marginTop: "25px" }}
                        >
                            <p className={style.defaultStyle}>Status</p>
                            {checkStatus() ? (
                                <Dropdown
                                    placeholder="Select status"
                                    options={statusList}
                                    className={style.border}
                                    onChange={(e) => {
                                        setActiveStatus(e.value);
                                    }}
                                    optionLabel="name"
                                    value={activeStatus}
                                />
                            ) : (
                                <InputText
                                    className={classNames(style.border)}
                                    disabled
                                    value={feedbackData[0]?.status}
                                />
                            )}
                            {
                                isInvalid && Object.keys(activeStatus).length == 0 && (
                                    // <div>
                                    <p style={{ color: "red" }}>Status is required</p>
                                )
                                // </div>
                            }
                        </div>
                        <div
                            className="flex justify-content-flex-end"
                            style={{ marginTop: "20px", justifyContent: "flex-end" }}
                        >
                            <button
                                className={style.cancelBtn}
                                style={{ cursor: "pointer" }}
                                onClick={closePopup}
                            >
                                Cancel
                            </button>
                            {checkStatus() ? (
                                <button
                                    className={style.submitBtn}
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                        activeStatus.name === "Resolved"
                                            ? setDialogVisible(true)
                                            : editFeedback()
                                    }
                                >
                                    Submit
                                </button>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                    {window.innerWidth >= 1110 && <div className="vl"></div>}{" "}
                    <div
                        className="flex-1"
                        style={{
                            // width: "30%",
                            margin: "5px",
                            // height: "600px",
                            // overflowY: "auto",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                padding: "6px 10px",
                                backgroundColor: "#ae9d81",
                                margin: "10px 10px",
                                justifyContent: "center",
                            }}
                        >
                            <p style={{ color: "#fff", margin: 0 }}>Chat Box</p>
                        </div>
                        <div
                            style={{ margin: "5px", display: "flex", alignItems: "center" }}
                        >
                            <InputTextarea
                                // type="text"
                                rows={2}
                                value={chat}
                                className="p-inputtext-lg"
                                style={{
                                    width: "88%",
                                    height: "40px",
                                    fontSize: "15px",
                                    marginLeft: "5px",
                                    overflowWrap: "break-word",
                                    overflow: "scroll",
                                    overflowX: "hidden",
                                    overflowY: "hidden",
                                }}
                                placeholder="Comments"
                                onChange={(e) => {
                                    setChat(e.target.value);
                                }}
                            />
                            <i
                                className="pi pi-send"
                                style={{
                                    fontSize: "1.5rem",
                                    color: "#AE9D81",
                                    margin: "10px",
                                    transform: "rotateZ(45deg)",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    sendChat(chat);
                                }}
                            ></i>
                        </div>
                        {isLoading ? (
                            <div
                                className="spinner spinner-center"
                                style={{ marginLeft: "200px", width: "30px", height: "30px" }}
                            ></div>
                        ) : (
                            <div style={{ overflow: "scroll", height: "700px" }}>
                                {chatList?.length !== 0 ? (
                                    <div div className="flex-1 p-4 flexbox-3">
                                        <div style={{ overflow: "auto" }}>
                                            {chatList.map((chat, index) => (
                                                <div
                                                    key={chat.id}
                                                    className={
                                                        localStorage.getItem("userName") ===
                                                            chat.messageFrom
                                                            ? "user2"
                                                            : "user"
                                                    }
                                                >
                                                    <div
                                                        style={{
                                                            fontWeight: 200,
                                                            fontSize: "15px",
                                                            margin: "0px 0px 5px 0px",
                                                        }}
                                                    >
                                                        {chat?.messageFrom}
                                                    </div>
                                                    <p
                                                        style={{
                                                            fontWeight: 400,
                                                            margin: "0px",
                                                            wordWrap: "break-word",
                                                            width: "250px",
                                                        }}
                                                    >
                                                        {chat?.message}
                                                    </p>

                                                    <span
                                                        style={{
                                                            fontWeight: 200,
                                                            fontSize: "15px",
                                                            margin: "0px 0px -5px 0px",
                                                        }}
                                                    ></span>
                                                    <span
                                                        style={{
                                                            fontWeight: 200,
                                                            fontSize: "15px",
                                                            margin: "0px 0px -5px 0px",
                                                            float: "right",
                                                        }}
                                                    >
                                                        {formatDate(chat.messageDate)}
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <div style={{ padding: "100px 0" }}>
                                            <p style={{ textAlign: "center" }}>No Chats Found</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    {/* {chatError} */}
                </div>
            </div>
        </div >
    );
}
export default DepartmentStaffView;
