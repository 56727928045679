import React, { useState, useEffect } from 'react';
import UseCookies from '../../Helpers/UseCookies';
import './style.scss'

// -------------Prime Recat Imports---------------
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Tag } from 'primereact/tag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faL } from '@fortawesome/free-solid-svg-icons';

import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { Paginator } from 'primereact/paginator';




// -----------------------------------


// ----------Assets Import------------
import editIcon from '../../../src/assets/images/editIcon.jpg';
// -----------------------------------


//------Imports from other Components-----
import { AddOrganization, GetOrganizationList, EditOrganization, DeleteOrganization, exportOrganization } from '../../Services/OrganizationService';
//----------------------------------------


//-----------Context ---------------
import { useDispatch, useSelector } from "react-redux";
import { addOrganizationList, setNavVisibilty } from '../../Redux/AppSlice';
import { exportDataInExcel } from '../../Services/ExportServices';
import { GetFeedbackCategoryList } from '../../Services/FeedbackCategoryServices';
import { GetFeedbackDepartmentCategoryList, GetActiveDepartments, AddFeedbackDepartmentCategory, EditFeedbackDepartmentCategory } from '../../Services/FeedbackDepartmentCategoryServices';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
//----------------------------------------

function FeedbackDepartmentCategory() {
    const Context = useSelector((state) => state.AppContext);
    const dispatch = useDispatch();
    const [cookies, setCookie, removeCookie] = UseCookies();
    const [isAddFeedbackDepartmentCategory, setIsAddFeedbackDepartmentCategory] = useState(false);
    const [feedbackDepartmentCategoryList, setFeedbackDepartmentCategoryList] = useState("");
    const [activeDepartments, setActiveDepartments] = useState("");
    const [feedbackDepartmentCategory, setFeedbackDepartmentCategory] = useState({
        "id": 0, "departmentId": 0, "departmentName": "",
        "headName": "", "email": "", "isDeleted": false, "status": true
    })
    const [feedbackDepatmentCategoryExists, setFeedbackDepatmentCategoryExists] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const navigate = useNavigate();
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [isFeedbackDepartmentCategoryInvalid, setIsFeedbackDepartmentCategoryInvalid] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isEmailValid, setIsEmailValid] = useState(true);
    // Pagination
    const [first, setFirst] = useState(0)
    const [totalCount, setTotalRecords] = useState(0)

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        // name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        // userName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const header = () => {
        return <div className="heading" style={{ fontWeight: "700", fontSize: "23px", color: "#ae9d81", textAlign: 'center' }}>
            <p style={{ margin: '0px' }}>{isAdd ? "Add" : "Edit"} Feedback Department Category Details</p>
        </div>
    }

    const IsEmailValid = (email) => {
        let isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        setIsEmailValid(isValid);
        console.log(isValid);
        return isValid
    }
    const popup = (() => {
        return (
            <>
                <Dialog header={header} visible={isAddFeedbackDepartmentCategory} style={{ width: '50vw' }}
                    closable={false} onHide={() => {
                        setIsAddFeedbackDepartmentCategory(false); setFeedbackDepartmentCategory({
                            "id": 0, "departmentId": 0, "departmentName": "",
                            "headName": "", "email": "", "isDeleted": true, "status": true
                        });
                    }}>
                    <div className="popup " >
                        {/* <div className="heading">
                            <p>Add New Details</p>
                        </div> */}
                        <div className="body"  >
                            <div className="label grid" >
                                <div className='col' >Department Head Name<span style={{ color: 'red' }}>*</span></div>
                                <div className='col' >Department Email<span style={{ color: 'red' }}>*</span></div>
                            </div>
                            <div className="input grid ">
                                <div className="col">
                                    <InputText placeholder="Enter Head Name" value={feedbackDepartmentCategory?.headName} onChange={(e) => { setFeedbackDepatmentCategoryExists(false); setFeedbackDepartmentCategory((prev) => ({ ...prev, headName: e.target.value })) }} />
                                    {isFeedbackDepartmentCategoryInvalid && feedbackDepartmentCategory?.headName == "" &&
                                        // <div>
                                        <p style={{ color: "red" }}>Department head name is required</p>
                                        // </div>
                                    }
                                </div>
                                <div className="col">
                                    <InputText placeholder="Enter Department Email" value={feedbackDepartmentCategory?.email} onChange={(e) => { IsEmailValid(e.target.value); setFeedbackDepatmentCategoryExists(false); setFeedbackDepartmentCategory((prev) => ({ ...prev, email: e.target.value })) }} />
                                    {isFeedbackDepartmentCategoryInvalid && feedbackDepartmentCategory?.email == "" &&
                                        // <div>
                                        <p style={{ color: "red" }}>Department email is required</p>
                                        // </div>
                                    }
                                    {isFeedbackDepartmentCategoryInvalid && feedbackDepartmentCategory?.email && !isEmailValid &&
                                        // <div>
                                        <p style={{ color: "red" }}>Department email is required</p>
                                        // </div>
                                    }
                                </div>
                            </div>
                            <div className="label grid" >
                                <div className='col' >Department<span style={{ color: 'red' }}>*</span></div>
                                <div className='col' >Status</div>
                            </div>
                            <div className="input grid ">
                                <div className="col">
                                    <Dropdown placeholder="Select Department" options={activeDepartments} optionLabel='name' optionValue='id' value={feedbackDepartmentCategory?.departmentId} onChange={(e) => { setFeedbackDepatmentCategoryExists(false); setFeedbackDepartmentCategory((prev) => ({ ...prev, departmentId: e.value })) }} />
                                    {isFeedbackDepartmentCategoryInvalid && feedbackDepartmentCategory?.departmentId == "" &&
                                        // <div>
                                        <p style={{ color: "red" }}>Department is required</p>
                                        // </div>
                                    }
                                    {feedbackDepatmentCategoryExists &&
                                        // <div>
                                        <p style={{ color: "red" }}>Department is already exists</p>
                                        // </div>
                                    }
                                </div>

                                <div className="col ">
                                    <label className="switch ">
                                        <input type="checkbox" checked={feedbackDepartmentCategory?.status} onChange={(e) => { setFeedbackDepartmentCategory((prev) => ({ ...prev, status: e.target.checked })); }} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>

                            </div>
                        </div>
                        <div className="footer flex  grid">
                            <div className="col flex justify-content-center ">
                                <Button label="Cancel"
                                    onClick={() => {
                                        setFeedbackDepatmentCategoryExists(false);
                                        setIsFeedbackDepartmentCategoryInvalid(false);
                                        setIsAddFeedbackDepartmentCategory(false);
                                        setFeedbackDepartmentCategory({
                                            "id": 0, "departmentId": 0, "departmentName": "",
                                            "headName": "", "email": "", "isDeleted": true, "status": true
                                        });
                                    }}
                                    style={{ backgroundColor: '#8E8E8E', border: "none", margin: "10px", height: '40px' }} />
                                <Button label={isAdd ? "Submit" : "Update"} onClick={() => { isAdd ? addFeedbackDepartmentCategory() : editFeedbackDepartmentCategory() }} style={{ backgroundColor: '#ae9d81', border: "none", margin: "10px", height: '40px' }} />
                            </div>
                        </div>
                    </div>
                </Dialog>

            </>
        )
    })
    const getList = (async () => {
        setIsLoading(true);
        await GetFeedbackDepartmentCategoryList(cookies().bearerToken)
            .then((res) => {
                if (res?.status === 200) {
                    setFeedbackDepartmentCategoryList(res?.data?.map((_) => {
                        return {
                            "id": _?.id,
                            "departmentId": _?.departmentId,
                            "departmentName": _?.departmentName,
                            "headName": _?.headName,
                            "email": _?.email,
                            "isDeleted": _?.isDeleted,
                            "status": _?.isDeleted ? "Inactive" : "Active"
                        }
                    }
                    ));
                }
            })
            .catch((error) => {
                //console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
                // //console.log(feedbackDepartmentCategoryList);
            })
    });

    const getActiveDepartments = (async () => {
        await GetActiveDepartments(cookies().bearerToken)
            .then((res) => {
                if (res?.status === 200) {
                    setActiveDepartments(res?.data)
                }
            })
            .catch((error) => {
                //console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
                // //console.log(feedbackDepartmentCategoryList);
            })
    });


    const addFeedbackDepartmentCategory = (async () => {
        if (feedbackDepartmentCategory?.headName == "" ||
            feedbackDepartmentCategory?.email == "" ||
            feedbackDepartmentCategory?.departmentId == 0 ||
            !IsEmailValid(feedbackDepartmentCategory?.email)
        ) {
            setIsFeedbackDepartmentCategoryInvalid(true);
            return;
        }
        setIsFeedbackDepartmentCategoryInvalid(false);
        let data = {
            "departmentId": feedbackDepartmentCategory?.departmentId,
            "headName": feedbackDepartmentCategory?.headName,
            "email": feedbackDepartmentCategory?.email,
            "isDeleted": !feedbackDepartmentCategory?.status,
        };
        let isExist = feedbackDepartmentCategoryList.filter((dep) => dep?.departmentId == feedbackDepartmentCategory?.departmentId)
        if (isExist.length > 0) {
            setFeedbackDepatmentCategoryExists(true);
            toast.error(`Details for ${isExist[0].departmentName} already exists`, { position: "top-right" });
            return;
        }
        console.log(data);
        setFeedbackDepatmentCategoryExists(false);
        setIsAdd(true);
        let token = cookies()?.bearerToken
        setIsAddFeedbackDepartmentCategory(false);
        await AddFeedbackDepartmentCategory(data, token)
            .then((res) => {
                //console.log(res);
                if (res?.status === 200) {
                    getList();
                    setFeedbackDepartmentCategory({
                        "id": 0, "departmentId": 0, "departmentName": "",
                        "headName": "", "email": "", "isDeleted": true, "status": true
                    });
                    toast.success("New feedback department category is added successfully", { position: "top-right" });
                }
                else {
                    setFeedbackDepartmentCategory({
                        "id": 0, "departmentId": 0, "departmentName": "",
                        "headName": "", "email": "", "isDeleted": true, "status": true
                    });
                    // if (res?.response?.data === "Violation of UNIQUE KEY constraint 'Unique_Org_Name'. Cannot insert duplicate key in object 'dbo.OrganizationMaster'. The duplicate key value is (Marassi).") {
                    //     toast.error("Organization name already exists", { position: "top-right" });
                    // }
                    // else {
                    toast.error(res?.response?.data, { position: "top-right" });
                    // }

                }
                setIsAdd(false);
                setIsAddFeedbackDepartmentCategory(false);
            })
            .catch((error) => {
                //console.log(error);
            })
    })

    const editFeedbackDepartmentCategory = (() => {
        if (feedbackDepartmentCategory?.headName == "" ||
            feedbackDepartmentCategory?.email == "" ||
            feedbackDepartmentCategory?.departmentId == 0 ||
            !IsEmailValid(feedbackDepartmentCategory?.email)
        ) {
            setIsFeedbackDepartmentCategoryInvalid(true);
            return;
        }
        setIsFeedbackDepartmentCategoryInvalid(false);
        let data = {
            "departmentId": feedbackDepartmentCategory?.departmentId,
            "headName": feedbackDepartmentCategory?.headName,
            "email": feedbackDepartmentCategory?.email,
            "id": feedbackDepartmentCategory?.id,
            "isDeleted": !feedbackDepartmentCategory?.status
        }
        //console.log("Edit Org Data", data);
        console.log(data);
        let isExist = feedbackDepartmentCategoryList.filter((dep) => dep?.departmentId == feedbackDepartmentCategory?.departmentId && dep?.id !== feedbackDepartmentCategory?.id)
        console.log(isExist);
        if (isExist.length > 0) {
            setFeedbackDepatmentCategoryExists(true);
            toast.error(`Details for ${isExist[0].departmentName} already exists`, { position: "top-right" });
            return;
        }
        setFeedbackDepatmentCategoryExists(false);
        let token = cookies()?.bearerToken
        // //console.log(data, token);
        EditFeedbackDepartmentCategory(data, token)
            .then((res) => {
                //console.log(res);
                getList();
                setIsAddFeedbackDepartmentCategory(false);
                setFeedbackDepartmentCategory({
                    "id": 0, "departmentId": 0, "departmentName": "",
                    "headName": "", "email": "", "isDeleted": true, "status": true
                })
                toast.success("Feedback Department Category is updated successfully", { position: "top-right" });

            })
            .catch((error) => {
                //console.log(error);
            })

    });


    const back = () => {
        navigate('/dashboard');
    };




    const statusBodyTemplate = ((row) => {
        // //console.log(row);
        if (row.isDeleted !== true) {
            return <p style={{ color: "#45AA08" }}  >Active</p>
        }
        else {
            return <p style={{ color: "#FF4700" }} >Inactive</p>
        }
    });

    // const actionButtonTemplate = ((row) => {
    //     return <div  onClick={(e,row)=>{//console.log(e.target,row);}} >
    //         <img src={editIcon} style={{cursor:'pointer'}}  /></div>
    // })

    const actionButtonTemplate = (row) => {

        return (
            <div onClick={(e) => {
                setIsAddFeedbackDepartmentCategory(true);
                setIsAdd(false);
                setFeedbackDepartmentCategory((prev) => ({
                    ...prev,
                    "id": row?.id,
                    "departmentId": row?.departmentId,
                    "headName": row?.headName,
                    "email": row?.email,
                    "isDeleted": row?.status,
                    "status": !row?.isDeleted
                }))
                // console.log(row, "row");
            }}>
                <img src={editIcon} style={{ cursor: 'pointer' }} />
            </div>
        );
    };
    const onGlobalFilterChange = (e) => {
        const value = e ? e.target.value : "";
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };




    useEffect(() => {
        setIsLoading(false)
        getList();
        getActiveDepartments();
    }, [])
    const sideNavVisible = (() => {
        //console.log("Side Nav", Context.sideNavVisibility);
        dispatch(
            setNavVisibilty({ sideNavVisibility: true }))
    })
    const getPage = (event) => {

        //console.log("Page change", event);
    }
    const exportOrganization = () => {
        exportDataInExcel('Organization', cookies().bearerToken);
    }

    // Export Filter
    const [exportFilterDialog, setExportFilterDialog] = useState(false);
    const [exportDetails, setExportDetails] = useState({
        startDate: "",
        endDate: "",
        organization: "",
        desk: "",
        status: true
    });

    return (
        <>
            <Dialog header={<h3>Export Filter</h3>} draggable={false} style={{ width: '50vw', height: "max-content" }} closable={true} onHide={() => setExportFilterDialog(false)} visible={exportFilterDialog} >
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="StartDate" className="font-bold block mb-2">Start Date
                        </label>
                        <Calendar
                            id="StartDate"
                            style={{ width: "95%" }}
                            value={exportDetails?.startDate}
                            placeholder="Choose start end"
                            onChange={((value) => setExportDetails(() => ({ ...exportDetails, startDate: value })))}
                        ></Calendar>
                    </div>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="EndDate" className="font-bold block mb-2">End Date
                        </label>
                        <Calendar
                            id="EndDate"
                            style={{ width: "95%" }}
                            value={exportDetails?.endDate}
                            placeholder="Choose end date"
                            onChange={((value) => setExportDetails(() => ({ ...exportDetails, endDate: value })))}
                        ></Calendar>
                    </div>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="organization" className="font-bold block mb-2">Organization
                        </label>
                        <InputText
                            id="organization"
                            style={{ width: "95%" }}
                            value={exportDetails?.organization}
                            onChange={((value) => setExportDetails(() => ({ ...exportDetails, organization: value })))}
                            placeholder="Enter organization" ></InputText>
                    </div>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="Status" className="font-bold block mb-2">Status
                        </label>
                        {/* <InputText
                        id="Status"
                        style={{ width: "95%" }}
                        value={exportDetails?.status}

                        placeholder="Enter organization" ></InputText> */}
                        <div className="input grid ">
                            <div className="col ">
                                <label className="switch ">
                                    <input type="checkbox" value={exportDetails?.status} checked={exportDetails?.status} onChange={((e) => setExportDetails(() => ({ ...exportDetails, status: e.target.checked })))} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>

                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "25px",
                    }}
                >
                    <Button
                        label="Submit"
                        onClick={''}
                        // icon={"pi pi-file-export"}
                        style={{
                            backgroundColor: " #ae9d81",
                            color: "#fff",
                            border: "none",
                            // borderRadius: "20px",
                            marginRight: "0",
                            cursor: "pointer",
                            padding: "0.6rem",
                        }}
                    />
                </div>


            </Dialog>
            <div className="feedbackdepartmentcategory" >
                {/* <ToastContainer /> */}
                <div className="header">
                    <div style={{ display: "flex", flexDirection: "row", flexGrow: "3", justifyContent: "flex-start" }} className='flex align-items-center org'>
                        <i className="pi pi-arrow-circle-left nav-back-btn" onClick={back} style={{ marginLeft: '30px', cursor: 'pointer', fontSize: '1.5rem' }} />
                        <div className="heading">
                            <p>Feedback Department Category</p>
                        </div>
                    </div>
                    <div className='flex align-items-center'>
                        <div className='mx-2'>
                            <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Status</h4>
                            <div className="search">
                                <span className="p-input-icon-left p-input-icon-right">
                                    <i className="pi pi-search" style={{ color: 'black', fontWeight: '700', fontSize: '1rem' }} />
                                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                                    <i className="pi pi-times" style={{ color: 'black', fontWeight: '400' }} onClick={() => { setGlobalFilterValue(""); onGlobalFilterChange() }} />
                                </span>
                            </div>
                        </div>
                        <div className='mx-2 align-self-end'>
                            <Button label="Add New" onClick={() => { setIsAdd(true); setIsAddFeedbackDepartmentCategory(true) }} style={{ backgroundColor: '#ae9d81', border: 'none' }} />
                        </div>
                    </div>
                </div>

                <div className="datatable">
                    {isLoading ?
                        <div className='spinner spinner-center'></div>
                        : <DataTable
                            value={feedbackDepartmentCategoryList}
                            rows={10}
                            paginator
                            filters={filters}
                            tableStyle={{
                                width: '90rem',
                            }}
                            style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                            stripedRows
                            emptyMessage={<><p style={{ textAlign: "center" }}>Feedback department category list is empty</p></>}
                            globalFilterFields={['headName', 'departmentName', 'email', "status"]}>

                            <Column field="headName" header="Department Head Name"></Column>
                            <Column field="departmentName" header="Department"></Column>
                            <Column field="email" header="Department Email"></Column>
                            <Column field="status" header="Status" body={statusBodyTemplate} ></Column>
                            <Column rowEditor header="Action" body={actionButtonTemplate}></Column>
                        </DataTable>}
                </div>
            </div>

            <div>
                {isAddFeedbackDepartmentCategory == true ? popup() : ""}
            </div>
        </>
    )
}

export default FeedbackDepartmentCategory