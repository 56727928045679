import { useState } from 'react';
import UseCookies from '../../Helpers/UseCookies';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import './style.scss';
import { Button } from 'primereact/button';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { GetActiveStorageLocation, GetFoundItem, GetStatusForFoundItem, EditFoundItemForGSR } from '../../Services/LostandFoundService';
import { CiImageOn } from 'react-icons/ci';
import { DownloadFile } from '../../Services/Files';


function AdminFoundView() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = UseCookies();
    const [foundItem, setFoundItem] = useState({

        "id": 0,
        "firstName": "",
        "lastName": "",
        "email": "",
        "phoneNumber": "",
        "categoryId": "",
        "category": "",
        "whatDidYouFind": "",
        "foundDateTime": "",
        "whereDidYouFind": "strng",
        "statusId": "",
        "storageLocationId": "",
        "isDeleted": false,
        "files": []
    });
    const [status, setStatus] = useState([]);
    const [storageLocation, setStorageLocation] = useState([]);







    const getFoundItem = async (foundId) => {
        await GetFoundItem(foundId, cookies().bearerToken)
            .then((res) => {
                if (res.status === 200) {
                    //console.log("Found Item", res?.data);
                    setFoundItem(res?.data);
                }
            })
            .catch((err) => {
                //console.log(err);
            })
            .finally(() => {
            })
    };





    const getStatusForFoundItem = async () => {
        await GetStatusForFoundItem(cookies().bearerToken)
            .then((res) => {
                if (res.status === 200) {
                    //console.log("Found Item Status", res?.data);
                    // Set a status list in react state
                    setStatus(res?.data)
                }
            })
            .catch((err) => {
                //console.log(err);
            })
            .finally(() => {
            })
    };

    // Get Storage Location
    const getStorageLocation = async () => {
        await GetActiveStorageLocation(cookies().bearerToken)
            .then((res) => {
                if (res.status === 200) {
                    //console.log("Storage Location", res?.data);
                    setStorageLocation(res?.data);
                }
            })
            .catch((err) => {
                //console.log(err);
            })
            .finally(() => {
            })
    };

    const downloadFile = (_) => {
        DownloadFile(_.fileName, _.blobUrl, cookies()?.bearerToken);
    };


    useEffect(() => {
        getStatusForFoundItem();
        getStorageLocation();
        getFoundItem(id);
    }, [])




    return (
        <div className="EditFoundItem ">
            <div className="header" style={{ backgroundColor: '#ae9d81', color: 'white' }}>
                <i className="pi pi-arrow-left" style={{ fontSize: '1.5rem' }} onClick={() => navigate('/admin-lost-found/found')}></i>
                <div style={{ margin: 'auto', fontWeight: '600', fontSize: '18px' }}>View Found Form</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0px 50px' }}>
                <div>
                    <p>First Name</p>
                    <div>
                        <InputText disabled placeholder='Enter First Name' style={{ width: '350px' }} value={foundItem?.firstName} />
                    </div>
                </div>
                <div>
                    <p>Last Name</p>
                    <div>
                        <InputText disabled placeholder='Enter Last Name' style={{ width: '350px' }} value={foundItem?.lastName} />
                    </div>
                </div>
                <div>
                    <p>Email</p>
                    <div>
                        <InputText disabled placeholder='Enter Email' style={{ width: '350px' }} value={foundItem?.email} />
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 50px 0px 50px' }}>
                <div>
                    <p>Mobile Number</p>
                    <div>
                        <InputText disabled placeholder='Enter Mobile Number' style={{ width: '350px' }} value={foundItem?.phoneNumber} />
                    </div>
                </div>
                <div>
                    <p>Category</p>
                    <div>
                        <InputText disabled placeholder='Select Category' style={{ width: '350px', borderColor: '#ae9d81' }} value={foundItem?.category} />
                    </div>
                </div>
                <div>
                    <p>What did you Found?</p>
                    <div>
                        <InputText disabled placeholder='Enter what did you found' style={{ width: '350px' }} value={foundItem?.whatDidYouFind} />
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 50px 0px 50px' }}>
                <div>
                    <p> When did you Found?(Date)</p>
                    <div>
                        <Calendar disabled placeholder='Select date' className="calendar" style={{ width: '350px' }} showIcon value={new Date(foundItem?.foundDateTime)} maxDate={new Date()} />
                    </div>
                </div>
                <div>
                    <p>Where did you Found?</p>
                    <div>
                        <InputText disabled placeholder='Enter where did you found' style={{ width: '350px', }} value={foundItem?.whereDidYouFind} />
                    </div>
                </div>
                <div>
                    <p>Attachment</p>
                    <div>
                        <div style={{ width: '350px', height: '160px', border: '1px solid #ae9d81', borderRadius: '10px' }} >
                            <div style={{ padding: "10px", overflow: 'scroll', height: '150px   ' }}>
                                {foundItem?.files?.length > 0 ?
                                    (foundItem?.files.map((image) => {
                                        return (
                                            <div style={{ display: "flex", alignItems: "center", marginLeft: '5px', overflowX: 'hidden' }}>
                                                <CiImageOn style={{ fontSize: 20 }} />
                                                <p style={{ fontSize: '12px', padding: "0 10px", cursor: "pointer" }} onClick={() => {
                                                    downloadFile(image);
                                                }}>{image.fileName}</p>
                                            </div>)
                                    })) : (
                                        <p style={{ margin: '5px', fontWeight: 400, }}>No Attachment</p>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ display: 'flex', position: 'absolute', bottom: '130px' }}>
                <div style={{ margin: '0px 50px 0px 50px', }} >
                    <p> Storage Location</p>
                    <div>
                        <Dropdown options={storageLocation} disabled placeholder='Select Storage Location' className="calendar" style={{ width: '350px', borderColor: '#ae9d81' }}
                            optionLabel='name' optionValue='id' value={foundItem?.storageLocationId} onChange={(e) => { setFoundItem({ ...foundItem, storageLocationId: e.value }) }} />
                    </div>

                </div>
                <div style={{ margin: '0px 50px 0px 50px', position: 'absolute', left: '545px' }} >
                    <p> Status</p>
                    <div>
                        <Dropdown options={status} disabled placeholder='Select status' className="calendar" style={{ width: '350px', borderColor: '#ae9d81' }}
                            value={foundItem?.statusId} optionLabel='name' optionValue='id' onChange={(e) => { setFoundItem({ ...foundItem, statusId: e.value }) }} />
                    </div>

                </div>
            </div>

            <div style={{
                display: 'flex', justifyContent: 'end', gap: '20px', margin: "10px 50px 50px 0px"
            }} >
                <Button label='Cancel' className='cnl-btn' onClick={() => { navigate('/admin-lost-found/found') }} />

            </div>

        </div>
    )
}

export default AdminFoundView 
